﻿import * as React from 'react';
import { useEffect, useRef } from "react";
import Unity, { UnityContext } from "react-unity-webgl";
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { useDispatchWithType, useSystemConnection, useUser } from '../store';
import { Box, CircularProgress, CircularProgressProps, Fade, Typography } from '@mui/material';
import { logEvent } from '../components/Analytics';
import { CheckIsMobile } from '../components/Admin/Utils';
import { showModal } from '../store/rootReducer';
import { SystemControlHub } from '../components/react-signalr/systemControlHub';

const gameWidth: number = 960;
const gameHeight: number = 600;
const gameMinWidth: number = 300;
const gameMinHeight: number = 300;

const version = 5.86;
export default function Game() {
    const user = useUser();
    const dispatch = useDispatchWithType();

    const systemConnection = useSystemConnection();
    const hubRef = React.useRef<SystemControlHub>();
    const unityRef = React.useRef<UnityContext>(new UnityContext({
        loaderUrl: `/Unity/Build/WebGL.loader.js?version=${version}`,
        dataUrl: `/Unity/Build/WebGL.data?version=${version}`,
        frameworkUrl: `/Unity/Build/WebGL.framework.js?version=${version}`,
        codeUrl: `/Unity/Build/WebGL.wasm?version=${version}`,
    }));

    const [loadingNum, SetLoadingNum] = React.useState<number>(0);

    useEffect(() => {
        //連上遊戲
        ConnectEvent();

        unityRef.current.on("canvas", function (canvas) {
            canvas.width = gameWidth;
            canvas.height = gameHeight;
            //canvas.width = window.innerWidth;
            //canvas.height = window.innerHeight;
        });
        unityRef.current.on("progress", progression => {
            SetLoadingNum(progression);
        });
        unityRef.current.on("StartChatTo", function (userId: string) {
            hubRef.current?.invoke.OpenChatDialog(userId);
            logEvent('Chat', `Game`, `${userId}`);
        });
        unityRef.current.on("SendExchangeTo", function (userId: string) {
            dispatch(showModal({ modalType: "EXCHANGE_CARD", modalProps: { receiverId: userId } }));
            logEvent('ExchangeCard', `Game`, `${userId}`);
        });
        unityRef.current.on("ServiceBell", function (boothId: string) {
            dispatch(showModal({ modalType: "SERVICE_BELL", modalProps: { boothId: boothId } }));
        });
        unityRef.current.on("UnityToReact", function (msg) {
            var obj = JSON.parse(msg);
            if (obj.sendType == "other") {
                hubRef.current?.send.SendGameMessage(msg);
            }
        });
        return (() => {
            //斷開遊戲連線
            console.log('Game: return');
            DisconnectEvent();
            console.log('Game: start shut down game');
            unityRef.current.quitUnityInstance()
                .then(function () {
                    console.log('quitUnityInstance');
                }).catch(function (error) {
                    console.log('quitUnityInstance error');
                    console.log(error);
                });
            unityRef.current.removeAllEventListeners();
            if (hubRef.current)
                hubRef.current.send.ExitGame();
        })
    }, []);

    useEffect(() => {
        if (systemConnection != null) {
            hubRef.current = new SystemControlHub(systemConnection);

            //收到新訊息，傳到遊戲
            hubRef.current.addHandler.ReceiveGameMessage((message) => {
                unityRef.current.send("[SINGLETON] AppBridge", "ReactToUnity", message);
            });
        }
        return (() => {
            if (hubRef.current) {
                hubRef.current.removeHandler.ReceiveGameMessage();
            }
        })
    }, [systemConnection]);



    function ConnectEvent() {
        var obj: MsgModle = {
            type: "Connected", userId: user.userId, payload: {}
        };
        unityRef.current.send("[SINGLETON] AppBridge", "ReactToUnity", JSON.stringify(obj));
    }
    function DisconnectEvent() {
        var obj: MsgModle = {
            type: "Disconnected", userId: user.userId, payload: {}
        };
        unityRef.current.send("[SINGLETON] AppBridge", "ReactToUnity", JSON.stringify(obj));
    }

    useEffect(() => {
        const handler = function (e) {
            if (["ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight",
                "Numpad2", "Numpad4", "Numpad8", "Numpad6"].indexOf(e.code) > -1) {
                e.preventDefault();
            }
        }
        window.addEventListener("keydown", handler, false);
        return () => {
            window.removeEventListener("keydown", handler, false);
        }
    }, []);

    function vh(v) {
        var h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
        return (v * h) / 100;
    }
    function vw(v) {
        var w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
        return (v * w) / 100;
    }

    if (CheckIsMobile()) {
        return (
            <>
                <Fade in={loadingNum < 1}>
                    <Box position='absolute' width='100%'>
                        <Box sx={{ //這邊樣式比例要跟unity一樣才行
                            //aspectRatio: `calc(${gameWidth} / ${gameHeight})`,
                            margin: 'auto',
                            height: 'calc(100vh - 58px - 64px)',
                            width: '100vw',
                            padding: '12px 12px 0'
                        }}>
                            <Box width='100%' height='100%' display='flex' flexDirection="column" justifyContent='center' alignItems='center' bgcolor="primary.light" rowGap={3}>
                                <CircularProgressWithLabel value={loadingNum * 100} />
                                建議使用電腦版 以獲得更良好的體驗
                            </Box>
                        </Box>
                    </Box>
                </Fade>
                <Unity
                    unityContext={unityRef.current}
                    matchWebGLToCanvasSize={true}
                    devicePixelRatio={2}
                    tabIndex={-1}
                    style={{
                        margin: 'auto',
                        display: 'flex',
                        justifyContent: 'center',
                        height: 'calc(100vh - 58px - 64px)',
                        width: '100vw',
                        padding: '12px 12px 0',

                        /*
                        width: '90vw',
                        height: '80vh',
                        minWidth: gameMinWidth,
                        minHeight: gameMinHeight,
                        */
                    }}
                />
            </>
        );
    } else {
        return (<>
            <Fade in={loadingNum < 1}>
                <Box position='absolute' width='100%'>
                    <Box sx={{ //這邊樣式比例要跟unity一樣才行
                        //aspectRatio: `calc(${gameWidth} / ${gameHeight})`,
                        margin: 'auto',
                        height: '62.5vw',
                        width: Math.min(vw(62.5), vh(90)) * 16 / 9,
                        maxWidth: '100%',
                        maxHeight: '90vh'
                    }}>
                        <Box width='100%' height='100%' display='flex' flexDirection="column" justifyContent='center' alignItems='center' bgcolor="primary.light" rowGap={3}>
                            <CircularProgressWithLabel value={loadingNum * 100} />
                            建議使用電腦版 以獲得更良好的體驗
                        </Box>
                    </Box>
                </Box>
            </Fade>
            <Unity
                unityContext={unityRef.current}
                matchWebGLToCanvasSize={true}
                devicePixelRatio={2}
                tabIndex={-1}
                style={{
                    margin: 'auto',
                    display: 'flex',
                    justifyContent: 'center',
                    height: '62.5vw',
                    width: Math.min(vw(62.5), vh(90)) * 16 / 9,
                    maxWidth: '100%',
                    maxHeight: '90vh',
                    /*
                    width: '90vw',
                    height: '80vh',
                    minWidth: gameMinWidth,
                    minHeight: gameMinHeight,
                    */
                }}
            />
        </>);
    }
}

function CircularProgressWithLabel(props: CircularProgressProps & { value: number }) {
    return (
        <Box position="relative" display="inline-flex" style={{ width: 'fit-content' }}>
            <CircularProgress variant="determinate" size='10rem' {...props} />
            <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <Typography variant="caption" component="div" color="textSecondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

interface MsgModle {
    type: string,
    userId: string,
    payload: any
}

function dispatch(arg0: any) {
    throw new Error('Function not implemented.');
}
