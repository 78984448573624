﻿
import { AnyAction, Middleware, MiddlewareAPI } from "@reduxjs/toolkit";
import { DialogControl } from "../../components/class";
import { getCookie, setCookie } from "../../cookie-utils";
import { ReducerData } from "../rootReducer";
import { cookiesGet, cookiesSet } from "./redux-cookies";

let DIALOG_INITIALIZE = "DIALOG_INITIALIZE";
let OPEN_DIALOG = "OPEN_DIALOG";
let DIALOG_TO_BUBBLE = "DIALOG_TO_BUBBLE";
let CLOSE_DIALOG = "CLOSE_DIALOG";
let BUBBLE_TO_DIALOG = "BUBBLE_TO_DIALOG";
let CLOSE_BUBBLE = "CLOSE_BUBBLE";

//actions
export function dialogInitialize() {
    return {
        type: DIALOG_INITIALIZE
    };
}
export function openDialog(id) {
    return {
        type: OPEN_DIALOG,
        id: id
    };
}
export function dialogToBubble(id) {
    return {
        type: DIALOG_TO_BUBBLE,
        id: id
    };
}
export function closeDialog(id) {
    return {
        type: CLOSE_DIALOG,
        id: id
    };
}
export function bubbleToDialog(id) {
    return {
        type: BUBBLE_TO_DIALOG,
        id: id
    };
}
export function closeBubble(id) {
    return {
        type: CLOSE_BUBBLE,
        id: id
    };
}

//middleware
export const dialogControlMiddleware: Middleware = store => next => action => {
    let dialogData: DialogControl = (store.getState().rootReducer as ReducerData).dialogControl;
    let readableDialogs = [...dialogData.readableDialogs]; //右往左排
    let unreadableDialogs = [...dialogData.unreadableDialogs]; //下往上排
    const record = (data: DialogControl) => {
        let odl: string[] = [], bbl: string[] = [];
        for (let item of data.readableDialogs) { odl.push(item); } //Opening Dialog List
        for (let item of data.unreadableDialogs) { bbl.push(item); } //Bubble List
        let encodeString = encodeURI(JSON.stringify(
            { odl: odl, bbl: bbl }
        ))
        setCookie("chat", encodeString);
    }
    switch (action.type) {
        case DIALOG_INITIALIZE: {
            const cookieString = getCookie("chat");
            if (cookieString) {
                let cookie = JSON.parse(decodeURI(cookieString));
                readableDialogs = cookie.odl;
                unreadableDialogs = cookie.bbl;
                store.dispatch({
                    type: 'UPDATEDIALOG',
                    payload: { readableDialogs: [...readableDialogs], unreadableDialogs: [...unreadableDialogs] }
                });
            }
            return next(action);
        }
        case OPEN_DIALOG: {
            let index = unreadableDialogs.findIndex(element => element == action.id);
            if (index > -1)
                unreadableDialogs.splice(index, 1);
            //檢查重複項
            let oldIndex = readableDialogs.findIndex(element => element == action.id);
            if (oldIndex > -1)
                readableDialogs.push(...readableDialogs.splice(oldIndex, 1));
            else
                readableDialogs.push(action.id)
            if (readableDialogs.length > 2) {
                //把第一個對話框變成泡泡
                unreadableDialogs.unshift(readableDialogs.shift());
            }
            store.dispatch({
                type: 'UPDATEDIALOG',
                payload: { readableDialogs: [...readableDialogs], unreadableDialogs: [...unreadableDialogs] }
            });
            record({ readableDialogs, unreadableDialogs });
            return next(action);
        }
        case DIALOG_TO_BUBBLE: {
            let index = readableDialogs.findIndex(element => element == action.id);
            if (index > -1)
                readableDialogs.splice(index, 1);
            unreadableDialogs.push(action.id);

            store.dispatch({
                type: 'UPDATEDIALOG',
                payload: { readableDialogs: [...readableDialogs], unreadableDialogs: [...unreadableDialogs] }
            });
            record({ readableDialogs, unreadableDialogs });
            return next(action);
        }
        case CLOSE_DIALOG: {
            let index = readableDialogs.findIndex(element => element == action.id);
            if (index > -1)
                readableDialogs.splice(index, 1);
            store.dispatch({
                type: 'UPDATEDIALOG',
                payload: { readableDialogs: [...readableDialogs], unreadableDialogs: [...unreadableDialogs] }
            });
            record({ readableDialogs, unreadableDialogs });
            return next(action);
        }
        case BUBBLE_TO_DIALOG: {
            let index = unreadableDialogs.findIndex(element => element == action.id);
            if (index > -1)
                unreadableDialogs.splice(index, 1);
            readableDialogs.push(action.id);
            if (readableDialogs.length > 2) {
                //把第一個對話框變成泡泡
                unreadableDialogs.unshift(readableDialogs.shift());
            }
            store.dispatch({
                type: 'UPDATEDIALOG',
                payload: { readableDialogs: [...readableDialogs], unreadableDialogs: [...unreadableDialogs] }
            });
            record({ readableDialogs, unreadableDialogs });
            return next(action);
        }
        case CLOSE_BUBBLE: {
            let index = unreadableDialogs.findIndex(element => element == action.id);
            if (index > -1)
                unreadableDialogs.splice(index, 1);
            store.dispatch({
                type: 'UPDATEDIALOG',
                payload: { readableDialogs: [...readableDialogs], unreadableDialogs: [...unreadableDialogs] }
            });
            record({ readableDialogs, unreadableDialogs });
            return next(action);
        }
    }
    return next(action);
}