﻿import { HubConnection } from "@microsoft/signalr";
import { createAction, createReducer, PayloadAction } from "@reduxjs/toolkit";
import { User, BoothFieldName, DialogControl, UserFieldName } from "../components/class";

const initialData: ReducerData = {
    systemConnection: null,
    dialogControl: { readableDialogs: [], unreadableDialogs: [] },
    user: null,
    boothFieldNameList: [],
    userFieldNameList: [],
    modalControl: {
        modalType: null, modalProps: {}
    },
    scrollTrigger: false,
    watchedStartAnounce: false
};

const systemConnection = createAction<HubConnection>('SYSTEMCONNECTION');
const updateDialog = createAction<DialogControl>('UPDATEDIALOG');
export const loginUser = createAction<User>('LOGINUSER');
export const updateUser = createAction<User>('UPDATEUSER');
export const logoutUser = createAction<void>('LOGOUTUSER');
const updateBoothFieldNameList = createAction<BoothFieldName[]>('UPDATE_BOOTH_FIELDNAMELIST');
const updateUserFieldNameList = createAction<UserFieldName[]>('UPDATE_USER_FIELDNAMELIST');
export const showModal = createAction<Modal>('SHOW_MODAL');
export const hideModal = createAction<void>('HIDE_MODAL');
export const setScrollTrigger = createAction<boolean>('SET_SCROLL_TRIGGER');
export const closeStartAnounce = createAction<void>('CLOSE_START_ANOUNCE');

export const rootReducer = createReducer(initialData, (builder) =>
    builder
        //儲存SignalR連線
        .addCase(systemConnection, (state, action: PayloadAction<HubConnection>) => ({
            ...state,
            systemConnection: action.payload
        }))
        //更新對話框資料
        .addCase(updateDialog, (state, action: PayloadAction<DialogControl>) => ({
            ...state,
            dialogControl: action.payload
        }))
        //放入已登入的使用者資料
        .addCase(loginUser, (state, action: PayloadAction<User>) => ({
            ...state,
            user: action.payload
        }))
        //更新使用者資料
        .addCase(updateUser, (state, action: PayloadAction<User>) => ({
            ...state,
            user: action.payload
        }))
        //登出，刪除使用者資料
        .addCase(logoutUser, (state, action: PayloadAction<void>) => ({
            ...state,
            user: null
        }))
        //更新攤位欄位名稱列表
        .addCase(updateBoothFieldNameList, (state, action: PayloadAction<BoothFieldName[]>) => ({
            ...state,
            boothFieldNameList: [...action.payload]
        }))
        //更新使用者欄位名稱列表
        .addCase(updateUserFieldNameList, (state, action: PayloadAction<UserFieldName[]>) => ({
            ...state,
            userFieldNameList: [...action.payload]
        }))
        //打開Modal
        .addCase(showModal, (state, action: PayloadAction<Modal>) => ({
            ...state,
            modalControl: action.payload
        }))
        //關閉Modal
        .addCase(hideModal, (state, action: PayloadAction<void>) => ({
            ...state,
            modalControl: {
                modalType: null, modalProps: {}
            }
        }))
        //更新後台滾動觸發器狀態
        .addCase(setScrollTrigger, (state, action: PayloadAction<boolean>) => ({
            ...state,
            scrollTrigger: action.payload
        }))
        //看完大會公告
        .addCase(closeStartAnounce, (state, action: PayloadAction<void>) => ({
            ...state,
            watchedStartAnounce: true
        }))
);

export interface ReducerData {
    systemConnection: HubConnection,
    dialogControl: DialogControl,
    user: User,
    boothFieldNameList: BoothFieldName[],
    userFieldNameList: UserFieldName[],
    modalControl: Modal,
    scrollTrigger: boolean,
    watchedStartAnounce: boolean;
}

export interface Modal {
    modalType: string;
    modalProps: any;
}