﻿import * as React from 'react';
import { useTheme } from '@mui/material';
import { SxProps } from "@mui/system";
import { Badge, BadgeProps } from '@mui/material';
import { isNullOrUndefined } from "../Admin/Utils";

export default function UserOnlineBadge(props: UserOnlineBadgeProps & BadgeProps) {
    const { isOnline, ripple, badgeOnlineColor, badgeOfflineColor,
        badgeWidth, badgeHeight, badgePosTop, badgePosRight, badgePosBottom, badgePosLeft, badgeStyle, sx, ...other } = props;

    const theme = useTheme();

    return (
        <Badge
            variant="dot"
            overlap="circular"
            invisible={isNullOrUndefined(isOnline)}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }}
            sx={{
                '& .MuiBadge-badge': {
                    width: badgeWidth ?? "14px",
                    height: badgeHeight ?? "14px",
                    border: '2px solid ' + theme.palette.background.paper,
                    borderRadius: "50%",
                    backgroundColor: (isOnline === true) ? (badgeOnlineColor ?? theme.palette.success.light) : (badgeOfflineColor ?? theme.palette.grey[400]),
                    top: badgePosTop,
                    right: badgePosRight,
                    bottom: badgePosBottom,
                    left: badgePosLeft,

                    '&::after': {
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        borderRadius: '50%',
                        animation: (isOnline === true && ripple === true) && 'ripple 1.2s infinite ease-in-out',
                        border: (isOnline === true && ripple === true) && `1px solid ${theme.palette.success.light}`,
                        content: '""',
                    },
                    ...badgeStyle
                },
                '@keyframes ripple': {
                    '0%': {
                        transform: 'scale(.6)',
                        opacity: 1,
                    },
                    '100%': {
                        transform: 'scale(1.8)',
                        opacity: 0,
                    },
                }
            }}
            {...other}
        />
    );
}
export interface UserOnlineBadgeProps {
    isOnline?: boolean; //是否上線
    ripple?: boolean; //是否有燈泡閃動效果
    badgeOnlineColor?: string; //燈泡上線顏色
    badgeOfflineColor?: string; //燈泡離線顏色
    badgeWidth?: number | string; //燈泡寬度
    badgeHeight?: number | string; //燈泡高度
    badgePosTop?: number | string; //燈泡距離上方位置
    badgePosRight?: number | string; //燈泡距離右方位置
    badgePosBottom?: number | string; //燈泡距離下方位置
    badgePosLeft?: number | string; //燈泡距離左方位置
    badgeStyle?: React.CSSProperties; //燈泡Style
}