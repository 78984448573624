﻿import * as React from "react";
import { Container, ContainerProps } from "reactstrap";
import "./StyleSheet.css";

export const CustomContainer = (props: ContainerProps) => {
    const { children, ...other } = props;
    return (
        <Container className={"custom-container-width"} {...other}>
            {children}
        </Container>
    );
};