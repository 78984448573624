﻿import * as React from 'react';
import { Button, Dialog } from '../responsive-components';
import { Backdrop, Box, CircularProgress, FormHelperText, IconButton, Stack, styled, TextField, Theme, ToggleButton, ToggleButtonGroup, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { hideModal, showModal } from '../../store/rootReducer';
import { useDispatchWithType } from '../../store';
import { ChevronLeft, ErrorOutline, HelpOutline } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { useAffixStampMutation, useGetStampsQuery } from '../services/chat';
import { useProgressiveImage } from '../../Posters/LazyBackground';
const loading = 'images/loading.jpg'

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    '& .MuiToggleButtonGroup-grouped': {
        margin: theme.spacing(1),
        border: 0,
        '&.Mui-disabled': {
            border: 0,
            '&::after': {
                content: "url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI2NCIgaGVpZ2h0PSI4NiIgdmlld0JveD0iMCAwIDY0IDg2Ij4NCiAgPGcgaWQ9IkJhbkljb24iIHRyYW5zZm9ybT0idHJhbnNsYXRlKDEyIDc0OSkiPg0KICAgIDxwYXRoIGlkPSJJY29uX21hdGVyaWFsLWJsb2NrIiBkYXRhLW5hbWU9Ikljb24gbWF0ZXJpYWwtYmxvY2siIGQ9Ik0zNSwzQTMyLDMyLDAsMSwwLDY3LDM1LDMyLjAxMiwzMi4wMTIsMCwwLDAsMzUsM1pNOS40LDM1QTI1LjU5MywyNS41OTMsMCwwLDEsMzUsOS40YTI1LjI4OCwyNS4yODgsMCwwLDEsMTUuNjgsNS40MDhMMTQuODA4LDUwLjY4QTI1LjI4OCwyNS4yODgsMCwwLDEsOS40LDM1Wk0zNSw2MC42YTI1LjI4OCwyNS4yODgsMCwwLDEtMTUuNjgtNS40MDhMNTUuMTkyLDE5LjMyQTI1LjI4OCwyNS4yODgsMCwwLDEsNjAuNiwzNSwyNS41OTMsMjUuNTkzLDAsMCwxLDM1LDYwLjZaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTUgLTc1MikiIGZpbGw9IiNmZjYxNjEiLz4NCiAgICA8cmVjdCBpZD0ic3F1YXJlIiB3aWR0aD0iNjQiIGhlaWdodD0iMjIiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0xMiAtNjg1KSIgZmlsbD0ibm9uZSIvPg0KICA8L2c+DQo8L3N2Zz4=')",
                //content: "'已送出'",
                position: "absolute",
                inset: 0,
                color: "red",
                fontWeight: "bold",
                backgroundColor: "rgba(255, 255, 255, 0.5)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            },
        },
        '&:not(:first-of-type)': {
            borderRadius: theme.shape.borderRadius,
        },
        '&:first-of-type': {
            borderRadius: theme.shape.borderRadius,
        },
        '&.Mui-selected': {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.grey[200],
            '& .MuiTypography-root': {
                fontWeight: "bold",
            },
        }
    },
}));

export interface StampModalProps {
    chatId: string;
}
export function StampModal(props: StampModalProps) {
    const { chatId } = props;
    const { t, i18n } = useTranslation();
    const dispatch = useDispatchWithType();

    const [open, setOpen] = React.useState<boolean>(true);
    const { data: stampStatus, isLoading: isLoadingStatus, isSuccess } = useGetStampsQuery(chatId, { refetchOnMountOrArgChange: true });

    //選擇的印章 未選擇 null、已選擇 index
    const [selectItem, setSelectItem] = React.useState<number>(null);
    const handleChangeSelect = (event, item) => {
        if (item !== null)
            setSelectItem(item);
    }
    const imageSrc_1 = useProgressiveImage("images/企業銀幣.png");
    const imageSrc_2 = useProgressiveImage("images/英雄勳章.png");
    const SilverCoin = () => (<img src={imageSrc_1 || loading} width="74px" height="74px" />);
    const HeroMedal = () => (<img src={imageSrc_2 || loading} width="74px" height="74px" />);
    const itemList = [
        { img: <SilverCoin />, name: t('dialog.stamp.silverCoin') },
        { img: <HeroMedal />, name: t('dialog.stamp.heroMedal') }
    ];
    const [affixStamp, { isLoading }] = useAffixStampMutation();

    //顯示說明
    const [showDesc, setShowDesc] = React.useState<boolean>(false);
    const handleShowDesc = () => setShowDesc(true), handleHideDesc = () => setShowDesc(false);

    //英雄勳章獎品
    const [heroReward, setHeroReward] = React.useState<string>("");
    const handleChangeReward = (event: React.ChangeEvent<HTMLInputElement>) => setHeroReward(event.target.value);

    //優雅的關閉
    function onClose() {
        setOpen(false);
    }
    React.useEffect(() => {
        if (!open)
            setTimeout(() => {
                dispatch(hideModal());
            }, 300);
    }, [open]);

    const handleConfirm = () => {
        if (selectItem === 1) {
            if (heroReward == "")
                console.log("[獎品內容]不得為空");
            else {
                affixStamp({ chatId: chatId, type: selectItem, rewardContent: heroReward }).unwrap()
                    .then((result) => {
                        //200 OK
                        if (result.isSuccess) {
                            setOpen(false);
                        } else {

                        }
                    })
                    .catch((error) => {
                        //後端報錯 ex:500、404
                        console.error("發生錯誤", error)
                    });
            }
        }
        else {
            affixStamp({ chatId: chatId, type: selectItem }).unwrap()
                .then((result) => {
                    //200 OK
                    if (result.isSuccess) {
                        setOpen(false);
                    } else {

                    }
                })
                .catch((error) => {
                    //後端報錯 ex:500、404
                    console.error("發生錯誤", error)
                });
        }
    };
    const handleCancel = () => {
        setOpen(false);
    };

    if (!isSuccess) {
        return (
            <Dialog open={open} onClose={onClose} maxWidth={"xs"} sx={{ px: 2, py: 1 }}>
                <Stack alignItems="center" p={2}>
                    <Typography variant='h5'>
                        <ErrorOutline fontSize="inherit" />
                    </Typography>
                    <Typography>出錯囉！請重整再試</Typography>
                </Stack>
            </Dialog>
        )
    }
    if (stampStatus.data == null) {
        return (
            <Dialog open={open} onClose={onClose} maxWidth={"xs"} sx={{ px: 2, py: 1 }}>
                <Stack alignItems="center" p={2}>
                    <Typography fontSize="1.5rem">{i18n.language == "ch" ? "失敗" : "Fail"} 😢</Typography>
                    <Typography>{stampStatus.message}</Typography>
                </Stack>
            </Dialog>
        )
    }

    if (showDesc) {
        return (
            <Dialog open={showDesc} onClose={handleHideDesc} maxWidth={"xs"} fullWidth sx={{ px: 2, py: 1 }}>
                <Box display="flex">
                    <IconButton onClick={handleHideDesc}>
                        <ChevronLeft color="inherit" />
                    </IconButton>
                </Box>
                <Stack p={3} pt={0} overflow="auto">
                    <Typography variant="h6" fontWeight="bold" paragraph>{t('dialog.stamp.descTitle')}</Typography>
                    <Box display="flex" alignItems="flex-start">
                        <SilverCoin />
                        <Box ml={1}>
                            <Typography fontWeight="bold">{t('dialog.stamp.silverCoin')}</Typography>
                            <Typography>{t('dialog.stamp.silverCoinDesc')}</Typography>
                        </Box>
                    </Box>
                    <Box display="flex" alignItems="flex-start" mt={3}>
                        <HeroMedal />
                        <Box ml={1}>
                            <Typography fontWeight="bold">{t('dialog.stamp.heroMedal')}</Typography>
                            <Typography>{t('dialog.stamp.heroMedalDesc')}</Typography>
                        </Box>
                    </Box>
                </Stack>
            </Dialog>
        )
    }
    else {
        return (
            <>
                <Dialog open={open} onClose={onClose} maxWidth={"xs"} fullWidth sx={{ px: 2, py: 1 }}>
                    <IconButton onClick={handleShowDesc} sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}>
                        <HelpOutline />
                    </IconButton>
                    <Stack alignItems="center" p={3}>
                        <Typography>{t('dialog.stamp.question')}</Typography>
                        <StyledToggleButtonGroup
                            color="primary"
                            value={selectItem}
                            exclusive
                            onChange={handleChangeSelect}
                        >
                            {itemList.map((item, index) => (
                                <ToggleButton key={index.toString()} value={index} disabled={!stampStatus.data[index]}>
                                    <Stack alignItems="center" p={1}>
                                        {item.img}
                                        <Typography pt={1}>{item.name}</Typography>
                                    </Stack>
                                </ToggleButton>
                            ))}
                        </StyledToggleButtonGroup>
                        {selectItem === 1 &&
                            <Box mt={1} mb={3}>
                                <TextField
                                    variant="outlined"
                                    label={t('dialog.stamp.heroReward.label')}
                                    placeholder={t('dialog.stamp.heroReward.placeholder')}
                                    value={heroReward}
                                    onChange={handleChangeReward}
                                    fullWidth
                                    required
                                />
                            </Box>
                        }
                        <Box display="flex" justifyContent="flex-end">
                            <LoadingButton variant="contained" color="primary" fullWidth onClick={handleConfirm} disabled={selectItem == null} loading={isLoading}>{t('dialog.stamp.confirm')}</LoadingButton>
                        </Box>
                    </Stack>
                </Dialog>
                {
                    (isLoading || isLoadingStatus) &&
                    <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.modal + 1 }} open={isLoading || isLoadingStatus}>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                }
            </>
        )
    }
}