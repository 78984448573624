﻿import * as React from 'react';
import { DialogProps as MuiDialogProps } from '@mui/material/Dialog';
import { Button, ConfirmButton, Dialog, DialogActions, DialogContent, important, TextField, Upload } from '../components/responsive-components';
import { Col, Row } from 'reactstrap';
import { Publish } from '@mui/icons-material';
import { Booth, BoothType, ItemType, UserRole } from '../components/class';
import { Trans, useTranslation } from "react-i18next";
import { boothTypeList } from '../components/boothType';
import { useForm } from 'react-hook-form';
import { Backdrop, Box, CircularProgress, MenuItem, styled, TextFieldProps, Typography, TypographyProps } from '@mui/material';
import { boothCustomFieldList, uniqueBoothFieldList } from '../components/CustomField';
import { ErrorMessage } from '@hookform/error-message';
import { useGetBoothFieldNameListQuery } from '../components/services/booth';
import { useUploadBoothLogoMutation } from '../components/services/file';
import TextEditor from '../components/Editor/CKEditor';
import { useUser } from '../store';

export interface EditDialogProps {
    data: Booth;
    onClose: (result?: Booth) => void;
    isLoading?: boolean;
}

const spaceValue = '6px';
const phoneRegExp = /^$|^(((\+\d{3})|(\(\+\d{3}\))) ?)?((\d{1,4})|(\(\d{2,4}\)))[- ]?\d{1,4}[- ]?\d{2,4}( ?#(\d+)){0,2}$/i;
const dateRegExp = /^$|^(1\d{3}|20\d{2})\.(0[1-9]|1[0-2])\.(0[1-9]|[12]\d|3[0-1])$/i;

const StyledTypography: (props: TypographyProps) => JSX.Element = styled(Typography)(({ theme }) => ({
    fontSize: '16px',
    fontWeight: 'bold',
    color: theme.palette.text.primary,
}));
const StyledTextField: (props: TextFieldProps) => JSX.Element = styled(TextField)(({ theme }) => ({
    height: '35px',
}));

export default function EditIntroDialog(props: EditDialogProps & Omit<MuiDialogProps, keyof EditDialogProps>) {
    const { data, onClose, isLoading, ...other } = props;
    const { t, i18n } = useTranslation();

    const userRole = useUser().role;
    const isNormalBooth = (data.type === BoothType.一般攤位 || data.type === BoothType.廣告贊助);

    const { register, watch, formState: { errors }, handleSubmit, setValue, reset } = useForm({
        defaultValues: data, mode: 'onChange'
    });
    React.useEffect(() => {
        reset(data);
    }, [data])

    const watchCategoryField = watch("category");
    const handleCategoryChange = (category: number) => setValue("category", category);
    const watchBoothLocationField = watch("boothLocation");
    const handleBoothLocationChange = (boothLocation: string) => setValue("boothLocation", boothLocation);
    const watchCustomContent = !isNormalBooth ? watch("customContent"): '';
    const handleCustomContentChange = (customContent: string) => setValue("customContent", customContent);

    //應徵方式
    const howToApplyIndex = data.fieldList.findIndex(x => x.fieldId === "job")
    const howToApplyElement = howToApplyIndex != -1 ? boothCustomFieldList.find(x => x.id === "job") : null;

    //進行圖片上傳
    const [uploadBoothLogo, { isLoading: isUploading }] = useUploadBoothLogoMutation();
    const logoUrlValue = watch("logoUrl");
    const handleLogoUrlChange = (url: string) => setValue("logoUrl", url);
    function onSubmit(data) {
        if (logoFile != null) {
            const formData = new FormData();
            formData.append("files", logoFile);
            uploadBoothLogo({ boothId: data.boothId, formData }).unwrap().then(result => {
                if (result.isSuccess) {
                    let uri = result.data.urls[0];
                    handleLogoUrlChange(uri);
                    onClose({ ...data, logoUrl: uri });
                }
            });
        }
        else {
            onClose(data);
        }
    }
    const [logoFile, setLogoFile] = React.useState<File>();

    //欄位名稱
    const { data: boothFieldNameList } = useGetBoothFieldNameListQuery();
    function boothFieldIdToName(fieldId: string) {
        let fieldName = '';
        if (boothFieldNameList) {
            let boothFieldName = boothFieldNameList.data.find(x => x.fieldId === fieldId);
            if (boothFieldName) {
                if (i18n.language === 'en') {
                    if (boothFieldName.fieldName[1])
                        fieldName = boothFieldName.fieldName[1];
                }
                else {
                    if (boothFieldName.fieldName[0])
                        fieldName = boothFieldName.fieldName[0];
                }
            }
        }
        return fieldName;
    }
    function boothFieldValueToType(fieldId: string, value: string) {
        let fieldValue = null;
        if (boothFieldNameList) {
            let boothFieldName = boothFieldNameList.data.find(x => x.fieldId === fieldId);
            if (boothFieldName) {
                switch (boothFieldName.fieldType) {
                    case ItemType.String:
                        fieldValue = String(value);
                        break;
                    case ItemType.Int:
                        fieldValue = Number(value);
                        break;
                    case ItemType.Bool:
                        fieldValue = Boolean(value);
                        break;
                    default:
                        fieldValue = '';
                }
            }
        }
        return fieldValue;
    }

    //圖片調版
    const [width, setWidth] = React.useState(477);
    const [height, setHeight] = React.useState(159);
    const divRef = React.createRef<HTMLDivElement>(); //控制寬度的外層
    React.useEffect(() => {
        const resizeObserver = new ResizeObserver(entries => {
            setWidth(entries[0].target.clientWidth);
            setHeight(entries[0].target.clientWidth / 3);
        });
        if (divRef.current) {
            resizeObserver.observe(divRef.current);
            setWidth(divRef.current.clientWidth);
            setHeight(divRef.current.clientWidth / 3);
        }
        return (() => {
            resizeObserver.disconnect();
        })
    }, [other.open, divRef.current]);

    return (
        <Dialog disableEnforceFocus {...other} >
            <Box component="form" onSubmit={handleSubmit(onSubmit)}>
                <DialogContent>
                    <Box className="d-flex flex-row flex-wrap justify-content-end" paddingRight='12px'>
                        <Button onClick={() => onClose()}>{t('booth.editDialog.discard')}</Button>
                    </Box>
                    <Box className="d-flex flex-row flex-wrap">
                        <Box className="d-flex flex-row flex-wrap">
                            <Box flex='1 0 40%' className="d-flex flex-row flex-wrap align-content-start"
                                padding='12px'>
                                {other.open &&
                                    <div ref={divRef} className="d-flex flex-column w-100 mt-2 mb-2">
                                        <StyledTypography>
                                            {important}<Trans i18nKey={'booth.editDialog.logoUrl'} />
                                        </StyledTypography>
                                        <Upload
                                            value={logoUrlValue} onChange={(file) => { setLogoFile(file) }}
                                            width={width} height={height}
                                        >
                                            <div className="d-flex justify-content-center align-items-center"
                                                style={{
                                                    border: "1px solid rgba(0, 0, 0, 0.23)",
                                                    borderRadius: "4px",
                                                    color: "darkgray",
                                                    cursor: "pointer",
                                                    width: `${width}px`,
                                                    height: `${height}px`,
                                                }}>
                                                <Publish />
                                                <Trans i18nKey={'booth.editDialog.upload'} />
                                            </div>
                                        </Upload>
                                        <Typography variant='subtitle2'>
                                            {t('manage.boothEdit.imageSize_1')}
                                            <span style={{ color: 'red' }}>{t('manage.boothEdit.imageSize_2')}</span>
                                            {t('manage.boothEdit.imageSize_3')}
                                        </Typography>
                                    </div>
                                }

                                <Box flex='1 0 65%' className="d-flex flex-column mt-2"
                                    paddingLeft={spaceValue} paddingRight={spaceValue}>
                                    <StyledTypography>
                                        <Trans i18nKey={'booth.editDialog.category'} />
                                    </StyledTypography>
                                    <StyledTextField
                                        variant="outlined"
                                        size="small"
                                        margin="dense"
                                        select
                                        placeholder='請選擇'
                                        value={watchCategoryField}
                                        onChange={(event) => handleCategoryChange(Number(event.target.value))}
                                    >
                                        {boothTypeList.map((option) => (
                                            <MenuItem key={option.type} value={option.type}>
                                                {t(option.nameKey)}
                                            </MenuItem>
                                        ))}
                                    </StyledTextField>
                                </Box>
                                <Box flex='0 0 35%' className="d-flex flex-column mt-2"
                                    paddingLeft={spaceValue} paddingRight={spaceValue}>
                                    <StyledTypography>
                                        <Trans i18nKey={'booth.editDialog.boothNo'} />
                                    </StyledTypography>
                                    <StyledTextField
                                        variant="outlined"
                                        size="small"
                                        margin="dense"
                                        inputProps={{ maxLength: 3001 }}
                                        error={!!errors.boothNo}
                                        helperText={errors.boothNo ? errors.boothNo.message : ' '}
                                        {...register("boothNo")}
                                        disabled={!userRole || userRole < UserRole.administrator}
                                    />
                                </Box>
                                <Box flex='1 0 60%' className="d-flex flex-column mt-2"
                                    paddingLeft={spaceValue} paddingRight={spaceValue}>
                                    <StyledTypography>
                                        <Trans i18nKey={'booth.editDialog.boothLocation'} />
                                    </StyledTypography>
                                    <StyledTextField
                                        variant="outlined"
                                        size="small"
                                        margin="dense"
                                        select
                                        placeholder='請選擇'
                                        value={watchBoothLocationField}
                                        onChange={(event) => handleBoothLocationChange(event.target.value)}
                                        disabled={!userRole || userRole < UserRole.administrator}
                                    >
                                        <MenuItem value="浩然圖書館前">浩然圖書館前</MenuItem>
                                    </StyledTextField>
                                </Box>
                            </Box>

                            <Box flex='1 0 40%' padding='12px'
                                minWidth='300px' className="d-flex flex-row mt-2 flex-wrap align-content-start">
                                <Box flex='1 0 50%' className="d-flex flex-column mt-2"
                                    paddingLeft={spaceValue} paddingRight={spaceValue}>
                                    <StyledTypography>
                                        {important}<Trans i18nKey={'booth.editDialog.chName'} />
                                    </StyledTypography>
                                    <StyledTextField
                                        variant='outlined'
                                        size="small"
                                        margin="dense"
                                        inputProps={{ maxLength: 40 }}
                                        error={!!errors.chName}
                                        {...register("chName", {
                                            required: `${t('booth.editDialog.error_required')}`
                                        })}
                                    />
                                    <div style={{ minHeight: '24px' }}>
                                        <ErrorMessage
                                            errors={errors}
                                            name={'chName'}
                                            render={({ message }) =>
                                                <Typography sx={{ color: (theme) => theme.palette.error.main }}>
                                                    {`${t(message)}`}
                                                </Typography>
                                            }
                                        />
                                    </div>
                                </Box>
                                <Box flex='0 0 50%' className="d-flex flex-column mt-2"
                                    paddingLeft={spaceValue} paddingRight={spaceValue}>
                                    <StyledTypography>
                                        {important}<Trans i18nKey={'booth.editDialog.abbrev'} />
                                    </StyledTypography>
                                    <StyledTextField
                                        variant='outlined'
                                        size="small"
                                        margin="dense"
                                        error={!!errors.abbrev}
                                        inputProps={{ maxLength: 40 }}
                                        {...register("abbrev", {
                                            required: `${t('booth.editDialog.error_required')}`
                                        })}
                                    />
                                    <div style={{ minHeight: '24px' }}>
                                        <ErrorMessage
                                            errors={errors}
                                            name={'abbrev'}
                                            render={({ message }) =>
                                                <Typography sx={{ color: (theme) => theme.palette.error.main }}>
                                                    {`${t(message)}`}
                                                </Typography>
                                            }
                                        />
                                    </div>
                                </Box>
                                <Box flex='1 0 50%' className="d-flex flex-column mt-2"
                                    paddingLeft={spaceValue} paddingRight={spaceValue}>
                                    <StyledTypography>
                                        {important}<Trans i18nKey={'booth.editDialog.enName'} />
                                    </StyledTypography>
                                    <StyledTextField
                                        variant='outlined'
                                        size="small"
                                        margin="dense"
                                        error={!!errors.enName}
                                        inputProps={{ maxLength: 80 }}
                                        {...register("enName", {
                                            required: `${t('booth.editDialog.error_required')}`
                                        })}
                                    />
                                    <div style={{ minHeight: '24px' }}>
                                        <ErrorMessage
                                            errors={errors}
                                            name={'enName'}
                                            render={({ message }) =>
                                                <Typography sx={{ color: (theme) => theme.palette.error.main }}>
                                                    {`${t(message)}`}
                                                </Typography>
                                            }
                                        />
                                    </div>
                                </Box>



                                {boothCustomFieldList.map((field) => {
                                    let boothField = data.fieldList.find(x => x.fieldId === field.id);
                                    let index = data.fieldList.findIndex(x => x.fieldId === field.id);
                                    if (boothField && ['unifiedBusinessNo', 'numOfEmployees', 'establishment', 'phone', 'address', 'website', 'fax'].includes(boothField.fieldId))
                                        return (
                                            <Box flex='0 0 50%' className="d-flex flex-column mt-2" key={field.id}
                                                paddingLeft={spaceValue} paddingRight={spaceValue}>
                                                <StyledTypography>
                                                    {boothFieldIdToName(field.id)}
                                                </StyledTypography>
                                                {field.editComponent({
                                                    displayTitle: boothFieldIdToName(field.id),
                                                    size: "small",
                                                    value: watch(`fieldList.${index}.value`),
                                                    onChange: (event) => setValue(`fieldList.${index}.value`, event.target.value),
                                                    errorMessage: errors.fieldList && errors.fieldList[index]?.value?.message,
                                                    inputProps: {
                                                        //maxLength: 3001,
                                                        ...register(`fieldList.${index}.value`, field.validation)
                                                    },
                                                })}
                                            </Box>
                                        )
                                    else
                                        return (
                                            <div key={field.id} />
                                        )
                                })}
                            </Box>
                        </Box>

                        <Box className="d-flex flex-row flex-wrap" style={{ flex: 1 }}>
                            {!isNormalBooth &&
                                <Box flex='1 0' className="d-flex flex-column mt-2" padding='12px'>
                                    <StyledTypography>
                                        <Trans i18nKey={'booth.editDialog.customContent'} />
                                    </StyledTypography>
                                    <TextEditor
                                        data={watchCustomContent}
                                        onChange={handleCustomContentChange}
                                    />
                                    <div style={{ minHeight: '24px' }}>
                                        <ErrorMessage
                                            errors={errors}
                                            name={'customContent'}
                                            render={({ message }) =>
                                                <Typography sx={{ color: (theme) => theme.palette.error.main }}>
                                                    {`${t(message)}`}
                                                </Typography>
                                            }
                                        />
                                    </div>
                                </Box>
                            }
                            {boothCustomFieldList.map((field) => {
                                let boothField = data.fieldList.find(x => x.fieldId === field.id);
                                let index = data.fieldList.findIndex(x => x.fieldId === field.id);
                                if (boothField && !uniqueBoothFieldList.includes(boothField.fieldId))
                                    return (
                                        <Box flex='1 0 100%' className="d-flex flex-column mt-2" padding='12px' key={field.id}>
                                            <StyledTypography>
                                                {boothFieldIdToName(field.id)}
                                            </StyledTypography>
                                            {field.editComponent({
                                                displayTitle: boothFieldIdToName(field.id),
                                                value: watch(`fieldList.${index}.value`),
                                                onChange: (event) => setValue(`fieldList.${index}.value`, event.target.value),
                                                errorMessage: errors.fieldList && errors.fieldList[index]?.value?.message,
                                                inputProps: {
                                                    //maxLength: 3001,
                                                    ...register(`fieldList.${index}.value`, field.validation)
                                                },
                                            })}
                                        </Box>
                                    )
                                else
                                    return (
                                        <div key={field.id} />
                                    )
                            })}
                            {howToApplyElement &&
                                <Box flex='1 0 100%' className="d-flex flex-column mt-2" padding='12px' key={howToApplyElement.id}>
                                    <StyledTypography>
                                        {boothFieldIdToName(howToApplyElement.id)}
                                    </StyledTypography>
                                    {howToApplyElement.editComponent({
                                        displayTitle: boothFieldIdToName(howToApplyElement.id),
                                        size: "small",
                                        value: watch(`fieldList.${howToApplyIndex}.value`),
                                        onChange: (event) => setValue(`fieldList.${howToApplyIndex}.value`, event.target.value),
                                        errorMessage: errors.fieldList && errors.fieldList[howToApplyIndex]?.value?.message,
                                        inputProps: {
                                            //maxLength: 3001,
                                            ...register(`fieldList.${howToApplyIndex}.value`, howToApplyElement.validation)
                                        },
                                    })}
                                </Box>
                            }
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <ConfirmButton type="submit" disabled={isUploading || isLoading}>
                        <Trans i18nKey={'booth.editDialog.saveButton'} />
                    </ConfirmButton>
                </DialogActions>
            </Box>
        </Dialog>
    )
}