﻿import { grey } from '@mui/material/colors';
import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { Theme } from '@mui/material/styles';

export interface TypeOfCard {
    unknown: string;
    student: string;
    nonStudent: string;
    admin: string;
}
declare module '@mui/material/styles/createPalette' {
    interface TypeBackground {
        secondary: string;
        black: string;
    }
    interface TypeText {
        white: string;
        card: TypeOfCard;
    }
}
declare module '@mui/styles' {
    interface DefaultTheme extends Theme { }
}
declare module '@mui/material/styles' {
    interface Palette {
        appBar: Palette['primary'];
    }
    interface PaletteOptions {
        appBar: PaletteOptions['primary'];
    }
}
let mainTheme = createTheme({
    palette: {
        primary: {
            light: '#D9ECFF',
            main: '#1763FB',
            dark: '#1045af',
            contrastText: '#ffffff',
        },
        secondary: {
            light: '#f73378',
            main: '#f50057',
            dark: '#ab003c',
            contrastText: '#ffffff',
        },
        background: {
            paper: '#ffffff',
            default: '#ffffff',
            secondary: grey[200],
            black: '#101010'
        },
        appBar: {
            light: '#5b636a',
            main: "#343a40",
            contrastText:"#f5ffff"
        },
        text: {
            card: {
                unknown: grey[400],
                student: '#1763FB',
                nonStudent: '#1045af',
                admin: '#ff8f20'
            }
        }
    },
    typography: {
        //fontFamily: 'GenJyuuGothic',
    },
    components: {
        MuiTablePagination: {
            styleOverrides: {
                displayedRows: {
                    margin: 'auto'
                }
            }
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    wordBreak: 'break-word',
                    whiteSpace: 'pre-wrap'
				}
			}
        },
        MuiInputLabel: {
            styleOverrides: {
                asterisk: {
                    color: 'red'
                }
            }
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    boxShadow: 'none'
                }
            }
        }
    },
});

mainTheme = responsiveFontSizes(mainTheme);

export default mainTheme;
