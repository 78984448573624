﻿import * as React from 'react';
import { Backdrop, Box, CircularProgress, Dialog, Typography } from '@mui/material';
import { Upload } from '../../components/responsive-components';
import { useUser } from '../../store';
import { SubTitle } from '../../components/responsive-components/Typography';
import { Trans, useTranslation } from 'react-i18next';
import { Publish } from '@mui/icons-material';
//import { SaveImage } from '../../components/Admin/Utils';
import { useLazyGetBoothQuery, useUpdateBoothEdmMutation, useUpdateBoothPosterMutation } from '../../components/services/booth';
import { BoothType } from '../../components/class';
import { useUploadEDMMutation, useUploadPosterMutation } from '../../components/services/file';

export default function UploadPoster(props: UploadPosterProps) {
    const { boothId } = props;

    const user = useUser();
    const { t, i18n } = useTranslation();
    const [change, forceUpdate] = React.useState([]);

    const [isUploading, setIsUploading] = React.useState<boolean>(false);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [dialogMessage, setDialogMessage] = React.useState("");
    const dialogContents = (
        <div style={{ padding: '1rem 2rem', textAlign: 'center', fontSize: '1rem' }} >
            <p style={{ marginBottom: 0 }}>
                {dialogMessage}
            </p>
        </div>
    );

    const [getBooth, { booth, isBoothLoading }] = useLazyGetBoothQuery({
        selectFromResult: ({ data, isLoading }) => ({ booth: data?.data, isBoothLoading: isLoading })
    });
    React.useEffect(() => {
        if (user && boothId && boothId != "") {
            getBooth({ boothId: boothId });
        }
    }, [boothId, change]);

    //EDM上傳
    const [uploadEdm] = useUploadEDMMutation();
    const [updateBoothEdm, { isLoading: isEdmUpdateLoading }] = useUpdateBoothEdmMutation();
    async function UploadEdm(data) {
        setIsUploading(true);
        const formData = new FormData();
        formData.append("file", data);
        const uploadResult = await uploadEdm({ boothId, formData }).unwrap();
        if (uploadResult.isSuccess) {
            let uri = uploadResult.data.url;
            const updateResult = await updateBoothEdm({ boothId: boothId, uri: uri }).unwrap();
            if (updateResult.isSuccess) {
                forceUpdate([]);
                setDialogMessage(t('manage.uploadPoster.success'));
            }
            else
                setDialogMessage(t('manage.uploadPoster.error'));
        }
        else {
            setDialogMessage(t('manage.uploadPoster.error'));
        }
        setOpenDialog(true);
        setIsUploading(false);
    }

    //海報上傳
    const [uploadPoster] = useUploadPosterMutation();
    const [updateBoothPoster, { isLoading: isPosterUpdateLoading }] = useUpdateBoothPosterMutation();
    async function UploadPoster(data) {
        setIsUploading(true);
        const formData = new FormData();
        formData.append("file", data);
        const uploadResult = await uploadPoster({ boothId, formData }).unwrap();
        if (uploadResult.isSuccess) {
            let uri = uploadResult.data.url;
            const updateResult = await updateBoothPoster({ boothId: boothId, uri: uri }).unwrap();
            if (updateResult.isSuccess) {
                forceUpdate([]);
                setDialogMessage(t('manage.uploadPoster.success'));
            }
            else
                setDialogMessage(t('manage.uploadPoster.error'));
        }
        else {
            setDialogMessage(t('manage.uploadPoster.error'));
        }
        setOpenDialog(true);
        setIsUploading(false);
    }

    const poster = { width: 474, height: 574 };

    return (
        <div>
            <SubTitle
                icon='circle'
                text={t('manage.uploadPoster.title')}
            />
            {(user && (boothId && boothId != "")) && <>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '0px 2.5rem',
                }}>
                    <Box style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                    }}>
                        {(booth && booth.type !== BoothType.服務台) &&
                            <Box style={{ display: 'flex', flexDirection: 'column', marginRight: '24px' }}>
                                <Typography >
                                    <Trans i18nKey="manage.uploadPoster.edm">
                                        <span style={{ fontWeight: 'bold' }}>上傳EDM</span> (顯示於公司資訊頁面)
                                    </Trans>
                                </Typography>
                            <Upload
                                value={booth.fieldList.find(x => x.fieldId === 'edm')?.value ?? ''} onChange={(file) => { UploadEdm(file) }}
                                    width={poster.width} height={poster.height}
                                >
                                    <div className="d-flex justify-content-center align-items-center"
                                        style={{
                                            border: "1px solid rgba(0, 0, 0, 0.23)",
                                            borderRadius: "4px",
                                            color: "darkgray",
                                            cursor: "pointer",
                                            width: `${poster.width}px`,
                                            height: `${poster.height}px`,
                                        }}>
                                        <Publish />
                                        <Trans i18nKey={'manage.uploadPoster.upload'} />
                                    </div>
                                </Upload>
                            </Box>
                        }
                        <Box style={{ display: 'flex', flexDirection: 'column' }}>
                            <Typography>
                                <Trans i18nKey="manage.uploadPoster.poster">
                                    <span style={{ fontWeight: 'bold' }}>上傳海報</span> (顯示於海報)
                                </Trans>
                            </Typography>
                            <Upload
                                value={booth?.fieldList.find(x => x.fieldId === 'poster')?.value ?? ''} onChange={(file) => { UploadPoster(file) }}
                                width={poster.width} height={poster.height}
                            >
                                <div className="d-flex justify-content-center align-items-center"
                                    style={{
                                        border: "1px solid rgba(0, 0, 0, 0.23)",
                                        borderRadius: "4px",
                                        color: "darkgray",
                                        cursor: "pointer",
                                        width: `${poster.width}px`,
                                        height: `${poster.height}px`,
                                    }}>
                                    <Publish />
                                    <Trans i18nKey={'manage.uploadPoster.upload'} />
                                </div>
                            </Upload>
                        </Box>
                    </Box>
                    <Typography variant='subtitle1'>
                        <Trans i18nKey="manage.uploadPoster.imageSize">
                            建議尺寸: <span style={{ color: 'red' }}>直式A系列(A1~A4)</span>，寬874px、長1240px
                        </Trans>
                    </Typography>
                </div>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={isBoothLoading || isPosterUpdateLoading || isEdmUpdateLoading || isUploading}>
                    <CircularProgress />
                </Backdrop>
                <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>{dialogContents}</Dialog>
            </>}
        </div>
    )
}
interface UploadPosterProps {
    boothId: string;
}