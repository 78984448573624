﻿import * as React from 'react';
import { Seminar } from '../../components/class';
import { SeminarModel, useDeleteSeminarMutation, usePutSeminarMutation } from '../../components/services/seminar';
import { ResultType } from '../../components/services/utils';
import { SeminarEditDialog } from './SeminarEdit';
import { Box, Button, Divider, FormControl, FormControlLabel, IconButton, Link, List, ListItem, Paper, Radio, RadioGroup, Stack, styled, Tooltip, Typography, TypographyProps, useTheme } from '@mui/material';
import { BorderButton, ConfirmButton, TextField } from '../../components/responsive-components';
import { Add, HelpOutline } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { CountDownTimer } from '../../components/Admin/Utils';
import Manual_Manage from '../../components/manuals/承辦人操作手冊.pdf';

const newSeminar: Seminar = {
    seminarId: '',
    chTitle: '',
    enTitle: '',
    chAbbrev: '',
    enAbbrev: '',
    agendas: [],
    channelId: '',
    youtubeLink: null
}
export function SeminarEditList(props: SeminarEditListProps) {
    const { seminars, onChange } = props;
    const { t, i18n } = useTranslation();

    const [open, setOpen] = React.useState(false);
    const [value, setValue] = React.useState<Seminar>(null);
    function handleNewSeminar() {
        setValue(newSeminar)
        setOpen(true);
    }
    function handleEditSeminar(value: Seminar) {
        setValue(value)
        setOpen(true);
    }
    function handleCloseDialog() {
        setOpen(false);
        setValue(null)
    }

    //新增編輯說明會
    const [updateSeminar, { }] = usePutSeminarMutation();
    function handleSubmitSeminar(value: Seminar) {
        let modelValue: SeminarModel = {
            seminarId: value.seminarId,
            chTitle: value.chTitle,
            enTitle: value.enTitle,
            chAbbrev: value.chAbbrev,
            enAbbrev: value.enAbbrev,
            agendas: [],
            channelId: value.channelId,
            youtubeLink: value.youtubeLink
        }
        return new Promise<ResultType<Seminar>>((resolve, reject) => {
            updateSeminar(modelValue).unwrap().then((result) => {
                if (result.isSuccess) {
                    let index = seminars.findIndex(x => x.seminarId === result.data.seminarId)
                    if (index === -1) {
                        onChange([...seminars, result.data]);
                    }
                    else {
                        let newArray = [...seminars];
                        newArray[index] = result.data;
                        onChange(newArray);
                    }
                    setOpen(false);
                } else {
                    console.log(result.message);
                }
                resolve(result);
            }).catch(() => {
                reject();
            });
        });
    }

    //刪除說明會
    const [deleteSeminar, { }] = useDeleteSeminarMutation();
    function handleDeleteJobOpening(value: Seminar) {
        let modelValue: SeminarModel = {
            seminarId: value.seminarId,
            chTitle: value.chTitle,
            enTitle: value.enTitle,
            chAbbrev: value.chAbbrev,
            enAbbrev: value.enAbbrev,
            agendas: [],
            channelId: value.channelId,
            youtubeLink: value.youtubeLink
        }
        return new Promise<ResultType<Seminar>>((resolve, reject) => {
            deleteSeminar(modelValue).unwrap().then((result) => {
                if (result.isSuccess) {
                    let index = seminars.findIndex(x => x.seminarId === modelValue.seminarId)
                    if (index !== -1) {
                        let newArray = [...seminars];
                        newArray.splice(index, 1);
                        onChange(newArray);
                    }
                } else {
                    console.log(result.message);
                }
                resolve(result);
            }).catch(() => {
                reject();
            });
        });
    }

    return (<>
        <Stack flexDirection="row-reverse">
            <Box display="flex" justifyContent="flex-end">
                <BorderButton onClick={() => handleNewSeminar()}>
                    <Add fontSize={'small'} />
                    <Typography variant='subtitle1' fontWeight='bold' style={{ textTransform: 'initial' }}>
                        {t('seminar.editList.newseminar')}
                    </Typography>
                </BorderButton>
            </Box>
            <Stack flex="1">
                {seminars.map((tile, index) => (
                    <Paper key={tile.seminarId} sx={{ mx: 2, my: 0.5, px: 3, py: 0.5 }}>
                        <SeminarEditItem
                            data={tile}
                            handleEdit={handleEditSeminar}
                            onClickDelete={handleDeleteJobOpening}
                        />
                    </Paper >
                ))}
            </Stack>
        </Stack>
        <SeminarEditDialog
            defaultValue={value}
            open={open}
            handleSubmit={handleSubmitSeminar}
            handleClose={handleCloseDialog}
        />
    </>)
}
interface SeminarEditListProps {
    seminars: Seminar[];
    onChange: (dataList: Seminar[]) => void;
}

export function SeminarEditItem(props: SeminarEditProps) {
    const { data, handleEdit, onClickDelete } = props;
    const { t, i18n } = useTranslation();
    const theme = useTheme();

    React.useEffect(() => {
        if (data) {
            setIsOnline((data.youtubeLink !== null));
        }
    }, [data])

    //更改線上線下
    const [isOnline, setIsOnline] = React.useState((data.youtubeLink !== null));

    return (
        <Box display="flex">
            <Box order={1}>
                <Button onClick={() => handleEdit(data)}>
                    <Typography variant='subtitle1' fontWeight='bold'
                        sx={{ textTransform: 'initial', color: (theme) => theme.palette.grey[400] }}>
                        {t('seminar.editList.editseminar')}
                    </Typography>
                </Button>
                <Button onClick={() => onClickDelete(data)}>
                    <Typography variant='subtitle1' fontWeight='bold'
                        sx={{ textTransform: 'initial', color: (theme) => theme.palette.grey[400] }}>
                        {t('seminar.editList.deleteseminar')}
                    </Typography>
                </Button>
            </Box>
            <Box flex="1">
                <Typography fontWeight='bold' variant={'subtitle1'} paddingY="6px">
                    {i18n.language == "ch" ? data.chTitle : data.enTitle}
                </Typography>

                {isOnline && <>
                    <div style={{ display: 'flex', marginBottom: '0.5rem' }}>
                        <Button variant='contained' href={`./LiveStream?id=${data.seminarId}`} target="_blank">
                            {t('seminar.editList.step1.text2')}
                        </Button>
                    </div>
                    <div style={{ display: 'flex', marginBottom: '0.5rem' }}>
                        <Typography variant={'subtitle2'} style={{ marginRight: '12px' }}>
                            {t('seminar.editList.step1.title')}
                        </Typography>
                        <div>
                            <Typography variant={'subtitle2'}>
                                {t('seminar.editList.step1.text1')}
                            </Typography>
                        </div>
                    </div>
                    <div style={{ display: 'flex', marginBottom: '0.5rem' }}>
                        <Typography variant={'subtitle2'} style={{ marginRight: '12px' }}>
                            {t('seminar.editList.step2.title')}
                        </Typography>
                        <div>
                            <Typography variant={'subtitle2'}>
                                {t('seminar.editList.step2.text')}
                            </Typography>
                        </div>
                    </div>
                    <div style={{ display: 'flex', marginBottom: '0.5rem' }}>
                        <Typography variant={'subtitle2'} style={{ marginRight: '12px' }}>
                            {t('seminar.editList.step3.title')}
                        </Typography>
                        <div>
                            <Typography variant={'subtitle2'}>
                                {t('seminar.editList.step3.text')}
                            </Typography>
                        </div>
                    </div>
                </>}
            </Box>
        </Box>
    )
}
interface SeminarEditProps {
    data: Seminar;
    handleEdit?: (value: Seminar) => void;
    onClickDelete?: (value: Seminar) => void;
}