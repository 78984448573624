﻿export enum City {
    不限 = 0,
    基隆市,
    臺北市,
    新北市,
    桃園市,
    新竹市,
    新竹縣,
    苗栗縣,
    臺中市,
    彰化縣,
    南投縣,
    雲林縣,
    嘉義市,
    嘉義縣,
    臺南市,
    高雄市,
    屏東縣,
    臺東縣,
    花蓮縣,
    宜蘭縣,
    澎湖縣,
    金門縣,
    連江縣,
}

export const cityData: CityDataType[] = [
    { nameId: 'city_data.none', type: City.不限 },
    { nameId: 'city_data.KeelungCity', type: City.基隆市 },
    { nameId: 'city_data.TaipeiCity', type: City.臺北市 },
    { nameId: 'city_data.NewTaipeiCity', type: City.新北市 },
    { nameId: 'city_data.TaoyuanCity', type: City.桃園市 },
    { nameId: 'city_data.HsinchuCity', type: City.新竹市 },
    { nameId: 'city_data.HsinchuCounty', type: City.新竹縣 },
    { nameId: 'city_data.MiaoliCounty', type: City.苗栗縣 },
    { nameId: 'city_data.TaichungCity', type: City.臺中市 },
    { nameId: 'city_data.ChanghuaCounty', type: City.彰化縣 },
    { nameId: 'city_data.NantouCounty', type: City.南投縣 },
    { nameId: 'city_data.YunlinCounty', type: City.雲林縣 },
    { nameId: 'city_data.ChiayiCity', type: City.嘉義市 },
    { nameId: 'city_data.ChiayiCounty', type: City.嘉義縣 },
    { nameId: 'city_data.TainanCity', type: City.臺南市 },
    { nameId: 'city_data.KaohsiungCity', type: City.高雄市 },
    { nameId: 'city_data.PingtungCounty', type: City.屏東縣 },
    { nameId: 'city_data.TaitungCounty', type: City.臺東縣 },
    { nameId: 'city_data.HualienCounty', type: City.花蓮縣 },
    { nameId: 'city_data.YilanCounty', type: City.宜蘭縣 },
    { nameId: 'city_data.PenghuCounty', type: City.澎湖縣 },
    { nameId: 'city_data.KinmenCounty', type: City.金門縣 },
    { nameId: 'city_data.LienchiangCounty', type: City.連江縣 },
];
interface CityDataType {
    nameId: string;
    type: City;
}