﻿import * as React from 'react';
import { Button, Dialog } from '../responsive-components';
import { Box, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { hideModal } from '../../store/rootReducer';
import { useDispatchWithType, useSystemConnection } from '../../store';
import { SystemControlHub } from '../react-signalr/systemControlHub';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVideo } from '@fortawesome/free-solid-svg-icons';
import { useHistory, useLocation } from 'react-router';

export interface PrivateVideoChatModalProps {
    chatId: string;
}
export function PrivateVideoChatModal(props: PrivateVideoChatModalProps) {
    const { chatId } = props;
    const { t } = useTranslation();
    const history = useHistory();
    const pathName = useLocation().pathname;
    const dispatch = useDispatchWithType();

    const [open, setOpen] = React.useState(true);

    //優雅的關閉
    function onClose() {
        setOpen(false);
    }
    React.useEffect(() => {
        if (!open)
            setTimeout(() => {
                dispatch(hideModal());
            }, 300);
    }, [open]);

    const handleConfirm = () => {
        if (pathName.toLowerCase().match('/room'))
            window.open(`/room?roomid=${chatId}`, "_blank");
        else
            history.push(`/room?roomid=${chatId}`);
        setOpen(false);
    };
    const handleCancel = () => {
        setOpen(false);
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth={"xs"} fullWidth sx={{ px: 2, py: 1 }}>
            <Stack alignItems="center" p={3}>
                <Typography>{t('dialog.video.question')}</Typography>
                <Box display="flex" width="70px" height="70px" justifyContent="center" alignItems="center" sx={{ color: 'primary.main' }}>
                    <FontAwesomeIcon icon={faVideo} color="inherit" size="3x" fixedWidth />
                </Box>
                <Box display="flex">
                    <Button variant="contained" color="secondary" fullWidth onClick={handleCancel}>{t('dialog.video.no')}</Button>
                    <Box width="14px" />
                    <Button variant="contained" color="primary" fullWidth onClick={handleConfirm}>{t('dialog.video.yes')}</Button>
                </Box>
            </Stack>
        </Dialog>
    )
}