﻿import { GridLocaleText } from "@mui/x-data-grid";

export const zhTWGrid: Partial<GridLocaleText> = {
    // Root
    noRowsLabel: '沒有資料。',
    noResultsOverlayLabel: '資料沒有符合的結果。',
    errorOverlayDefaultLabel: '發生錯誤。',

    // Density selector toolbar button text
    toolbarDensity: '表格間距',
    toolbarDensityLabel: '表格間距',
    toolbarDensityCompact: '緊密',
    toolbarDensityStandard: '標準',
    toolbarDensityComfortable: '寬鬆',

    // Columns selector toolbar button text
    toolbarColumns: '列',
    toolbarColumnsLabel: '選擇列',

    // Filters toolbar button text
    toolbarFilters: '篩選器',
    toolbarFiltersLabel: '顯示篩選器',
    toolbarFiltersTooltipHide: '隱藏篩選器',
    toolbarFiltersTooltipShow: '顯示篩選器',
    toolbarFiltersTooltipActive: (count) => `${count} 個篩選器`,

    // Export selector toolbar button text
    toolbarExport: '匯出',
    toolbarExportLabel: '匯出',
    toolbarExportCSV: '匯出 CSV',

    // Columns panel text
    columnsPanelTextFieldLabel: '搜索列',
    columnsPanelTextFieldPlaceholder: '列名',
    columnsPanelDragIconLabel: '重排序列',
    columnsPanelShowAllButton: '顯示所有',
    columnsPanelHideAllButton: '隱藏所有',

    // Filter panel text
    filterPanelAddFilter: '添加篩選器',
    filterPanelDeleteIconLabel: '删除',
    filterPanelOperators: '邏輯運算子',
    filterPanelOperatorAnd: '以及',
    filterPanelOperatorOr: '或是',
    filterPanelColumns: '列',
    filterPanelInputLabel: '值',
    filterPanelInputPlaceholder: '篩選值',

    // Filter operators text
    filterOperatorContains: '包含',
    filterOperatorEquals: '等於',
    filterOperatorStartsWith: '開始於',
    filterOperatorEndsWith: '结束於',
    filterOperatorIs: '是',
    filterOperatorNot: '不是',
    filterOperatorAfter: '在後面',
    filterOperatorOnOrAfter: '正在後面',
    filterOperatorBefore: '在前面',
    filterOperatorOnOrBefore: '正在前面',
    filterOperatorIsEmpty: '為空',
    filterOperatorIsNotEmpty: '不為空',

    // Filter values text
    filterValueAny: 'Any',
    filterValueTrue: 'True',
    filterValueFalse: 'False',

    // Column menu text
    columnMenuLabel: '選單',
    columnMenuShowColumns: '顯示',
    columnMenuFilter: '篩選器',
    columnMenuHideColumn: '隱藏',
    columnMenuUnsort: '恢復預設',
    columnMenuSortAsc: '升序',
    columnMenuSortDesc: '降序',

    // Column header text
    columnHeaderFiltersTooltipActive: (count) =>
        count !== 1 ? `${count} 個篩選器` : `${count} 個篩選器`,
    columnHeaderFiltersLabel: '顯示篩選器',
    columnHeaderSortIconLabel: '排序',

    // Rows selected footer text
    footerRowSelected: (count) => `共選中了${count.toLocaleString()}行`,

    // Total rows footer text
    footerTotalRows: '所有行:',

    // Total visible rows footer text
    footerTotalVisibleRows: (visibleCount, totalCount) =>
        `${visibleCount.toLocaleString()} / ${totalCount.toLocaleString()}`,

    // Checkbox selection text
    checkboxSelectionHeaderName: '多選框',

    // Boolean cell text
    booleanCellTrueLabel: 'True',
    booleanCellFalseLabel: 'False',

    // Used core components translation keys
    MuiTablePagination: {},
}