﻿import * as React from 'react';
import { FilledButton } from '../responsive-components';
import { styled } from '@mui/material';
import { useTranslation } from 'react-i18next';

const WhiteboardRoot = styled('div')({
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    overflow: 'hidden',
    pointerEvents: 'none',
});

interface WhiteboardProps {
    open: boolean;
}
const WhiteboardFrame = styled('div')<WhiteboardProps>(({ theme, open }) => ({
    zIndex: 3,
    height: '100%',
    marginLeft: open ? '0' : '100%',
    marginRight: '0',
    transform: open ? 'translate(0px, 0px)' : 'translate(100%, 0px)',
    transition: 'all 1.5s ' + theme.transitions.easing.easeInOut,
    pointerEvents: open ? 'auto' : 'none',
}));

const WhiteboardButton = styled(FilledButton)({
    position: 'absolute',
    top: -50,
    right: -10,
    fontSize: '1.2rem',
    zIndex: 4
});

export function Whiteboard(props) {
    const { room } = props;
    const { t, i18n } = useTranslation();

    const [open, setOpen] = React.useState(false);
    const handleClick = () => {
        setOpen(!open);
    };

    return (
        <>
            <WhiteboardButton onClick={handleClick}>
                {open ? t('media.close_whiteBoard'): t('media.show_whiteBoard')}
            </WhiteboardButton>
            <WhiteboardRoot>
                <WhiteboardFrame open={open}>
                    <iframe style={{ width: '100%', height: '100%' }} src={'https://excalidraw.com/#room=' + (room && room != '' ? room : '06180ca411b39adc4e6c,JZ65N4gVpsy85V2KU-mqtQ')} frameBorder="0"></iframe>
                </WhiteboardFrame>
            </WhiteboardRoot>
        </>
    )
};