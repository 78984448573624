﻿import { Box, Card, CardContent, Paper, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Booth, BoothExtendField, ItemType } from "../components/class";
import { boothCustomFieldList, uniqueBoothFieldList } from "../components/CustomField";
import { useGetBoothFieldNameListQuery } from '../components/services/booth';

export default function BoothIntro(props: BoothIntroProps) {
    const { normalBoothData } = props;
    const { t, i18n } = useTranslation();

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('lg'));
    const matchesPhone = useMediaQuery(theme.breakpoints.down('sm'));

    const { data: boothFieldNameList } = useGetBoothFieldNameListQuery();
    function boothFieldIdToName(fieldId: string) {
        let fieldName = '';
        if (boothFieldNameList) {
            let boothFieldName = boothFieldNameList.data.find(x => x.fieldId === fieldId);
            if (boothFieldName) {
                if (i18n.language === 'en') {
                    if (boothFieldName.fieldName[1])
                        fieldName = boothFieldName.fieldName[1];
                }
                else {
                    if (boothFieldName.fieldName[0])
                        fieldName = boothFieldName.fieldName[0];
                }
            }
        }
        return fieldName;
    }
    function boothFieldValueToType(fieldId: string, value: string) {
        let fieldValue = null;
        if (boothFieldNameList) {
            let boothFieldName = boothFieldNameList.data.find(x => x.fieldId === fieldId);
            if (boothFieldName) {
                switch (boothFieldName.fieldType) {
                    case ItemType.String:
                        fieldValue = String(value);
                        break;
                    case ItemType.Int:
                        fieldValue = Number(value);
                        break;
                    case ItemType.Bool:
                        fieldValue = Boolean(value);
                        break;
                    default:
                        fieldValue = '';
                }
            }
        }
        return fieldValue;
    }

    //EDM
    const edmIndex = normalBoothData.fieldList.findIndex(x => x.fieldId === "edm")
    const edm = edmIndex != -1 ? normalBoothData.fieldList[edmIndex].value : null;

    //應徵方式
    const howToApplyIndex = normalBoothData.fieldList.findIndex(x => x.fieldId === "job")
    const howToApplyElement = howToApplyIndex != -1 ? boothCustomFieldList.find(x => x.id === "job") : null;

    return (
        <div style={{
            display: 'flex',
            //flexWrap: matches ? 'nowrap' : 'wrap',
            //flexDirection: matches ? 'column' : 'row',
            flexWrap: 'nowrap',
            flexDirection: 'column'
        }}>
            <Box style={{ flex: '1 0 50%', paddingRight: !matches && '12px' }}>
                <Stack spacing={4}>
                    {normalBoothData && normalBoothData.fieldList.filter(x => !uniqueBoothFieldList.includes(x.fieldId) && x.value !== null).filter(x => x.fieldId !== 'video').map((boothField) => {
                        let field = boothCustomFieldList.find(x => x.id === boothField.fieldId);
                        if (field && (boothField.value != '' && boothField.value != null)) {
                            return (
                                <div key={boothField.fieldId}>
                                    {field && field.displayComponent({
                                        displayTitle: `${boothFieldIdToName(boothField.fieldId)}`,
                                        displayValue: `${boothFieldValueToType(boothField.fieldId, boothField.value)}`,
                                    })}
                                </div>
                            )
                        }
                    })}
                </Stack>
            </Box>
            <Box style={{ flex: '1 0 50%', marginTop: /*matches && */'12px' }}>
                {edm &&
                    <Box>
                        <img src={edm} style={{ width: '100%' }} />
                    </Box>
                }
                {howToApplyElement &&
                    <Card
                        sx={{
                            backgroundColor: (theme) => theme.palette.primary.main,
                            color: (theme) => theme.palette.primary.contrastText,
                            marginTop: '12px'
                        }}
                    >
                        <CardContent>
                            {howToApplyElement.displayComponent({
                                displayTitle: `${boothFieldIdToName(howToApplyElement.id)}`,
                                displayValue: `${boothFieldValueToType(howToApplyElement.id, normalBoothData.fieldList[howToApplyIndex].value)}`,
                            })
                            }
                        </CardContent>
                    </Card>
                }
            </Box>
        </div>
    )
}
interface BoothIntroProps {
    normalBoothData: Booth;
}