﻿import * as React from 'react';
import { useUser } from '../../../store';
import BoothArticleList from '../../../components/Article/BoothArticleList';
import { SubTitle } from '../../../components/responsive-components/Typography';
import { useTranslation } from 'react-i18next';
import { Container } from '@mui/material';
import { UserRole } from '../../../components/class';

export default function ArticleListManage(props) {
    const { t, i18n } = useTranslation();
    const user = useUser();

    const [isManage, setIsManage] = React.useState(false);
    React.useEffect(() => {
        if (user) {
            let isboothMember = false;
            if (user.boothIds.length > 0 && user.role >= UserRole.normal) {
                isboothMember = true;
            }
            else if (user.role >= UserRole.administrator) {
                isboothMember = true;
            }
            setIsManage(isboothMember);
        }
    }, [user])

    return (<>
            <SubTitle
                icon='circle'
                text={t('manage.page.manageTool.article')}
            />
            {user &&
                <BoothArticleList boothId={null} isManage={isManage} showPin={true} />
            }
    </>)
};