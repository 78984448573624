﻿import * as React from 'react';
import { useUser } from '../../store';
import { SubTitle } from '../../components/responsive-components/Typography';
import { useTranslation } from 'react-i18next';
import { Box, Button, Divider, Paper, Theme, Typography, useMediaQuery, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { BoothQandA, UserRole } from '../../components/class';
import { BoardCardGridList } from '../../Booth/BoardCard/BoardCardTable';
import { cloneDeep } from 'lodash';
import { useGetAllBoothQandAsQuery, usePostQandAMutation } from '../../components/services/booth';
import { isNullOrUndefined } from '../../components/Admin/Utils';

const toggleSx: SxProps<Theme>[] = [{ color: "text.primary" }, { color: "primary", fontWeight: "bold" }];

export default function BoardCardManage(props: BoardCardManageProps) {
    const { boothId } = props;

    const { t, i18n } = useTranslation();
    const user = useUser();

    const [isManage, setIsManage] = React.useState(false);
    React.useEffect(() => {
        if (user) {
            let isboothMember = false;
            if (user.boothIds.includes(boothId) && user.role >= UserRole.normal) {
                isboothMember = true;
            }
            else if (user.role >= UserRole.administrator) {
                isboothMember = true;
            }
            setIsManage(isboothMember);
        }
    }, [user])

    return (
        <div>
            <SubTitle
                icon='circle'
                text={t('manage.page.prepTool.boardCardTable')}
            />
            {user && 
                <BoardCardManagePanel boothId={boothId} isManage={isManage} />
            }
        </div>
    )
};
interface BoardCardManageProps {
    boothId: string;
}

function BoardCardManagePanel(props: BoardCardManagePanelProps) {
    const user = useUser();
    const { boothId, isManage } = props;

    const { t, i18n } = useTranslation();

    const theme = useTheme();
    const tabletMatches = useMediaQuery(theme.breakpoints.up('sm'));
    const pcMatches = useMediaQuery(theme.breakpoints.up('lg'));

    const { data: boardsData, isLoading, isSuccess, isError, refetch } = useGetAllBoothQandAsQuery(boothId);
    const [postQandA] = usePostQandAMutation();

    const [boards, setBoards] = React.useState<BoothQandA[]>([]);
    React.useEffect(() => {
        if (boothId) {
            refetch();
        }
    }, [boothId])
    React.useEffect(() => {
        if (isSuccess) {
            if (boardsData.data)
            setBoards(cloneDeep(boardsData.data))
            //setBoards(JSON.parse(JSON.stringify(boardsData.data)));
        }
    }, [boardsData])

    //篩選問題
    enum boardType {
        全部 = -1,
        待回覆 = 0,
        已回覆 = 1
	}
    const [filterType, setFilterType] = React.useState<boardType>(-1);
    function handleChangeFilterType(value: boardType) {
        setFilterType(value);
    }
    function filterBorads(dataList: BoothQandA[]) {
        switch (filterType) {
            case boardType.待回覆:
                return dataList.filter(x => isNullOrUndefined(x.answer) || x.answer === '');
            case boardType.已回覆:
                return dataList.filter(x => !isNullOrUndefined(x.answer) && x.answer !== '' );
            default:
                return dataList;
		}
    }

    //是否用新舊排序
    const [isNew, setIsNew] = React.useState<boolean>(true);
    function handleChangeSort(value: boolean) {
        setIsNew(value);
    }

    //資料更改
    function onChange(dataList: BoothQandA[]) {
        setBoards([...dataList]);
    }

    return (<>
        <div style={{
            display: 'flex',
            padding: '12px 0px',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexWrap: 'wrap'
        }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Button
                    onClick={() => handleChangeFilterType(boardType.全部)}
                    sx={toggleSx[Number(filterType === boardType.全部)]}
                >
                    <Typography variant={tabletMatches ? 'subtitle1' : 'subtitle2'}>
                        {t('boardCard.table.none_filter')}
                    </Typography>
                </Button>
                <Button
                    onClick={() => handleChangeFilterType(boardType.待回覆)}
                    sx={toggleSx[Number(filterType === boardType.待回覆)]}
                >
                    <Typography variant={tabletMatches ? 'subtitle1' : 'subtitle2'}>
                        {t('boardCard.table.not_reply_filter')}
                    </Typography>
                </Button>
                <Button
                    onClick={() => handleChangeFilterType(boardType.已回覆)}
                    sx={toggleSx[Number(filterType === boardType.已回覆)]}
                >
                    <Typography variant={tabletMatches ? 'subtitle1' : 'subtitle2'}>
                        {t('boardCard.table.already_reply_filter')}
                    </Typography>
                </Button>
            </div>

            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Button
                    onClick={() => handleChangeSort(true)}
                    sx={toggleSx[Number(isNew)]}
                >
                    <Typography variant={tabletMatches ? 'subtitle1' : 'subtitle2'}>
                        {t('boardCard.table.newest_sort')}
                    </Typography>
                </Button>
                <Divider orientation="vertical" variant="middle" flexItem style={{ backgroundColor: '#000000' }} />
                <Button
                    onClick={() => handleChangeSort(false)}
                    sx={toggleSx[Number(!isNew)]}
                >
                    <Typography variant={tabletMatches ? 'subtitle1' : 'subtitle2'}>
                        {t('boardCard.table.popular_sort')}
                    </Typography>
                </Button>
            </div>
        </div>

        <Paper style={{ height: 780, padding: '12px 0px' }}>
            <Box style={{ overflowY: 'scroll', height: '100%' }}>
                <BoardCardGridList
                    boards={filterBorads(boards)}
                    isManage={isManage}
                    onChange={onChange}
                    sortByNew={isNew}
                    matches={tabletMatches}
                />
            </Box>
        </Paper>
    </>)
};
interface BoardCardManagePanelProps {
    boothId: string;
    isManage: boolean;
}
