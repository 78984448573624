﻿import { Button, CardContent, Divider, Paper, Typography } from '@mui/material';
import * as React from 'react';
import { useHistory, useLocation, useParams, useRouteMatch } from "react-router-dom";
import RetypePage from './RetypePage';
import ExpiredPage from './ExpiredPage';
import LoginPage from './LoginPage';
import RegisterPage from './RegisterPage';
import VerifyPage from './VerifyPage';
import ErrorPage from '../components/ErrorPage/404Page';
import { useLazyGetIsLoginQuery } from '../components/services/login';
import { useDispatchWithType, useUser } from '../store';
import { loginUser, updateUser } from '../store/rootReducer';
import { useLazyCheckInviteIfNewAccountQuery, useGetInviteQuery, useLazyInviteStateCheckQuery, AccountLoginType, InviteState, useRegisterWithInviteMutation, useVerifyInviteMutation } from '../components/services/invite';
import { BoothManageInvitation } from '../components/class';
import { PaperWrap } from '../Login/LoginWindow';
import { isNullOrUndefined } from '../components/Admin/Utils';

export default function InviteIndex() {
    const history = useHistory();
    const location = useLocation();
    const query = new URLSearchParams(useLocation().search);
    const user = useUser();

    //頁面控制
    const [code, setCode] = React.useState<string>(query.get("id"));
    const [page, setPage] = React.useState<string>(null);
    let { action } = useParams<{ action?: string }>();
    React.useEffect(() => {
        if (code && (!action ||
            action?.toLowerCase() === "register" ||
            action?.toLowerCase() === "login" ||
            action?.toLowerCase() === "expired" ||
            action?.toLowerCase() === "verify" ||
            action?.toLowerCase() === "retype")) {
            setPage(action?.toLowerCase());
        }
        else {
            setPage("error");
        }
    }, [action]);

    //邀請碼確認
    const [invite, setInvite] = React.useState<BoothManageInvitation>(null);
    const { data: inviteData } = useGetInviteQuery(code, {
        refetchOnMountOrArgChange: true
    });
    React.useEffect(() => {
        if (inviteData) {
            if (inviteData.isSuccess) {
                setInvite(inviteData.data);
                getIsLogin();
            }
            else {
                setPage("error");
            }
        }
    }, [inviteData])

    //登入狀態
    const dispatch = useDispatchWithType();
    const [getIsLogin, { data: loginState, isError, error }] = useLazyGetIsLoginQuery();
    React.useEffect(() => {
        if (loginState) {
            if (loginState.isSuccess) {
                console.log('Already Login!')
                dispatch(loginUser(loginState.data));

                handleInviteStateCheck();
            }
            else {
                console.log('Not Login!')

                handleCheckInviteIfNewAccount();
            }
        }
    }, [loginState])

    //確認邀請是否需要開新帳號(使用者未登入)
    const [accountLoginTypeList, setAccountLoginTypeList] = React.useState<AccountLoginType[]>([]);
    const [checkInviteIfNewAccount, { data: ifNewAccountData, isSuccess: isIfNewAccountSuccess }] = useLazyCheckInviteIfNewAccountQuery();
    function handleCheckInviteIfNewAccount() {
        checkInviteIfNewAccount(code);
    }
    React.useEffect(() => {
        if (!ifNewAccountData || !isIfNewAccountSuccess) {
            return;
        }

        if (!ifNewAccountData.isSuccess) {
            return;
        }

        if (ifNewAccountData.data.length > 0) {
            setAccountLoginTypeList(ifNewAccountData.data);
            history.push(`/Invite/login?id=${code}`);
        }
        else {
            history.push(`/Invite/register?id=${code}`);
        }
    }, [ifNewAccountData])

    //註冊相關
    const [registerWithInvite, { }] = useRegisterWithInviteMutation();
    async function onRegisterSubmit(password: string) {
        if (!password || !code) {
            return "Error";
        }

        await registerWithInvite({ inviteCode: code, password: password }).unwrap()
            .then(result => {
                if (result.isSuccess && result.data) {
                    dispatch(loginUser(result.data));
                    history.push(`/Invite/verify?id=${code}`);
                    return null;
                }

                if (!result.isSuccess && result.data) {
                    dispatch(loginUser(result.data));
                    history.push(`/Invite/expired?id=${code}`);
                    return null;
                }

                return result.message;
            }).catch((error) => {
                //後端報錯 ex:500、404
                console.error("發生錯誤", error)
                return "Unconnect";
            });
    }

    //登入相關
    function handleLoginSuccess() {
        handleInviteStateCheck();
    }

    //確認邀請狀態(使用者已登入)
    const [inviteStateCheck, { data: inviteStateData, isSuccess: isInviteStateSuccess }] = useLazyInviteStateCheckQuery();
    function handleInviteStateCheck() {
        inviteStateCheck(code);
    }
    React.useEffect(() => {
        if (!inviteStateData || !isInviteStateSuccess) {
            return;
        }

        if (inviteStateData.isSuccess && inviteStateData.data === InviteState.success) {
            history.push(`/Invite/verify?id=${code}`);
        }
        else {
            if (inviteStateData.data === InviteState.retype) {
                history.push(`/Invite/retype?id=${code}`);
            }
            else if (inviteStateData.data === InviteState.expired) {
                history.push(`/Invite/expired?id=${code}`);
            }
            else if (inviteStateData.data === InviteState.owned) {
                history.push(`/Invite/retype?id=${code}`);
            }
            else {
                history.push(`/Invite/error?id=${code}`);
            }
        }
    }, [inviteStateData])

    //邀請接受驗證
    const [verifyInvite, { }] = useVerifyInviteMutation();
    async function handleVerifyInvite(verify: boolean) {
        if (isNullOrUndefined(verify) || !code) {
            return "Error";
        }

        await verifyInvite({ inviteCode: code, verify: verify }).unwrap()
            .then(result => {
                if (result.isSuccess) {
                    dispatch(updateUser({ ...user, boothIds: [...user.boothIds, result.data as string] }));

                    history.push(`/`);
                    return null;
                }

                setPage("error");
                return result.message;
            }).catch((error) => {
                //後端報錯 ex:500、404
                console.error("發生錯誤", error)
                return "Unconnect";
            });
    }

    //重新確認帳號狀態
    function handleRecheckInvite() {
        handleCheckInviteIfNewAccount();
    }

    return (
        <div className="root bg-lavender">
            <PaperWrap className="py-5" elevation={3}>
                {page === "register" &&
                    <RegisterPage
                        code={code}
                        data={invite}
                        handleSubmitRegister={onRegisterSubmit}
                    />
                }
                {page === "login" &&
                    <LoginPage
                        code={code}
                        data={invite}
                        handleLoginSuccess={handleLoginSuccess}
                        normalLogin={accountLoginTypeList.includes(AccountLoginType.normal)}
                        googleLogin={accountLoginTypeList.includes(AccountLoginType.google)}
                    />
                }
                {page === "expired" &&
                    <ExpiredPage
                        code={code}
                        data={invite}
                    />
                }
                {page === "verify" &&
                    <VerifyPage
                        code={code}
                        data={invite}
                        handleSubmitVerify={handleVerifyInvite}
                    />
                }
                {page === "retype" &&
                    <RetypePage
                        code={code}
                        data={invite}
                        handleRecheckInvite={handleRecheckInvite}
                    />
                }
                {page === "error" &&
                    <ErrorPage />
                }
            </PaperWrap>
        </div >
    );
}