﻿import * as React from 'react';
import { SubTitle } from '../../../components/responsive-components/Typography';
import { useTranslation } from 'react-i18next';
import { Seminar } from '../../../components/class';
import { cloneDeep } from 'lodash';
import { useGetAllSeminarsQuery } from '../../../components/services/seminar';
import { AgendaList } from '../../../Booth/Seminar/AgendaList';

export default function StageProgram() {
    const { t } = useTranslation();

    const { data: seminarsData, isSuccess, refetch } = useGetAllSeminarsQuery(null, { refetchOnMountOrArgChange: true });
    const [seminars, setSeminars] = React.useState<Seminar[]>([]);
    React.useEffect(() => {
        if (isSuccess) {
            setSeminars(cloneDeep(seminarsData.data))
        }
    }, [seminarsData])

    function handleChange(dataList: Seminar[]) {
        refetch();
    }

    return (<>
        <SubTitle
            icon='circle'
            text={t('manage.title.manage.programSchedule')}
        />
        <AgendaList
            seminars={seminars}
            isManage={true}
            onChange={handleChange}
            showTitle={false}
        />
    </>)
};