﻿import * as React from 'react';
import { Box, Typography, styled, Toolbar, alpha, BoxProps } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link, scroller } from 'react-scroll';
import { ChevronRight, ExpandMore, Key, Logout } from '@mui/icons-material';
import { ManageSideBarItem } from './ManageBoardIndex';
import { TreeItem, TreeView, TreeItemProps, TreeViewProps, treeItemClasses } from '@mui/lab';
import { useDispatchWithType, useScrollTrigger } from '../store';
import { useLogoutMutation } from '../components/services/login';
import { deleteAllCookie } from '../cookie-utils';
import { logoutUser, setScrollTrigger } from '../store/rootReducer';
import scrollProps from './scrollProps';

const ManageSideBarItem: (props: TreeItemProps) => JSX.Element = styled(TreeItem)(({ theme }) => ({
	"& a": {
		textDecoration: 'none',
	},
	//spy
	[`& a.active .${treeItemClasses.content}`]: {
		//backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
		//backgroundColor: theme.palette.action.selected,
		'&:hover': {
			backgroundColor: theme.palette.action.selected,
			[`& .${treeItemClasses.label}`]: {
				color: theme.palette.primary.main,
			}
		},
		[`& .${treeItemClasses.label}`]: {
			fontWeight: 'bold',
			color: theme.palette.primary.main,
		}
	},
	//第一層
	[`&.section > .${treeItemClasses.content}`]: {
		minHeight: theme.spacing(5),
		[`& .${treeItemClasses.label}`]: {
			fontWeight: 'normal',
			color: theme.palette.primary.main,
		},
		[`&:hover .${treeItemClasses.label}`]: {
			fontWeight: 'bold',
			color: theme.palette.primary.main,
		},
		//下拉icon
		[`& .${treeItemClasses.iconContainer}`]: {
			color: theme.palette.grey[700],
		},
		'&.Mui-selected, &.Mui-selected.Mui-focused': {
			backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
		},
	},
	//全部
	[`& .${treeItemClasses.content}`]: {
		width: "248px",
		minHeight: theme.spacing(4),
		flexDirection: 'row-reverse',
		borderRadius: "0px 50px 50px 0px",
		[`& .${treeItemClasses.label}`]: {
			display: "flex",
			alignItems: "center",
		},
		'&.Mui-focused': {
			backgroundColor: 'unset',
		},
		'&.Mui-selected, &.Mui-selected.Mui-focused': {
			//backgroundColor: theme.palette.action.selected,
			backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.hoverOpacity),
			
		},
		'&.Mui-selected:hover, &:hover': {
			//backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
			backgroundColor: theme.palette.action.selected,
		}
	},
	[`& .${treeItemClasses.group}`]: {
		marginLeft: 0,
		//第二層
		[`& .${treeItemClasses.content}`]: {
			paddingBlock: theme.spacing(0.5),
			paddingLeft: theme.spacing(4),
			[`& .${treeItemClasses.label}`]: {
				fontWeight: 'normal',
				color: theme.palette.grey[600],
			},
			[`&:hover .${treeItemClasses.label}`]: {
				fontWeight: 'bold',
				color: theme.palette.grey[700],
			},
			//下拉icon
			[`& .${treeItemClasses.iconContainer}`]: {
				color: theme.palette.grey[500],
			}
		},
		//第三層
		[`& .${treeItemClasses.group} .${treeItemClasses.content}`]: {
			paddingLeft: theme.spacing(6),
		},
		[`& .${treeItemClasses.group} a.active .${treeItemClasses.content}`]: {
			backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
		},
	}
}));

const ManageSideBarTree: (props: TreeViewProps) => JSX.Element = styled(TreeView)(({ theme }) => ({
	flexGrow: 1,
	overflow: 'hidden',
	"&:hover": {
		overflowY: 'auto'
	},
	"&::-webkit-scrollbar": {
		width: 6,
	},
	"&::-webkit-scrollbar-thumb": {
		borderRadius: 5,
		//boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.1)',
		background: alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
	}
}));

const Gap: (props: BoxProps) => JSX.Element = styled(Box)(({ theme }) => ({
	height: 0,
	"&.overflow": {
		content: "' '",
		height: "4px",
		background: `radial-gradient(${theme.palette.grey[400]} 0%, ${theme.palette.grey[300]} 20%, ${theme.palette.background.default} 80%)`,
		transition: theme.transitions.create(['background']),
	}
}));

function ManageSideBarButton(props: ManageSideBarButtonProps) {
	const { treeData, handleChange, currentPage, className, showIcon } = props;
	const { t, i18n } = useTranslation();

	async function handleClick() {
		await handleChange(treeData);
	}
	//避免不是同一瀏覽頁的spy link亮起
	var activeClass = treeData?.value === currentPage ? 'active' : 'x';

	const TreeNode = ManageSideBarItem;
	const TreeBranch = ManageSideBarButton;
	if (!treeData) return null;
	if (treeData.pageList?.length > 0) {
		return (
			<TreeNode nodeId={treeData.name} className={className}
				label={<>
					<Box mr={1}>{showIcon && treeData.icon}</Box>
					<Typography variant="subtitle1" fontWeight="inherit">
						{treeData.noTranslate ? (i18n.language === "ch" ? treeData.name : treeData.enName) : t(treeData.name)}
					</Typography>
				</>}
			>
				{treeData.pageList.map((data) => (
					<TreeBranch
						treeData={data}
						handleChange={handleChange}
						currentPage={currentPage}
						key={`${data.className}: ${t(data.name)}`}
					/>
				))}
			</TreeNode>
		)
	} else {
		return (
			<Link to={treeData.name} {...scrollProps} activeClass={activeClass}>
				<TreeNode nodeId={treeData.name} className={className}
					label={<>
						<Box mr={1}>{showIcon && treeData.icon}</Box>
						<Typography variant="body2" fontWeight="inherit">
							{treeData.noTranslate ? (i18n.language === "ch" ? treeData.name : treeData.enName) : t(treeData.name)}
						</Typography>
					</>}
					onClick={handleClick} />
			</Link>
		)
	}
}
interface ManageSideBarButtonProps {
	treeData: ManageSideBarItem;
	handleChange: (node: ManageSideBarItem) => Promise<void>;
	currentPage: string;
	className?: string;
	showIcon?: boolean;
}

export default function ManageSideBar(props: ManageSideBarProps) {
	const { allowSideBarList, handleChange, currentPage, defaultExpanded } = props;
	const { t } = useTranslation();

	//登出
	const dispatch = useDispatchWithType();
	const [logout] = useLogoutMutation();
	const handleClickLogout = () => {
		logout().unwrap().then(result => {
			if (result.isSuccess) {
				dispatch(logoutUser());
				deleteAllCookie();
				window.location.href = '/Login';
			}
		}).catch((error) => {
			console.log("Logout Failed!");
			console.log(error);
		});
	};

	//紀錄目前節點
	const [currentNode, setCurrentNode] = React.useState<ManageSideBarItem>();
	const [selected, setSelected] = React.useState<string[]>(defaultExpanded);
	function find(nodes: ManageSideBarItem[], criteriaFn) {
		let path = [];
		for (let i = 0; i < nodes.length; i++) {
			if (nodes[i].pageList?.length > 0) {
				path = find(nodes[i].pageList, criteriaFn);
				if (path.length > 0) {
					return [nodes[i].name, ...path];
                }
			}
			else if (criteriaFn(nodes[i])) {
				return [nodes[i].name];
			}
		}
		return path;
	}
	async function handleOnChange(node: ManageSideBarItem) {
		let element = find(allowSideBarList, item => item.name === node.name);
		setSelected(element);
		setCurrentNode(node);
		return handleChange(node.value, node.query);
	};

	//偵測跳頁完成後，重新刷新scroll
	const scrollTrigger = useScrollTrigger();
	React.useEffect(() => {
		if (scrollTrigger) {
			setTimeout(() => {
				scroller.scrollTo(currentNode.name, scrollProps);
			}, 100);
			dispatch(setScrollTrigger(false));
		}
	}, [scrollTrigger]);

	//判斷TreeView有沒有overflow
	const containerRef = React.useRef<HTMLBaseElement>();
	const [isOverflow, setIsOverflow] = React.useState<boolean>(false);
	React.useEffect(() => {
		const resizeObserver = new ResizeObserver(entries => {
			setIsOverflow(entries[0].target.scrollHeight > entries[0].target.clientHeight);
		});
		resizeObserver.observe(containerRef.current)
		return (() => {
			resizeObserver.disconnect();
		})
	}, []);

	const TreeView = ManageSideBarTree;
	const TreeBranch = ManageSideBarButton;
	return (
		<Box display="flex" flex="1" flexDirection="column" overflow="hidden" paddingTop={11}>
			<TreeView
				ref={containerRef}
				defaultCollapseIcon={<ExpandMore />}
				defaultExpandIcon={<ChevronRight />}
				defaultExpanded={defaultExpanded}
				selected={selected}
			>
				{allowSideBarList.map((data) => (
					<TreeBranch
						treeData={data}
						currentPage={currentPage}
						handleChange={handleOnChange}
						key={`${data.className}: ${t(data.name)}`}
						className="section"
						showIcon
					/>
				))}
			</TreeView>
			<Gap className={isOverflow ? "overflow" : ""} />
			<Box display="flex" flex="1 0 0" />
			<Box display="flex" alignItems="center" pl={1.5} py={1} color="primary.main"
				sx={{
					width: "248px",
					borderRadius: "0px 50px 50px 0px",
					cursor: "pointer",
					"&:hover": { background: (theme) => theme.palette.action.selected, fontWeight: 'bold', }
				}}
				onClick={handleClickLogout}
			>
				<Box mr={1}><Logout /></Box>
				<Typography variant="subtitle1" color="primary.main" fontWeight="inherit">
					{t('manage.title.logout')}
				</Typography>
			</Box>
			<Toolbar variant="dense" />
		</Box >
	)
}
interface ManageSideBarProps {
	allowSideBarList: ManageSideBarItem[];
	handleChange?: (newValue: string, query?: string) => Promise<void>;
	currentPage: string;
	defaultExpanded?: string[];
}