﻿import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Backdrop, Box, Breakpoint, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Stack, styled, Theme, Typography } from '@mui/material';
import { SxProps } from "@mui/system";
import { IQuickStartContentProps, useQuickStartContents } from './QuickStartContent';
import StepProgressBar from '../components/StepProgressBar';
import { useUser } from '../store';
import { CardType } from '../components/class';
import { useHistory, useLocation } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';

export interface QuickStartProps {
    maxWidth?: Breakpoint;
    scroll?: "body" | "paper";
    sx?: SxProps<Theme>;
}

export default function QuickStart(props: QuickStartProps) {
    const { sx, ...other } = props;
    const user = useUser();
    const history = useHistory();
    let query = new URLSearchParams(useLocation().search);
    const { t, i18n } = useTranslation();
    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };

    React.useEffect(() => {
        setType(user?.type);
    }, [user]);

    const [page, setPage] = React.useState<number>(0);
    const [type, setType] = React.useState<CardType>();
    const content = useQuickStartContents({ onPrevious: handlePrevious, onNext: handleNext, userType: type });
    function handlePrevious() {
        if (page > 0)
            setPage(page => page - 1);
    };
    function handleNext() {
        if (page < content.length)
            setPage(page => page + 1);
    };
    function handleFinish() {
        if (query.get('redirect')) {
            history.push(query.get('redirect'));
        }
        else {
            history.push("/");
        }
    };
    const Background = () => (<Backdrop open sx={{ background: (theme) => theme.palette.background.default }} />);
    const Button = PageStepButton;

    if (page == content.length) {
        return (
            <Dialog open fullWidth={true} maxWidth="sm" sx={{ "& .MuiDialog-paper": { minHeight: "80vh", border: "3px solid", boxShadow: "none", borderRadius: "10px" }, ...sx }} {...other} disableEscapeKeyDown BackdropComponent={Background}>
                <Box display="flex" justifyContent="space-between" px={3.75} py={3} sx={{ background: (theme) => theme.palette.primary.main }}>
                    <Box display="flex" color="primary.contrastText" my="-10px" ml="-25px">
                        <Button color="inherit" size="small" onClick={() => changeLanguage('ch')} sx={{ minWidth: "50px" }}>
                            {t('navbar.translation.ch')}
                        </Button>
                        <Divider orientation='vertical' flexItem sx={{ borderColor: "#ffffff", my: "6px" }} />
                        <Button color="inherit" size="small" onClick={() => changeLanguage('en')} sx={{ minWidth: "50px" }}>
                            {t('navbar.translation.en')}
                        </Button>
                    </Box>
                    <StepProgressBar total={3} currentStep={page}
                        width={100}
                        color={(theme) => theme.palette.background.black}
                        emptyColor={(theme) => theme.palette.background.default} />
                </Box>
                {/*<Divider sx={{ borderBottomWidth: "3px", borderColor: (theme) => theme.palette.background.black }} />*/}
                <DialogContent sx={{ display: "flex", flexDirection: "column" }}>
                    <Stack justifyContent="center" alignItems="center" flex="1 1 100%">
                        {/*<svg xmlns="http://www.w3.org/2000/svg" width="60.076" height="60.071" viewBox="0 0 60.076 60.071">*/}
                        {/*    <g id="Group_892" data-name="Group 892" transform="translate(0 0)">*/}
                        {/*        <path id="Path_379" data-name="Path 379" d="M45.594,36.9a2.425,2.425,0,0,1-.49.41,1.3,1.3,0,0,1-.242.108.789.789,0,0,1-.97-.444,1.653,1.653,0,0,1-.142-.818c.028-1.576-.074-3.166-.138-4.738a1.626,1.626,0,0,1,.423-1.143c7.193-7.994,10.3-14.809,10.579-19.7A4.863,4.863,0,0,0,49.5,5.462c-4.887.277-11.7,3.385-19.7,10.579a1.626,1.626,0,0,1-1.143.423c-1.572-.064-3.162-.166-4.738-.138a1.641,1.641,0,0,1-.818-.142.79.79,0,0,1-.445-.97,1.369,1.369,0,0,1,.109-.242,2.425,2.425,0,0,1,.41-.49A61.142,61.142,0,0,1,37.353,3.617,37.88,37.88,0,0,1,42.317,1.4,24.634,24.634,0,0,1,46.8.256,18.669,18.669,0,0,1,50.793.023a15.3,15.3,0,0,1,3.466.59L54.3.624a7.611,7.611,0,0,1,5.155,5.155c0,.013.007.025.011.037a15.25,15.25,0,0,1,.589,3.467,18.608,18.608,0,0,1-.232,3.994,24.634,24.634,0,0,1-1.146,4.482,38.014,38.014,0,0,1-2.215,4.964A61.142,61.142,0,0,1,45.594,36.9" fill="#1763fb" />*/}
                        {/*        <path id="Path_380" data-name="Path 380" d="M22.4,59.242a2.829,2.829,0,0,1-.829-2V26.494a.966.966,0,0,0-.965-.966H17.375a.967.967,0,0,0-.683.283l-5.381,5.381a.966.966,0,0,0,.683,1.649h4.768a.966.966,0,0,1,.966.966v3.729a.965.965,0,0,1-.966.965H2.831a2.83,2.83,0,0,1-2-4.831L13.518,20.98A3.794,3.794,0,0,1,16.2,19.869h7.235a3.8,3.8,0,0,1,3.8,3.8V48.077a.966.966,0,0,0,1.649.683l5.38-5.38a.969.969,0,0,0,.283-.684V39.564a.966.966,0,0,0-1.058-.961l-1.411.136a.965.965,0,0,1-1.059-.961V34.1a.966.966,0,0,1,.914-.964l5.443-.291a2.828,2.828,0,0,1,2.83,2.829l0,8.2a3.793,3.793,0,0,1-1.112,2.684L26.4,59.242a2.829,2.829,0,0,1-4,0" fill="#1763fb" />*/}
                        {/*    </g>*/}
                        {/*</svg>*/}
                        <Typography variant="h4" fontWeight="bold" align="center" sx={{ color: "primary.main", paddingBottom: 1 }}>
                            <Trans i18nKey={"quickStart.final.title1"} components={[<span style={{ color: "inherit", fontStyle: "italic" }}></span>]} />
                        </Typography>
                        <Typography variant="h6" fontWeight="bold" align="center" sx={{ whiteSpace: "pre-wrap" }}>
                            <Trans i18nKey={(type === CardType.unknown || type === CardType.student)
                                ? "quickStart.final.title2.student" : "quickStart.final.title2.nonStudent"} />
                        </Typography>
                    </Stack>
                </DialogContent>
                {/*<Divider sx={{ borderBottomWidth: "3px", borderColor: (theme) => theme.palette.background.black }} />*/}
                <Box display="flex" justifyContent="space-between" padding="8px 24px" sx={{ background: (theme) => theme.palette.primary.main, color: (theme) => theme.palette.background.paper }}>
                    <Button color="inherit" onClick={handlePrevious}>
                        <Trans i18nKey={"quickStart.button.previous"} />
                    </Button>
                    <Button sx={{ background: "white", color: "black", border: "2px solid black", minWidth: "90px", "&:hover": { background: "white", color: "black" } }} onClick={handleFinish}>
                        <Trans i18nKey={"quickStart.button.finish"} />
                    </Button>
                </Box>
            </Dialog>
        )
    } else {
        return (
            <Dialog open fullWidth={true} maxWidth="sm" sx={{ "& .MuiDialog-paper": { minHeight: "80vh", border: "3px solid", boxShadow: "none", borderRadius: "10px" }, ...sx }} {...other} disableEscapeKeyDown BackdropComponent={Background}>
                <Box display="flex" justifyContent="space-between" px={3.75} py={3} sx={{ background: (theme) => theme.palette.primary.main }}>
                    <Box display="flex" color="primary.contrastText" my="-10px" ml="-25px">
                        <Button color="inherit" size="small" onClick={() => changeLanguage('ch')} sx={{ minWidth: "50px" }}>
                            {t('navbar.translation.ch')}
                        </Button>
                        <Divider orientation='vertical' flexItem sx={{ borderColor: "#ffffff", my: "6px" }} />
                        <Button color="inherit" size="small" onClick={() => changeLanguage('en')} sx={{ minWidth: "50px" }}>
                            {t('navbar.translation.en')}
                        </Button>
                    </Box>
                    <StepProgressBar total={3} currentStep={page}
                        width={100}
                        color={(theme) => theme.palette.background.black}
                        emptyColor={(theme) => theme.palette.background.default} />
                </Box>
                {content[page]}
            </Dialog>
        )
    }
}
export const PageStepButton = styled(Button)({
    fontWeight: "bold",
});
export const PageStepLoadingButton = styled(LoadingButton)({
    fontWeight: "bold",
});

export function Test(props: IQuickStartContentProps) {
    const { onPrevious, onNext } = props;
    return (
        <Box>
            <Button onClick={onPrevious}>Previous</Button>
            <Button onClick={onNext}>Next</Button>
        </Box>
    )
}