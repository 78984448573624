﻿import * as React from 'react';
import { useUser } from '../../store';
import { SubTitle } from '../../components/responsive-components/Typography';
import { useTranslation } from 'react-i18next';
import { UserRole } from '../../components/class';
import JobOpeningTable from '../../Booth/JobOpening/JobOpeningTable';

export default function JobOpeningManage(props: JobOpeningManageProps) {
    const { boothId } = props;

    const { t, i18n } = useTranslation();
    const user = useUser();

    const [isManage, setIsManage] = React.useState(false);
    React.useEffect(() => {
        if (user) {
            let isboothMember = false;
            if (boothId && user.boothIds.includes(boothId)) {
                isboothMember = true;
            }
            else if (user.role >= UserRole.administrator) {
                isboothMember = true;
            }
            setIsManage(isboothMember);
        }
    }, [user, boothId])

    return (
        <div>
            <SubTitle
                icon='circle'
                text={t('manage.page.prepTool.jobOpening')}
            />
            {user && boothId && boothId != '' &&
                <JobOpeningTable boothId={boothId} isManage={isManage} />
            }
        </div>
    )
};
interface JobOpeningManageProps {
    boothId: string;
}