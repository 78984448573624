﻿import * as React from "react";

export const useProgressiveImage = src => {
    const [sourceLoaded, setSourceLoaded] = React.useState(null)

    React.useEffect(() => {
        const img = new Image()
        img.src = src
        img.onload = () => setSourceLoaded(src)
    }, [src])

    return sourceLoaded
}

export const LazyBackground = (props) => {
    const { source, placeholder, children, ...other } = props;
    const loaded = useProgressiveImage(source)

    return (
        <div style={{ backgroundImage: `url(${loaded || placeholder})` }} {...other}>
            {children}
        </div>
    )
}