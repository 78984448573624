﻿import * as React from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, CardActions, Dialog, DialogContent, FormControl, FormControlLabel, MenuItem, Paper, Radio, RadioGroup, Stack, styled, TextFieldProps, Typography, TypographyProps } from '@mui/material';
import { useForm } from 'react-hook-form';
import { JobOpening } from '../../components/class';
import { CancelButton, CardContent, ConfirmButton, important, TextField } from '../../components/responsive-components';
import * as yup from "yup";
import { City, cityData } from './City';
import { ResultType } from '../../components/services/utils';
import { CountDownTimer } from '../../components/Admin/Utils';
import { useTranslation } from 'react-i18next';
import { ErrorMessage } from '@hookform/error-message';

const StyledTitleTypography: (props: TypographyProps) => JSX.Element = styled(Typography)(({ theme }) => ({
    fontWeight: 'bold',
    marginRight: '24px',
}));
const StyledTextField: (props: TextFieldProps) => JSX.Element = styled(TextField)(({ theme }) => ({

}));

export function JobOpeningFormDialog(props: JobOpeningFormDialogProps) {
    const { open, defaultValue, handleSubmit, handleClose } = props;
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            scroll='paper'
            PaperProps={{
                style: {
                    overflow: 'initial'
                }
            }}
            maxWidth={"md"} fullWidth
        >
            <JobOpeningForm
                defaultValue={defaultValue}
                handleSubmit={handleSubmit}
                handleClose={handleClose}
            />
        </Dialog>
    )
}
interface JobOpeningFormDialogProps {
    open: boolean;
    defaultValue: JobOpening;
    handleSubmit: (value: JobOpening) => Promise<ResultType<JobOpening>>;
    handleClose: () => void;
}

const schema = yup.object().shape({
    //dataId: yup.string(),
    //boothId: yup.string(),
    title: yup.string().trim().required('必填項目'),
    forEngineer: yup.boolean().required('必填項目'),
    place: yup.number().required('必填項目'),
    placeDescipt: yup.string().trim().required('必填項目'),
    demandNum: yup.string().trim().required('必填項目'),
    treatment: yup.string().trim().required('必填項目'),
    qualify: yup.string().trim().required('必填項目'),
    content: yup.string().trim().required('必填項目'),
    memo: yup.string().trim(),
    link: yup.string().trim().url(),
});

export function JobOpeningForm(props: JobOpeningFormProps) {
    const { defaultValue, handleClose, handleSubmit } = props;
    const { t, i18n } = useTranslation();

    const [isLoading, setIsLoading] = React.useState(false);

    const { register, handleSubmit: handleOnSubmit, formState: { errors }, watch, setValue, reset } = useForm<JobOpening>({
        mode: "onChange", resolver: yupResolver(schema), defaultValues: defaultValue
    });

    const forEngineer = watch('forEngineer');
    const place = watch('place');
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.name == 'place') {
            setValue('place', Number(event.target.value));
        }
        if (event.target.name == 'forEngineer') {
            setValue('forEngineer', (event.target.value === 'true'));
        }
    };

    const [success, setSuccess] = React.useState<boolean>(false);
    const [error, setError] = React.useState<boolean>(false);
    async function onSubmit(data: JobOpening) {
        setIsLoading(true);
        await handleSubmit(data).then((result) => {
            if (result.isSuccess) {
                CountDownTimer(2, handleClose);
                setSuccess(true);
            } else {
                setError(true);
            }
        }).catch((error) => {
            console.log(error);
            setError(true);
        });
        setIsLoading(false);
    }

    return (
        <Box component="form" onSubmit={handleOnSubmit(onSubmit)} style={{ overflowY: 'auto', display: 'flex', flexDirection: 'column' }}>
            <CardContent>
                <Typography fontWeight='bold' variant='h6' style={{ paddingRight: '64px' }}>
                    {defaultValue && defaultValue.chName}
                </Typography>
                <Typography style={{ fontSize: '18px' }}>
                    {defaultValue && defaultValue.enName}
                </Typography>
                <Box className="d-flex flex-row flex-wrap justify-content-end" style={{ position: 'absolute', top: '24px', right: '24px' }}>
                    <Button onClick={() => handleClose()}>{t('jobOpening.form.back')}</Button>
                </Box>
            </CardContent>
            <CardContent style={{ flex: 1, overflow: 'auto' }}>
                <Stack spacing={1}>
                    <div id='name-field' style={{ display: 'flex' }}>
                        {important}
                        <TextField
                            variant='standard'
                            placeholder={t('jobOpening.form.name.placeholder')}
                            error={!!errors.title}
                            {...register("title")}
                            disabled={isLoading || success}
                            style={{ width: '100%' }}
                            InputProps={{ style: { fontSize: '28px' } }}
                        />
                    </div>
                    <div style={{ minHeight: '24px' }}>
                        <ErrorMessage
                            errors={errors}
                            name={'title'}
                            render={({ message }) =>
                                <Typography sx={{ color: (theme) => theme.palette.error.main }}>
                                    {`${t(message)}`}
                                </Typography>
                            }
                        />
                    </div>

                    <div id='forEngineer-field' style={{ display: 'flex', alignItems: 'center' }}>
                        <StyledTitleTypography>
                            {important}{t('jobOpening.form.forEngineer.title')}
                        </StyledTitleTypography>
                        <FormControl component='fieldset'>
                            <RadioGroup
                                row
                                name='forEngineer'
                                value={forEngineer}
                                onChange={handleChange}
                            >
                                <FormControlLabel control={<Radio />} value={true} label={`${t('jobOpening.form.forEngineer.yes')}`}
                                    disabled={isLoading} style={{ marginBottom: '0px' }} />
                                <FormControlLabel control={<Radio />} value={false} label={`${t('jobOpening.form.forEngineer.no')}`}
                                    disabled={isLoading} style={{ marginBottom: '0px' }} />
                            </RadioGroup>
                        </FormControl>
                    </div>
                    <div style={{ minHeight: '24px' }}>
                        <ErrorMessage
                            errors={errors}
                            name={'forEngineer'}
                            render={({ message }) =>
                                <Typography sx={{ color: (theme) => theme.palette.error.main }}>
                                    {`${t(message)}`}
                                </Typography>
                            }
                        />
                    </div>

                    <div id='place-field' style={{ display: 'flex', alignItems: 'center' }}>
                        <StyledTitleTypography>
                            {important}{t('jobOpening.form.place.title')}
                        </StyledTitleTypography>
                        <StyledTextField
                            select
                            size='small'
                            variant='outlined'
                            placeholder={t('jobOpening.form.place.placeholder')}
                            name='place'
                            value={place}
                            onChange={handleChange}
                            error={!!errors.place}
                            {...register("place")}
                            disabled={isLoading || success}
                        >
                            {cityData.map((city) => (
                                <MenuItem value={city.type}>{t(city.nameId)}</MenuItem>
                            ))}
                        </StyledTextField>
                    </div>
                    <div style={{ minHeight: '24px' }}>
                        <ErrorMessage
                            errors={errors}
                            name={'place'}
                            render={({ message }) =>
                                <Typography sx={{ color: (theme) => theme.palette.error.main }}>
                                    {`${t(message)}`}
                                </Typography>
                            }
                        />
                    </div>

                    <div id='placeDescipt-field' style={{ display: 'flex', alignItems: 'center' }}>
                        <StyledTitleTypography>
                            {important}{t('jobOpening.form.placeDescipt.title')}
                        </StyledTitleTypography>
                        <StyledTextField
                            variant='outlined'
                            size='small'
                            placeholder={t('jobOpening.form.placeDescipt.placeholder')}
                            error={!!errors.placeDescipt}
                            {...register("placeDescipt")}
                            disabled={isLoading || success}
                            style={{ flex: 1 }}
                        />
                    </div>
                    <div style={{ minHeight: '24px' }}>
                        <ErrorMessage
                            errors={errors}
                            name={'placeDescipt'}
                            render={({ message }) =>
                                <Typography sx={{ color: (theme) => theme.palette.error.main }}>
                                    {`${t(message)}`}
                                </Typography>
                            }
                        />
                    </div>

                    <div id='demandNum-field' style={{ display: 'flex', alignItems: 'center' }}>
                        <StyledTitleTypography>
                            {important}{t('jobOpening.form.demandNum.title')}
                        </StyledTitleTypography>
                        <StyledTextField
                            variant='outlined'
                            size='small'
                            placeholder={t('jobOpening.form.demandNum.placeholder')}
                            error={!!errors.demandNum}
                            {...register("demandNum")}
                            disabled={isLoading || success}
                            style={{ width: '120px', marginRight: '24px' }}
                        />
                        <Typography>人</Typography>
                    </div>
                    <div style={{ minHeight: '24px' }}>
                        <ErrorMessage
                            errors={errors}
                            name={'demandNum'}
                            render={({ message }) =>
                                <Typography sx={{ color: (theme) => theme.palette.error.main }}>
                                    {`${t(message)}`}
                                </Typography>
                            }
                        />
                    </div>

                    <div id='treatment-field' style={{ display: 'flex', alignItems: 'center' }}>
                        <StyledTitleTypography>
                            {important}{t('jobOpening.form.treatment.title')}
                        </StyledTitleTypography>
                        <StyledTextField
                            variant='outlined'
                            size='small'
                            placeholder={t('jobOpening.form.treatment.placeholder')}
                            error={!!errors.treatment}
                            {...register("treatment")}
                            disabled={isLoading || success}
                            style={{ flex: 1 }}
                        />
                    </div>
                    <div style={{ minHeight: '24px' }}>
                        <ErrorMessage
                            errors={errors}
                            name={'treatment'}
                            render={({ message }) =>
                                <Typography sx={{ color: (theme) => theme.palette.error.main }}>
                                    {`${t(message)}`}
                                </Typography>
                            }
                        />
                    </div>

                    <div id='qualify-field'>
                        <StyledTitleTypography>
                            {important}{t('jobOpening.form.qualify.title')}
                        </StyledTitleTypography>
                        <TextField
                            multiline
                            rows={4}
                            variant='outlined'
                            size='small'
                            placeholder={t('jobOpening.form.qualify.placeholder')}
                            error={!!errors.qualify}
                            {...register("qualify")}
                            disabled={isLoading || success}
                            style={{ width: '100%' }}
                        />
                    </div>
                    <div style={{ minHeight: '24px' }}>
                        <ErrorMessage
                            errors={errors}
                            name={'qualify'}
                            render={({ message }) =>
                                <Typography sx={{ color: (theme) => theme.palette.error.main }}>
                                    {`${t(message)}`}
                                </Typography>
                            }
                        />
                    </div>

                    <div id='content-field'>
                        <StyledTitleTypography>
                            {important}{t('jobOpening.form.content.title')}
                        </StyledTitleTypography>
                        <TextField
                            multiline
                            rows={4}
                            variant='outlined'
                            size='small'
                            placeholder={t('jobOpening.form.content.placeholder')}
                            error={!!errors.content}
                            {...register("content")}
                            disabled={isLoading || success}
                            style={{ width: '100%' }}
                        />
                    </div>
                    <div style={{ minHeight: '24px' }}>
                        <ErrorMessage
                            errors={errors}
                            name={'content'}
                            render={({ message }) =>
                                <Typography sx={{ color: (theme) => theme.palette.error.main }}>
                                    {`${t(message)}`}
                                </Typography>
                            }
                        />
                    </div>

                    <div id='memo-field'>
                        <StyledTitleTypography>
                            {t('jobOpening.form.memo.title')}
                        </StyledTitleTypography>
                        <TextField
                            multiline
                            rows={4}
                            variant='outlined'
                            size='small'
                            placeholder={t('jobOpening.form.memo.placeholder')}
                            error={!!errors.memo}
                            {...register("memo")}
                            disabled={isLoading || success}
                            style={{ width: '100%' }}
                        />
                    </div>
                    <div style={{ minHeight: '24px' }}>
                        <ErrorMessage
                            errors={errors}
                            name={'memo'}
                            render={({ message }) =>
                                <Typography sx={{ color: (theme) => theme.palette.error.main }}>
                                    {`${t(message)}`}
                                </Typography>
                            }
                        />
                    </div>

                    <div id='link-field'>
                        <StyledTitleTypography>
                            {t('jobOpening.form.link.title')}
                        </StyledTitleTypography>
                        <TextField
                            variant='outlined'
                            size='small'
                            placeholder={t('jobOpening.form.link.placeholder')}
                            error={!!errors.link}
                            {...register("link")}
                            disabled={isLoading || success}
                            style={{ width: '100%' }}
                        />
                    </div>
                    <div style={{ minHeight: '24px' }}>
                        <ErrorMessage
                            errors={errors}
                            name={'link'}
                            render={({ message }) =>
                                <Typography sx={{ color: (theme) => theme.palette.error.main }}>
                                    {`${t(message)}`}
                                </Typography>
                            }
                        />
                    </div>
                </Stack>
            </CardContent>
            <CardContent style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <ConfirmButton type='submit' loading={isLoading} disabled={success} >
                    {success ?
                        <Typography color='green'>
                            {t('jobOpening.form.success')}
                        </Typography> :
                        <Typography>
                            {t('jobOpening.form.submit')}
                        </Typography>
                    }
                </ConfirmButton>
                {error &&
                    <Typography color='error'>
                        {t('jobOpening.form.error')}
                    </Typography>
                }
            </CardContent>
        </Box>
    )
}
interface JobOpeningFormProps {
    defaultValue: JobOpening;
    handleSubmit: (value: JobOpening) => Promise<ResultType<JobOpening>>;
    handleClose: () => void;
}