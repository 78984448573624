﻿import * as React from "react";
import { Backdrop, Box, CircularProgress, Dialog as HintDialog, Typography } from '@mui/material';
import Dropzone, { DropzoneRef, FileRejection } from 'react-dropzone';
import { Trans } from "react-i18next";
import { ErrorText } from "./Typography";

interface UploadProps {
    value?: string;
    uploadPath?: string;
    onChange: (value: File) => void;
    dropProps?: object;
    children: React.ReactNode;
    dropzoneRef?: React.Ref<DropzoneRef>;
    containerStyle?: React.CSSProperties;
    imgStyle?: React.CSSProperties;
    clearImage?: string[];
    width?: number;
    height?: number;
    textCenter?: boolean;
}

export function Upload(props: UploadProps) {
    const { value, uploadPath, onChange, dropProps, children,
        dropzoneRef, containerStyle, imgStyle, clearImage, width, height, textCenter } = props;
    const [file, setFile] = React.useState(null);
    const [errorMessages, setErrorMessage] = React.useState([]);
    const [waiting, setWaiting] = React.useState(false);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [dialogMessage, setDialogMessage] = React.useState("");

    const onDropAccepted = <T extends File>(acceptedFiles: T[]) => {
        let upload = Object.assign(acceptedFiles[0], {
            preview: URL.createObjectURL(acceptedFiles[0])
        })
        setErrorMessage([]);
        onChange(upload);
        setFile(upload);
    }

    const onDropRejected = (rejectedFile: FileRejection[]) => {
        let errorMessages = [];
        rejectedFile.forEach(file => file.errors.forEach(error => errorMessages.push(error.message)))
        setErrorMessage(errorMessages);
        setDialogMessage("上傳失敗");
        setOpenDialog(true);
    }

    const dialogContents = (
        <div style={{ padding: '1rem 2rem', textAlign: 'center', fontSize: '1rem' }} >
            <p style={{ marginBottom: 0 }}>
                {dialogMessage}
            </p>
        </div>
    );

    React.useEffect(() => {
        if (file) {
            setFile(null);
        }
    }, [clearImage])

    return (
        <>
            <Dropzone
                ref={dropzoneRef}
                maxFiles={1}
                multiple={false}
                maxSize={2000000} //2MB
                accept='image/jpeg,image/jpg,image/png'
                onDropAccepted={onDropAccepted}
                onDropRejected={onDropRejected}
            >
                {({ getRootProps, getInputProps, acceptedFiles }) => (
                    <Box className="w-100 h-100" {...getRootProps()}>
                        {/* @ts-ignore */}
                        <input {...getInputProps()} />

                        {
                            file ?
                                <div style={containerStyle}>
                                    <div style={{
                                        backgroundImage: `url(${file.preview})`,
                                        width: width ?? `477px`,
                                        height: height ?? `159px`,
                                        backgroundSize: 'cover',
                                        backgroundPosition: 'center',
                                        backgroundRepeat: 'no-repeat',
                                        ...imgStyle
                                    }} />
                                </div>
                                : value && value != "" ?
                                    <div style={containerStyle}>
                                        <div style={{
                                            backgroundImage: `url(${value})`,
                                            width: `${width ?? 477}px`,
                                            height: `${height ?? 159}px`,
                                            backgroundSize: 'cover',
                                            backgroundPosition: 'center',
                                            backgroundRepeat: 'no-repeat',
                                            ...imgStyle
                                        }} />
                                    </div>
                                    : children
                        }

                    </Box>
                )}
            </Dropzone>
            <span style={textCenter ? { textAlign: "center" } : null}><Trans i18nKey={'uploadLimit'} />: 2MB</span>
            {errorMessages.map(message => (
                <ErrorText>{message}</ErrorText>
            ))}
            <Backdrop open={waiting}><CircularProgress /></Backdrop>
            <HintDialog open={openDialog} onClose={() => setOpenDialog(false)}>{dialogContents}</HintDialog>
        </>
    )
}