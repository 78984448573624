﻿import * as React from 'react';
import { Divider, IconButton, InputAdornment, Stack, Typography } from '@mui/material';
import { useHistory, useLocation } from "react-router-dom";
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useDispatchWithType } from '../store';
import { profileObj, useLoginMutation, UserId } from '../components/services/login';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { GoogleLoginResponse } from 'react-google-login';
import { useLoginWithGoogleMutation } from '../components/services/login';
import { User, BoothManageInvitation } from '../components/class';
import { LowerTextField, SubmitButton, UpperTextField, TextButton } from '../Login/LoginWindow';
import { googleClientId } from '../config';
const HomeIcon = "images/logo.png"

type formData = {
    password: string;
}
export default function LoginPage(props: LoginPageProps) {
    const { code, data, handleLoginSuccess, normalLogin, googleLogin } = props;

    const history = useHistory();
    let query = new URLSearchParams(useLocation().search);

    const [login, { isLoading }] = useLoginMutation();
    const [loginWithGoogle, { isLoading: isGoogleLoading }] = useLoginWithGoogleMutation();
    const dispatch = useDispatchWithType();
    const { t, i18n } = useTranslation();

    const schema = yup.object().shape({
        password: yup.string().required(t('login.password.error')),
    });
    const { register, handleSubmit, formState: { errors } } = useForm<formData>({
        mode: "onBlur", resolver: yupResolver(schema),
        defaultValues: { password: "" }
    });

    const [errorMessage, setErrorMessage] = React.useState<string>("");

    function onLoginSubmit(input: { password: string }) {
        if (!data) {
            setErrorMessage("Error");
            return;
        }

        setErrorMessage("");
        login({ account: data.toEmail, password: input.password }).unwrap()
            .then(result => {
                if (result.isSuccess) {
                    let source: User = result.data;
                    dispatch({
                        type: 'LOGINUSER',
                        payload: source
                    });

                    handleLoginSuccess();
                }
                else {
                    setErrorMessage(result.message);
                    let source: UserId = result.data;
                    if (source && source.userId) {
                        history.push('/Verify?u=' + result.data.userId);
                    }
                }
            }).catch((error) => {
                //後端報錯 ex:500、404
                setErrorMessage("Unconnect");
                console.error("發生錯誤", error)
            });
    }

    //Google登入
    function handleGoogleLoginSuccess(res: GoogleLoginResponse) {
        setErrorMessage("");
        const googleData: profileObj = { tokenId: res.tokenId, ...res.profileObj };

        loginWithGoogle(googleData).unwrap()
            .then(result => {
                if (result.isSuccess) {
                    dispatch({
                        type: 'LOGINUSER',
                        payload: result.data
                    });

                    handleLoginSuccess();
                }
                else {
                    setErrorMessage(result.message);
                }
            }).catch((error) => {
                //後端報錯 ex:500、404
                setErrorMessage("Unconnect");
                console.error("發生錯誤", error)
            });
    }
    function handleGoogleLoginFailure() {

    }

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };

    return (
        <div>
            <div className="d-flex align center w-100">
                <img src={HomeIcon} style={{ height: '36px', maxWidth: '100%' }} />
                <Typography variant='subtitle2' style={{ padding: 6, wordBreak: "keep-all" }}>{t('navbar.title')}</Typography>
            </div>

            <div style={{ margin: "24px 0px", color: '#cccccc' }}>
                <Typography variant="h5" style={{ fontWeight: 'bold' }}>
                    {t('login.subtitle1')}
                </Typography>
                <Typography variant="h5">
                    {t('login.subtitle2')}
                </Typography>
                <Typography variant="h5">
                    {t('login.subtitle3')}
                </Typography>
            </div>
            {normalLogin &&
                <Stack component="form" sx={{ minWidth: 200 }} onSubmit={handleSubmit(onLoginSubmit)}>
                    <UpperTextField
                        disabled
                        value={data?.toEmail}
                        variant="outlined"
                        size='small'
                        placeholder={t('login.account.placeholder')}
                        FormHelperTextProps={{ style: { position: 'absolute', bottom: 0, right: 0 } }}
                    />
                    <LowerTextField
                        variant="outlined"
                        size='small'
                        type={'password'}
                        placeholder={t('login.password.placeholder')}
                        inputRef={register('password').ref}
                        error={!!errors.password}
                        helperText={errors.password ? errors.password.message : ' '}
                        {...register("password")}

                        FormHelperTextProps={{ style: { position: 'absolute', bottom: 0, right: 0 } }}
                        disabled={isLoading}
                    />
                    <div className="d-flex center" style={{ height: 30 }}>
                        <Typography variant='body1' sx={{ color: (theme) => theme.palette.error.main }}>{errorMessage}</Typography>
                    </div>
                    <SubmitButton type="submit" variant="contained" loading={isLoading}>
                        {t('login.button')}
                    </SubmitButton>
                </Stack>
            }
            {(normalLogin) &&
                <Divider sx={{ my: 2 }} />
            }
            <div style={{ height: 12 }} />
            <div className="d-flex center">
                <Typography variant='body2'>Click to</Typography>
                {i18n.language == 'ch' ?
                    <TextButton onClick={() => changeLanguage('en')}>ENG</TextButton> :
                    <TextButton onClick={() => changeLanguage('ch')}>中文版</TextButton>
                }
            </div>
        </div >
    );
}
interface LoginPageProps {
    code: string;
    data: BoothManageInvitation;
    handleLoginSuccess: () => void;
    normalLogin: boolean;
    googleLogin: boolean;
}