﻿import * as React from 'react';
import { IconButton, InputAdornment, Stack, Typography } from '@mui/material';
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { LowerTextField, PaperWrap, SubmitButton, TextButton, UpperTextField } from './LoginWindow';
import { useRegisterMutation } from '../components/services/login';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import './LoginStyleSheet.css';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const HomeIcon = "images/logo.png"

type formData = {
    account: string;
    password: string;
}
export default function RegisterWindow(props) {
    const history = useHistory();

    const [registerAccount, { isLoading }] = useRegisterMutation();
    const { t, i18n } = useTranslation();

    const schema = yup.object().shape({
        account: yup.string().required(t('register.account.error1')).email(t('register.account.error2')),
        password: yup.string().required(t('register.password.error1')).min(6, t('register.password.error2')),
    });
    const { register, handleSubmit, formState: { errors } } = useForm<formData>({
        mode: "onBlur", resolver: yupResolver(schema),
        defaultValues: { account: "", password: "" }
    });

    const [showPassword, setShowPassword] = React.useState(false);

    const [errorMessage, setErrorMessage] = React.useState<string>("");

    function onRegisterSubmit(data) {
        setErrorMessage("");
        registerAccount(data).unwrap()
            .then(result => {
                if (result.isSuccess) {
                    let id: string = result.data.userId;
                    history.push('/Verify?u=' + id);
                }
                else {
                    setErrorMessage(result.message);
                }
            }).catch((error) => {
                //後端報錯 ex:500、404
                setErrorMessage("Unconnect");
                console.error("發生錯誤", error)
            });
    }

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };

    return (
        <div className="root bg-lavender">
            <PaperWrap className="py-5" elevation={3}>
                <Stack width={280} justifyContent="stretch" alignItems="stretch">
                    <div className="d-flex align center w-100">
                        <img src={HomeIcon} style={{ height: '36px', maxWidth: '100%' }} />
                        <Typography variant='subtitle2' style={{ padding: 6, wordBreak: "keep-all" }}>{t('navbar.title')}</Typography>
                    </div>

                    <div style={{ margin: "24px 0px", color: '#cccccc' }}>
                        <Typography variant="h5">
                            {t('register.subtitle1')}
                        </Typography>
                        <Typography variant="h5">
                            {t('register.subtitle2')}
                        </Typography>
                        <Typography variant="h5">
                            {t('register.subtitle3')}
                        </Typography>
                    </div>

                    <Stack component="form" onSubmit={handleSubmit(onRegisterSubmit)}>
                        <UpperTextField
                            variant="outlined"
                            size='small'
                            placeholder={t('register.account.placeholder')}

                            inputRef={register('account').ref}
                            error={!!errors.account}
                            helperText={errors.account ? errors.account.message : ' '}
                            {...register("account")}

                            FormHelperTextProps={{ style: { position: 'absolute', bottom: 0, right: 0 } }}
                            disabled={isLoading}
                        />
                        <LowerTextField
                            variant="outlined"
                            size='small'
                            type="password"
                            placeholder={t('register.password.placeholder')}

                            inputRef={register('password').ref}
                            error={!!errors.password}
                            helperText={errors.password ? errors.password.message : ' '}
                            {...register("password")}

                            FormHelperTextProps={{ style: { position: 'absolute', bottom: 0, right: 0 } }}
                            disabled={isLoading}
                            InputProps={{
                                endAdornment:
                                    <InputAdornment position="end" className="ml-1">
                                        <IconButton onClick={handleClickShowPassword}>
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                            }}
                        />
                        <div className="d-flex center" style={{ height: 30 }}>
                            <Typography variant='body1' sx={{ color: (theme) => theme.palette.error.main }}>{errorMessage}</Typography>
                        </div>
                        <SubmitButton disabled type="submit" variant="contained" loading={isLoading}>
                            {t('register.button')}
                        </SubmitButton>
                        {/*<Typography color='secondary' variant="body1" align='center'>*/}
                        {/*    註冊開放時間為9/30*/}
                        {/*</Typography>*/}
                        {/*<Typography color='secondary' variant="body1" align='center' style={{ marginBottom: 12 }}>*/}
                        {/*    限 @nycu.edu.tw 信箱註冊*/}
                        {/*</Typography>*/}
                    </Stack>
                    <Typography variant='body2' align='center'>
                        {t('register.desciption')}
                        <a className="link" href='https://docs.google.com/document/d/1bY284ltQIg_nrmmnzhvuZz6K4EiS1aqCDmQi6CdjcrU/edit?usp=sharing' target='_blank'>
                            {t('register.privacy_policy')}
                        </a>
                    </Typography>

                    <div style={{ height: 12 }} />
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Typography variant='body2'>Click to</Typography>
                        {i18n.language == 'ch' ?
                            <TextButton onClick={() => changeLanguage('en')}>ENG</TextButton> :
                            <TextButton onClick={() => changeLanguage('ch')}>中文版</TextButton>
                        }
                    </div>
                </Stack>
            </PaperWrap>
        </div >
    );
}