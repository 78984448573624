﻿import * as React from "react";
import { useTranslation } from "react-i18next";
import { Col, Row } from 'reactstrap';
import { LazyBackground } from './LazyBackground';
import './PosterWall.css';
import './../components/responsive-components/StyleSheet.css';
import { Backdrop, Container, Dialog, useMediaQuery } from "@mui/material";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel/lib/js'
import { Booth, Poster } from "../components/class";
import { format } from "date-fns";
import { zhTW } from "date-fns/locale";
import { useSystemConnection } from "../store";
import { SystemControlHub } from "../components/react-signalr/systemControlHub";
import { useGetAllPosterQuery } from "../components/services/booth";

const loading = 'images/loading.jpg'

export default function Posters() {
    const { t, i18n } = useTranslation();
    const matches = useMediaQuery('(min-width:768px)');

    //換連線位置
    const systemConnection = useSystemConnection();
    const hubRef = React.useRef<SystemControlHub>();
    React.useEffect(() => {
        if (systemConnection != null) {
            hubRef.current = new SystemControlHub(systemConnection);
            hubRef.current.send.ChangeLocation({ location: 5, refId: null });
        }
    }, [systemConnection]);

    const [index, setIndex] = React.useState<number>(0);
    const [open, setOpen] = React.useState(false);
    const { posters = [] } = useGetAllPosterQuery(null, {
        selectFromResult: ({ data }) => ({ posters: data?.data })
    });

    function handleButtonClick(index: number) {
        setIndex(index);
        setOpen(true);
    }

    const renderArrowPrev = (onClickHandler: () => void, hasPrev: boolean, label: string) => (
        <button type="button" aria-label={label} className={hasPrev ? "prevArrow" : "control-arrow prevArrow control-disabled"} onClick={onClickHandler} />
    );
    const renderArrowNext = (onClickHandler: () => void, hasNext: boolean, label: string) => (
        <button type="button" aria-label={label} className={hasNext ? "nextArrow" : "control-arrow nextArrow control-disabled"} onClick={onClickHandler} />
    );

    const CarouselView = () => (
        <div style={{ width: "90%", margin: "auto" }}>
            <Carousel
                autoPlay={false}
                interval={1e11}
                showArrows={matches}
                showStatus={false}
                showIndicators={false}
                showThumbs={false}
                useKeyboardArrows={false}
                emulateTouch={true}
                selectedItem={index}
                renderArrowPrev={renderArrowPrev}
                renderArrowNext={renderArrowNext}
            >
                {posters.map((data, index) => (
                    <div className="posterCard" key={data.booth.boothId}>
                        <img src={data.posterUrl} />
                    </div>
                ))}
            </Carousel>
            <div className="posterClose" onClick={() => setOpen(false)}>×</div>
        </div>
    );

    return (
        <Container>
            <Row>
                <Col xs="12">
                    <h1 className="title">
                        {t('title.posters')}
                    </h1>
                </Col>
                <Col xs="12">
                    <div className="posterButtonList mx-3">
                        {posters.map((data, index) => (
                            <div className="col col-6 col-sm-5ths my-3" key={data.booth.boothId}>
                                <LazyBackground className="posterButton"
                                    source={data.posterUrl}
                                    placeholder={loading}
                                    onClick={() => handleButtonClick(index)}
                                >
                                    <span>{i18n.language == "ch" ? data.booth.chName : data.booth.enName}</span>
                                </LazyBackground>
                            </div>
                        ))}
                    </div>
                    <Dialog PaperComponent={() => <CarouselView />} open={open} />
                </Col>
            </Row>
        </Container>
    )
}