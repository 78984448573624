﻿import * as React from 'react';
import { Box, BoxProps, IconButton, styled, useMediaQuery, useTheme } from "@mui/material";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import ScrollContainer, { ScrollContainerProps } from "react-indiana-drag-scroll";
import { useTranslation } from 'react-i18next';
import { ResponsiveStyleValue } from "@mui/system";
import * as CSS from 'csstype';

const StyledScrollContainer = styled(ScrollContainer)(({ theme }) => ({
    display: "flex",
    "& > .MuiButtonBase-root": {
        display: "grid",
        alignContent: "center",
        marginBlock: theme.spacing(1),
        fontSize: "clamp(1.4rem, 2.5vw, 1.5rem)",
        fontWeight: 'bold',
        padding: theme.spacing(1),
        [theme.breakpoints.down("md")]: {
            marginBlock: theme.spacing(1.5),
            fontSize: "clamp(1rem, 2.5vw, 1.5rem)",
            fontWeight: 'bold',
            padding: theme.spacing(0.5),
        },
        '&:hover': {
            backgroundColor: theme.palette.action.hover,
        },
        '& .dayColor': {
            color: theme.palette.text.primary,
        },
        '& .weekColor': {
            color: theme.palette.text.secondary,
        },
        '&.active': {
            backgroundColor: theme.palette.primary.main,
        },
        '&.active .dayColor, &.active .weekColor': {
            color: theme.palette.primary.contrastText,
        },
    },
    "&::-webkit-scrollbar": {
        width: 4,
        height: 4,
    },
    "&::-webkit-scrollbar-thumb": {
        borderRadius: 5,
        boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.1)',
        backgroundImage: 'linear-gradient(to right, #fbc2eb 0%, #a6c1ee 100%)'
    }
}));

//遮住scrollbar的
const ScrollBarCover: (props: BoxProps) => JSX.Element = styled(Box)(({ theme }) => ({
    position: 'absolute',
    background: theme.palette.background.default,
    width: '100%',
    bottom: 0,
    right: 0,
    height: 5,
    transition: theme.transitions.create(['opacity']),
    opacity: 1
}));

export const ScrollMenu = React.forwardRef<ScrollMenuRef, ScrollMenuProps>((props, ref) => {
    const { data, handleDateClick, hasMonth } = props;
    const { i18n } = useTranslation();

    const theme = useTheme();
    const isScreen = useMediaQuery(theme.breakpoints.up('lg'));

    const containerRef = React.useRef<HTMLDivElement>();
    const responsiveRef = React.useRef<HTMLDivElement>();
    const [itemWidth, setItemWidth] = React.useState<number>();
    const [isOverflow, setIsOverflow] = React.useState<boolean>(false);
    const itemMarginX: number = isScreen ? 8 : 4;
    const arrowWidth: number = 40;

    React.useEffect(() => {
        const resizeObserver = new ResizeObserver(entries => {
            //檢查有沒有scroll bar
            setIsOverflow(entries[0].target.firstElementChild.scrollWidth > entries[0].target.firstElementChild.clientWidth);

            //指定item寬高
            let width = entries[0].target.clientWidth;
            if (width < (360/*sm*/ - 32/*padding*/ - 2 * arrowWidth))
                setItemWidth(width / 3);
            else if (width < (600/*sm*/ - 32/*padding*/ - 2 * arrowWidth))
                setItemWidth(width / 4);
            else if (width < (768/*sm*/ - 48/*padding*/ - 2 * arrowWidth))
                setItemWidth(width / 6);
            else if (width < (992/*md*/ - 48/*padding*/ - 2 * arrowWidth))
                setItemWidth(width / 8);
            else
                setItemWidth(width / 8);
        });
        resizeObserver.observe(responsiveRef.current)
        return (() => {
            resizeObserver.disconnect();
        })
    }, []);
    React.useImperativeHandle(ref, () => ({
        initial: () => {
            if (containerRef.current) {
                //初始化顯示日期
                const index = data.findIndex(element => element.isClicked) ?? 0;
                containerRef.current.scrollTo((itemWidth + 2 * itemMarginX) * index, 0);
                setShowBar(true);
                setTimeout(() => { if (!touched) setShowBar(false) }, 2000);
            }
        }
    }));

    function onArrowClick(direction) {
        const increment = direction === 'left' ? -1 : 1;
        if (containerRef.current) {
            containerRef.current.scrollBy({
                top: 0,
                left: increment * (itemWidth + 2 * itemMarginX) * 3,
                behavior: 'smooth'
            });
        }
    };
    const [touched, setTouched] = React.useState(false);
    const [showBar, setShowBar] = React.useState(false);
    React.useEffect(() => {
        function handleTouch(event) {
            if (containerRef.current && containerRef.current.contains(event.target)) {
                setTouched(true);
                setShowBar(true)
            }
        }
        function handleTouchEnd(event) {
            if (containerRef.current) {
                setTouched(false);
                setShowBar(false);
            }
        }
        document.addEventListener("touchmove", handleTouch);
        document.addEventListener("touchend", handleTouchEnd);
        return () => {
            document.removeEventListener("touchmove", handleTouch);
            document.removeEventListener("touchend", handleTouchEnd);
        };
    }, [containerRef]);

    const ScrollContainer = StyledScrollContainer;
    return (
        <Box display="flex" alignItems="center" my={{ xs: 1, md: 3 }}>
            {isOverflow &&
                <Arrow direction='left' width={arrowWidth} onClick={() => onArrowClick('left')} />
            }

            <Box ref={responsiveRef} position="relative"
                display="flex" flex="1" maxWidth="calc(100% - 80px)" justifyContent="center"
            >
                <ScrollContainer innerRef={containerRef} vertical={false} hideScrollbars={isScreen || !isOverflow}>
                    {data.map((item, index) => (
                        <IconButton key={item.date.getTime()}
                            onClick={() => handleDateClick(index)}
                            className={(item.isClicked ? "active" : "")}
                            style={{ height: itemWidth, width: itemWidth, marginInline: `${itemMarginX}px` }}
                        >
                            {new Date().getFullYear() === item.date.getFullYear() ?
                                <div className="dayColor">
                                    {item.date.toLocaleString(i18n.language == "ch" ? "zh-TW" : 'en-US',
                                        { day: "2-digit", ...(hasMonth !== false) && { month: "2-digit" } })}
                                </div>
                                :
                                <div className="dayColor" style={{ transform: 'scale(0.8, 1.0)' }}>
                                    {item.date.toLocaleString(i18n.language == "ch" ? "zh-TW" : 'en-US',
                                        { day: "2-digit", ...(hasMonth !== false) && { month: "2-digit", year: "2-digit" } })}
                                </div>
                            }
                            <div className="weekColor">
                                {item.date.toLocaleString(i18n.language == "ch" ? "zh-TW" : 'en-US',
                                    { weekday: "short" })}
                            </div>
                        </IconButton>
                    ))}
                </ScrollContainer>
                {!isScreen &&
                    <ScrollBarCover sx={isOverflow && showBar && { opacity: 0 }} />
                }
            </Box>
            {isOverflow &&
                <Arrow direction='right' width={arrowWidth} onClick={() => onArrowClick('right')} />
            }
        </Box>
    )
});

function Arrow(props) {
    const { direction, width, onClick } = props;
    const iconWidth: number = width - 16;
    return (
        <IconButton onClick={onClick}>
            {direction === 'left' ?
                <ChevronLeft style={{ width: iconWidth, height: iconWidth }} /> :
                <ChevronRight style={{ width: iconWidth, height: iconWidth }} />}
        </IconButton>
    );
}

export interface ScrollMenuProps {
    data: ScrollItemData[];
    handleDateClick: (index: number) => void;
    hasMonth?: boolean;
}

export interface ScrollMenuRef {
    initial: () => void;
}

export interface ScrollItemData {
    date: Date;
    isClicked: boolean;
}