﻿import * as React from 'react';
import { makeStyles } from "@mui/styles";
import { useTranslation } from 'react-i18next';
import { Button } from '../components/responsive-components';
import { Divider, styled } from '@mui/material';
//import { useSelector } from 'react-redux';
//import { ReducerData } from '../../store/rootReducer';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    divider: {
        width: 2,
        margin: '6px 0px',
	},
}));

const TranslateButton = styled(Button)({
    minWidth: 'unset',
    fontWeight: 'bold',
    color: 'inherit'
});

function Translation() {
    const classes = useStyles();

    const { t, i18n } = useTranslation();

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };

    return (
        <div className={classes.root}>
            <TranslateButton type="button" onClick={() => changeLanguage('ch')}>
                {t('navbar.translation.ch')}
            </TranslateButton>
            <Divider orientation='vertical' flexItem className={classes.divider} />
            <TranslateButton type="button" onClick={() => changeLanguage('en')}>
                {t('navbar.translation.en')}
            </TranslateButton>
        </div>
    )
}

export default Translation;