﻿import * as React from 'react';
import { useLocation } from 'react-router';
import { Row } from 'reactstrap';
import ErrorPage from '../components/ErrorPage/404Page';

export default function Questionnaire() {
    const query = new URLSearchParams(useLocation().search);
    const srcList = {
        //活動回饋問券
        feedback: "https://docs.google.com/forms/d/e/1FAIpQLSet_Ys-2rgO2_48r-YRDf49A5wt5kGSquSTh3hc_0M39twYEQ/viewform?embedded=true",
        //加入Open House
        joinOpenHouse: "https://docs.google.com/forms/d/e/1FAIpQLSdWg-4jRbtzxKpJwawJLwYhDqXoYZrOd-3BiNSKpkJO6w34yQ/viewform?embedded=true",
        //活動
        events: "https://docs.google.com/forms/d/e/1FAIpQLSf7l-bs3ReVmVRNLLpQdKxHMSgtqSSgzfLlTK79WDm9Q1aY9Q/viewform?embedded=true",
        //專刊
        reports: "https://docs.google.com/forms/d/e/1FAIpQLSeBJx2mtG_j3Joeye--Ekzh62KexVYM_yYwMqrCIpLqhdWbZw/viewform?embedded=true",
        //就博(1)
        expo_f: "https://docs.google.com/forms/d/e/1FAIpQLSf8nqPoa614Isynd-FmQGiwj-15xkgzCqL1vlRfBv-Uy265vA/viewform?embedded=true",
        //就博(2)
        expo_s: "https://docs.google.com/forms/d/e/1FAIpQLScE5ZTdF_UIANrS-86pSLiVrrzOZlEHjBW4KKWAgGHFJRGFDA/viewform?embedded=true",
        //認識Open House(1)
        know_f:"https://docs.google.com/forms/d/e/1FAIpQLScdyhhmeeBw8EneFbWXR4NX5tSNaGl-VijA34cVU31VUuusVA/viewform?embedded=true",
        //認識Open House(2)
        know_s: "https://docs.google.com/forms/d/e/1FAIpQLSdbNyl5Hqw-ZE41IlL-4Qcd8zREu_tEwSAJLIPYFCIB2mEbnA/viewform?embedded=true"
    };
    const [src, setSrc] = React.useState("");
    React.useLayoutEffect(() => {
        let title: string = query.get("t");
        setSrc(srcList[title] ?? "");
    }, []);
    if (src != "") {
        return (
            <Row style={{ height: "calc(100vh - 120px)", paddingTop: "1rem" }}>
                <iframe src={src} width="100%" height="100%" frameBorder="0" marginHeight={0} marginWidth={0}>
                    載入中…
            </iframe>
            </Row>
        )
    }
    else
        return <ErrorPage />
}