﻿import * as React from 'react';
import { DialogProps as MuiDialogProps } from '@mui/material/Dialog';
import { Button, ConfirmButton, Dialog, DialogActions, DialogContent, important, Upload } from '../responsive-components';
import { Edit, Publish } from '@mui/icons-material';
import { CardType, FieldPrivacy, ItemType, CardInfo, UserExtendField } from '../class';
import { Trans, useTranslation } from "react-i18next";
import { useForm, UseFormRegister, UseFormSetValue, UseFormUnregister, UseFormWatch } from 'react-hook-form';
import { Avatar, Box, BoxProps, DialogTitle, Divider, Fab, MenuItem, styled, TextField, TextFieldProps, Toolbar, Typography, TypographyProps } from '@mui/material';
import { SelectPrivacyField, uniqueUserFieldList, userCustomFieldList } from '../CustomField';
import { ErrorMessage } from '@hookform/error-message';
import { useUserFieldNameList } from '../../store/index';
//import { SaveImage } from '../Admin/Utils';
import { isNullOrUndefined } from '../Admin/Utils';
import { DropzoneRef } from 'react-dropzone';
import { cloneDeep } from 'lodash';
import { useUploadUserLogoMutation } from '../services/file';

const paddingValue = '0px';
const spaceValue = '18px';
const phoneRegExp = /^$|^(((\+\d{3})|(\(\+\d{3}\))) ?)?((\d{1,4})|(\(\d{2,4}\)))[- ]?\d{1,4}[- ]?\d{2,4}( ?#(\d+)){0,2}$/i;

const StyledTitle: (props: TypographyProps) => JSX.Element = styled(Typography)(({ theme }) => ({
    fontSize: '18px',
    fontWeight: 'bold',
    color: theme.palette.text.secondary,
    margin: '12px 0px',
}));
const StyledEditTitle: (props: BoxProps) => JSX.Element = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end'
}));
const StyledTypography: (props: TypographyProps) => JSX.Element = styled(Typography)(({ theme }) => ({
    fontSize: '16px',
    fontWeight: 'bold',
    color: theme.palette.text.primary,
}));
const StyledTextField: (props: TextFieldProps) => JSX.Element = styled(TextField)(({ theme }) => ({
    height: '35px',
    '& .MuiInputLabel-root': {
        '& .MuiInputLabel-asterisk': {
            color: 'red',
        }
    }
}));

export default function EditUserDataDialog(props: EditUserDataProps & Omit<MuiDialogProps, keyof EditUserDataProps>) {
    const { userId, data, onClose, isLoading, ...other } = props;
    const { t, i18n } = useTranslation();

    return (
        <Dialog {...other}>
            <DialogTitle>
                <Box className="d-flex flex-row flex-wrap justify-content-end" paddingRight={paddingValue}>
                    <Button onClick={() => onClose()}>{t('user.editDialog.discard')}</Button>
                </Box>
            </DialogTitle>
            <DialogContent>
                <EditUserData
                    userId={userId}
                    data={data}
                    onClose={onClose}
                    isLoading={isLoading}
                />
            </DialogContent>
        </Dialog>
    )
}

export type EditUserDataRef = {
    handleSubmit: () => Promise<boolean>;
}
export const EditUserData = React.forwardRef(
    function EditUserData(props: EditUserDataProps, ref) {
        const { userId, data, onClose, isLoading, editType, ...other } = props;
        const { t, i18n } = useTranslation();

        const { register, unregister, watch, formState: { errors }, handleSubmit, setValue } = useForm({
            defaultValues: data, mode: 'onChange'
        });

        //Ref
        React.useImperativeHandle(ref, () => ({
            async handleSubmit() {
                let tempResult = false
                await handleSubmit(async (obj) => {
                    await onSubmit(obj).then((result) => {
                        tempResult = result;
                    });
                })().catch((error) => {
                    tempResult = false;
                })
                console.log(`tempResult: ${tempResult}`);
                return tempResult;
            }
        }));

        //進行圖片上傳
        const [uploadUserLogo, { isLoading: isUploading }] = useUploadUserLogoMutation();
        const logoUrlValue = watch("logoUrl");
        const handleLogoUrlChange = (url: string) => setValue("logoUrl", url);
        const [clearImage, setClearImage] = React.useState([]);
        function handleUseDefaultAvatar(url: string) {
            setLogoFile(null);
            handleLogoUrlChange(url);
            setClearImage([...clearImage, url]);
        }
        const onSubmit = async (data: any): Promise<boolean> => {
            let tempResult = false
            if (logoFile != null) {
                const formData = new FormData();
                formData.append("files", logoFile);
                await uploadUserLogo({ userId, formData }).unwrap().then(async result => {
                    if (result.isSuccess) {
                        let uri = result.data.urls[0];
                        handleLogoUrlChange(uri);
                        await onClose({ ...data, logoUrl: uri }).then(result => {
                            tempResult = result;
                            console.log(`onClose: ${tempResult}`);
                        })
                    }
                }).catch((error) => {
                    tempResult = false;
                })
                console.log(`onSubmit: ${tempResult}`);
                return tempResult;
            }
            else {
                await onClose(data).then(result => {
                    tempResult = result;
                    console.log(`onClose: ${tempResult}`);
                });
                console.log(`onSubmit: ${tempResult}`);
                return tempResult;
            }
        }
        const [logoFile, setLogoFile] = React.useState<File>();

        //圖片編輯按鈕
        const dropzoneRef: React.Ref<DropzoneRef> = React.createRef();
        const openDialog = () => {
            if (dropzoneRef.current) {
                dropzoneRef.current.open()
            }
        };

        //欄位名稱
        const userFieldNameList = useUserFieldNameList();
        function userFieldIdToName(fieldId: string) {
            let fieldName = '';
            let userFieldName = userFieldNameList.find(x => x.fieldId === fieldId);
            if (userFieldName) {
                if (i18n.language === 'en') {
                    if (userFieldName.fieldName[1])
                        fieldName = userFieldName.fieldName[1];
                }
                else {
                    if (userFieldName.fieldName[0])
                        fieldName = userFieldName.fieldName[0];
                }
            }
            return fieldName;
        }
        function userFieldValueToType(fieldId: string, value: string) {
            let fieldValue = null;
            let userFieldName = userFieldNameList.find(x => x.fieldId === fieldId);
            if (userFieldName) {
                switch (userFieldName.fieldType) {
                    case ItemType.String:
                        fieldValue = String(value);
                        break;
                    case ItemType.Int:
                        fieldValue = Number(value);
                        break;
                    case ItemType.Bool:
                        fieldValue = Boolean(value);
                        break;
                    default:
                        fieldValue = '';
                }
            }
            return fieldValue;
        }

        //客製化欄位之權限
        const watchCustomField = watch(`infoFields`);
        const handleCustomPrivacyChange = (privacy: FieldPrivacy, index: number) => {
            if (index >= 0 && index < watchCustomField.length)
                setValue(`infoFields.${index}.privacy`, privacy);
        }

        //電話權限編輯
        const watchPhonePrivacyField = watch("phonePrivacy");
        const handlePhonePrivacyChange = (privacy: FieldPrivacy) => setValue("phonePrivacy", privacy);

        //信箱權限編輯
        const watchEmailPrivacyField = watch("emailPrivacy");
        const handleEmailPrivacyChange = (privacy: FieldPrivacy) => setValue("emailPrivacy", privacy);

        //客製化欄位調整
        const watchAll = watch();
        React.useEffect(() => {
            if (data && userFieldNameList) {
                let tempList = [...data.infoFields];
                let change = false;
                for (let index = 0; index < userFieldNameList.length; index++) {
                    if (!tempList.find(x => x.fieldId === userFieldNameList[index].fieldId) &&
                        userFieldNameList[index].cardTypeList.includes(data.type)) {
                        tempList.splice(index, 0, { fieldId: userFieldNameList[index].fieldId, value: '', privacy: FieldPrivacy.公開 });
                        change = true;
                    }
                }
                if (change) setValue('infoFields', cloneDeep(tempList));
            }
        }, [data, userFieldNameList])

        return (
            <Box component="form" onSubmit={handleSubmit(onSubmit)}>
                <Box className="d-flex flex-row flex-wrap" sx={{ width: '100%' }}>
                    <Box className="d-flex flex-row flex-wrap" sx={{ width: '100%' }}>
                        {(isNullOrUndefined(editType) || editType === UserEditType.all || editType === UserEditType.secondary) &&
                            <Box flex='1 0' className="d-flex flex-column mt-2 justify-content-end">
                                <Box position="relative" className="d-flex flex-column mt-2"
                                    sx={{ width: 'fit-content', margin: 'auto' }}>
                                    <Fab size="small" onClick={openDialog}
                                        sx={{ position: 'absolute', top: 0, right: 0, background: "rgba(255,255,255,0.8)" }}
                                    >
                                        <Edit />
                                    </Fab>
                                    <Upload
                                        value={logoUrlValue} onChange={(file) => { setLogoFile(file) }}
                                        dropzoneRef={dropzoneRef}
                                        width={158} height={158}
                                        imgStyle={{ borderRadius: '50%', margin: "auto" }} textCenter
                                        clearImage={clearImage}
                                    >
                                        <div className="d-flex justify-content-center align-items-center"
                                            style={{
                                                border: "1px solid rgba(0, 0, 0, 0.23)",
                                                borderRadius: "4px",
                                                color: "darkgray",
                                                cursor: "pointer",
                                                width: `${158}px`,
                                                height: `${158}px`,
                                            }}>
                                            <Publish />
                                            <Typography align="center">
                                                {t('user.editDialog.upload')}
                                            </Typography>
                                        </div>
                                    </Upload>
                                    <Button
                                        variant="outlined"
                                        onClick={() => handleUseDefaultAvatar(`./images/Avatar_1.png`)}
                                        sx={{ margin: 'auto', mt: 3, width: 'fit-content' }}
                                >
                                    <Avatar src={`./images/Avatar_1.png`} />
                                        <Typography noWrap ml={1}>
                                            {t('user.editDialog.presetPhoto')}
                                        </Typography>
                                    </Button>
                                </Box>
                                <div style={{ minHeight: '24px' }} />
                            </Box>
                        }

                        {(isNullOrUndefined(editType) || editType === UserEditType.all || editType === UserEditType.primary) &&
                            <>
                                <StyledTitle flex='1 0 100%'>
                                    {t('user.editDialog.title_1')}
                                </StyledTitle>
                                <Box flex='1 0 100%' paddingLeft={paddingValue} paddingRight={paddingValue}
                                    className="d-flex flex-row mt-2 flex-wrap" sx={{ maxWidth: '100%' }}>
                                    {/*名*/}
                                    <Box flex='1 0 50%' className="d-flex flex-column mt-2 justify-content-end mw-100">
                                        <StyledTextField
                                            required
                                        label={t('user.editDialog.lastName')}
                                            variant='outlined'
                                            size="small"
                                            margin="dense"
                                            inputProps={{ maxLength: 3001 }}
                                            error={!!errors.lastName}
                                        {...register("lastName", {
                                                required: `${t('user.editDialog.error_required')}`
                                            })}
                                        />
                                        <div style={{ minHeight: '24px' }}>
                                            <ErrorMessage
                                                errors={errors}
                                            name={'lastName'}
                                                render={({ message }) =>
                                                    <Typography sx={{ color: (theme) => theme.palette.error.main }}>
                                                        {`${t(message)}`}
                                                    </Typography>
                                                }
                                            />
                                        </div>
                                    </Box>

                                {/*姓*/}
                                    <Box flex='1 0 50%' className="d-flex flex-column mt-2 justify-content-end mw-100"
                                        paddingLeft={spaceValue}>
                                        <StyledTextField
                                        label={t('user.editDialog.firstName')}
                                        required
                                        variant='outlined'
                                        size="small"
                                        margin="dense"
                                        error={!!errors.firstName}
                                        inputProps={{ maxLength: 3001 }}
                                        {...register("firstName", {
                                            required: `${t('user.editDialog.error_required')}`
                                        })}
                                        />
                                        <div style={{ minHeight: '24px' }}>
                                            <ErrorMessage
                                            errors={errors}
                                            name={'firstName'}
                                            render={({ message }) =>
                                                <Typography sx={{ color: (theme) => theme.palette.error.main }}>
                                                    {`${t(message)}`}
                                                </Typography>
                                            }
                                            />
                                        </div>
                                    </Box>

                                    {/*電話*/}
                                    <Box flex='0 0 100%' className="d-flex flex-column mt-2 justify-content-end mw-100">
                                        <StyledEditTitle>
                                            {data.type === CardType.nonStudent &&
                                                <SelectPrivacyField
                                                    value={watchPhonePrivacyField}
                                                    onChange={(event) => handlePhonePrivacyChange(Number(event.target.value))}
                                                />
                                            }
                                        </StyledEditTitle>
                                        <StyledTextField
                                            required
                                            label={t('user.editDialog.phone')}
                                            variant='outlined'
                                            size="small"
                                            margin="dense"
                                            inputProps={{ maxLength: 3001 }}
                                            error={!!errors.phone}
                                            {...register("phone", {
                                                required: `${t('user.editDialog.error_required')}`,
                                                pattern: {
                                                    value: phoneRegExp,
                                                    message: `${t('user.editDialog.error_isPhone')}`
                                                }
                                            })}
                                        />
                                        <div style={{ minHeight: '24px' }}>
                                            <ErrorMessage
                                                errors={errors}
                                                name={'phone'}
                                                render={({ message }) =>
                                                    <Typography sx={{ color: (theme) => theme.palette.error.main }}>
                                                        {`${t(message)}`}
                                                    </Typography>
                                                }
                                            />
                                        </div>
                                    </Box>

                                    {/*信箱*/}
                                    <Box flex='1 0 100%' className="d-flex flex-column mt-2 justify-content-end mw-100">
                                        <StyledEditTitle>
                                            {data.type === CardType.nonStudent &&
                                                <SelectPrivacyField
                                                    value={watchEmailPrivacyField}
                                                    onChange={(event) => handleEmailPrivacyChange(Number(event.target.value))}
                                                />
                                            }
                                        </StyledEditTitle>
                                        <StyledTextField
                                            required
                                            label={t('user.editDialog.email')}
                                            variant='outlined'
                                            size="small"
                                            margin="dense"
                                            inputProps={{ maxLength: 3001 }}
                                            error={!!errors.email}
                                            {...register("email", {
                                                required: `${t('user.editDialog.error_required')}`
                                            })}
                                        />
                                        <div style={{ minHeight: '24px' }}>
                                            <ErrorMessage
                                                errors={errors}
                                                name={'email'}
                                                render={({ message }) =>
                                                    <Typography sx={{ color: (theme) => theme.palette.error.main }}>
                                                        {`${t(message)}`}
                                                    </Typography>
                                                }
                                            />
                                        </div>
                                    </Box>
                                    {data.type === CardType.student &&
                                        <Typography flex='1 0 100%' sx={{ color: (theme) => theme.palette.primary.main }}>
                                            {`${t('user.editDialog.notice_phone_email')}`}
                                        </Typography>
                                    }

                                    {/*學生欄位*/}
                                    {data.type === CardType.student &&
                                        <StudentEditInfo
                                            data={watchAll}
                                            register={register}
                                            unregister={unregister}
                                            setValue={setValue}
                                            watch={watch}
                                            errors={errors}
                                            isLoading={isLoading}
                                            userFieldIdToName={userFieldIdToName}
                                            watchCustomField={watchCustomField}
                                            handleCustomPrivacyChange={handleCustomPrivacyChange}
                                        />
                                    }

                                {/*企業欄位*/}
                                {data.type !== CardType.student &&
                                        <NonStudentEditInfo
                                            data={watchAll}
                                            register={register}
                                            setValue={setValue}
                                            watch={watch}
                                            errors={errors}
                                            isLoading={isLoading}
                                            userFieldIdToName={userFieldIdToName}
                                            watchCustomField={watchCustomField}
                                            handleCustomPrivacyChange={handleCustomPrivacyChange}
                                        />
                                    }
                                </Box>
                            </>
                        }
                    </Box>

                    {(isNullOrUndefined(editType) || editType === UserEditType.all || editType === UserEditType.primary) &&
                        <>
                            {data.type === CardType.student &&
                                <Box style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexDirection: 'row',
                                    width: '100%'
                                }}>
                                    <StyledTitle>
                                        {t('user.editDialog.title_2')
                                    /*cardType === CardType.nonStudent &&
                                    t('user.editDialog.title_3')*/}
                                    </StyledTitle>

                                    {(isNullOrUndefined(editType) || editType === UserEditType.all) &&
                                        <Divider style={{ flexGrow: 1, margin: '0px 20px' }} />
                                    }
                                </Box>
                            }
                            <Box className="d-flex flex-row flex-wrap" sx={{ flexGrow: 1, width: '100%' }}>
                                {/*學生底部欄位*/}
                                {data.type === CardType.student &&
                                    <StudentEditInfoBottom
                                        data={watchAll}
                                        register={register}
                                        unregister={unregister}
                                        setValue={setValue}
                                        watch={watch}
                                        errors={errors}
                                        isLoading={isLoading}
                                        userFieldIdToName={userFieldIdToName}
                                        watchCustomField={watchCustomField}
                                        handleCustomPrivacyChange={handleCustomPrivacyChange}
                                    />
                                }

                                {/*企業底部欄位*/}
                            {data.type !== CardType.student &&
                                    <NonStudentEditInfoBottom
                                        data={watchAll}
                                        register={register}
                                        setValue={setValue}
                                        watch={watch}
                                        errors={errors}
                                        isLoading={isLoading}
                                        userFieldIdToName={userFieldIdToName}
                                        watchCustomField={watchCustomField}
                                        handleCustomPrivacyChange={handleCustomPrivacyChange}
                                    />
                                }
                            </Box>
                        </>
                    }
                    <Toolbar variant='dense' />
                </Box>
                {!ref &&
                    <DialogActions>
                        <ConfirmButton type="submit" disabled={isUploading || isLoading}>
                            <Trans i18nKey={'user.editDialog.saveButton'} />
                        </ConfirmButton>
                    </DialogActions>
                }
            </Box>
        )
    }
);
interface EditUserDataProps {
    userId: string;
    data: CardInfo;
    onClose: (result?: CardInfo) => Promise<boolean>;
    isLoading?: boolean;
    editType?: UserEditType;
}
export enum UserEditType {
    all = 0,
    primary = 1,
    secondary = 2
}

function StudentEditInfo(props: StudentEditInfoProps) {
    const { data, isLoading, register, watch, setValue, errors,
        userFieldIdToName, watchCustomField, handleCustomPrivacyChange } = props;
    const { t, i18n } = useTranslation();

    //學校
    const schoolIndex = data.infoFields.findIndex(x => x.fieldId === "school")
    const schoolElement = schoolIndex != -1 ? userCustomFieldList.find(x => x.id === "school") : null;
    const watchSchoolField = watch(`infoFields.${schoolIndex != -1 ? schoolIndex : 0}.value`);
    const handleSchoolChange = (school: string) => {
        setValue(`infoFields.${schoolIndex != -1 ? schoolIndex : 0}.value`, school);
    }
    //校區
    const campusIndex = data.infoFields.findIndex(x => x.fieldId === "campus")
    const campusElement = campusIndex != -1 ? userCustomFieldList.find(x => x.id === "campus") : null;
    const watchCampusField = watch(`infoFields.${campusIndex != -1 ? campusIndex : 0}.value`);
    const handleCampusChange = (campus: string) => {
        setValue(`infoFields.${campusIndex != -1 ? campusIndex : 0}.value`, campus);
    }
    //學院
    const academyIndex = data.infoFields.findIndex(x => x.fieldId === "academy")
    const academyElement = academyIndex != -1 ? userCustomFieldList.find(x => x.id === "academy") : null;
    const watchAcademyField = watch(`infoFields.${academyIndex != -1 ? academyIndex : 0}.value`);
    const handleAcademyChange = (academy: string) => {
        setValue(`infoFields.${academyIndex != -1 ? academyIndex : 0}.value`, academy);
    }
    //年級
    const gradeIndex = data.infoFields.findIndex(x => x.fieldId === "grade")
    const gradeElement = gradeIndex != -1 ? userCustomFieldList.find(x => x.id === "grade") : null;
    const watchGradeField = watch(`infoFields.${gradeIndex != -1 ? gradeIndex : 0}.value`);
    const handleGradeChange = (grade: string) => {
        setValue(`infoFields.${gradeIndex != -1 ? gradeIndex : 0}.value`, grade);
    }

    return (<>
        {/*學校*/}
        {schoolElement &&
            <Box flex='1 0 100%' className="d-flex flex-column mt-2 justify-content-end"
                paddingLeft={paddingValue} paddingRight={paddingValue}>
                <StyledEditTitle>
                    <SelectPrivacyField
                        value={watchCustomField[schoolIndex].privacy}
                        onChange={(event) => handleCustomPrivacyChange(Number(event.target.value), schoolIndex)}
                    />
                </StyledEditTitle>
                {schoolElement.editComponent({
                    displayTitle: userFieldIdToName(schoolElement.id),
                    value: watchSchoolField,
                    onChange: (event) => handleSchoolChange(event.target.value),
                    errorMessage: errors.infoFields && errors.infoFields[schoolIndex]?.value?.message,
                    inputProps: {
                        maxLength: 3001,
                        ...register(`infoFields.${schoolIndex}.value`, schoolElement.validation)
                    },
                })}
            </Box>
        }
        {/*校區*/}
        {campusElement &&
            <Box flex='1 0 100%' className="d-flex flex-column mt-2 justify-content-end"
                paddingLeft={paddingValue} paddingRight={paddingValue}>
                <StyledEditTitle>
                    <SelectPrivacyField
                        value={watchCustomField[campusIndex].privacy}
                        onChange={(event) => handleCustomPrivacyChange(Number(event.target.value), campusIndex)}
                    />
                </StyledEditTitle>
                {campusElement.editComponent({
                    displayTitle: userFieldIdToName(campusElement.id),
                    value: watchCampusField,
                    onChange: (event) => handleCampusChange(event.target.value),
                    errorMessage: errors.infoFields && errors.infoFields[campusIndex]?.value?.message,
                    inputProps: {
                        maxLength: 3001,
                        ...register(`infoFields.${campusIndex}.value`, campusElement.validation)
                    },
                })}
            </Box>
        }
        {/*學院*/}
        {academyElement &&
            <Box flex='1 0 100%' className="d-flex flex-column mt-2 justify-content-end"
                paddingLeft={paddingValue} paddingRight={paddingValue}>
                <StyledEditTitle>
                    <SelectPrivacyField
                        value={watchCustomField[academyIndex].privacy}
                        onChange={(event) => handleCustomPrivacyChange(Number(event.target.value), academyIndex)}
                    />
                </StyledEditTitle>
                {academyElement.editComponent({
                    displayTitle: userFieldIdToName(academyElement.id),
                    value: watchAcademyField,
                    onChange: (event) => handleAcademyChange(event.target.value),
                    errorMessage: errors.infoFields && errors.infoFields[academyIndex]?.value?.message,
                    inputProps: {
                        maxLength: 3001,
                        ...register(`infoFields.${academyIndex}.value`, academyElement.validation)
                    },
                })}
            </Box>
        }
        {/*年級*/}
        {gradeElement &&
            <Box flex='1 0 100%' className="d-flex flex-column mt-2 justify-content-end"
                paddingLeft={paddingValue} paddingRight={paddingValue}>
                <StyledEditTitle>
                    <SelectPrivacyField
                        value={watchCustomField[gradeIndex].privacy}
                        onChange={(event) => handleCustomPrivacyChange(Number(event.target.value), gradeIndex)}
                    />
                </StyledEditTitle>
                {gradeElement.editComponent({
                    displayTitle: userFieldIdToName(gradeElement.id),
                    value: watchGradeField,
                    onChange: (event) => handleGradeChange(event.target.value),
                    errorMessage: errors.infoFields && errors.infoFields[gradeIndex]?.value?.message,
                    inputProps: {
                        maxLength: 3001,
                        ...register(`infoFields.${gradeIndex}.value`, gradeElement.validation)
                    },
                })}
            </Box>
        }
    </>)
}
function StudentEditInfoBottom(props: StudentEditInfoProps) {
    const { data, isLoading, register, watch, setValue, errors, unregister,
        userFieldIdToName, watchCustomField, handleCustomPrivacyChange } = props;
    const { t, i18n } = useTranslation();

    //應屆畢業生/正職/找實習
    const targetListIndex = data.infoFields.findIndex(x => x.fieldId === "targetList")
    const targetListElement = targetListIndex != -1 ? userCustomFieldList.find(x => x.id === "targetList") : null;
    const watchTargetListField = watch(`infoFields.${targetListIndex != -1 ? targetListIndex : 0}.value`);
    const handleTargetListChange = (targetList: string) => {
        setValue(`infoFields.${targetListIndex != -1 ? targetListIndex : 0}.value`, targetList);
    }

    return (<>
        {/*應屆畢業生/正職/找實習*/}
        {targetListElement &&
            <Box flex='1 0 100%' className="d-flex flex-column mt-2 justify-content-end"
                paddingLeft={paddingValue} paddingRight={paddingValue}>
                <StyledEditTitle>
                    <StyledTypography>
                        {userFieldIdToName(targetListElement.id)}
                    </StyledTypography>
                    <SelectPrivacyField
                        value={watchCustomField[targetListIndex].privacy}
                        onChange={(event) => handleCustomPrivacyChange(Number(event.target.value), targetListIndex)}
                    />
                </StyledEditTitle>
                {targetListElement.editComponent({
                    value: watchTargetListField,
                    onChange: (event) => handleTargetListChange(event.target.value),
                    //style: { height: '35px' }
                })}
            </Box>
        }

        {/*客製化欄位*/}
        {data.infoFields.map((userField, index) => {
            let field = userCustomFieldList.find(x => x.id === userField.fieldId);
            if (field && !uniqueUserFieldList.includes(userField.fieldId))
                return (
                    <Box flex='1 0 100%' className="d-flex flex-column mt-2 justify-content-end mw-100" paddingLeft={paddingValue} paddingRight={paddingValue} key={field.id}>
                        <StyledEditTitle>
                            <SelectPrivacyField
                                value={watchCustomField[index].privacy}
                                onChange={(event) => handleCustomPrivacyChange(Number(event.target.value), index)}
                            />
                        </StyledEditTitle>
                        {field.editComponent({
                            displayTitle: userFieldIdToName(field.id),
                            value: watch(`infoFields.${index}.value`),
                            onChange: (event) => setValue(`infoFields.${index}.value`, event.target.value),
                            errorMessage: errors.infoFields && errors.infoFields[index]?.value?.message,
                            inputProps: {
                                maxLength: 3001,
                                ...register(`infoFields.${index}.value`, field.validation)
                            },
                        })}
                    </Box>
                )
            else
                return (
                    <div key={userField.fieldId} />
                )
        })}
    </>)
}
interface StudentEditInfoProps {
    data: CardInfo;
    register: UseFormRegister<CardInfo>;
    unregister: UseFormUnregister<CardInfo>;
    watch: UseFormWatch<CardInfo>;
    setValue: UseFormSetValue<CardInfo>;
    errors: any;
    isLoading: boolean;
    userFieldIdToName: (fieldId: string) => string;
    watchCustomField: UserExtendField[];
    handleCustomPrivacyChange: (privacy: FieldPrivacy, index: number) => void;
}

function NonStudentEditInfo(props: NonStudentEditInfoProps) {
    const { data, isLoading, register, watch, setValue, errors } = props;
    const { t, i18n } = useTranslation();

    return (<>
    </>)
}
function NonStudentEditInfoBottom(props: NonStudentEditInfoProps) {
    const { data, isLoading, register, watch, setValue, errors,
        userFieldIdToName, watchCustomField, handleCustomPrivacyChange } = props;
    const { t, i18n } = useTranslation();

    return (<>
        {/*客製化欄位*/}
        {data.infoFields.map((userField, index) => {
            let field = userCustomFieldList.find(x => x.id === userField.fieldId);
            if (field && !uniqueUserFieldList.includes(userField.fieldId))
                return (
                    <Box flex='1 0 100%' className="d-flex flex-column mt-2 justify-content-end mw-100" paddingLeft={paddingValue} paddingRight={paddingValue} key={field.id}>
                        <StyledEditTitle>
                            <SelectPrivacyField
                                value={watchCustomField[index].privacy}
                                onChange={(event) => handleCustomPrivacyChange(Number(event.target.value), index)}
                            />
                        </StyledEditTitle>
                        {field.editComponent({
                            displayTitle: userFieldIdToName(field.id),
                            value: watch(`infoFields.${index}.value`),
                            onChange: (event) => setValue(`infoFields.${index}.value`, event.target.value),
                            errorMessage: errors.infoFields && errors.infoFields[index]?.value?.message,
                            inputProps: {
                                maxLength: 3001,
                                ...register(`infoFields.${index}.value`, field.validation)
                            },
                        })}
                    </Box>
                )
            else
                return (
                    <div key={userField.fieldId} />
                )
        })}
    </>)
}
interface NonStudentEditInfoProps {
    data: CardInfo;
    register: UseFormRegister<CardInfo>;
    watch: UseFormWatch<CardInfo>;
    setValue: UseFormSetValue<CardInfo>;
    errors: any;
    isLoading: boolean;
    userFieldIdToName: (fieldId: string) => string;
    watchCustomField: UserExtendField[];
    handleCustomPrivacyChange: (privacy: FieldPrivacy, index: number) => void;
}