﻿import * as React from 'react';
import { Agenda, Seminar } from '../../components/class';
import { useTranslation } from 'react-i18next';
import { BorderButton, Button } from '../../components/responsive-components';
import { Add } from '@mui/icons-material';
import { Box, Paper, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { ScrollItemData, ScrollMenu, ScrollMenuRef } from '../../Stage/Schedule/ScrollMenu';
import { AgendaEditDialog } from './AgendaEditDialog';
import { ResultType } from '../../components/services/utils';
import { AgendaModel, useDeleteAgendaMutation, usePostAgendaMutation, usePutAgendaMutation } from '../../components/services/seminar';
import AgendaCard from './AgendaCard';

const newAgenda: Agenda = {
    seminarId: '',
    agendaId: '',
    title: '',
    boothId: null,
    startTime: '',
    endTime: '',
    eventContent: '',
    boothName: '',
    boothChName: '',
    boothEnName: ''
}
export function AgendaList(props: AgendaListProps) {
    const { isManage, seminars, onChange } = props;
    const { t, i18n } = useTranslation();
    const showTitle = props.showTitle ?? true;

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('lg'));
    const matchesPhone = useMediaQuery(theme.breakpoints.down('sm'));
    const scrollRef = React.useRef<ScrollMenuRef>();

    const [editAgenda, setEditAgenda] = React.useState<Agenda>(newAgenda);

    const [currentIndex, setCurrentIndex] = React.useState<number>(0);
    function changeCurentSeminar(index: number) {
        setCurrentIndex(index);
    }

    //編輯視窗控制
    const [open, setOpen] = React.useState(false);
    function handleNewAgenda() {
        setEditAgenda(newAgenda);
        setOpen(true);
    }
    function handleEditAgenda(agenda: Agenda) {
        setEditAgenda(agenda);
        setOpen(true);
    }
    function handleCloseDialog() {
        setOpen(false);
    }

    //新增編輯說明會
    const [insertAgenda,] = usePostAgendaMutation();
    const [updateAgenda, { }] = usePutAgendaMutation();
    function handleSubmitAgenda(value: AgendaModel, seminarId: string) {
        let tempSeminars = [...seminars];
        let modelValue: AgendaModel = {
            ...value,
            seminarId: seminarId
        }
        if (modelValue.agendaId === "") {
            return new Promise<ResultType<Agenda>>((resolve, reject) => {
                insertAgenda(modelValue).unwrap().then((result) => {
                    if (result.isSuccess) {
                        let index = tempSeminars[currentIndex].agendas.findIndex(x => x.agendaId === result.data.agendaId);
                        if (index === -1) {
                            tempSeminars[currentIndex].agendas = [...tempSeminars[currentIndex].agendas, result.data]
                            onChange([...tempSeminars]);
                        }
                        else {
                            let newArray = [...tempSeminars[currentIndex].agendas];
                            newArray[index] = result.data;
                            tempSeminars[currentIndex].agendas = newArray;
                            onChange([...tempSeminars]);
                        }
                    } else {
                        console.log(result.message);
                    }
                    resolve(result);
                }).catch(() => {
                    reject();
                });
            });
        } else {
            return new Promise<ResultType<Agenda>>((resolve, reject) => {
                updateAgenda(modelValue).unwrap().then((result) => {
                    if (result.isSuccess) {
                        let index = tempSeminars[currentIndex].agendas.findIndex(x => x.agendaId === result.data.agendaId);
                        if (index === -1) {
                            tempSeminars[currentIndex].agendas = [...tempSeminars[currentIndex].agendas, result.data]
                            onChange([...tempSeminars]);
                        }
                        else {
                            let newArray = [...tempSeminars[currentIndex].agendas];
                            newArray[index] = result.data;
                            tempSeminars[currentIndex].agendas = newArray;
                            onChange([...tempSeminars]);
                        }
                    } else {
                        console.log(result.message);
                    }
                    resolve(result);
                }).catch(() => {
                    reject();
                });
            });
        }
    }

    //設定日期區段
    const [dateData, setDateData] = React.useState<ScrollItemData[]>([]);
    function handleChangeDate(index: number) {
        if (dateData[index]) {
            let tempArray = [...dateData];
            for (let temp of tempArray) {
                if (temp.isClicked) {
                    temp.isClicked = false;
                }
            }
            tempArray[index].isClicked = true;
            setDateData(tempArray);
        }
    }
    //初始化日期
    const [init, setInit] = React.useState<string[]>([]);
    React.useEffect(() => {
        let tempDateList: ScrollItemData[] = [];
        //假如有說明會資料
        if (seminars && seminars.length > 0) {
            for (let agenda of seminars[currentIndex].agendas) {
                let dateString = agenda.startTime;
                if (tempDateList.findIndex(x => x.date.toDateString() === new Date(dateString).toDateString()) === -1) tempDateList.push({ date: new Date(dateString), isClicked: false });
            }
            tempDateList.sort((a, b) => { if (a.date > b.date) return 1; else return -1; });

            let nowDate = new Date(new Date().toDateString());
            //先確認是否有說明會資料在裡面，有的話優先查詢最近有說明會資料的日期
            for (let index = tempDateList.length - 1; index >= 0; index--) {
                if (seminars[currentIndex].agendas.filter(x => new Date(x.startTime).toDateString() === tempDateList[index].date.toDateString()).length > 0 &&
                    tempDateList[index].date >= nowDate) {
                    for (let temp of tempDateList) {
                        temp.isClicked = false;
                    }
                    tempDateList[index].isClicked = true;
                }
            }
            //若無則找最近的日期或當日
            if (!tempDateList.find(x => x.isClicked === true))
                for (let index = 0; index < tempDateList.length; index++) {
                    if (tempDateList[index].date > nowDate) {
                        tempDateList[index > 0 ? index - 1 : 0].isClicked = true;
                        break;
                    }
                    //當日已超過所有活動時間
                    if (index === tempDateList.length - 1) {
                        tempDateList[index].isClicked = true;
                    }
                }
            setInit([]);
        }
        setDateData([...tempDateList]);
    }, [seminars, currentIndex])
    React.useEffect(() => { scrollRef.current.initial(); }, [init]);

    //刪除說明會
    const [deleteAgenda, { }] = useDeleteAgendaMutation();
    function handleDeleteAgenda(value: Agenda, seminarId: string) {
        let tempSeminars = [...seminars];
        let modelValue: AgendaModel = {
            seminarId: seminarId,
            agendaId: value.agendaId,
            boothId: value.boothId,
            title: value.title,
            timeSlot: { startTime: value.startTime, endTime: value.endTime },
            eventContent: value.eventContent
        }
        return new Promise<ResultType<Agenda>>((resolve, reject) => {
            deleteAgenda(modelValue).unwrap().then((result) => {
                if (result.isSuccess) {
                    let index = tempSeminars[currentIndex].agendas.findIndex(x => x.agendaId === modelValue.agendaId);
                    if (index !== -1) {
                        let newArray = [...tempSeminars[currentIndex].agendas];
                        newArray.splice(index, 1);
                        tempSeminars[currentIndex].agendas = newArray;
                        onChange([...tempSeminars]);
                    }
                } else {
                    console.log(result.message);
                }
                resolve(result);
            }).catch(() => {
                reject();
            });
        });
    }

    return (
        <div style={{ position: 'relative', width: '100%' }}>
            {showTitle &&
                <div style={{
                    display: 'flex',
                    alignItems: 'flex-end',
                    justifyContent: 'space-between',
                    paddingLeft: !matches && '140px',
                }}>
                    <Typography fontWeight='bold' style={{ fontSize: '30px' }}>
                        {t('seminar.agendaList.title')}
                        {/*<Typography variant="subtitle1">{"※報名成功即同意分享名片資料給企業"}</Typography>*/}
                    </Typography>
                    {isManage && seminars[currentIndex] &&
                        <BorderButton onClick={handleNewAgenda}>
                            <Add fontSize={'small'} />
                            <Typography variant='subtitle1' fontWeight='bold' style={{ textTransform: 'initial' }}>
                                {t('seminar.agendaList.newagenda')}
                            </Typography>
                        </BorderButton>
                    }
                </div>
            }
            {!showTitle && isManage &&
                <Box display="flex" justifyContent="flex-end">
                    {seminars && seminars[currentIndex] &&
                        <BorderButton onClick={handleNewAgenda}>
                            <Add fontSize={'small'} />
                            <Typography variant='subtitle1' fontWeight='bold' style={{ textTransform: 'initial' }}>
                                {t('seminar.agendaList.newagenda')}
                            </Typography>
                        </BorderButton>
                    }
                </Box>
            }
            <Stack>
                <Box paddingLeft={!matches && '140px'}>
                    <ScrollMenu
                        ref={scrollRef}
                        hasMonth={true}
                        data={dateData}
                        handleDateClick={handleChangeDate}
                    />
                </Box>
                <Box style={{ display: 'flex', flexDirection: !matches ? 'row' : 'column' }}>
                    <Stack spacing={2} padding={!matches ? 3 : 1} direction={!matches ? 'column' : "row"} style={{ overflow: 'auto' }}>
                        {seminars && seminars.map((seminar, index) => (
                            <Button
                                onClick={() => changeCurentSeminar(index)}
                                variant={index === currentIndex ? 'contained' : 'outlined'}
                                color={index === currentIndex ? 'primary' : 'inherit'}
                                key={seminar.seminarId}
                            >
                                {i18n.language == "ch" ? seminar.chTitle : seminar.enTitle}
                            </Button>
                        ))}
                    </Stack>
                    <Paper variant='outlined' sx={{
                        minWidth: '300px', minHeight: '941px', height: '941px',
                        overflowY: 'auto', backgroundColor: '#f5f5f5',
                        flex: 1
                    }}>
                        <Stack padding={!matchesPhone ? 3 : 1} spacing={1}>
                            {seminars.length > 0 && seminars[currentIndex].agendas.filter(x => new Date(x.startTime).toDateString() === dateData.find(x => x.isClicked === true)?.date.toDateString())
                                .sort((a, b) => new Date(a.startTime) < new Date(b.startTime) ? -1 : 0).map((agenda) => (
                                    <AgendaCard
                                        matches={matchesPhone}
                                        isManage={isManage}
                                        seminarId={seminars[currentIndex].seminarId}
                                        seminarChName={seminars[currentIndex].chTitle}
                                        seminarEnName={seminars[currentIndex].enTitle}
                                        data={agenda}
                                        key={agenda.agendaId}
                                        onEdit={handleEditAgenda}
                                        onDelete={handleDeleteAgenda}
                                    />
                                ))}
                        </Stack>
                    </Paper>
                </Box>
            </Stack>
            {isManage &&
                <AgendaEditDialog
                    currentDate={dateData.find(x => x.isClicked === true)?.date ?? new Date()}
                    defaultValue={editAgenda}
                    seminars={seminars}
                    currentSeminar={currentIndex}
                    open={open}
                    handleSubmit={handleSubmitAgenda}
                    handleClose={handleCloseDialog}
                />
            }
        </div>
    )
}
interface AgendaListProps {
    isManage?: boolean;
    seminars: Seminar[];
    onChange?: (dataList: Seminar[]) => void;
    showTitle?: boolean;
}