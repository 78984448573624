﻿import * as React from 'react';
import { Backdrop, Button, CircularProgress, Dialog, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useCheckAccountQuery } from '../../components/services/user';
import { SubTitle } from '../../components/responsive-components/Typography';
import { googleClientId } from '../../config';
import { GoogleLoginResponse, GoogleLoginResponseOffline, useGoogleLogin } from 'react-google-login';
import { profileObj, useBindGoogleAccountMutation } from '../../components/services/login';
import { Cancel, CheckCircleOutline, SvgIconComponent } from '@mui/icons-material';

export default function UserSignInMethod() {
    const { t } = useTranslation();

    const [openDialog, setOpenDialog] = React.useState(false);
    const [isSuccess, setIsSuccess] = React.useState<boolean>();
    const [dialogMessage, setDialogMessage] = React.useState("");
    const dialogContents = (
        <div style={{ padding: '1rem 2rem', textAlign: 'center', fontSize: '2.5vmin', minWidth: "300px", minHeight: "220px" }} >
            {isSuccess ? <CheckCircleOutline sx={{ fontSize: "10vmin", color: (theme) => theme.palette.success.light }} />
                : <Cancel sx={{ fontSize: "10vmin", color: "error.light" }} />}
            <p style={{ marginBottom: 0, marginTop: "0.75rem" }}>
                {dialogMessage}
            </p>
        </div>
    );

    /*const { haveGoogle, account, isLoading, refetch } = useCheckAccountQuery(undefined, {
        selectFromResult: (result) => ({
            haveGoogle: result.data?.data.google, account: result.data?.data.googleAccount, ...result
        })
    });
    
    const { signIn, loaded } = useGoogleLogin({ clientId: googleClientId, onSuccess: handleSuccess });
    const [bindGoogle] = useBindGoogleAccountMutation();
    function handleSuccess(response: GoogleLoginResponse | GoogleLoginResponseOffline) {
        if ("googleId" in response) {
            const googleData: profileObj = { tokenId: response.tokenId, ...response.profileObj };
            bindGoogle(googleData).unwrap().then(result => {
                if (result.isSuccess) {
                    refetch();
                    setIsSuccess(true);
                    setDialogMessage(t('manage.signInMethod.success'));
                }
                else {
                    setIsSuccess(false);
                    setDialogMessage(t('manage.signInMethod.error'));
                }
                setOpenDialog(true);
            }).catch((error) => {
                //後端報錯 ex:500、404
                setDialogMessage(t('manage.signInMethod.error'));
                console.error("發生錯誤", error)
            });
        }
    }

    const ViewGoogleAccount = <>
        <Typography>{t('manage.signInMethod.google.account') + account}</Typography>
    </>;
    const BindGoogleAccount = <>
        <Typography>{t('manage.signInMethod.google.notYet')}</Typography>
        <Button variant="contained" onClick={signIn} disabled={!loaded}>{t('manage.signInMethod.google.button')}</Button>
    </>;*/

    return (<>
        <SubTitle
            icon='circle'
            text={t('manage.title.account.signInMethod')}
        />
        {/*haveGoogle === true && ViewGoogleAccount}
        {haveGoogle === false && BindGoogleAccount}
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
            <CircularProgress />
        </Backdrop>*/}
        <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>{dialogContents}</Dialog>
    </>)
};