﻿import { toRecord } from "./Admin/Utils";

//國籍
export const countryList: MenuItemType[] = [
	{ nameKey: "selectOptions.country.native", value: "本國籍" },
	{ nameKey: "selectOptions.country.Albania", value: "阿爾巴尼亞" },
	{ nameKey: "selectOptions.country.Algeria", value: "阿爾及利亞" },
	{ nameKey: "selectOptions.country.Angola", value: "安哥拉" },
	{ nameKey: "selectOptions.country.Anguilla", value: "安圭拉" },
	{ nameKey: "selectOptions.country.Argentina", value: "阿根廷" },
	{ nameKey: "selectOptions.country.Armenia", value: "亞美尼亞" },
	{ nameKey: "selectOptions.country.Aruba", value: "阿路巴" },
	{ nameKey: "selectOptions.country.Australia", value: "澳大利亞" },
	{ nameKey: "selectOptions.country.Austria", value: "奧地利" },
	{ nameKey: "selectOptions.country.Azerbaijan", value: "亞塞拜然" },
	{ nameKey: "selectOptions.country.Bahamas", value: "巴哈馬" },
	{ nameKey: "selectOptions.country.Bahrain", value: "巴林" },
	{ nameKey: "selectOptions.country.Bangladesh", value: "孟加拉" },
	{ nameKey: "selectOptions.country.Barbados", value: "巴貝多" },
	{ nameKey: "selectOptions.country.Belarus", value: "白俄羅斯" },
	{ nameKey: "selectOptions.country.Belgium", value: "比利時" },
	{ nameKey: "selectOptions.country.Belize", value: "貝里斯" },
	{ nameKey: "selectOptions.country.Benin", value: "貝南" },
	{ nameKey: "selectOptions.country.Bermuda", value: "百慕達" },
	{ nameKey: "selectOptions.country.Bhutan", value: "不丹" },
	{ nameKey: "selectOptions.country.Bolivia", value: "玻利維亞" },
	{ nameKey: "selectOptions.country.BosniaAndHerzegovina", value: "波希尼亞及赫塞哥維那" },
	{ nameKey: "selectOptions.country.Botswana", value: "波札那" },
	{ nameKey: "selectOptions.country.Brazil", value: "巴西" },
	{ nameKey: "selectOptions.country.BruneiDarussalam", value: "汶萊" },
	{ nameKey: "selectOptions.country.Bulgaria", value: "保加利亞" },
	{ nameKey: "selectOptions.country.BurkinaFaso", value: "有吉納法索" },
	{ nameKey: "selectOptions.country.Burundi", value: "蒲隆地" },
	{ nameKey: "selectOptions.country.Cambodia", value: "柬埔寨" },
	{ nameKey: "selectOptions.country.Cameroon", value: "喀麥隆" },
	{ nameKey: "selectOptions.country.Canada", value: "加拿大" },
	{ nameKey: "selectOptions.country.CapeVerde", value: "維德角島" },
	{ nameKey: "selectOptions.country.CaymanIslands", value: "開曼群島" },
	{ nameKey: "selectOptions.country.CentralAfricanRep", value: "中非共和國" },
	{ nameKey: "selectOptions.country.Chad", value: "查德" },
	{ nameKey: "selectOptions.country.Chile", value: "智利" },
	{ nameKey: "selectOptions.country.China", value: "中國大陸" },
	{ nameKey: "selectOptions.country.Colombia", value: "哥倫比亞" },
	{ nameKey: "selectOptions.country.Congo(Rep)", value: "剛果共和國" },
	{ nameKey: "selectOptions.country.CookIslands", value: "科克群島" },
	{ nameKey: "selectOptions.country.CostaRica", value: "哥斯大黎加" },
	{ nameKey: "selectOptions.country.Ivoire", value: "象牙海岸" },
	{ nameKey: "selectOptions.country.Croatia", value: "克羅埃西亞" },
	{ nameKey: "selectOptions.country.Cyprus", value: "塞浦路斯" },
	{ nameKey: "selectOptions.country.Czech(Rep)", value: "捷克" },
	{ nameKey: "selectOptions.country.Luxembourg", value: "盧森堡" },
	{ nameKey: "selectOptions.country.Macao", value: "澳門" },
	{ nameKey: "selectOptions.country.Macedonia", value: "馬其頓" },
	{ nameKey: "selectOptions.country.Madagascar", value: "馬達加斯加" },
	{ nameKey: "selectOptions.country.Malawi", value: "馬拉威" },
	{ nameKey: "selectOptions.country.Malaysia", value: "馬來西亞" },
	{ nameKey: "selectOptions.country.Maldives", value: "馬爾地夫" },
	{ nameKey: "selectOptions.country.Mali", value: "馬利" },
	{ nameKey: "selectOptions.country.Malta", value: "馬爾他" },
	{ nameKey: "selectOptions.country.Mauritius", value: "模里西斯" },
	{ nameKey: "selectOptions.country.Mauritania", value: "茅利塔尼亞" },
	{ nameKey: "selectOptions.country.Mexico", value: "墨西哥" },
	{ nameKey: "selectOptions.country.Moldova", value: "摩爾多瓦" },
	{ nameKey: "selectOptions.country.Mongolia", value: "蒙古" },
	{ nameKey: "selectOptions.country.Morocco", value: "摩洛哥" },
	{ nameKey: "selectOptions.country.Myanmar", value: "緬甸" },
	{ nameKey: "selectOptions.country.Namibia", value: "納米比亞" },
	{ nameKey: "selectOptions.country.Nauru", value: "諾魯" },
	{ nameKey: "selectOptions.country.Nepal", value: "尼泊爾" },
	{ nameKey: "selectOptions.country.Netherlands", value: "荷蘭" },
	{ nameKey: "selectOptions.country.NewCaledonia", value: "新喀里多尼亞" },
	{ nameKey: "selectOptions.country.NewZealand", value: "紐西蘭" },
	{ nameKey: "selectOptions.country.Niger", value: "尼日" },
	{ nameKey: "selectOptions.country.Nigeria", value: "奈及利亞" },
	{ nameKey: "selectOptions.country.Norway", value: "挪威" },
	{ nameKey: "selectOptions.country.Oman", value: "阿曼" },
	{ nameKey: "selectOptions.country.Pakistan", value: "巴基斯坦" },
	{ nameKey: "selectOptions.country.Panama", value: "巴拿馬" },
	{ nameKey: "selectOptions.country.PapuaNewGuinea", value: "巴布亞紐幾內亞" },
	{ nameKey: "selectOptions.country.Paraguay", value: "巴拉圭" },
	{ nameKey: "selectOptions.country.Peru", value: "秘魯" },
	{ nameKey: "selectOptions.country.Philippines", value: "菲律賓" },
	{ nameKey: "selectOptions.country.Poland", value: "波蘭" },
	{ nameKey: "selectOptions.country.Portugal", value: "葡萄牙" },
	{ nameKey: "selectOptions.country.Qatar", value: "卡達" },
	{ nameKey: "selectOptions.country.Romania", value: "羅馬尼亞" },
	{ nameKey: "selectOptions.country.RussianFederation", value: "俄羅斯" },
	{ nameKey: "selectOptions.country.Rwanda", value: "盧安達" },
	{ nameKey: "selectOptions.country.SaintChristopher", value: "聖克里斯多福及尼維斯" },
	{ nameKey: "selectOptions.country.DemRepofCongo", value: "剛果民主共和國" },
	{ nameKey: "selectOptions.country.Denmark", value: "丹麥" },
	{ nameKey: "selectOptions.country.Dominican", value: "Rep.多明尼加" },
	{ nameKey: "selectOptions.country.Dominica", value: "多米尼克" },
	{ nameKey: "selectOptions.country.Ecuador", value: "厄瓜多爾" },
	{ nameKey: "selectOptions.country.Egypt", value: "埃及" },
	{ nameKey: "selectOptions.country.ElSalvador", value: "薩爾瓦多" },
	{ nameKey: "selectOptions.country.Eritrea", value: "厄利垂亞" },
	{ nameKey: "selectOptions.country.Estonia", value: "愛沙尼亞" },
	{ nameKey: "selectOptions.country.Ethiopia", value: "衣索匹亞" },
	{ nameKey: "selectOptions.country.Fiji", value: "斐濟" },
	{ nameKey: "selectOptions.country.Finland", value: "芬蘭" },
	{ nameKey: "selectOptions.country.FrenchPolynesia", value: "法屬玻里尼西亞" },
	{ nameKey: "selectOptions.country.France", value: "法國" },
	{ nameKey: "selectOptions.country.Gabon", value: "加彭" },
	{ nameKey: "selectOptions.country.Georgia", value: "喬治亞" },
	{ nameKey: "selectOptions.country.Germany", value: "德國" },
	{ nameKey: "selectOptions.country.Ghana", value: "迦納" },
	{ nameKey: "selectOptions.country.Gibraltar", value: "直布羅陀" },
	{ nameKey: "selectOptions.country.UnitedKingdom", value: "英國" },
	{ nameKey: "selectOptions.country.Greece", value: "希臘" },
	{ nameKey: "selectOptions.country.Grenada", value: "格瑞那達" },
	{ nameKey: "selectOptions.country.Guatemala", value: "瓜地馬拉" },
	{ nameKey: "selectOptions.country.Guinea", value: "幾內亞" },
	{ nameKey: "selectOptions.country.Guyana", value: "蓋亞那" },
	{ nameKey: "selectOptions.country.Haiti", value: "海地" },
	{ nameKey: "selectOptions.country.Honduras", value: "宏都拉斯" },
	{ nameKey: "selectOptions.country.HongKong", value: "香港" },
	{ nameKey: "selectOptions.country.Hungary", value: "匈牙利" },
	{ nameKey: "selectOptions.country.Iceland", value: "冰島" },
	{ nameKey: "selectOptions.country.India", value: "印度" },
	{ nameKey: "selectOptions.country.Indonesia", value: "印尼" },
	{ nameKey: "selectOptions.country.Iran", value: "依朗" },
	{ nameKey: "selectOptions.country.Iraq", value: "伊拉克" },
	{ nameKey: "selectOptions.country.Ireland", value: "愛爾蘭" },
	{ nameKey: "selectOptions.country.Israel", value: "以色列" },
	{ nameKey: "selectOptions.country.Italy", value: "義大利" },
	{ nameKey: "selectOptions.country.Jamaica", value: "牙買加" },
	{ nameKey: "selectOptions.country.Japan", value: "日本" },
	{ nameKey: "selectOptions.country.Jordan", value: "約旦" },
	{ nameKey: "selectOptions.country.Kenya", value: "肯亞" },
	{ nameKey: "selectOptions.country.Korea(Rep)", value: "韓國" },
	{ nameKey: "selectOptions.country.Kuwait", value: "科威特" },
	{ nameKey: "selectOptions.country.LaoPeople", value: "寮國" },
	{ nameKey: "selectOptions.country.Latvia", value: "拉脫維亞" },
	{ nameKey: "selectOptions.country.Lesotho", value: "賴索托" },
	{ nameKey: "selectOptions.country.SaintLucia", value: "聖露西亞" },
	{ nameKey: "selectOptions.country.SaintVincentGrenadines", value: "聖文森及格瑞那丁" },
	{ nameKey: "selectOptions.country.SaoTomeandPrincipe", value: "聖多美及普林西比" },
	{ nameKey: "selectOptions.country.SaudiArabia", value: "沙烏地阿拉伯" },
	{ nameKey: "selectOptions.country.Senegal", value: "塞內加爾" },
	{ nameKey: "selectOptions.country.Seychelles", value: "塞席爾" },
	{ nameKey: "selectOptions.country.SierraLeone", value: "獅子山" },
	{ nameKey: "selectOptions.country.Singapore", value: "新加坡" },
	{ nameKey: "selectOptions.country.Slovakia", value: "斯洛伐克" },
	{ nameKey: "selectOptions.country.Slovenia", value: "斯洛維尼亞" },
	{ nameKey: "selectOptions.country.SolomonIslands", value: "索羅門群島" },
	{ nameKey: "selectOptions.country.Somalia", value: "索馬利亞" },
	{ nameKey: "selectOptions.country.SouthAfrica", value: "南非" },
	{ nameKey: "selectOptions.country.Spain", value: "西班牙" },
	{ nameKey: "selectOptions.country.SriLanka", value: "斯里蘭卡" },
	{ nameKey: "selectOptions.country.Sudan", value: "蘇丹" },
	{ nameKey: "selectOptions.country.Suriname", value: "蘇利南" },
	{ nameKey: "selectOptions.country.Swaziland", value: "史瓦濟蘭" },
	{ nameKey: "selectOptions.country.Sweden", value: "瑞典" },
	{ nameKey: "selectOptions.country.Switzerland", value: "瑞士" },
	{ nameKey: "selectOptions.country.SyrianArab", value: "敘利亞" },
	{ nameKey: "selectOptions.country.Tanzania", value: "坦尚尼亞" },
	{ nameKey: "selectOptions.country.Thailand", value: "泰國" },
	{ nameKey: "selectOptions.country.Togo", value: "多哥" },
	{ nameKey: "selectOptions.country.TrinidadTobago", value: "千里達及托貝哥" },
	{ nameKey: "selectOptions.country.Tunisia", value: "突尼西亞" },
	{ nameKey: "selectOptions.country.Turkey", value: "土耳其" },
	{ nameKey: "selectOptions.country.Uganda", value: "烏干達" },
	{ nameKey: "selectOptions.country.Ukraine", value: "烏克蘭" },
	{ nameKey: "selectOptions.country.UnitedArabEmirates", value: "阿拉伯聯合大公國" },
	{ nameKey: "selectOptions.country.USA", value: "美國" },
	{ nameKey: "selectOptions.country.Uruguay", value: "烏拉圭" },
	{ nameKey: "selectOptions.country.Venezuela", value: "委內瑞拉" },
	{ nameKey: "selectOptions.country.VietNam", value: "越南" },
	{ nameKey: "selectOptions.country.WesternSamoa", value: "西薩摩亞" },
	{ nameKey: "selectOptions.country.Yemen", value: "葉門" },
	{ nameKey: "selectOptions.country.Yugoslavia", value: "南斯拉夫" },
	{ nameKey: "selectOptions.country.Zambia", value: "尚比亞" },
	{ nameKey: "selectOptions.country.Zimbabwe", value: "辛巴威" }
];

//可工作地點
export const workPlaceList: MenuItemType[] = [
	{ nameKey: "selectOptions.workPlace.TPE", value: "台北市" },
	{ nameKey: "selectOptions.workPlace.NTPC", value: "新北市" },
	{ nameKey: "selectOptions.workPlace.ILA", value: "宜蘭縣" },
	{ nameKey: "selectOptions.workPlace.KEL", value: "基隆市" },
	{ nameKey: "selectOptions.workPlace.TYN", value: "桃園市" },
	{ nameKey: "selectOptions.workPlace.HSZ", value: "新竹縣市" },
	{ nameKey: "selectOptions.workPlace.ZMI", value: "苗栗縣" },
	{ nameKey: "selectOptions.workPlace.TXG", value: "台中市" },
	{ nameKey: "selectOptions.workPlace.CHW", value: "彰化縣" },
	{ nameKey: "selectOptions.workPlace.NTC", value: "南投縣" },
	{ nameKey: "selectOptions.workPlace.YUN", value: "雲林縣" },
	{ nameKey: "selectOptions.workPlace.CYI", value: "嘉義縣市" },
	{ nameKey: "selectOptions.workPlace.TNN", value: "台南市" },
	{ nameKey: "selectOptions.workPlace.KHH", value: "高雄市" },
	{ nameKey: "selectOptions.workPlace.PIF", value: "屏東縣" },
	{ nameKey: "selectOptions.workPlace.TTT", value: "台東縣" },
	{ nameKey: "selectOptions.workPlace.HUN", value: "花蓮縣" },
	{ nameKey: "selectOptions.workPlace.PEH", value: "澎湖縣" },
	{ nameKey: "selectOptions.workPlace.KNH", value: "金門縣" },
	{ nameKey: "selectOptions.workPlace.LNN", value: "連江縣" },
	{ nameKey: "selectOptions.workPlace.other", value: "其他地區" },
	{ nameKey: "selectOptions.workPlace.remote", value: "線上遠端" },
];

//校區
export const campusList: MenuItemType[] = [
	{ value: "交大校區", nameKey: 'selectOptions.campus.交大校區' },
	{ value: "陽明校區", nameKey: 'selectOptions.campus.陽明校區' },
]

//學院
export const academyList: MenuItemType[] = [
	{ value: "電機學院", nameKey: 'selectOptions.department.電機學院' },
	{ value: "資訊學院", nameKey: 'selectOptions.department.資訊學院' },
	{ value: "工學院", nameKey: 'selectOptions.department.工學院' },
	{ value: "理學院", nameKey: 'selectOptions.department.理學院' },
	{ value: "生物科技學院", nameKey: 'selectOptions.department.生物科技學院' },
	{ value: "管理學院", nameKey: 'selectOptions.department.管理學院' },
	{ value: "人文社會學院", nameKey: 'selectOptions.department.人文社會學院' },
	{ value: "客家文化學院", nameKey: 'selectOptions.department.客家文化學院' },
	{ value: "光電學院", nameKey: 'selectOptions.department.光電學院' },
	{ value: "科技法律學院", nameKey: 'selectOptions.department.科技法律學院' },
	{ value: "國際半導體產業學院", nameKey: 'selectOptions.department.國際半導體產業學院' },
	{ value: "智慧科學暨綠能學院", nameKey: 'selectOptions.department.智慧科學暨綠能學院' },
	{ value: "前瞻系統工程教育院", nameKey: 'selectOptions.department.前瞻系統工程教育院' },
	{ value: "共同教育委員會", nameKey: 'selectOptions.department.共同教育委員會' },
	{ value: "台灣聯合大學系統", nameKey: 'selectOptions.department.台灣聯合大學系統' },
	{ value: "人文與社會科學院", nameKey: 'selectOptions.department.人文與社會科學院' },
	{ value: "牙醫學院", nameKey: 'selectOptions.department.牙醫學院' },
	{ value: "生命科學院", nameKey: 'selectOptions.department.生命科學院' },
	{ value: "生物醫學暨工程學院", nameKey: 'selectOptions.department.生物醫學暨工程學院' },
	{ value: "產學創新研究學院", nameKey: 'selectOptions.department.產學創新研究學院' },
	{ value: "醫學院", nameKey: 'selectOptions.department.醫學院' },
	{ value: "藥物科學院", nameKey: 'selectOptions.department.藥物科學院' },
	{ value: "護理學院", nameKey: 'selectOptions.department.護理學院' },
	{ value: "共同教育中心", nameKey: 'selectOptions.department.共同教育中心' },
	{ value: "學士班大一大二不分系", nameKey: 'selectOptions.department.學士班大一大二不分系' },
	{ value: "跨專業長期照顧與管理碩士學位學程", nameKey: 'selectOptions.department.跨專業長期照顧與管理碩士學位學程' },
	{ value: "跨領域/跨院", nameKey: 'selectOptions.department.跨領域/跨院' },
];
export const academyDictionary = toRecord(academyList, "value");

//年級
export const gradeList: MenuItemType[] = [
	{ value: "大學一年級", nameKey: "selectOptions.grade.大學一年級" },
	{ value: "大學二年級", nameKey: "selectOptions.grade.大學二年級" },
	{ value: "大學三年級", nameKey: "selectOptions.grade.大學三年級" },
	{ value: "大學四年級", nameKey: "selectOptions.grade.大學四年級" },
	{ value: "碩士一年級", nameKey: "selectOptions.grade.碩士一年級" },
	{ value: "碩士二年級", nameKey: "selectOptions.grade.碩士二年級" },
	{ value: "碩士三年級", nameKey: "selectOptions.grade.碩士三年級" },
	{ value: "博士一年級", nameKey: "selectOptions.grade.博士一年級" },
	{ value: "博士二年級", nameKey: "selectOptions.grade.博士二年級" },
	{ value: "博士三年級", nameKey: "selectOptions.grade.博士三年級" },
];
export const gradeDictionary = toRecord(gradeList, "value");

export interface MenuItemType {
	nameKey: string,
	value: string
}
export interface CheckItemType {
	id: string;
	nameKey: string;
}