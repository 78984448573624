﻿import { Redeem } from '@mui/icons-material';
import { Box, Button, LinearProgress, Stack, Typography } from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams, GridRowParams, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton, GridValueGetterParams } from '@mui/x-data-grid';
import format from 'date-fns/format';
import { cloneDeep } from 'lodash';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { UserRole } from '../../components/class';
import { renderCellExpand } from '../../components/DataGrid/renderCellExpand';
import { ConfirmButton, Dialog, DialogActions, DialogContent, DialogTitle, SubTitle } from '../../components/responsive-components';
import { useGetAllBoothNamesQuery } from '../../components/services/booth';
import { HeroMedalHistoryData, useLazyGetHeroRewardHistoryDataQuery, useRedeemHeroRewardMutation } from '../../components/services/point';
import { useUser } from '../../store';
import { zhTWGrid } from '../data-grid-locale';

export default function HeroMedalHistory() {
    const { t, i18n } = useTranslation();

    const user = useUser();
    const [getHistory] = useLazyGetHeroRewardHistoryDataQuery();
    const { data: boothList } = useGetAllBoothNamesQuery();

    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [isError, setIsError] = React.useState<boolean>(false);

    const [buttonList, setButtonList] = React.useState<{ chName: string; enName: string; data: HeroMedalHistoryData[] }[]>([]);
    const [currentIndex, setCurrentIndex] = React.useState<number>();
    React.useEffect(() => {
        init();
    }, [user, boothList]);
    async function init() {
        try {
            setIsLoading(true);
            if (user) {
                let list: { chName: string; enName: string; data: HeroMedalHistoryData[] }[] = [];
                if (user.role >= UserRole.administrator) {
                    let result = await getHistory(null).unwrap();
                    if (result.isSuccess)
                        list.push({ chName: "全部", enName: "All", data: result.data });
                    else
                        throw new Error(result.message);
                }
                await Promise.all(user.boothIds.map(async (boothId) => {
                    let booth = boothList?.data.find(x => x.boothId === boothId);
                    if (booth != null) {
                        let result = await getHistory(boothId).unwrap();
                        if (result.isSuccess)
                            list.push({ chName: booth.chName, enName: booth.enName, data: result.data });
                        else
                            throw new Error(result.message);
                    }
                }));
                setButtonList(list);
                setCurrentIndex(0);
            }
        } catch {
            setIsError(true);
            console.error("發生問題");
        } finally {
            setIsLoading(false);
        }
    }

    //控制兌換面板
    const [value, setValue] = React.useState<HeroMedalHistoryData>();
    const [open, setOpen] = React.useState(false);
    function handleExchange(value: HeroMedalHistoryData) {
        setOpen(true);
        setValue(value);
    }

    const handleClose = (isExchangedSuccess: boolean) => {
        //如果成功兌換，更新資料
        if (isExchangedSuccess) {
            setButtonList((buttonList) => {
                let list = cloneDeep(buttonList);
                let index = list[currentIndex].data.findIndex(x => x.id === value.id);
                if (index >= 0)
                    list[currentIndex].data[index].isExchanged = true;
                return list;
            });
        }
        setOpen(false);
    };

    return (<>
        <SubTitle
            icon='circle'
            text={t('manage.title.loyaltySystem.heroMedalHistory')}
        />
        {isLoading &&
            <LinearProgress />
        }
        {isError &&
            <Typography align="center" color="error.main">
                {t('manage.heroMedalHistory.error')}
            </Typography>
        }
        {buttonList.length > 1 && buttonList.map((item, index) => (
            <Button key={index.toString()} variant="outlined" onClick={() => setCurrentIndex(index)} sx={{ mr: 0.5, my: 0.5 }}>
                {i18n.language === "ch" ? item.chName : item.enName}
            </Button>
        ))}
        {currentIndex !== null &&
            <HeroMedalHistoryList
                data={buttonList[currentIndex]?.data ?? []}
                loading={isLoading}
                showExchangeButton={user?.role >= UserRole.administrator}
                handleExchange={handleExchange}
            />
        }
        <RedeemDialog value={value} open={open} onClose={handleClose} />
    </>)
}

interface HeroMedalHistoryListProps {
    data: HeroMedalHistoryData[];
    loading: boolean;
    showExchangeButton: boolean;
    handleExchange: (value: HeroMedalHistoryData) => void;
}
function HeroMedalHistoryList(props: HeroMedalHistoryListProps) {
    const { data, loading, showExchangeButton, handleExchange } = props;
    const { t, i18n } = useTranslation();

    const columns: GridColDef[] = [
        ...(showExchangeButton ? [
            {
                field: 'studentId',
                headerName: t('manage.heroMedalHistory.options.studentId'),
                renderCell: renderCellExpand,
            },
            {
                field: 'studentName',
                headerName: t('manage.heroMedalHistory.options.studentName'),
                renderCell: renderCellExpand,
            },
            {
                field: 'reward',
                headerName: t('manage.heroMedalHistory.options.reward'),
                width: 200,
                renderCell: renderCellExpand,
            },
            {
                field: 'studentEmail',
                headerName: t('manage.heroMedalHistory.options.studentEmail'),
                width: 200,
                renderCell: renderCellExpand,
            },
        ] : [
            {
                field: 'studentAcademy',
                headerName: t('manage.heroMedalHistory.options.studentAcademy'),
                renderCell: renderCellExpand,
            },
            {
                field: 'studentGrade',
                headerName: t('manage.heroMedalHistory.options.studentGrade'),
                renderCell: renderCellExpand,
            },
            {
                field: 'studentName',
                headerName: t('manage.heroMedalHistory.options.studentName'),
                renderCell: renderCellExpand,
            },
            {
                field: 'reward',
                headerName: t('manage.heroMedalHistory.options.reward'),
                width: 200,
                renderCell: renderCellExpand,
            },
        ]),
        {
            field: 'boothName',
            headerName: t('manage.heroMedalHistory.options.boothName'),
            valueGetter: ({ row: { senderBoothChName, senderBoothEnName } }: GridValueGetterParams<string, HeroMedalHistoryData>) => {
                if (i18n.language === "ch")
                    return senderBoothChName ?? "";
                else
                    return senderBoothEnName ?? "";
            },
            renderCell: renderCellExpand,
        },
        {
            field: 'senderName',
            headerName: t('manage.heroMedalHistory.options.senderName'),
            renderCell: renderCellExpand,
        },
        ...(showExchangeButton ? [
            {
                field: 'id',
                headerName: t('manage.heroMedalHistory.options.exchange'),
                minWidth: 140,
                renderCell: ({ row }: GridRenderCellParams<string, HeroMedalHistoryData>) => {
                    if (row.isExchanged)
                        return (<Typography color="secondary.main">{t('manage.heroMedalHistory.hasExchanged')}</Typography>);
                    return (
                        <ConfirmButton size="small" onClick={() => handleExchange(row)}>
                            {t('manage.heroMedalHistory.exchange')}
                        </ConfirmButton>
                    )
                }
            }
        ] : []),
        {
            field: 'createTime',
            headerName: t('manage.heroMedalHistory.options.createTime'),
            type: 'dateTime',
            width: 180,
            valueGetter: ({ value }: GridValueGetterParams<string>) => format(new Date(value), "yyyy/MM/dd HH:mm:ss"),
        },
    ];

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                <GridToolbarExport csvOptions={{ utf8WithBom: true }} />
            </GridToolbarContainer>
        );
    }

    return (
        <DataGrid rows={data} columns={columns}
            pageSize={10}
            autoHeight
            disableColumnMenu
            disableColumnSelector
            components={{
                Toolbar: CustomToolbar,
            }}
            localeText={i18n.language == "ch" ? zhTWGrid : null}
            loading={loading}
        />
    )
}

interface RedeemDialogProps {
    open: boolean;
    value: HeroMedalHistoryData;
    onClose: (isExchangedSuccess: boolean) => void;
}
function RedeemDialog(props: RedeemDialogProps) {
    const { open, value, onClose } = props;
    const { t, i18n } = useTranslation();

    function handleClose() {
        onClose(false);
    }

    const [response, setResponse] = React.useState<React.ReactNode>("");
    const [finish, setFinish] = React.useState<boolean>(false);
    const [redeemReward, { isLoading }] = useRedeemHeroRewardMutation();
    function onSubmit() {
        redeemReward({ studentId: value.studentId, id: value.id }).unwrap()
            .then((result) => {
                //200 OK
                if (result.isSuccess) {
                    setResponse(<b>兌換成功✨</b>);
                    setFinish(true);
                    setTimeout(() => {
                        onClose(true);
                    }, 300);
                } else {
                    setResponse(<><b>兌換失敗</b><br />{result.message}</>);
                }
            })
            .catch((error) => {
                //後端報錯 ex:500、404
                console.error("發生錯誤", error);
                setResponse("Unconnect");
            });
    }
    return (
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
            <DialogTitle>
                <Box display="flex" alignItems="center">
                    <Redeem sx={{ color: 'primary.main', fontSize: "1.375rem", mr: 2 }} />
                    <Typography variant="h6" fontWeight="bold">{t("manage.heroMedalHistory.redeemDialog.title")}</Typography>
                </Box>
            </DialogTitle>
            <DialogContent sx={{ py: 0 }}>
                <Stack>
                    <Typography>{t('manage.heroMedalHistory.redeemDialog.receiver')}</Typography>
                    <Typography color="text.primary" sx={{ padding: 1 }}>
                        <small>學號：</small><b>{value?.studentId}</b>
                        <br />
                        <small>學院：</small><span style={{ marginRight: "40px" }}><b>{value?.studentAcademy}</b></span>
                        <small>年級：</small><b>{value?.studentGrade}</b>
                        <br />
                        <small>姓名：</small><b>{value?.studentName}</b>
                        <br />
                        <small>電子信箱：</small><b>{value?.studentEmail}</b>
                    </Typography>
                    <Typography>{t('manage.heroMedalHistory.redeemDialog.sender')}</Typography>
                    <Typography color="text.primary" sx={{ padding: 1 }}>
                        <small>攤位：</small><b>{value?.senderBoothChName} {value?.senderBoothEnName}</b>
                        <br />
                        <small>姓名：</small><b>{value?.senderName}</b>
                    </Typography>
                    <Typography>{t('manage.heroMedalHistory.redeemDialog.reward')}</Typography>
                    <Typography color="text.primary" sx={{ padding: 1 }}>
                        {value?.reward}
                    </Typography>
                </Stack>
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'flex-end' }}>
                <Typography variant="body1" mr={1}>{response}</Typography>
                <ConfirmButton type="submit" loading={isLoading} onClick={onSubmit} disabled={finish}>{t('manage.heroMedalHistory.redeemDialog.confirm')}</ConfirmButton>
            </DialogActions>
        </Dialog>
    )
}