﻿import * as React from 'react';
import { ButtonProps, styled, TypographyProps, useMediaQuery, useTheme } from '@mui/material';
import { Card, CardActions, CardContent } from '@mui/material';
import { IconButton, Typography } from '@mui/material';
import { Link as LinkIcon, MarkunreadMailbox, FavoriteBorder, Favorite, CheckCircle, Place, DoubleArrow, RemoveCircle } from '@mui/icons-material';
import { JobOpening, User, UserRole } from '../../components/class';
import { useTranslation } from 'react-i18next';
import { useDispatchWithType, useUser } from '../../store';
import { Button } from '../../components/responsive-components';
import { useChangeRecruitsMutation } from '../../components/services/user';
import { cityData } from './City';

const StyledButton: (props: ButtonProps) => JSX.Element = styled(Button)(({ theme }) => ({
    '&.MuiButton-root': {
        width: 'fit-content',
        fontWeight: 'bold',
        color: 'white',
        backgroundColor: '#12C735',
        padding: '6px 12px',
    },
}));

const StyledTitleTypography: (props: TypographyProps) => JSX.Element = styled(Typography)(({ theme }) => ({
    fontWeight: 'bold',
    paddingRight: '12px',
}));

export default function JobOpeningCard(props: JobOpeningCardProps) {
    return (
        <Card variant='outlined' style={{
            position: 'relative', display: 'flex', flexDirection: 'column',
            /*margin: 'auto',*/ height: '320px', borderColor: 'initial'
        }}>
            <JobOpeningCardContent
                {...props}
            />
        </Card>
    )
};

export function JobOpeningCardContent(props: JobOpeningCardProps) {
    const { data, isManage, moreInfo, onClickEdit, onClickDelete, onClickMoreInfo, onClickApply } = props;

    const user = useUser();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));
    const dispatch = useDispatchWithType();
    const { t, i18n } = useTranslation();

    //編輯與刪除
    const handleClickEdit = () => {
        if (onClickEdit) {
            onClickEdit(data);
        }
    };
    const handleClickDelete = () => {
        if (onClickDelete) {
            onClickDelete(data);
        }
    };

    //更多資訊
    const handleClickMoreInfo = () => {
        if (onClickMoreInfo) {
            onClickMoreInfo(data);
        }
    };

    //我要應徵
    const handleClickApply = () => {
        if (onClickApply) {
            onClickApply(data);
        }
    };

    //收藏功能
    const [bookMark, setBookMark] = React.useState<boolean>(false);
    React.useEffect(() => {
        if (user && user.saveRecruitIds) {
            setBookMark(user.saveRecruitIds.includes(data.dataId));
        }
    }, [user, data])
    const [changeBookMark, { isLoading: isBookMarkLoading }] = useChangeRecruitsMutation();
    const handleClickFavorite = () => {
        changeBookMark({ dataId: data.dataId }).unwrap()
            .then((result) => {
                if (result.isSuccess) {
                    const temp: User = { ...user, saveRecruitIds: result.data }

                    dispatch({
                        type: 'UPDATEUSER',
                        payload: temp
                    });
                }
            }).catch((error) => {
                console.log(error);
            }
            );
    };

    return (<>
        <CardContent sx={{ flex: "1 1", overflow: moreInfo ? 'auto' : 'hidden', "& .MuiTypography-root": { whiteSpace: "pre-wrap" }, pb: 0, mt: moreInfo? '24px' : '0px' }}>
            <Typography noWrap={!moreInfo} fontWeight='bold' variant='h6' style={{ paddingRight: '24px' }}>
                {data && data.chName}
            </Typography>
            <Typography noWrap={!moreInfo} style={{ fontSize: '18px' }}>
                {data && data.enName}
            </Typography>

            <div style={{ height: '18px' }} />

            <Typography noWrap={!moreInfo} fontWeight='bold' sx={{ fontSize: '28px', color: (theme) => theme.palette.primary.main }}>
                {data && data.title}
            </Typography>
            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                <Typography variant='subtitle2' style={{ display: 'flex', marginRight: '24px' }}>
                    <Place fontSize='small' style={{ color: '#E82A2A', marginRight: '2px' }} />
                    {data && `${t('jobOpening.card.work_place')} ${data.place < cityData.length ? t(cityData[data.place].nameId) : t(cityData[0].nameId)}`}
                </Typography>
                <Typography variant='subtitle2' style={{ display: 'flex' }}>
                    {(data && data.forEngineer) ?
                        <CheckCircle sx={{ color: theme => theme.palette.success.light, marginRight: '2px' }} /> :
                        <RemoveCircle sx={{ color: theme => theme.palette.grey[400], marginRight: '2px' }} />
                    }
                    {t('jobOpening.card.forEngineer')}
                </Typography>
            </div>

            <div style={{ height: '18px' }} />

            <StyledTitleTypography>
                {t('jobOpening.card.placeDescipt_title')}
            </StyledTitleTypography>
            <Typography style={{
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                overflow: 'hidden',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: !moreInfo && 2,
            }}>
                {data && data.placeDescipt}
            </Typography>

            {moreInfo && <>
                <div style={{ height: '18px' }} />

                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    <StyledTitleTypography>
                        {t('jobOpening.card.demandNum_title')}
                    </StyledTitleTypography>
                    <Typography>
                        {data && data.demandNum}
                    </Typography>
                </div>

                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    <StyledTitleTypography>
                        {t('jobOpening.card.treatment_title')}
                    </StyledTitleTypography>
                    <Typography>
                        {data && data.treatment}
                    </Typography>
                </div>

                <div style={{ height: '18px' }} />

                <StyledTitleTypography>
                    {t('jobOpening.card.qualify_title')}
                </StyledTitleTypography>
                <Typography>
                    {data && data.qualify}
                </Typography>

                <div style={{ height: '18px' }} />

                <StyledTitleTypography>
                    {t('jobOpening.card.content_title')}
                </StyledTitleTypography>
                <Typography>
                    {data && data.content}
                </Typography>

                <div style={{ height: '18px' }} />

                <StyledTitleTypography>
                    {t('jobOpening.card.memo_title')}
                </StyledTitleTypography>
                <Typography>
                    {data && data.memo}
                </Typography>
            </>}
        </CardContent>
        <CardActions style={{ justifyContent: 'flex-end' }}>
            {!isManage && ((user !== null && user.role >= UserRole.normal) &&
                <IconButton
                    onClick={handleClickFavorite}
                    style={{
                        /*zIndex: 2,*/
                        height: 'fit-content',
                        borderRadius: '25%',
                        position: 'absolute',
                        top: '6px',
                        right: '6px'
                    }}
                >
                    {bookMark ?
                        <Favorite fontSize={matches ? 'medium' : 'small'} style={{ color: '#fa6980' }} /> :
                        <FavoriteBorder fontSize={matches ? 'medium' : 'small'} style={{ color: '#545569' }} />
                    }
                </IconButton>)
            }
            {!isManage && onClickMoreInfo &&
                <StyledButton
                    onClick={handleClickMoreInfo}
                    endIcon={<DoubleArrow />}
                >
                    {t('jobOpening.card.more_info')}
                </StyledButton>
            }
            {!isManage && onClickApply &&
                <StyledButton
                    onClick={handleClickApply}
                    startIcon={<MarkunreadMailbox />}
                >
                    {t('jobOpening.card.apply')}
                </StyledButton>
            }

            {isManage && (onClickEdit || onClickDelete) &&
                <div id='top-button-list'
                    style={{
                        position: 'absolute', top: '4px', right: '4px', zIndex: 20,
                        display: 'flex', flexDirection: 'column', alignItems: 'center',
                    }}
                >
                    {onClickEdit &&
                        <Button
                            onClick={() => handleClickEdit()}
                            style={{ padding: '2px 9px', minWidth: 'auto', }}
                        >
                            {t('jobOpening.card.edit')}
                        </Button>
                    }
                    {onClickDelete &&
                        <Button
                            onClick={() => handleClickDelete()}
                            style={{ padding: '2px 9px', minWidth: 'auto', }}
                        >
                            {t('jobOpening.card.delete')}
                        </Button>
                    }
                </div>
            }
            {isManage &&
                <LinkIcon color={data.link ? 'primary' : 'disabled'} />
            }
        </CardActions>
    </>)
};

interface JobOpeningCardProps {
    isManage?: boolean;
    data: JobOpening;
    moreInfo?: boolean;
    onClickEdit?: (value: JobOpening) => void;
    onClickDelete?: (value: JobOpening) => void;
    onClickMoreInfo?: (value: JobOpening) => void;
    onClickApply?: (value: JobOpening) => void;
}