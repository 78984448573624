﻿import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { BoothArticle } from '../class';
import { ResultType } from './utils';

export interface MultiUploadResult {
    count: number;
    size: number;
    urls: string[];
}

export interface SingleUploadResult {
    size: number;
    url: string;
}

export const baseApi = createApi({
    reducerPath: 'fileApi',
    baseQuery: fetchBaseQuery(),
    endpoints: (builder) => ({
        getUserLogo: builder.query<File, { userId: string, fileName: string }>({
            query: ({ userId, fileName }) => `logo/user/${userId}/${fileName}`,
        }),
        getUserUploadedLogo: builder.query<ResultType<string>, string>({
            query: (userId) => `logo/user/${userId}`,
        }),
        getBoothLogo: builder.query<File, { boothId: string, fileName: string }>({
            query: ({ boothId, fileName }) => `logo/booth/${boothId}/${fileName}`,
        }),
        getBoothUploadedLogo: builder.query<ResultType<string>, string>({
            query: (boothId) => `logo/booth/${boothId}`,
        }),
        getPoster: builder.query<File, { boothId: string, fileName: string }>({
            query: ({ boothId, fileName }) => `poster/${boothId}/${fileName}`,
        }),
        getEDM: builder.query<File, { boothId: string, fileName: string }>({
            query: ({ boothId, fileName }) => `edm/${boothId}/${fileName}`,
        }),
        uploadUserLogo: builder.mutation<ResultType<MultiUploadResult>, { userId: string, formData: FormData }>({
            query: ({ userId, formData }) => ({
                url: `logo/user/${userId}`,
                method: 'POST',
                body: formData
            }),
        }),
        uploadBoothLogo: builder.mutation<ResultType<MultiUploadResult>, { boothId: string, formData: FormData }>({
            query: ({ boothId, formData }) => ({
                url: `logo/booth/${boothId}`,
                method: 'POST',
                body: formData
            }),
        }),
        uploadPoster: builder.mutation<ResultType<SingleUploadResult>, { boothId: string, formData: FormData }>({
            query: ({ boothId, formData }) => ({
                url: `poster/${boothId}`,
                method: 'POST',
                body: formData
            }),
        }),
        uploadEDM: builder.mutation<ResultType<SingleUploadResult>, { boothId: string, formData: FormData }>({
            query: ({ boothId, formData }) => ({
                url: `edm/${boothId}`,
                method: 'POST',
                body: formData
            }),
        }),
        deleteUserLogo: builder.mutation<ResultType<null>, { userId: string, fileName: string }>({
            query: ({ userId, fileName }) => ({
                url: `logo/user/${userId}/${fileName}`,
                method: 'DELETE',
                body: ''
            }),
        }),
        deleteBoothLogo: builder.mutation<ResultType<null>, { boothId: string, fileName: string }>({
            query: ({ boothId, fileName }) => ({
                url: `logo/booth/${boothId}/${fileName}`,
                method: 'DELETE',
                body: ''
            }),
        }),
        getImageInChatRoom: builder.query<File, { chatId: string, fileName: string }>({
            query: ({ chatId, fileName }) => `chat/${chatId}/${fileName}`,
        }),
        getBoothImage: builder.query<File, { boothId: string, fileName: string }>({
            query: ({ boothId, fileName }) => `booth/${boothId}/${fileName}`,
        }),
        getUserImage: builder.query<File, { userId: string, fileName: string }>({
            query: ({ userId, fileName }) => `user/${userId}/${fileName}`,
        }),
        uploadImageInChatRoom: builder.mutation<ResultType<SingleUploadResult>, { chatId: string, formData: FormData }>({
            query: ({ chatId, formData }) => ({
                url: `lupload/image/chat/${chatId}`,
                method: 'POST',
                body: formData
            }),
        }),
        uploadFileInChatRoom: builder.mutation<ResultType<SingleUploadResult>, { chatId: string, formData: FormData }>({
            query: ({ chatId, formData }) => ({
                url: `upload/file/chat/${chatId}`,
                method: 'POST',
                body: formData
            }),
        }),
        uploadBoothImage: builder.mutation<ResultType<SingleUploadResult>, { boothId: string, formData: FormData }>({
            query: ({ boothId, formData }) => ({
                url: `upload/image/booth/${boothId}`,
                method: 'POST',
                body: formData
            }),
        }),
        uploadUserImage: builder.mutation<ResultType<SingleUploadResult>, { userId: string, formData: FormData }>({
            query: ({ userId, formData }) => ({
                url: `upload/image/user/${userId}`,
                method: 'POST',
                body: formData
            }),
        })
    }),
})

//exhibitor或superuser或admin用api
export const fileApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
    }),
    overrideExisting: false
});

export const { useGetUserUploadedLogoQuery } = fileApi;
export const { useLazyGetUserUploadedLogoQuery } = fileApi;
export const { useGetBoothUploadedLogoQuery } = fileApi;
export const { useLazyGetBoothUploadedLogoQuery } = fileApi;
export const { useUploadUserLogoMutation } = fileApi;
export const { useUploadBoothLogoMutation } = fileApi;
export const { useUploadPosterMutation } = fileApi;
export const { useUploadEDMMutation } = fileApi;
export const { useDeleteUserLogoMutation } = fileApi;
export const { useDeleteBoothLogoMutation } = fileApi;
export const { useUploadImageInChatRoomMutation } = fileApi;
export const { useUploadFileInChatRoomMutation } = fileApi;
export const { useUploadBoothImageMutation } = fileApi;
export const { useUploadUserImageMutation } = fileApi;