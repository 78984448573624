﻿import { Box, Button, Dialog, DialogActions, Typography } from '@mui/material';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { SystemControlHub } from '../components/react-signalr/systemControlHub';
import { useSystemConnection } from '../store';
import { useGetSeminarSignInStatusQuery, useSignInOnSeminarMutation } from '../components/services/seminar';

interface SeminarSignInProps {
    seminarId: string;
    disableSignIn?: boolean;
}
export default function SeminarSignIn(props: SeminarSignInProps) {
    const { seminarId, disableSignIn } = props;

    const { t } = useTranslation();

    const { data: seminarSignInStatus } = useGetSeminarSignInStatusQuery(seminarId, { refetchOnMountOrArgChange: true });
    const [signInDataId, setSignInDataId] = React.useState<string>(); //目前沒有作用
    const [isSignable, setIsSignable] = React.useState<boolean>();
    React.useEffect(() => { if (seminarSignInStatus) setIsSignable(seminarSignInStatus.data); }, [seminarSignInStatus]);

    const systemConnection = useSystemConnection();
    const hubRef = React.useRef<SystemControlHub>();

    const seminarIdRef = React.useRef<string>();
    React.useEffect(() => { seminarIdRef.current = seminarId; }, [seminarId]);

    React.useEffect(() => {
        if (systemConnection != null) {
            hubRef.current = new SystemControlHub(systemConnection);

            //收到簽到狀態更新的指示，更新簽到按鈕
            hubRef.current.addHandler.OnChangeSignInStatus((seminarId: string, isOpen: boolean) => {
                if (seminarIdRef.current) {
                    if (seminarId === seminarIdRef.current) {
                        setIsSignable(isOpen);
                        if (isOpen)
                            window.confirm("簽到時間開始囉！請按下簽到鈕簽到。\n(此確認框為提示，無簽到功能)")
                    }
                }
                else {
                    console.log("Seminar sign-in: Missing id.")
                }
            });
        }
        return (() => {
            if (hubRef.current) {
                hubRef.current.removeHandler.OnChangeSignInStatus();
            }
        })
    }, [systemConnection]);

    const [signIn] = useSignInOnSeminarMutation();
    const [open, setOpen] = React.useState(false);
    const [message, setMessage] = React.useState("");
    function handleClick() {
        if (seminarId)
            signIn(seminarId).unwrap()
                .then((result) => {
                    if (result.isSuccess) {
                        setMessage("Success !");
                        setOpen(true);
                        setIsSignable(false);
                    } else {
                        setMessage(result.message);
                        setOpen(true);
                    }
                });
    }

    if (!seminarId) {
        return (<></>);
    }
    else
    return (
        <>
            {!disableSignIn &&
                <Button color="primary" variant="contained" sx={{ marginTop: 1.5 }} disabled={!isSignable} onClick={handleClick}>
                    {t('onlineSeminar.signIn')}
                </Button>
            }
            <Box position="absolute" top="auto" right="0" bottom="-32px" left="0" px={2} pt="10px" pb={1}
                sx={{ border: "1px solid rgba(0,0,0,0.12)", borderBottom: "unset", background: "#FFDA48" }}
            >
                <Typography variant="body2" lineHeight="1">
                    {t('onlineSeminar.notice')}
                </Typography>
            </Box>
            <Dialog open={open}>
                <div style={{ padding: '2rem 2rem 1rem 2rem', textAlign: 'center', fontSize: '1rem' }} >
                    <span style={{ fontWeight: 'bold' }}>
                        {message}
                    </span>
                </div>
                <DialogActions style={{ justifyContent: 'center' }}>
                    <Button autoFocus onClick={() => { setOpen(false); }}>{t('onlineSeminar.ok')}</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}