﻿import * as React from 'react';
import { FormControl, FormControlLabel, FormHelperText, FormLabel, Link, OutlinedInput, Radio, RadioGroup } from '@mui/material';
import { Box, Stack, Toolbar, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { PlayCircleFilled } from '@mui/icons-material';
import { read, utils, writeFile } from 'xlsx';
import { useGetAllBoothNamesQuery } from '../../../components/services/booth';
import { BoothWithJobOpeningListModel, usePostNewBoothJobListMutation } from '../../../components/services/booth';

interface editState {
}
interface ImportData {
    boothName: string;
    title: string;
    isInternship: string;
    category: string;
    place: string;
    placeDescipt: string;
    demandNum: string;
    payType: string;
    payment: string;
    protectLabor: string;
    jobDescipt: string;
    qualification: string;
    plus: string;
    startWorking: string;
    hoursPerWeek: string;
    duration: string;
}
export default function NewBoothJobList(props) {
    const { boothList = [] } = useGetAllBoothNamesQuery(null, {
        selectFromResult: ({ data }) => ({ boothList: data?.data })
    });
    const [fileAccept, setFileAccept] = React.useState('.csv');
    const handleFileTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFileAccept((event.target as HTMLInputElement).value);
    };

    const schema = yup.object().shape({
    });
    const { register, handleSubmit, formState: { errors } } = useForm<editState>({
        mode: "onBlur", resolver: yupResolver(schema), defaultValues: {}
    });
    const [excelData, setExcelData] = React.useState<ImportData[]>([]);
    const onImport = (file) => {
        //讀檔
        if (fileAccept === ".csv") {
            onImportExcel(file).then((object) => { setExcelData(object) }).catch(() => {
                console.error("上傳失敗");
            })
        } else if (fileAccept === ".xlsx, .xls") {
            onImportExcel(file).then((object) => { setExcelData(object) }).catch(() => {
                console.error("上傳失敗");
            })
        }
    }

    const [postNewBoothJobList] = usePostNewBoothJobListMutation();
    const onSubmit = async (data: editState) => {
        setError(false)
        setLoading(true);
        let newJobOpenings: BoothWithJobOpeningListModel[] = [];

        for (let i = 0; i < excelData.length; i++) {
            let boothId = null;
            if (excelData[i].boothName && excelData[i].boothName.trim() != "") {
                let booth = boothList.find(x => x.chName == excelData[i].boothName);
                if (booth) {
                    boothId = booth.boothId;
                }
                else {
                    console.error(`第${i}筆：新增 ${excelData[i].title} 失敗`);
                    continue;
                }
            }
            let newJobOpening: BoothWithJobOpeningListModel = {
                boothId: boothId === "" ? null : boothId,
                title: excelData[i].title === "" ? null : excelData[i].title.toString(),
                isInternship: excelData[i].isInternship === "" ? true : Boolean(excelData[i].isInternship),
                category: excelData[i].category === "" ? "32" : excelData[i].category.toString(),
                place: excelData[i].place === "" ? "其他地區" : excelData[i].place.toString(),
                placeDescipt: excelData[i].placeDescipt === "" ? null : excelData[i].placeDescipt.toString(),
                demandNum: excelData[i].demandNum === "" ? 0 : Number(excelData[i].demandNum),
                payType: excelData[i].payType === "" ? "面議" : excelData[i].payType.toString(),
                payment: excelData[i].payment === "" ? null : Number(excelData[i].payment),
                protectLabor: excelData[i].protectLabor === "" ? false : Boolean(excelData[i].protectLabor),
                jobDescipt: excelData[i].jobDescipt === "" ? null : excelData[i].jobDescipt.toString(),
                qualification: excelData[i].qualification === "" ? null : excelData[i].qualification.toString(),
                plus: excelData[i].plus === "" ? null : excelData[i].plus.toString(),
                startWorking: excelData[i].startWorking === "" || excelData[i].startWorking === "立即" ? "立即" : new Date(Math.round((+excelData[i].startWorking - 25569) * 86400 * 1000) - 28800 * 1000).toISOString(),
                hoursPerWeek: excelData[i].hoursPerWeek === "" ? null : Number(excelData[i].hoursPerWeek),
                duration: excelData[i].duration === "" ? null : excelData[i].duration.toString(),
            };
            newJobOpenings.push(newJobOpening);
        }
        await postNewBoothJobList(newJobOpenings).unwrap().then((result) => {
            if (result.isSuccess) {
                setGenerateList(newJobOpenings);
            } else {
                setError(true)
                console.log(result.message);
            }
        }).catch((error) => {
            setError(true)
            console.log(error);
        });
        setLoading(false);
    };
    const [generateList, setGenerateList] = React.useState<BoothWithJobOpeningListModel[]>([]);
    const [error, setError] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    return (
        <div>
            <Typography variant='h6' style={{
                padding: 10,
                borderRadius: "6px 6px 0px 0px",
                fontWeight: 'bold',
                color: 'white',
                background: 'linear-gradient(to right, #368AFC, #7DDCFF)',
            }}>
                建立新職缺
            </Typography>
            <Typography ml={3}>
                使用匯入功能時，請先確保header欄位名稱包含以下：
            </Typography>
            <Typography ml={3} fontWeight="bold">
                boothName, title, isInternship, category, place, placeDescipt, demandNum, payType, payment, protectLabor, jobDescipt, qualification, plus, startWorking, hoursPerWeek, duration
            </Typography>
            <Link ml={3} href="./files/Addjob.xlsx" underline="hover" target="_blank">下載：範例樣本</Link>
            <Box component="form" sx={{ '& .MuiTextField-root': { minWidth: 320, width: '50%' } }} onSubmit={handleSubmit(onSubmit)}>
                <Stack alignItems="start" spacing={1} p={3}>
                    <FormControl component="fieldset">
                        <FormLabel component="legend">上傳檔案格式</FormLabel>
                        <RadioGroup row value={fileAccept} onChange={handleFileTypeChange}>
                            <FormControlLabel value=".csv" control={<Radio />} label=".csv" />
                            <FormControlLabel value=".xlsx, .xls" control={<Radio />} label=".xlsx, .xls" />
                        </RadioGroup>
                    </FormControl>
                    {fileAccept != "other" &&
                        <input type='file' accept={fileAccept} onChange={onImport} />
                    }

                    <Toolbar />

                    <div className="d-flex justify-content-center w-100">
                        <LoadingButton size='large' color="primary" variant="contained" type="submit"
                            loading={loading} loadingPosition="start" startIcon={<PlayCircleFilled />}>
                            生成
                        </LoadingButton>
                    </div>
                    {error && <>ERRRRROR</>}
                    {generateList.map((data, index) => (
                        <div key={index.toString()}>
                            <Typography paragraph>
                                {`名稱：${data.title} `}
                                {`公司：${boothList.find(x => x.boothId == data.boothId).chName}`}
                            </Typography>
                        </div>
                    ))}
                </Stack>
            </Box>
        </div>
    )
};

const onImportExcel = file => new Promise<ImportData[]>(function (resolve, reject) {
    // 獲取上傳的檔案物件
    const { files } = file.target;
    // 通過FileReader物件讀取檔案
    const fileReader = new FileReader();
    fileReader.onload = event => {
        try {
            const { result } = event.target;
            // 以二進位制流方式讀取得到整份excel表格物件
            const workbook = read(result, { type: 'binary' });
            // 儲存獲取到的資料
            let data = [];
            // 遍歷每張工作表進行讀取（這裡預設只讀取第一張表）
            for (const sheet in workbook.Sheets) {
                // esline-disable-next-line
                if (workbook.Sheets.hasOwnProperty(sheet)) {
                    // 利用 sheet_to_json 方法將 excel 轉成 json 資料
                    data = data.concat(utils.sheet_to_json(workbook.Sheets[sheet], { defval: '' }));
                    break; // 如果只取第一張表，就取消註釋這行
                }
            }
            // 最終獲取到並且格式化後的 json 資料
            resolve(data);
        } catch (e) {
            // 這裡可以丟擲檔案型別錯誤不正確的相關提示
            reject('檔案型別不正確！');
        }
    };
    // 以二進位制方式開啟檔案
    fileReader.readAsBinaryString(files[0]);
})