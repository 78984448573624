﻿import { Backdrop, Box, Button, Card, CircularProgress, Container, Grid, Toolbar, Typography, useMediaQuery, useTheme } from "@mui/material";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import HomeStepper from "./HomeStepper";
import { useDispatchWithType, useSystemConnection, useWatchedStartAnounce } from "../store";
import { SystemControlHub } from "../components/react-signalr/systemControlHub";
import { closeStartAnounce } from "../store/rootReducer";
import { SubTitle } from "../components/responsive-components/Typography";
import BoothArticleList from "../components/Article/BoothArticleList";
import { AgendaList } from "../Booth/Seminar/AgendaList";
import { useLazyGetAllSeminarsQuery } from "../components/services/seminar";
import { logEvent } from "../components/Analytics";
import { Carousel } from 'react-responsive-carousel/lib/js';
const HomeImg = "./images/2022交大秋季banner.png";
const HomeMobileImg = "./images/2022交大秋季m_banner.png";
const GameImage = "images/遊戲逛展_圖.png";
const BoothListImage = "images/多元交流_圖.png";
const SeminarImage = "images/多項議程_圖.png";
const PrizeImage = "images/集點好禮_圖.png";
const ChPointsRules = './manuals/集點規則.pdf';
const EnPointsRules = './manuals/集點規則 en.pdf';

export function Home() {
    const history = useHistory();

    const theme = useTheme();
    const isMatchesSm = useMediaQuery(theme.breakpoints.up('sm'));

    //換連線位置
    const systemConnection = useSystemConnection();
    const hubRef = React.useRef<SystemControlHub>();
    React.useEffect(() => {
        if (systemConnection != null) {
            hubRef.current = new SystemControlHub(systemConnection);
            hubRef.current.send.ChangeLocation({ location: 0, refId: null });
        }
    }, [systemConnection]);

    const { t, i18n } = useTranslation();

    //簽到相關
    const watchedStartAnounce = useWatchedStartAnounce();
    const dispatch = useDispatchWithType();
    const [openStepper, setOpenStepper] = React.useState<boolean>(false);
    React.useEffect(() => { if (!watchedStartAnounce) setOpenStepper(true) }, [watchedStartAnounce]);
    const handleCloseStepper = () => {
        setOpenStepper(false);
        dispatch(closeStartAnounce())
    }

    //說明會
    const [getSeminars, { data: seminars }] = useLazyGetAllSeminarsQuery();
    React.useEffect(() => { getSeminars() }, []);

    //主視覺
    const [index, setIndex] = React.useState<number>(0);
    const images = [];
    const CarouselView = () => (
        <Box>
            <Carousel
                autoPlay
                showArrows={isMatchesSm}
                showStatus
                showIndicators
                showThumbs={false}
                useKeyboardArrows={false}
                emulateTouch={true}
                selectedItem={index}
                infiniteLoop
            >
                {images.map((imageSrc, index) => (
                    <Box height={isMatchesSm ? "640px" : "30vh"} key={imageSrc}>
                        <img src={imageSrc} width="100%" height="100%" style={{ objectFit: "contain" }} />
                    </Box>
                ))}
            </Carousel>
        </Box>
    );

    const matches = useMediaQuery(theme.breakpoints.up('sm'));
    const Banner = () => (
        <Box width="100%">
            <img src={matches ? HomeImg : HomeMobileImg} width="100%" height="100%" style={{ objectFit: "cover" }} />
        </Box>
    );

    //跳轉
    const handleClickLink = (link: string) => {
        history.push(link);
    }

    return (<>
        <HomeStepper open={openStepper} onClose={handleCloseStepper} />

        <Banner />

        <Box style={{ margin: "2rem 0px" }}>
            <Container>
                <Typography variant='h6' align='center' gutterBottom fontWeight='bold' sx={{ color: theme => theme.palette.primary.main }}>
                    {t('home.title.part1')}
                </Typography>
                <Typography sx={{ maxWidth: '1000px', margin: 'auto' }}>
                    {t('home.title.part2')}
                </Typography>
            </Container>
        </Box>

        <Box style={{ backgroundColor: '#f5f5f5', minHeight: 530 }}>
            <Container style={{ paddingTop: '20px', paddingBottom: '6px' }}>
                <SubTitle icon='square' text={t('home.subTitle.cardButton.title')}
                    subText={t('home.subTitle.cardButton.subText')} />
                <Grid container spacing={2}>
                    <Grid item md={6} xs={12}>
                        <CardButton
                            icon={GameImage}
                            titleText={t('home.cardButton.game.title')}
                            contentText={t('home.cardButton.game.content')}
                            buttonText={t('home.cardButton.game.button')}
                            link={'./Game'}
                        />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <CardButton
                            icon={BoothListImage}
                            titleText={t('home.cardButton.booth.title')}
                            contentText={t('home.cardButton.booth.content')}
                            buttonText={t('home.cardButton.booth.button')}
                            link={'./BoothList'}
                        />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <CardButton
                            icon={SeminarImage}
                            titleText={t('home.cardButton.seminar.title')}
                            contentText={t('home.cardButton.seminar.content')}
                            buttonText={t('home.cardButton.seminar.button')}
                            link={'./Stage'}
                        />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Card sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'stretch', height: '100%' }}>
                            <img src={PrizeImage} style={{ padding: '51px 20px', height: 'fit-content', margin: 'auto', paddingBottom: '0px' }} />
                            <Box sx={{ display: 'flex', flexDirection: 'column', padding: '51px 16px' }}>
                                <Typography variant='h5' gutterBottom sx={{ color: theme => theme.palette.primary.main }}>
                                    {t('home.cardButton.prize.title')}
                                </Typography>
                                <Typography>
                                    {t('home.cardButton.prize.content')}
                                </Typography>
                                <Button onClick={() => window.open(i18n.language === "ch" ? ChPointsRules : EnPointsRules)} style={{ margin: 'auto 0px 0px auto' }}>{`${t('home.cardButton.prize.button')}>>`}</Button>
                            </Box>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
            <Toolbar />
        </Box>

        <Box>
            <Container style={{ minHeight: 500 }}>
                <SubTitle icon='square' text={t('home.subTitle.news.title')} link={'./ArticleList'} linkText={t('home.subTitle.news.link')} />
                <BoothArticleList boothId={null} showPin isManage={false} />
            </Container>
            <Toolbar />
        </Box>
        <Box>
            <Container style={{ paddingTop: '20px', paddingBottom: '6px', minHeight: 530 }}>
                <SubTitle icon='square' text={t('home.subTitle.article.title')} link={'./Stage'} linkText={t('home.subTitle.article.link')} />
                <AgendaList seminars={seminars?.data ?? []} showTitle={false} />
            </Container>
            <Toolbar />
        </Box>
    </>)
}

function CardButton(props: CardButtonProps) {
    const { icon, matches, titleText, contentText, buttonText, link } = props;

    const history = useHistory();
    function handleClickButton() {
        logEvent(`Home`, `ClickCardLink`,`${ link }`);
        history.push(link);
    }

    return (
        <Card sx={{ display: 'flex', flexWrap: matches ? 'nowrap' : 'wrap', alignItems: 'stretch', height: '100%' }}>
            <img src={icon} style={{ padding: '51px 20px', height: 'fit-content', margin: 'auto', paddingBottom: matches ? '51px' : '0px' }} />
            <Box sx={{ display: 'flex', flexDirection: 'column', padding: '51px 16px' }}>
                <Typography variant='h5' gutterBottom sx={{ color: theme => theme.palette.primary.main }}>
                    {titleText}
                </Typography>
                <Typography>
                    {contentText}
                </Typography>
                <Button onClick={handleClickButton} style={{ margin: 'auto 0px 0px auto' }}>{`${buttonText}>>`}</Button>
            </Box>
        </Card>
    )
}
interface CardButtonProps {
    icon: string;
    titleText: string;
    contentText: string;
    buttonText: string;
    link: string;
    matches?: boolean;
}