﻿import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { Booth, BoothExtendField, BoothFieldName, BoothPresence, BoothQandA, BoothType, CardInfo, JobOpening, VideoRecord } from '../class'
import { UserCardInfoModel } from './user';
import { ResultType } from './utils';

export interface BoothCardInfoModel extends Booth {
    isOnline: boolean;
}

export interface BoothDetailData extends Booth {
    createTime: string;
    managers: ManagerData[];
}
export interface ManagerData {
    userId: string;
    name: string;
    logo: string;
}
export interface ImportBoothModel extends Omit<Booth, "whiteboardRoomCode"> {
}
export interface ImportBoothModel {
    forWeb: boolean;
    forGame: boolean;
    boothId: string;
    type: BoothType;
    boothNo: string;
    boothLocation: string;
    category: number;
    chName: string;
    enName: string;
    abbrev: string;
    logoUrl: string;
    customContent: string;
    fieldList: BoothExtendField[];
}

export interface BoothModel extends Omit<Booth, "whiteboardRoomCode"> {
}
export interface BoothModel {
    boothId: string;
    type: BoothType;
    boothNo: string;
    boothLocation: string;
    category: number;
    chName: string;
    enName: string;
    abbrev: string;
    logoUrl: string;
    customContent: string;
    fieldList: BoothExtendField[];
}

export interface RecruitData {
    dataId: string;
    boothId: string;
    content: string;
    link: string;
    isEnd: boolean;
    updateTime: string;
}

export interface BellCall {
    boothId: string;
    receiverId: string;
    option: number;
    message: string;
}
export interface BoothWithJobOpeningListModel {
    boothId: string;
    title: string;
    isInternship: boolean;
    category: string;
    place: string;
    placeDescipt: string;
    demandNum: number;
    payType: string;
    payment: number;
    protectLabor: boolean;
    jobDescipt: string;
    qualification: string;
    plus: string;
    startWorking: string;
    hoursPerWeek: number;
    duration: string;
}
interface PosterData {
    booth: Booth;
    editTime: string;
    posterUrl: string;
};
export interface BellCallData {
    dataId: string;
    booth: Booth;
    sender: CardInfo;
    receiver: CardInfo;
    option: number;
    message: string;
    createTime: string;
}
//通用api
const baseApi = createApi({
    reducerPath: 'boothApi',
    baseQuery: fetchBaseQuery({ baseUrl: 'api/booth' }),
    endpoints: (builder) => ({
        getAllBooths: builder.query<ResultType<BoothCardInfoModel[]>, void>({
            query: () => `boothList`,
        }),
        getAllBoothNames: builder.query<ResultType<Pick<Booth, 'boothId' | 'chName' | 'enName' | 'type' | 'category'>[]>, void>({
            query: () => `boothList/name`,
        }),
        getTypeBooth: builder.mutation<ResultType<Booth[]>, BoothType>({
            query: (patch) => ({
                url: `type`,
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: patch,
            }),
        }),
        getBooth: builder.query<ResultType<Booth>, Pick<Booth, 'boothId'>>({
            query: ({ boothId }) => `${boothId}`,
        }),
        getBoothFieldNameList: builder.query<ResultType<BoothFieldName[]>, void>({
            query: () => `fieldname`,
        }),
        getBoothQandA: builder.query<ResultType<BoothQandA>, string>({
            query: (questId) => `question/${questId}`
        }),
        getAllBoothQandAs: builder.query<ResultType<BoothQandA[]>, string>({
            query: (boothId) => `questions/${boothId}`
        }),
        watchBoothVideo: builder.mutation<ResultType<VideoRecord>, string>({
            query: (boothId) => `WatchBoothVideo/${boothId}`
        }),
        getAllJobOpening: builder.query<ResultType<JobOpening[]>, void>({
            query: () => `getalljobopening`,
        }),
        getJobOpening: builder.query<ResultType<JobOpening[]>, Pick<Booth, 'boothId'>>({
            query: ({ boothId }) => `getalljobopening/${boothId}`,
        }),
        getBellCalls: builder.query<ResultType<BellCallData[]>, string>({
            query: (boothId) => `serviceBell/${boothId}`,
        }),
        postBellCall: builder.mutation<ResultType<number>, BellCall>({
            query: (patch) => ({
                url: `serviceBell`,
                method: 'POST',
                body: patch,
            }),
        }),
        getBoothManagers: builder.query<ResultType<UserCardInfoModel[]>, string>({
            query: (boothId) => `getManagers/${boothId}`,
        }),
        getBoothPresenceInfos: builder.mutation<ResultType<BoothPresence[]>, {boothIds: string[]}>({
            query: (patch) => ({
                url: `getBoothPresenceInfos`,
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: patch
            }),
        }),
        getAllPoster: builder.query<ResultType<PosterData[]>, void>({
            query: () => `poster`,
        }),
        getVideo: builder.query<ResultType<string>, string>({
            query: (boothId) => `${boothId}/getField/video`,
        }),
    }),
});

//exhibitor或superuser或admin用api
export const boothApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getBoothList: builder.query<ResultType<BoothDetailData[]>, void>({
            query: () => `getList`,
        }),
        putBooth: builder.mutation<ResultType<null>, BoothModel>({
            query: (patch) => ({
                url: ``,
                method: 'PUT',
                body: patch,
            }),
        }),
        postQandA: builder.mutation<ResultType<BoothQandA>, Pick<BoothQandA, "boothId" | "question">>({
            query: ({ ...patch }) => ({
                url: `question`,
                method: 'POST',
                body: patch,
            }),
        }),
        putQandA: builder.mutation<ResultType<BoothQandA>, Pick<BoothQandA, "dataId" | "answer">>({
            query: ({ ...patch }) => ({
                url: `question`,
                method: 'PUT',
                body: patch,
            }),
        }),
        putQandALikemark: builder.mutation<ResultType<boolean>, { userId: string, boardId: string }>({
            query: ({ userId, boardId }) => ({
                url: `likemark/${userId}/${boardId}`,
                method: 'PUT'
            }),
        }),
        updateJobOpening: builder.mutation<ResultType<JobOpening>, JobOpening>({
            query: ({ ...patch }) => ({
                url: `jobopening`,
                method: 'PUT',
                body: patch,
            }),
        }),
        deleteJobOpening: builder.mutation<ResultType<JobOpening>, Pick<JobOpening, 'dataId'>>({
            query: ({ dataId }) => ({
                url: `jobopening/${dataId}`,
                method: 'DELETE'
            }),
        }),
        postNewBooths: builder.mutation<ResultType<null>, ImportBoothModel[]>({
            query: (patch) => ({
                url: `addlist`,
                method: 'POST',
                body: patch,
            }),
        }),
        updateBoothPoster: builder.mutation<ResultType<null>, { uri: string } & Pick<Booth, 'boothId'>>({
            query: ({ boothId, ...patch }) => ({
                url: `poster/${boothId}`,
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: patch
            }),
        }),
        updateBoothEdm: builder.mutation<ResultType<null>, { uri: string } & Pick<Booth, 'boothId'>>({
            query: ({ boothId, ...patch }) => ({
                url: `edm/${boothId}`,
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: patch
            }),
        }),
        postNewBoothJobList: builder.mutation<ResultType<null>, BoothWithJobOpeningListModel[]>({
            query: (patch) => ({
                url: `jobopening/addlist`,
                method: 'POST',
                body: patch,
            }),
        }),
        updateVideo: builder.mutation<ResultType<null>, { value: string } & Pick<Booth, 'boothId'>>({
            query: ({ boothId, ...patch }) => ({
                url: `${boothId}/setField/video`,
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: patch
            }),
        }),
    }),
    overrideExisting: false
});

//通用api
export const { useGetAllBoothsQuery } = boothApi;
export const { useLazyGetAllBoothsQuery } = boothApi;
export const { useGetAllBoothNamesQuery } = boothApi;
export const { useLazyGetAllBoothNamesQuery } = boothApi;
export const { useGetTypeBoothMutation } = boothApi;
export const { useGetBoothQuery } = boothApi;
export const { useLazyGetBoothQuery } = boothApi;
export const { useGetBoothFieldNameListQuery } = boothApi;
export const { useLazyGetBoothFieldNameListQuery } = boothApi;
//export const { useGetRecruitsQuery } = boothApi;
export const { useGetBoothQandAQuery } = boothApi;
export const { useLazyGetBoothQandAQuery } = boothApi;
export const { useGetAllBoothQandAsQuery } = boothApi;
export const { useLazyGetAllBoothQandAsQuery } = boothApi;
export const { useWatchBoothVideoMutation } = boothApi;
export const { useGetAllJobOpeningQuery } = boothApi;
export const { useLazyGetAllJobOpeningQuery } = boothApi;
export const { useGetJobOpeningQuery } = boothApi;
export const { useLazyGetJobOpeningQuery } = boothApi;
export const { useGetBellCallsQuery } = boothApi;
export const { usePostBellCallMutation } = boothApi;
export const { useGetBoothManagersQuery } = boothApi;
export const { useLazyGetBoothManagersQuery } = boothApi;
export const { useGetBoothPresenceInfosMutation } = boothApi;
export const { useGetAllPosterQuery } = boothApi;
export const { useLazyGetAllPosterQuery } = boothApi;
export const { useGetVideoQuery } = boothApi;

//exhibitor或superuser或admin用api
export const { useGetBoothListQuery } = boothApi;
export const { usePutBoothMutation } = boothApi;
export const { usePostQandAMutation } = boothApi;
export const { usePutQandAMutation } = boothApi;
export const { usePutQandALikemarkMutation } = boothApi;
export const { useUpdateJobOpeningMutation } = boothApi;
export const { useDeleteJobOpeningMutation } = boothApi;
export const { usePostNewBoothsMutation } = boothApi;
export const { useUpdateBoothPosterMutation } = boothApi;
export const { useUpdateBoothEdmMutation } = boothApi;
export const { usePostNewBoothJobListMutation } = boothApi;
export const { useUpdateVideoMutation } = boothApi;