﻿import * as React from 'react';
import BoardCard from './BoardCard';
import { SxProps } from '@mui/system';
import { alpha, Box, BoxProps, ButtonProps, DialogContent, DialogTitle, Divider, Grid, MenuItem, selectClasses, styled, Tab, Tabs, Theme, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Help, PanTool, QuestionAnswer } from '@mui/icons-material';
import { useUser } from '../../store';
import { BoothQandA } from '../../components/class';
import AddBoardCardDialog from './AddBoardCardDialog';
import { useTranslation } from 'react-i18next';
import { Button, BorderButton, TextField } from '../../components/responsive-components';
import { useLazyGetAllBoothQandAsQuery, usePostQandAMutation, usePutQandALikemarkMutation, usePutQandAMutation } from '../../components/services/booth';
import { cloneDeep } from 'lodash';
import { logEvent } from '../../components/Analytics';

export default function BoardCardTable(props: BoardTableProps) {
    const user = useUser();
    const { boothId, isManage } = props;

    const { t, i18n } = useTranslation();

    const theme = useTheme();
    const tabletMatches = useMediaQuery(theme.breakpoints.up('sm'));
    const pcMatches = useMediaQuery(theme.breakpoints.up('lg'));

    const [boards, setBoards] = React.useState<BoothQandA[]>([]);
    const [getBoothQandA, { data: boothQandA }] = useLazyGetAllBoothQandAsQuery();
    React.useEffect(() => { if (boothId) getBoothQandA(boothId) }, [boothId]);
    React.useEffect(() => {
        if (boothQandA && boothQandA.data)
            setBoards(cloneDeep(boothQandA.data));
    }, [boothQandA]);

    const [postQandA] = usePostQandAMutation();

    //新問題
    const [open, setOpen] = React.useState(false);
    const handleClickOpenNewQuestion = () => {
        setOpen(true);
    };
    const handleCloseNewQuestion = () => {
        setOpen(false);
    };
    function handleAddBoardCardSubmit(value: Pick<BoothQandA, "question">) {
        return new Promise<void>((resolve, reject) => {
            postQandA({ ...value, boothId: boothId }).unwrap().then((result) => {
                if (result.isSuccess) {
                    setBoards([...boards, result.data]);
                    setIsNew(true);
                    resolve();
                }
            }).catch((error) => {
                console.log(error);
                reject();
            });
        });
    }

    //篩選問題
    enum boardType {
        全部 = -1,
        待回覆 = 0,
        已回覆 = 1
    }
    const [filterType, setFilterType] = React.useState<boardType>(-1);
    function handleChange(event: React.SyntheticEvent, newValue: any) {
        setFilterType(newValue);
    };
    function filterBorads(dataList: BoothQandA[]) {
        switch (filterType) {
            case boardType.待回覆:
                return dataList.filter(x => !x.answer || x.answer === '');
            case boardType.已回覆:
                return dataList.filter(x => x.answer && x.answer !== '');
            default:
                return dataList;
        }
    }

    //是否用新舊排序
    const [isNew, setIsNew] = React.useState<boolean>(true);
    function handleSelectSort(event: React.ChangeEvent<HTMLInputElement>) {
        let value = event.target.value === "true";
        setIsNew(value);
    }

    //資料更改
    function onChange(dataList: BoothQandA[]) {
        setBoards([...dataList]);
    }

    return (<>
        <DialogTitle>
            <Box display="flex" flexWrap="wrap" alignItems="center" justifyContent="space-between">
                <Typography variant="h6" fontWeight="bold" pt="5px">
                    <Box component="span" color="primary.main" mr={1}>
                        <Help fontSize="large" color="inherit" viewBox="0 2 24 24" />
                    </Box>
                    {t('boardCard.table.title')}
                </Typography>

                <Button variant='contained' onClick={() => handleClickOpenNewQuestion()} style={{ borderRadius: '8px' }}>
                    <PanTool fontSize={'small'} style={tabletMatches ? { marginRight: '8px', marginLeft: '-4px' } : {}} />
                    {tabletMatches &&
                        <Typography variant='subtitle1' style={{ textTransform: 'initial' }}>
                            {t('boardCard.table.askquestion')}
                        </Typography>
                    }
                </Button>
            </Box>
        </DialogTitle>
        <Box bgcolor="grey.200">
            <Tabs value={filterType} onChange={handleChange} centered>
                <Tab value={boardType.全部} label={t('boardCard.table.none_filter')} />
                <Tab value={boardType.待回覆} label={t('boardCard.table.not_reply_filter')} />
                <Tab value={boardType.已回覆} label={t('boardCard.table.already_reply_filter')} />
            </Tabs>
        </Box>
        <DialogContent sx={{ backgroundColor: theme => theme.palette.grey[100] }}>
            <BoardCardGridList
                boards={filterBorads(boards)}
                isManage={isManage}
                onChange={onChange}
                sortByNew={isNew}
                matches={tabletMatches}
            />
        </DialogContent>
        <Box p="10px 22px" display="flex" alignItems="center" justifyContent="space-between">
            <Typography>
                {`${t('boardCard.table.question_number')} `}
                <Box component="span" color="primary.main">{boards.length.toString()}</Box>
            </Typography>
            <TextField
                select
                value={String(isNew)}
                onChange={handleSelectSort}
                sx={{ [`& .${selectClasses.select}`]: { padding: "4px 32px 4px 10px !important", fontSize: "0.875rem" }, "& .MuiMenuItem-root": { fontSize: "0.875rem" } }}
            >
                <MenuItem value="true">{t('boardCard.table.newest_sort')}</MenuItem>
                <MenuItem value="false">{t('boardCard.table.popular_sort')}</MenuItem>
            </TextField>
        </Box>
        <AddBoardCardDialog
            open={open}
            handleSubmit={handleAddBoardCardSubmit}
            handleClose={handleCloseNewQuestion}
        />
    </>)
};
interface BoardTableProps {
    boothId: string;
    isManage?: boolean;
}

export function BoardCardGridList(props: BoardCardGridListProps) {
    const user = useUser();
    const { boards, isManage, onChange, sortByNew, matches } = props;

    const { t, i18n } = useTranslation();

    //回覆問題
    const [putQandA] = usePutQandAMutation();
    async function handleReply(value: Pick<BoothQandA, "dataId" | "answer">) {
        let success = false;
        const index = boards.findIndex(x => x.dataId == value.dataId)
        if (index >= 0) {
            //api
            const result = await putQandA(value).unwrap();
            if (result.isSuccess) {
                let tempValues = [...boards];
                tempValues[index] = result.data;
                onChange(tempValues);
                success = true;
            }
        }
        return success;
    }

    //按讚
    const [putQandALikemark] = usePutQandALikemarkMutation();
    async function handleLikeMarkClick(boardId: string, isLike: boolean) {
        const index = boards.findIndex(x => x.dataId == boardId);
        let success = false;
        if (index >= 0) {
            //api
            putQandALikemark({ userId: user.userId, boardId: boardId }).unwrap().then((result) => {
                if (result.isSuccess) {
                    toggleLikeMarkChange(boardId, user.userId, result.data);
                    //isLike = result.data;
                    success = true;

                    if (result.data)
                        logEvent('Booth', `${boards[index].boothId}: BoardCard`, `Like`);
                    else
                        logEvent('Booth', `${boards[index].boothId}: BoardCard`, `CancelLike`);
                }
            }).catch((error) => {
                console.log(error);
            });
        }
        return success;
    }
    function toggleLikeMarkChange(boardId: string, userId: string, toggle: boolean) {
        const index = boards.findIndex(x => x.dataId == boardId)
        if (index >= 0) {
            let tempValues = cloneDeep(boards);
            let likeIdIndex = tempValues[index].likers.findIndex(x => x == userId);
            if (toggle) {
                if (likeIdIndex == -1) {
                    tempValues[index].likers.push(userId);
                }
            }
            else {
                if (likeIdIndex != -1) {
                    tempValues[index].likers.splice(likeIdIndex, 1);
                }
            }
            onChange(tempValues);
        }
    }

    //排序方法
    function compare(a: BoothQandA, b: BoothQandA) {
        if (!sortByNew) {
            if (a.likers.length <= b.likers.length) {
                return 1;
            }
            else {
                return -1;
            }
        }
        else {
            if ((a.answer ? a.ansTime : a.askTime) <= (b.answer ? b.ansTime : b.askTime)) {
                return 1;
            }
            else {
                return -1;
            }
        }
    }

    //顯示數量
    const [displayMore, setDisplayMore] = React.useState<number>(1);
    function handleMoreCard() {
        setDisplayMore(displayMore + 6);
    }

    return (<>
        <Grid container spacing={3} style={{ margin: 'auto', width: '100%' }}>
            {boards.sort((a, b) => compare(a, b)).map((tile, index) => {
                if (matches || (index < displayMore))
                    return (
                        <Grid
                            item
                            xs={12} md={6} lg={4}
                            key={tile.dataId}
                            style={{ paddingLeft: '12px', paddingRight: '12px' }}
                        >
                            <BoardCard
                                data={tile}
                                isManage={isManage}
                                index={index}
                                handleReply={handleReply}
                                handleLikeMarkClick={handleLikeMarkClick}
                            />
                        </Grid>
                    )
            })}
        </Grid>

        {!matches &&
            (displayMore >= boards.length ||
                <div style={{ display: 'flex', padding: '20px 10px', justifyContent: 'center' }}>
                    <Button
                        onClick={() => handleMoreCard()}
                        sx={{
                            textTransform: 'none',
                            width: '100%',
                            outline: 'black 2px solid',
                            '&:focus': {
                                outline: 'black 2px solid',
                            },
                        }}
                    >
                        <Typography variant='h6'>
                            {t('boardCard.table.more')}
                        </Typography>
                    </Button>
                </div>
            )
        }
    </>)
}
interface BoardCardGridListProps {
    boards: BoothQandA[];
    isManage?: boolean;
    onChange: (dataList: BoothQandA[]) => void;
    sortByNew: boolean;
    matches?: boolean;
}