﻿import { MoreHoriz } from "@mui/icons-material";
import { Backdrop, Box, Button as MuiButton, ButtonProps, CircularProgress, ClickAwayListener, Fade, IconButton, IconButtonProps, MenuItem, MenuList, Paper, Popper as MuiPopper, PopperProps, styled } from "@mui/material";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useDispatchWithType } from "../../../store";
import { showModal } from "../../../store/rootReducer";
import { AppointmentStatus, ExchangeCardStatus, useChangeAppointmentStatusMutation, useChangeExchangeCardStatusMutation, useExchangeCardMutation } from "../../services/chat";

const CardMessageActionButton: (props: ButtonProps) => JSX.Element = styled(MuiButton)({
    padding: '2px 10px',
    minWidth: "40px"
});

interface ExchangeCardButtonProps {
    chatId: string;
    actionId: string;
    fromMe: boolean;
    status: ExchangeCardStatus;
}

export function ExchangeCardButton(props: ExchangeCardButtonProps) {
    const { chatId, actionId, fromMe, status } = props;
    const [updateExchangeCard, { isLoading }] = useChangeExchangeCardStatusMutation();
    const { t, i18n } = useTranslation();
    function handleReject() {
        updateExchangeCard({ chatId: chatId, actionId: actionId, isAccept: false }).unwrap();
    }
    function handleAccept() {
        updateExchangeCard({ chatId: chatId, actionId: actionId, isAccept: true }).unwrap();
    }
    const Button = CardMessageActionButton;
    if (!chatId)
        return null;
    else if (!actionId)
        return null;
    switch (status) {
        case ExchangeCardStatus.待回應:
            if (fromMe)
                return (
                    <Button variant="contained" color="warning" fullWidth disabled>{t('chat.button.determined')}</Button>
                )
            else
                return (<>
                    <Button variant="contained" color="secondary" fullWidth onClick={handleReject}>{t('chat.button.you_exchange_decline')}</Button>
                    <Box width="7px" />
                    <Button variant="contained" color="primary" fullWidth onClick={handleAccept}>{t('chat.button.you_exchange_accept')}</Button>
                    <Backdrop open={isLoading} sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                        <CircularProgress color="inherit" /></Backdrop>
                </>
                )
        case ExchangeCardStatus.已接受:
            return (
                <Button variant="contained" color="success" fullWidth disabled>✔{t('chat.button.other_exchange_accept')}</Button>
            )
        default:
            return (
                <Button variant="contained" color="error" fullWidth disabled>❌{t('chat.button.other_exchange_decline')}</Button>
            )
    }
}

interface AppointmentButtonProps {
    chatId: string;
    actionId: string;
    fromMe: boolean;
    status: AppointmentStatus;
}

export function AppointmentButton(props: AppointmentButtonProps) {
    const { chatId, actionId, fromMe, status } = props;
    const dispatch = useDispatchWithType();
    const [updateAppointment, { isLoading }] = useChangeAppointmentStatusMutation();
    const { t, i18n } = useTranslation();
    function handleReject() {
        dispatch(showModal({ modalType: "APPOINTMENT_DECLINE", modalProps: { chatId: chatId, actionId: actionId } }));
    }
    function handleAccept() {
        updateAppointment({ chatId: chatId, actionId: actionId, isAccept: true }).unwrap();
    }
    const Button = CardMessageActionButton;
    if (!chatId)
        return null;
    else if (!actionId)
        return null;
    switch (status) {
        case AppointmentStatus.待回應:
            if (fromMe)
                return (
                    <Button variant="contained" color="warning" fullWidth disabled>{t('chat.button.determined')}</Button>
                )
            else
                return (<>
                    <Button variant="contained" color="secondary" fullWidth onClick={handleReject}>{t('chat.button.you_exchange_decline')}</Button>
                    <Box width="7px" />
                    <Button variant="contained" color="primary" fullWidth onClick={handleAccept}>{t('chat.button.you_exchange_accept')}</Button>
                    <Backdrop open={isLoading} sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                        <CircularProgress color="inherit" /></Backdrop>
                </>
                )
        case AppointmentStatus.已接受:
            return (
                <Button variant="contained" color="success" fullWidth disabled>
                    {fromMe ? `✔${t('chat.button.you_appointment_accept')}` : `✔${t('chat.button.other_appointment_accept')}`}
                </Button>
            )
        default:
            return (
                <Button variant="contained" color="error" fullWidth disabled>
                    {fromMe ? `❌${t('chat.button.you_appointment_decline')}` : `❌${t('chat.button.other_appointment_decline')}`}
                </Button>
            )
    }
}

interface JoinRoomButtonProps {
    chatId: string;
}

export function JoinRoomButton(props: JoinRoomButtonProps) {
    const { chatId } = props;
    const dispatch = useDispatchWithType();
    const { t, i18n } = useTranslation();
    function handleJoin() {
        dispatch(showModal({ modalType: "PRIVATE_VIDEO_CHAT", modalProps: { chatId: chatId } }));
    }
    const Button = CardMessageActionButton;
    if (!chatId)
        return null;
    return (
        <Button variant="contained" color="primary" onClick={handleJoin}>{t('chat.button.joinRoom')}</Button>
    )
}

//More Action
interface MoreIconButtonProps extends IconButtonProps {
    contents: MoreContent[];
    onClick?: (event: React.MouseEvent) => void;
    onClose?: () => void;
}
export interface MoreContent {
    key: string;
    onClick: () => void;
    disabled?: boolean;
}

export function MoreIconButton(props: MoreIconButtonProps) {
    const { contents, onClick, onClose } = props;
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = React.useState<PopperProps['anchorEl']>(null);
    const [arrowRef, setArrowRef] = React.useState<HTMLElement | null>(null);
    const [open, setOpen] = React.useState<boolean>(false);
    const handleClick = (event: React.MouseEvent) => {
        setAnchorEl(event.currentTarget);
        setOpen((prevOpen) => !prevOpen);
        onClick(event);
    };
    const handleClose = () => {
        setOpen(false);
        onClose();
    };
    return (<>
        <IconButton size="small" onClick={handleClick}>
            <MoreHoriz fontSize="inherit" />
        </IconButton>
        <Popper open={open}
            anchorEl={anchorEl}
            placement='top'
            container={document.getElementById("chat")}
            transition
            popperOptions={{
                strategy: 'fixed',
            }}
            modifiers={[
                {
                    name: 'offset',
                    enabled: true,
                    options: {
                        offset: [0, 10]
                    },
                },
                {
                    name: 'flip',
                    enabled: false,
                },
                {
                    name: 'preventOverflow',
                    enabled: false,
                },
                {
                    name: 'arrow',
                    enabled: true,
                    options: {
                        element: arrowRef,
                    },
                },
                {
                    name: 'hide',
                    enabled: true,
                },
                {
                    name: 'unmountOnHide',
                    enabled: true,
                    phase: 'write',
                    fn({ state }) {
                        if (state.modifiersData.hide.isReferenceHidden) {
                            handleClose();
                        }
                    },
                }
            ]}
        >
            {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={100}>
                    <div>
                        <PopperArrow ref={setArrowRef} />
                        <Paper elevation={9} sx={{ overflowY: "auto", overscrollBehavior: "contain", borderRadius: "10px", minWidth: "110px" }}>
                            <ClickAwayListener onClickAway={() => setOpen(false)}>
                                <MenuList autoFocusItem={open}>
                                    {contents.map((element) => (
                                        <MenuItem key={element.key} onClick={() => { element.onClick(); handleClose(); }} disabled={element.disabled}>{t(element.key)}</MenuItem>
                                    ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </div>
                </Fade>
            )}
        </Popper>
    </>)
}

const Popper: (props: PopperProps) => JSX.Element = styled(MuiPopper)(({ theme }) => ({
    zIndex: theme.zIndex.modal - 1,
    "&[data-popper-reference-hidden]": {
        opacity: 0,
        pointerEvents: "none"
    }
}));

const PopperArrow = styled("span")({
    position: "absolute",
    fontSize: "7px",
    bottom: 0,
    left: 0,
    marginBottom: "-0.9em",
    width: "3em",
    height: "1em",
    "&:before": {
        content: "''",
        margin: "auto",
        display: "block",
        width: 0,
        height: 0,
        borderStyle: "solid",
        borderWidth: "1em 1em 0 1em",
        borderColor: "#fff transparent transparent transparent"
    }
});