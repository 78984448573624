﻿import * as React from 'react';
import { Route, Switch, useHistory, useLocation } from 'react-router';
import { Backdrop, createTheme, CssBaseline, Theme, ThemeProvider, Typography } from '@mui/material';
import { Layout } from './components/Layout';
import { Suspense } from 'react';
import Booth from './Booth/Booth';
import Game from './Game/GameDisplay';
import { Home } from './Home/Home';
import QuickStart from './QuickStart/QuickStart';
import { useTranslation } from 'react-i18next';
import Stage from './Stage/Stage';
import { LayoutVarient } from './components/Layout/Layout';
import Posters from './Posters/Posters';
import BoothList from './BoothList/BoothListPage';
import LiveStream from './LiveStream/LiveStream';
import Room from './Room/Room';
import RegisterWindow from './Login/RegisterWindow';
import LoginWindow from './Login/LoginWindow';
import VerifyWindow from './Login/VerifyWindow';
import Manage from './ManageBoard/ManageBoardIndex';
//import Report from './components/Report';
import { initGA, logPageView, setUserIdGA } from './components/Analytics';
import Questionnaire from './Questionnaire/Questionnaire';
import ForgotPasswordWindow from './Login/ForgotPasswordWindow';
import ResetPasswordWindow from './Login/ResetPasswordWindow';
import Test from './components/services/test';
import { useDispatchWithType, useUser } from './store';
import { useLazyGetIsLoginQuery } from './components/services/login';
import ErrorPage from './components/ErrorPage/404Page';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { MessagePackHubProtocol } from '@microsoft/signalr-protocol-msgpack';
import mainTheme from './theme';
import { dialogInitialize } from './store/middleware/dialogControl';
import { useLazyGetBoothFieldNameListQuery } from './components/services/booth';
import { useLazyGetUserFieldNameListQuery } from './components/services/user';
import ModalSystem from './components/Modal/ModalSystem';
import UserListPage from './UserList/UserListPage';
import JobListPage from './JobList/JobListPage';
import ArticleListPage from './ArticleList/ArticleListPage';
import InvitePage from './Invite/InviteIndex';
import { animateScroll, scroller } from 'react-scroll'
import { CardType, UserRole } from './components/class';
import ChooseCardWindow from './Login/ChooseCardWindow';
import { loginUser } from './store/rootReducer';
import BlockAnonymous from './components/BlockAnonymous';

function App() {
    return (
        <Suspense fallback={<Loader />}>
            <ThemeProvider theme={mainTheme}>
                <AppRouter />
            </ThemeProvider>
        </Suspense>
    )
}
function AppRouter() {
    const [getIsLogin, { data: loginState }] = useLazyGetIsLoginQuery();
    const [isLoading, setIsLoading] = React.useState<boolean>(true); //載入使用者登入資料

    //控制navbar顯示
    const [showNavBar, setShowNavBar] = React.useState<LayoutVarient>(null);
    const history = useHistory();
    const pathName = useLocation().pathname;
    const search = useLocation().search;
    let query = new URLSearchParams(search);
    React.useEffect(() => {
        let matches = ['/login', '/register', '/verify', '/forgotpassword', '/resetpassword', '/quickstart', '/invite'];
        let path = pathName.toLowerCase();
        if (matches.some(match => path.match(match))) {
            setShowNavBar('login');
        }
        else if (path.match('/manage')) {
            setShowNavBar('noFooter');
        }
        else {
            setShowNavBar(null);
        }
    }, [pathName])

    //控制頁面切換時的位置
    const hash = useLocation().hash;
    React.useEffect(() => {
        if (hash)
            scroller.scrollTo(hash.split('#')[1], {
                smooth: true,
                offset: -30,
            });
        else
            animateScroll.scrollToTop();
    }, [pathName, hash])

    //控制顯示頁面
    const user = useUser();
    const dispatch = useDispatchWithType();
    React.useEffect(() => {
        let matches = ['/login', '/register', '/verify', '/forgotpassword', '/resetpassword', '/invite'];
        let path = pathName.toLowerCase();
        if (matches.some(match => path.match(match))) {
            setIsLoading(false);
        }
        else {
            getIsLogin();
        }
    }, []);
    React.useEffect(() => {
        if (loginState) {
            if (loginState.isSuccess) {
                console.log('Already Login!')
                dispatch(loginUser(loginState.data));
                //訪客
                if (loginState.data?.role === UserRole.anonymous) {
                    return;
                }
                if (loginState.data?.type == CardType.unknown) {
                    history.push('/ChooseCard' + (query.get('redirect') ? "?redirect=" + query.get('redirect') : ""));
                }
                else if (loginState.data?.lastName == null || loginState.data?.firstName == null || loginState.data?.phone == null) {
                    history.push('/QuickStart' + (query.get('redirect') ? "?redirect=" + query.get('redirect') : ""));
                }
            }
            else {
                console.log('Not Login!')
                //history.push('/Login' + (pathName ? "?redirect=" + pathName + search : ""));
                setIsLoading(false);
            }
        }
    }, [loginState])
    React.useEffect(() => {
        if (user) {
            //在邀請碼環節登入不算入
            if (pathName.toLowerCase().match('/invite')) {
                return;
            }

            //訪客
            if (user?.role === UserRole.anonymous) {
                return;
            }

            if (user?.type == CardType.unknown) {
                history.push('/ChooseCard' + (query.get('redirect') ? "?redirect=" + query.get('redirect') : ""));
            }
            else if (user?.lastName === null || user?.firstName === null || user?.phone === null ) {
                history.push('/QuickStart' + (query.get('redirect') ? "?redirect=" + query.get('redirect') : ""));
            }
        }
    }, [pathName])

    //防止user資料變化影響GA及SignalR
    const [userId, setUserId] = React.useState<string>();
    React.useEffect(() => { setUserId(user?.userId) }, [user]);

    //初始化GA
    const [first, setFirst] = React.useState<boolean>(true);
    React.useEffect(() => {
        if (first) {
            initGA();
            setFirst(false);
        }
        logPageView();
    }, [history.location.pathname])
    React.useEffect(() => {
        if (userId) {
            setUserIdGA(userId);
            setIsLoading(false);
        }
        return () => {
            setUserIdGA(null);
        }
    }, [userId]);

    //初始化SignalR
    const connection = React.useRef<HubConnection>(null);
    React.useEffect(() => {
        if (user) {
            //建立連線
            const _connection = new HubConnectionBuilder()
                .withUrl("/systemControlHub")
                .withAutomaticReconnect()
                .withHubProtocol(new MessagePackHubProtocol())
                .build();
            //註冊後端Hub的方法
            //設定系統connection
            _connection.start().then(() => {
                connection.current = _connection;
                dispatch({
                    type: 'SYSTEMCONNECTION',
                    payload: _connection
                });
            });
        }
        return () => {
            if (connection.current)
                connection.current.stop();
        }
    }, [userId]);

    //初始化對話框資料
    React.useEffect(() => {
        dispatch(dialogInitialize());
    }, [])

    //初始化
    React.useEffect(() => {
        if (userId) {
            getUserFieldNameList();
            getBoothFieldNameList();
        }
    }, [userId]);
    const [getUserFieldNameList, { data: userFieldData }] = useLazyGetUserFieldNameListQuery();
    const [getBoothFieldNameList, { data: boothFieldData }] = useLazyGetBoothFieldNameListQuery();
    React.useEffect(() => {
        if (userFieldData?.isSuccess) {
            dispatch({
                type: 'UPDATE_USER_FIELDNAMELIST',
                payload: userFieldData.data
            });
        }
    }, [userFieldData]);
    React.useEffect(() => {
        if (boothFieldData?.isSuccess) {
            dispatch({
                type: 'UPDATE_BOOTH_FIELDNAMELIST',
                payload: boothFieldData.data
            });
        }
    }, [boothFieldData]);

    //重新定義alert
    function alert(value: string) {
        console.error(value);
    }
    React.useLayoutEffect(() => {
        window.alert = alert;
    }, [])

    return (
        <>
            <CssBaseline />
            <ModalSystem />
            <Layout varient={showNavBar}>
                <BlockAnonymous>
                    <Switch>
                        <Route exact path='/' component={Home} />
                        <Route path='/BoothList' component={BoothList} />
                        <Route path='/Booth' component={Booth} />
                        <Route path='/Game' component={Game} />
                        <Route path='/Login' component={LoginWindow} />
                        <Route path='/Register' component={RegisterWindow} />
                        <Route path='/Verify' component={VerifyWindow} />
                        <Route path='/ForgotPassword' component={ForgotPasswordWindow} />
                        <Route path='/ResetPassword' component={ResetPasswordWindow} />
                        <Route path='/Stage' component={Stage} />
                        <Route path='/QuickStart' component={QuickStart} />
                        <Route path='/ChooseCard' component={ChooseCardWindow} />
                        <Route path='/Posters' component={Posters} />
                        <Route path='/Room' component={Room} />
                        <Route path='/LiveStream' component={LiveStream} />
                        <Route path='/Manage' component={Manage} />
                        <Route path='/Quest' component={Questionnaire} />
                        {/*<Route path='/test' component={Test} />*/}
                        <Route path='/JobList' component={JobListPage} />
                        <Route path='/ArticleList' component={ArticleListPage} />
                        <Route path='/UserList' component={UserListPage} />
                        <Route path='/Invite/:action' component={InvitePage} />
                        <Route path='/Invite' component={InvitePage} />
                        <Route path='/' component={ErrorPage} />
                    </Switch>
                </BlockAnonymous>
            </Layout>
            <Backdrop open={isLoading} sx={{ color: '#fff', zIndex: 10000, background: (theme) => theme.palette.background.default }}>
                <Typography>Loading...</Typography>
            </Backdrop>
        </>
    )
}
const Loader = () => (
    <div>
        <div>loading...</div>
    </div>
);

export default App;