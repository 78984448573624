﻿import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { Room } from '../class'
import { ResultType } from './utils';

const baseApi = createApi({
    reducerPath: 'roomApi',
    baseQuery: fetchBaseQuery({ baseUrl: '/api/room' }),
    endpoints: (builder) => ({
        getRoom: builder.query<ResultType<Room>, string>({
            query: (roomId) => `${roomId}`,
        }),
        getRoomToken: builder.query <ResultType<string>, string>({
            query: (roomId) => `roomToken/${roomId}`,
        }),
        getBoothRoomToken: builder.query<ResultType<string>, string>({
            query: (boothId) => `boothRoomToken/${boothId}`,
        }),
    }),
})

//superuser或admin用api
export const roomApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getToken: builder.query<ResultType<string>, void>({
            query: () => `token`,
        }),
    }),
    overrideExisting: false
});

export const { useGetRoomQuery } = roomApi;
export const { useGetRoomTokenQuery } = roomApi;
export const { useLazyGetRoomTokenQuery } = roomApi;
export const { useGetBoothRoomTokenQuery } = roomApi;

export const { useGetTokenQuery } = roomApi;