﻿import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ResultType } from './utils';

interface DeleteFileModel {
    user: string;
    file: string;
};
export const imageFileApi = createApi({
    reducerPath: 'imageFileApi',
    baseQuery: fetchBaseQuery({ baseUrl: '/api/imageFile' }),
    endpoints: (builder) => ({
        uploadImageFile: builder.mutation<ResultType<string>, FormData>({
            query: ({ ...patch }) => ({
                url: `upload`,
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: patch
            }),
        }),
        deleteImageFile: builder.mutation<ResultType<null>, DeleteFileModel>({
            query: ({ ...patch }) => ({
                url: `delete`,
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: patch
            }),
        }),
        getPath: builder.query<ResultType<string>, { userId?: string, boothId?: string }>({
            query: ({ userId, boothId }) => `path?userId=${userId}&boothId=${boothId}`,
        })
    }),
})

//export const { useUploadImageFileMutation } = imageFileApi;
//export const { useDeleteImageFileMutation } = imageFileApi;
export const { useGetPathQuery } = imageFileApi;
export const { useLazyGetPathQuery } = imageFileApi;