﻿import { LinearProgress, Typography } from "@mui/material";
import { DataGrid, GridColDef, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton, GridValueGetterParams } from "@mui/x-data-grid";
import format from "date-fns/format";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { renderCellExpand } from "../../components/DataGrid/renderCellExpand";
import { SubTitle } from "../../components/responsive-components";
import { HeroMedalHistoryModel, useLazyGetMyHeroRewardHistoryQuery } from "../../components/services/point";
import { useUser } from "../../store";
import { zhTWGrid } from "../data-grid-locale";

export default function HeroRewardList() {
    const { t } = useTranslation();

    const user = useUser();
    const [getHistory, { data: heroRewardHistory, isLoading, isSuccess }] = useLazyGetMyHeroRewardHistoryQuery();
    React.useEffect(() => { if (user) getHistory() }, [user]);

    //檢查有沒有資料，沒有資料不顯示data grid
    const [showGrid, setShowGrid] = React.useState<boolean>(null)
    React.useEffect(() => {
        if (isSuccess && heroRewardHistory.data?.length != 0) setShowGrid(true);
        else setShowGrid(false);
    }, [heroRewardHistory]);

    return (<>
        <SubTitle
            icon='circle'
            text={t('manage.title.myPoints.heroMedalHistory')}
        />
        {isLoading &&
            <LinearProgress />
        }
        {showGrid &&
            <RewardList
                data={heroRewardHistory?.data ?? []}
                loading={isLoading}
            />
        }
        {showGrid === false &&
            <Typography align="center">
                {t('manage.heroMedalHistory.empty')}
            </Typography>
        }
    </>)
}

interface RewardListProps {
    data: HeroMedalHistoryModel[];
    loading: boolean;
}
function RewardList(props: RewardListProps) {
    const { data, loading } = props;
    const { t, i18n } = useTranslation();

    const columns: GridColDef[] = [
        {
            field: 'boothName',
            headerName: t('manage.heroMedalHistory.options.boothName'),
            valueGetter: ({ row: { senderBoothChName, senderBoothEnName } }: GridValueGetterParams<string, HeroMedalHistoryModel>) => {
                if (i18n.language === "ch")
                    return senderBoothChName ?? "";
                else
                    return senderBoothEnName ?? "";
            },
            renderCell: renderCellExpand,
        },
        {
            field: 'senderName',
            headerName: t('manage.heroMedalHistory.options.senderName'),
            renderCell: renderCellExpand,
        },
        {
            field: 'reward',
            headerName: t('manage.heroMedalHistory.options.reward'),
            width: 200,
            renderCell: renderCellExpand,
        },
        {
            field: 'createTime',
            headerName: t('manage.heroMedalHistory.options.createTime'),
            type: 'dateTime',
            width: 180,
            valueGetter: ({ value }: GridValueGetterParams<string>) => format(new Date(value), "yyyy/MM/dd HH:mm:ss"),
        }
    ];

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                <GridToolbarExport csvOptions={{ utf8WithBom: true }} />
            </GridToolbarContainer>
        );
    }

    return (
        <DataGrid rows={data} columns={columns}
            pageSize={10}
            autoHeight
            disableColumnMenu
            disableColumnSelector
            components={{
                Toolbar: CustomToolbar,
            }}
            localeText={i18n.language == "ch" ? zhTWGrid : null}
            loading={loading}
        />
    )
}
