﻿import * as React from 'react';
import { useSystemConnection } from '../store';

function BoothTimer(props: BoothTimerProps) {
    const { boothId } = props;

    const systemConnection = useSystemConnection();

    let timer = 0;
    const [enterTimer, setEnterTime] = React.useState<number>(0);

    React.useEffect(() => {
        if (systemConnection != null) {

		}
        return () => {
            if (systemConnection != null) {
                systemConnection.send("UpdateOnlineTime", boothId, timer)
            }
        }
    }, [systemConnection])

    React.useEffect(() => {
        const timerID = setInterval(
            () => tick(),
            1000
        );
        return () => {
            clearInterval(timerID);
        }
    }, [])

    function tick() {
        timer = timer + 1;
        setEnterTime(timer);
    }

    return (<></>)
};

export default BoothTimer;

interface BoothTimerProps {
    boothId: string,
}