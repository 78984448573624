﻿import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { User } from '../class'

export const liveStreamApi = createApi({
    reducerPath: 'liveStreamApi',
    baseQuery: fetchBaseQuery({ baseUrl: 'https://pokeapi.co/api/v2/' }),
    endpoints: (builder) => ({
        getPokemonByName: builder.query<User, string>({
            query: (name) => `pokemon/${name}`,
        }),
    }),
})

export const { useGetPokemonByNameQuery } = liveStreamApi;