﻿import * as React from 'react';
import { Backdrop, CircularProgress, Typography, Button, Box, Stack, useTheme } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { DataGrid, GridCellParams, GridColDef, GridRenderCellParams, GridToolbar, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton, GridValueFormatterParams, GridValueGetterParams } from '@mui/x-data-grid';
import { zhTWGrid } from '../data-grid-locale';
import { format } from 'date-fns';
import { SubTitle } from '../../components/responsive-components/Typography';
import { LotteryTicketType, LoyaltyCardPointType, PointData, TicketData, useLazyGetPointListQuery, useLazyGetTicketListQuery, useRedeemRewardMutation } from '../../components/services/point';
import { ConfirmButton, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '../../components/responsive-components';
import { useForm } from 'react-hook-form';
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { Redeem } from '@mui/icons-material';

export enum pointOrTicket { point, ticket }
export default function LotteryList() {
    const { t, i18n } = useTranslation();

    const [selectedIndex, setSelectedIndex] = React.useState<number>(0);
    const [getPointList, { data: pointList, isLoading }] = useLazyGetPointListQuery();
    const [getTicketList, { data: ticketList, isLoading: isLoadingTicket }] = useLazyGetTicketListQuery();
    const typeList = [
        {
            name: '說明會獎品兌換', type: pointOrTicket.point,
            parameter: LoyaltyCardPointType.說明會點數, exchangeButton: true
        },
        {
            name: '說明會抽獎券', type: pointOrTicket.ticket,
            parameter: LotteryTicketType.說明會, exchangeButton: false
        },
        {
            name: '企業抽獎券（銀幣）', type: pointOrTicket.ticket,
            parameter: LotteryTicketType.銀幣, exchangeButton: false
        },
        {
            name: '遊戲抽獎券（金幣）', type: pointOrTicket.ticket,
            parameter: LotteryTicketType.金幣, exchangeButton: false
        },
        {
            name: '英雄勳章（隱藏）', type: pointOrTicket.point,
            parameter: LoyaltyCardPointType.英雄勳章, exchangeButton: false
        }
    ];
    function handleClickType(index: number) {
        setSelectedIndex(index);
    }

    React.useEffect(() => {
        if (typeList[selectedIndex].type == pointOrTicket.point)
            getPointList((typeList[selectedIndex].parameter) as LoyaltyCardPointType);
        else
            getTicketList((typeList[selectedIndex].parameter) as LotteryTicketType);
    }, [selectedIndex]);

    const [value, setValue] = React.useState<PointData>();
    const [open, setOpen] = React.useState(false);
    function handleExchange(value: PointData) {
        setOpen(true);
        setValue(value);
    }

    const handleClose = (refetch: boolean) => {
        if (refetch) {
            if (typeList[selectedIndex].type == pointOrTicket.point)
                getPointList((typeList[selectedIndex].parameter) as LoyaltyCardPointType);
            else
                getTicketList((typeList[selectedIndex].parameter) as LotteryTicketType);
        }
        setOpen(false);
    };

    return (<>
        <SubTitle
            icon='circle'
            text={t('manage.title.loyaltySystem.lotteryList')}
            subText={t('manage.lotteryManage.subTitle')}
        />

        <RedeemWindow value={value} open={open} onClose={handleClose} />

        {typeList.map((item, index) => (
            <Button key={index.toString()} variant="outlined" onClick={() => handleClickType(index)}
                sx={{ m: 0.5 }}
            >
                {item.name}
            </Button>
        ))}

        {typeList[selectedIndex].type === pointOrTicket.point &&
            <PointList data={pointList?.data ?? []}
                showExchangeButton={typeList[selectedIndex].exchangeButton}
                handleExchange={handleExchange}
                loading={isLoading || isLoadingTicket}
            />
        }
        {typeList[selectedIndex].type === pointOrTicket.ticket &&
            <TicketList
                data={ticketList?.data ?? []}
                showExchangeButton={typeList[selectedIndex].exchangeButton}
            loading={isLoading || isLoadingTicket}
            />
        }
    </>)
}

function PointList(props: { data: PointData[], showExchangeButton: boolean, handleExchange: (value: PointData) => void, loading: boolean }) {
    const { data, loading, showExchangeButton, handleExchange } = props;
    const { t, i18n } = useTranslation();

    const columns: GridColDef[] = [
        {
            field: 'studentId',
            headerName: t('manage.lotteryManage.options.studentId'),
            flex: 1,
            minWidth: 120,
        },
        {
            field: 'fullName',
            headerName: t('manage.lotteryManage.options.fullName'),
            flex: 1,
            minWidth: 160,
            valueGetter: (params: GridValueGetterParams) => {
                if (i18n.language == "ch")
                    return `${params.row.lastName || ''} ${params.row.firstName || ''}`;
                else
                    return `${params.row.firstName || ''} ${params.row.lastName || ''}`;
            },
        },
        {
            field: 'points',
            headerName: t('manage.lotteryManage.options.points'),
            type: "number",
            flex: 1,
            minWidth: 180,
            headerAlign: "left"
        },
        {
            field: 'grade',
            headerName: t('manage.lotteryManage.options.grade'),
            flex: 1,
            minWidth: 120,
        },
        {
            field: 'department',
            headerName: t('manage.lotteryManage.options.department'),
            flex: 1,
            minWidth: 180,
        },
        {
            field: 'email',
            headerName: t('manage.lotteryManage.options.email'),
            flex: 1,
            minWidth: 140,
        },
        ...(showExchangeButton ? [
            {
                field: 'id',
                headerName: t('manage.lotteryManage.options.exchange'),
                minWidth: 140,
                renderCell: (params) =>
                    <strong>
                        {showExchangeButton &&
                            <ConfirmButton size="small" onClick={() => handleExchange(params.row as PointData)}>
                                {t('manage.lotteryManage.exchange')}
                            </ConfirmButton>
                        }
                    </strong>
            }
        ] : [])
    ];

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                <GridToolbarExport csvOptions={{ utf8WithBom: true }} />
            </GridToolbarContainer>
        );
    }

    return (
        <DataGrid rows={data} columns={columns}
            pageSize={10}
            autoHeight
            disableColumnMenu
            disableColumnSelector
            components={{
                Toolbar: CustomToolbar,
            }}
            localeText={i18n.language == "ch" ? zhTWGrid : null}
            loading={loading}
        />
    )
}

function TicketList(props: { data: TicketData[], showExchangeButton: boolean, loading: boolean }) {
    const { data, loading, showExchangeButton } = props;
    const { t, i18n } = useTranslation();

    const columns: GridColDef[] = [
        {
            field: 'id',
            headerName: t('manage.lotteryManage.options.id'),
            flex: 1,
            minWidth: 120,
        },

        {
            field: 'fullName',
            headerName: t('manage.lotteryManage.options.fullName'),
            flex: 0.5,
            minWidth: 160,
            valueGetter: (params: GridValueGetterParams) => {
                if (i18n.language == "ch")
                    return `${params.row.lastName || ''} ${params.row.firstName || ''}`;
                else
                    return `${params.row.firstName || ''} ${params.row.lastName || ''}`;
            },
        },
        {
            field: 'studentId',
            headerName: t('manage.lotteryManage.options.studentId'),
            flex: 1,
            minWidth: 140,
        },
        {
            field: 'grade',
            headerName: t('manage.lotteryManage.options.grade'),
            flex: 1,
            minWidth: 140,
        },
        {
            field: 'department',
            headerName: t('manage.lotteryManage.options.department'),
            flex: 1,
            minWidth: 180,
        },
        {
            field: 'email',
            headerName: t('manage.lotteryManage.options.email'),
            flex: 1,
            minWidth: 140,
        },
        {
            field: 'createTime',
            headerName: t('manage.lotteryManage.options.createTime'),
            type: 'dateTime',
            width: 180,
            valueFormatter: (params: GridValueFormatterParams) => {
                const dateValue = new Date(params.value.toString());
                return format(dateValue, "yyyy/MM/dd HH:mm:ss");
            },
        }
    ];

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                <GridToolbarExport csvOptions={{ utf8WithBom: true }} />
            </GridToolbarContainer>
        );
    }

    return (
        <DataGrid rows={data} columns={columns}
            pageSize={10}
            autoHeight
            disableColumnMenu
            disableColumnSelector
            components={{
                Toolbar: CustomToolbar,
            }}
            localeText={i18n.language == "ch" ? zhTWGrid : null}
            loading={loading}
        />
    )
}

interface RedeemWindowProps {
    open: boolean;
    value: PointData;
    onClose: (refetch: boolean) => void;
}
type formData = {
    count: number;
}
function RedeemWindow(props: RedeemWindowProps) {
    const { value, open, onClose } = props;
    const { t } = useTranslation();
    const theme = useTheme();

    const schema = yup.object().shape({
        count: yup.number().nullable().required('請輸入數字').typeError('請輸入數字').min(0, '數字不得小於0').integer('只允許整數'),
    });
    const { register, handleSubmit: handleOnSubmit, formState: { errors }, setValue, reset } = useForm<formData>({
        mode: "onBlur", resolver: yupResolver(schema),
        defaultValues: {
            count: null
        }
    });
    const quickNumber: number[] = [7, 14];
    const handleCountChange = (count: number) => setValue("count", count);

    const [remainPoint, setRemainPoint] = React.useState<number>();
    React.useEffect(() => { if (value) setRemainPoint(value.points) }, [value]);

    const [response, setResponse] = React.useState<React.ReactNode>("");

    const [redeemReward, { isLoading }] = useRedeemRewardMutation();
    function onSubmit(data: formData) {
        redeemReward({ userId: value.id, count: data.count }).unwrap()
            .then((result) => {
                //200 OK
                if (result.isSuccess) {
                    setRefetch(true);
                    setResponse(<b>兌換成功✨</b>);
                    setRemainPoint(point => (point - data.count));
                } else {
                    setResponse(<><b>兌換失敗</b><br />{result.message}</>);
                }
            })
            .catch((error) => {
                //後端報錯 ex:500、404
                console.error("發生錯誤", error);
                setResponse("Unconnect");
            });
    }

    const [refetch, setRefetch] = React.useState(false);
    const handleClose = () => {
        onClose(refetch);
        reset();
    };

    return (
        <Dialog open={open} onClose={handleClose} maxWidth={"sm"} fullWidth>
            <DialogTitle>
                <Box display="flex" alignItems="center">
                    <Redeem sx={{ color: 'primary.main', fontSize: "1.375rem", mr: 2 }} />
                    <Typography variant="h6" fontWeight="bold">{t("dialog.redeem.title")}</Typography>
                </Box>
            </DialogTitle>
            <Box component="form" onSubmit={handleOnSubmit(onSubmit)}>
                <DialogContent sx={{ py: 0 }}>
                    <Stack>
                        <Typography>{t('dialog.redeem.receiver')}</Typography>
                        <Typography color="text.primary" sx={{ padding: 1 }}>
                            {value?.department} {value?.grade}
                            <br />
                            {value?.studentId} {value?.lastName} {value?.firstName}
                        </Typography>
                        <Typography>
                            <Trans i18nKey="dialog.redeem.question" count={remainPoint}
                                components={[<strong></strong>, <span style={{ color: theme.palette.error.light, fontWeight: "bold" }}></span>]} />
                        </Typography>
                        <TextField
                            variant="outlined"
                            inputRef={register('count').ref}
                            error={!!errors.count}
                            helperText={errors.count ? errors.count.message : ' '}
                            {...register("count")}
                        />
                        <Stack direction="row" justifyContent="flex-end" spacing={1} sx={{ transform: "translateY(-15px)" }}>
                            {quickNumber.map((number) => (
                                <Button key={number} variant="outlined" onClick={() => handleCountChange(number)}>{number}</Button>
                            ))}
                        </Stack>
                        <Typography variant="body2" color="error.light">{t('dialog.redeem.notice')}</Typography>
                    </Stack>
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'flex-end' }}>
                    <Typography variant="body1" align="center" paragraph>{response}</Typography>
                    <ConfirmButton type="submit" loading={isLoading}>{t('dialog.redeem.confirm')}</ConfirmButton>
                </DialogActions>
            </Box>
        </Dialog>
    )
}