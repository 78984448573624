﻿import * as React from 'react';
import JobOpeningCard from './JobOpeningCard';
import { Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Add } from '@mui/icons-material';
import { useUser } from '../../store';
import { JobOpening } from '../../components/class';
import { JobOpeningFormDialog } from './JobOpeningForm';
import { useTranslation } from 'react-i18next';
import { BorderButton } from '../../components/responsive-components';
import { useLazyGetJobOpeningQuery, useUpdateJobOpeningMutation, useDeleteJobOpeningMutation } from '../../components/services/booth';
import { cloneDeep } from 'lodash';
import { City } from './City';
import { ResultType } from '../../components/services/utils';
import JobOpeningDialog from './JobOpeningDialog';
import { logEvent } from '../../components/Analytics';
import cryptoRandomString from 'crypto-random-string';

export default function JobOpeningTable(props: JobOpeningTableProps) {
    const user = useUser();
    const { boothId, isManage } = props;

    const { t, i18n } = useTranslation();

    const theme = useTheme();
    const tabletMatches = useMediaQuery(theme.breakpoints.up('sm'));
    const pcMatches = useMediaQuery(theme.breakpoints.up('lg'));

    const [getJobOpening,{ data: jobsData, isLoading, isSuccess, isError }] = useLazyGetJobOpeningQuery();

    const [jobs, setJobs] = React.useState<JobOpening[]>([]);
    React.useEffect(() => {
        if (boothId) {
            getJobOpening({ boothId: boothId });
        }
    }, [boothId])
    React.useEffect(() => {
        if (isSuccess) {
            setJobs(cloneDeep(jobsData.data))
        }
    }, [jobsData])

    //編輯視窗
    const [editOpen, setEditOpen] = React.useState(false);
    const [editData, setEditData] = React.useState<JobOpening>(null);
    function ClickEditFormButton(value: JobOpening) {
        setEditData(value);
        setEditOpen(true);
    }
    function ClickNewFormButton() {
        const newJob: JobOpening = {
            dataId: `Job-${cryptoRandomString({ length: 8 })}`,
            boothId: boothId,
            chName: '',
            enName: '',
            intro: '',
            title: '',
            forEngineer: false,
            place: City.不限,
            placeDescipt: '',
            demandNum: '',
            treatment: '',
            qualify: '',
            content: '',
            memo: '',
            link: '',
        }
        setEditData(newJob);
        setEditOpen(true);
    }
    function handleEditClose() {
        setEditData(null);
        setEditOpen(false);
    }

    //新增與編輯
    const [updateJobOpening, { }] = useUpdateJobOpeningMutation();
    function handleSubmitJobOpening(value: JobOpening) {
        return new Promise<ResultType<JobOpening>>((resolve, reject) => {
            updateJobOpening(value).unwrap().then((result) => {
                if (result.isSuccess) {
                    if (boothId) {
                        getJobOpening({ boothId: boothId });
                    }
                } else {
                    console.log(result.message);
                }
                resolve(result);
            }).catch(() => {
                reject();
            });
        });
    }

    //刪除
    const [deleteJobOpening, { }] = useDeleteJobOpeningMutation();
    function handleDeleteJobOpening(value: JobOpening) {
        return new Promise<ResultType<JobOpening>>((resolve, reject) => {
            deleteJobOpening({ dataId: value.dataId }).unwrap().then((result) => {
                if (result.isSuccess) {
                    if (boothId) {
                        getJobOpening({ boothId: boothId });
                    }
                } else {
                    console.log(result.message);
                }
                resolve(result);
            }).catch(() => {
                reject();
            });
        });
    }

    return (<>
        {isManage &&
            <div style={{
                display: 'flex',
                padding: '0px 12px',
                alignItems: 'flex-end',
                justifyContent: 'space-between',
                flexWrap: 'wrap'
            }}>
                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', flexWrap: 'wrap' }}>
                    <Typography fontWeight='bold' variant={tabletMatches ? 'subtitle1' : 'subtitle2'} style={{ margin: '0px 1.2rem' }}>
                        {`${t('jobOpening.table.subtitle')} `}
                    </Typography>
                </div>
                <BorderButton onClick={() => ClickNewFormButton()}>
                    <Add fontSize={'small'} style={tabletMatches ? { marginRight: '8px', marginLeft: '-4px' } : {}} />
                    {tabletMatches &&
                        <Typography variant='subtitle1' fontWeight='bold' style={{ textTransform: 'initial' }}>
                            {t('jobOpening.table.newjob')}
                        </Typography>
                    }
                </BorderButton>
            </div>
        }

        <JobOpeningGridList
            jobOpenings={jobs}
            isManage={isManage}
            onClickEdit={ClickEditFormButton}
            onClickDelete={handleDeleteJobOpening}
        />

        {isManage &&
            <JobOpeningFormDialog
            defaultValue={editData}
            open={editOpen}
                handleSubmit={handleSubmitJobOpening}
                handleClose={handleEditClose}
            />
        }
    </>)
};
interface JobOpeningTableProps {
    boothId: string;
    isManage: boolean;
}

export function JobOpeningGridList(props: JobOpeningGridListProps) {
    const { jobOpenings, isManage, onClickEdit, onClickDelete } = props;

    //展示視窗
    const [displayOpen, setDisplayOpen] = React.useState(false);
    const [displayData, setDisplayData] = React.useState<JobOpening>(null);
    function handleDisplayOpen(value: JobOpening) {
        if (!value) return;

        setDisplayData(value);
        setDisplayOpen(true);
        logEvent('Booth', `${value.boothId}: JobOpeningDetails`, `${value.title}`);
    }
    function handleDisplayClose() {
        setDisplayData(null);
        setDisplayOpen(false);
    }
    function handleDisplayChange(changeIndex: number) {
        let currentIndex = jobOpenings.findIndex(x => x.dataId === displayData.dataId);
        if (jobOpenings.length > 0) {
            let index = 0;
            if (currentIndex + changeIndex < 0) {
                index = jobOpenings.length - 1;
            }
            else if (jobOpenings.length > currentIndex + changeIndex) {
                index = currentIndex + changeIndex;
            }
            setDisplayData(jobOpenings[index]);
            logEvent('Booth', `${jobOpenings[index].boothId}: JobOpeningDetails`, `${jobOpenings[index].title}`);
        }
    }

    return (<>
        <Box display="grid" gridTemplateColumns={{ xs: "1fr", sm: "repeat(2, 1fr)", md: "repeat(3, 1fr)" }} gap={3}>
            {jobOpenings.map((tile, index) => (
                <JobOpeningCard
                    key={tile.dataId}
                    data={tile}
                    isManage={isManage}
                    onClickEdit={onClickEdit}
                    onClickDelete={onClickDelete}
                    onClickMoreInfo={handleDisplayOpen}
                />
            ))}
        </Box>

        {!isManage &&
            <JobOpeningDialog
                data={displayData}
                open={displayOpen}
                handleClose={handleDisplayClose}
                handleChangePrev={() => handleDisplayChange(-1)}
                handleChangeNext={() => handleDisplayChange(1)}
            />
        }
    </>)
}
interface JobOpeningGridListProps {
    jobOpenings: JobOpening[];
    isManage?: boolean;
    onClickEdit?: (value: JobOpening) => void;
    onClickDelete?: (value: JobOpening) => void;
}