﻿import * as React from 'react';
import { Button, ButtonProps, Container, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, styled } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Divider, Typography } from '@mui/material';
import { JobOpening } from '../../components/class';
import { Trans, useTranslation } from 'react-i18next';
import { TextField } from '../../components/responsive-components';
import { ArrowBackIosNew, ArrowForwardIos, Close, MarkunreadMailbox } from '@mui/icons-material';
import { JobOpeningCardContent } from './JobOpeningCard';

const StyledButton: (props: ButtonProps) => JSX.Element = styled(Button)(({ theme }) => ({
    '&.MuiButton-root': {
        width: 'fit-content',
        fontWeight: 'bold',
        color: 'white',
        backgroundColor: '#12C735',
        padding: '6px 12px',
    },
}));

export default function JobOpeningDialog(props: JobOpeningDialogProps) {
    const { data, open, handleChangePrev, handleChangeNext, handleClose } = props;

    const matches = useMediaQuery('(min-width:992px)');

    const { t, i18n } = useTranslation();

    function handleOpenLink(value: JobOpening) {
        if (value && value.link)
            window.open(value.link);
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            scroll='paper'
            PaperProps={{
                style: {
                    padding: matches && '18px 24px',
                    margin: '62px',
                    width: 'calc(100% - 124px)',
                    maxWidth: '500px',
                    maxHeight: '85vh',
                    overflow: 'initial'
                }
            }}
        >
            {handleChangePrev &&
                <IconButton
                    onClick={handleChangePrev}
                    style={{
                        position: 'absolute', top: '50%', left: '-48px',
                        color: 'white'
                    }}
                >
                    <ArrowBackIosNew />
                </IconButton>
            }
            {handleChangeNext &&
                <IconButton
                    onClick={handleChangeNext}
                    style={{
                        position: 'absolute', top: '50%', right: '-48px',
                        color: 'white'
                    }}
                >
                    <ArrowForwardIos />
                </IconButton>
            }

            <IconButton
                onClick={handleClose}
                style={{
                    position: 'absolute', top: '0px', right: '-54px',
                    backgroundColor: 'transparent', color: 'white'
                }}
            >
                <Close fontSize='large' />
            </IconButton>

            {data &&
                <JobOpeningCardContent
                    data={data}
                    moreInfo
                    onClickApply={data.link && handleOpenLink}
                />
            }
        </Dialog>
    );
}
interface JobOpeningDialogProps {
    data: JobOpening;
    open: boolean;
    handleClose: () => void;
    handleChangePrev?: () => void;
    handleChangeNext?: () => void;
}