﻿import * as React from "react";
import { Trans, useTranslation } from "react-i18next";
import { BorderButton } from "../components/responsive-components";
import { Close, Favorite, FavoriteBorderRounded, Language, People, Person, Phone, Place, Videocam, VideogameAsset } from "@mui/icons-material";
import { Col, Row } from "reactstrap";
import Linkify from "react-linkify";
import { Backdrop, Box, Button, CircularProgress, Container, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Paper, Stack, styled, Toolbar, ToolbarProps, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Booth, UserRole, User, BoothType, BoothArticle, JobOpening, Seminar } from "../components/class";
import { Media } from "./BoothMedia";
import { useHistory, useLocation } from "react-router-dom";
import ErrorPage from "../components/ErrorPage/408Page";
import BoothIntro from "./BoothIntro";
import BoothTimer from "./BoothTimer";
import BoothAppointment from "./BoothAppointment";
import { useDispatchWithType, useSystemConnection, useUser } from "../store";
import { showModal, updateUser } from "../store/rootReducer";
import { useChangeBookMarkMutation, UserCardInfoModel } from "../components/services/user";
import SideBar, { idData } from "../components/SideBar";
import BoothActionBar, { BoothActionsProps } from "./BoothActionBar";
import { useLazyGetBoothManagersQuery, useLazyGetBoothQuery, useLazyGetJobOpeningQuery } from "../components/services/booth";
import { SubTitle } from "../components/responsive-components/Typography";
import BoothArticleList from "../components/Article/BoothArticleList";
import JobOpeningTable, { JobOpeningGridList } from "./JobOpening/JobOpeningTable";
import { BoothAgendaList } from "./Seminar/BoothAgendaList";
import { SystemControlHub } from "../components/react-signalr/systemControlHub";
import { scroller } from 'react-scroll'
import { useLazyGetArticlesByBoothQuery } from "../components/services/article";
import { GetParticipantCount } from "../components/MediaSection";
import { useLazyGetSeminarsByBoothQuery } from "../components/services/seminar";
import AgendaCard from "./Seminar/AgendaCard";
import { BussinessCard } from "../components/BussinessCard/BussinessCardInfo";
import { UserCardDialog } from "../components/BussinessCard/UserCardDialog";
import ReactPlayer from "react-player";
import BoothArticleTable, { BoothArticleTableData } from "../components/Article/BoothArticleTable";
import { BoothArticleDialog } from "../components/Article/BoothArticlePage";
import { BoothCard } from "../components/BoothCard/BoothCard";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}
export default function BoothIndex() {
    const user = useUser();
    const dispatch = useDispatchWithType();
    const { t } = useTranslation();

    const theme = useTheme();

    let query = useQuery();
    const location = useLocation()
    let hash = useLocation().hash;
    const history = useHistory();

    const systemConnection = useSystemConnection();
    const hubRef = React.useRef<SystemControlHub>();
    React.useEffect(() => {
        if (systemConnection != null) {
            hubRef.current = new SystemControlHub(systemConnection);
            hubRef.current.send.ChangeLocation({ location: 3, refId: boothId });
        }
    }, [systemConnection]);

    const [boothId, setBoothId] = React.useState<string>();
    React.useEffect(() => {
        setBoothId(query.get('id'));

        //換連線位置
        if (hubRef.current)
            hubRef.current.send.ChangeLocation({ location: 3, refId: query.get('id') });
    }, [location]);

    const [getBooth, { booth, isError }] = useLazyGetBoothQuery({
        selectFromResult: ({ data, isError }) => ({ booth: data?.data, isError: isError })
    });

    const [getBoothManagers, { managers = [] }] = useLazyGetBoothManagersQuery({
        selectFromResult: ({ data }) => ({ managers: data?.data })
    });

    const [getJobOpening, { jobs = [] }] = useLazyGetJobOpeningQuery({
        selectFromResult: ({ data }) => ({ jobs: data?.data ?? [] })
    });

    const [getBoothAllArticle, { articles = [] }] = useLazyGetArticlesByBoothQuery({
        selectFromResult: ({ data }) => ({ articles: data?.data ?? [] })
    });

    const [getSeminars, { seminars=[] }] = useLazyGetSeminarsByBoothQuery({
        selectFromResult: ({ data }) => ({ seminars: data?.data ?? [] })
    });

    React.useEffect(() => {
        if (boothId) {
            getBooth({ boothId: boothId });
            getBoothManagers(boothId);
            getJobOpening({ boothId: boothId });
            getBoothAllArticle(boothId);
            getSeminars(boothId);
            GetParticipantCount(boothId).then((num) => {
                if (num == 0)
                    setShowOnCall(false);
                else {
                    setShowOnCall(true);
                }
            });
        }
    }, [boothId]);

    //攤位視訊開放顯示
    const [showOnCall, setShowOnCall] = React.useState<boolean>();

    //攤位視訊控制
    const handleOpenMedia = () => {
        if (hash !== "#media") {
            history.push({
                ...history.location, hash: "#media"
            });
        }
        /*else {

        }
        if (systemConnection)
            systemConnection.send('UpdateUserVideoRecord', boothId);*/
    }
    const handleCloseMedia = () => {
        if (hash === "#media") {
            history.push({
                ...history.location, hash: null
            });
        }
    }

    //預約面談
    const [openAppointment, setOpenAppointment] = React.useState(false);
    const handleOpenAppointment = () => {
        setOpenAppointment(true);
    }
    const handleCloseAppointment = () => {
        setOpenAppointment(false);
    }
    //服務鈴
    const handleRingBell = () => {
        dispatch(showModal({ modalType: "SERVICE_BELL", modalProps: { boothId: boothId } }));
    }

    //SideBar設定
    const [sideBarList, setSideBarList] = React.useState<idData[]>([]);
    React.useEffect(() => {
        if (booth) {
            if (booth.type === BoothType.服務台) {
                setSideBarList([
                    { nameKey: 'booth.title.intro', className: '公司簡介' },
                    { nameKey: 'booth.title.article', className: '最新消息' },
                ]);
            }
            else {
                setSideBarList([
                    { nameKey: 'booth.title.intro', className: '公司簡介' },
                    { nameKey: 'booth.title.jobs', className: '職缺徵才' },
                    { nameKey: 'booth.title.article', className: '最新消息' },
                    { nameKey: 'booth.title.agenda', className: '活動議程' },
                ]);
            }
        }
    }, [booth])

    //控制頁面切換時的位置
    React.useEffect(() => {
        if (booth) {
            if (hash)
                setTimeout(() => {
                    scroller.scrollTo(hash.split('#')[1], {
                        smooth: true,
                        offset: -30,
                    });
                }, 100);
        }
    }, [booth])

    if (booth) {
        return (<>
            {/*<SideBar sideBarList={sideBarList} />*/} 

            <BoothTimer boothId={boothId} />

            { /*預約面談*/}
            <BoothAppointment boothId={boothId} managers={managers.filter(x => x.userId != user?.userId)}
                open={openAppointment} onClose={handleCloseAppointment} />

            {hash === "#media" && user &&
                <Container>
                    <Box mb={2} color="text.white">
                        <SubTitle icon={<Videocam color='primary' fontSize='large' />}
                            text={t('booth.title.video')} style={{ paddingRight: '120px', paddingBottom: '0px' }} />
                        <Media booth={booth} />

                        {/*關閉視訊*/}
                        <Button variant="outlined" onClick={handleCloseMedia}>
                                {t("booth.button.closeMedia")}
                        </Button>
                    </Box>
                </Container>
            }

            {/*簡介*/}
            <IntroSection data={booth} />

            {/*聯絡人員*/}
            <ContactSection managers={managers} actionBarProps={{
                boothId: boothId,
                handleOpenMedia: handleOpenMedia,
                handleOpenAppointment: handleOpenAppointment,
                handleRingBell: handleRingBell,
                showOnCall: showOnCall
            }} />
            <StyledToolbar />

            {/*詳細資料*/}
            <CustomContentSection data={booth} />

            {/*徵才小卡*/}
            {jobs?.length > 0 && <>
                <StyledToolbar />
                <RecruitSection jobs={jobs} />
            </>}

            {/*發布消息*/}
            {articles?.length > 0 && <>
                <StyledToolbar />
                <ArticleSection articles={articles} />
            </>}

            {/*議程*/}
            {seminars?.length > 0 && <>
                <StyledToolbar />
                <SeminarSection seminars={seminars} />
            </>}
        </>)
    } else if (isError) {
        return <ErrorPage />;
    } else {
        return (
            <Backdrop open sx={{ color: "#fff" }}>
                <CircularProgress color="inherit" />
            </Backdrop>
        )
    }
}

const StyledToolbar: (props: ToolbarProps) => JSX.Element = styled(Toolbar)(({ theme }) => ({
    boxShadow: 'rgb(0 0 0 / 16%) 0px 3px 6px -2px',
}));

export function IntroSection(props: IntroSectionProps) {
    const { data } = props;
    const { t } = useTranslation();

    const dispatch = useDispatchWithType();
    const user = useUser();

    const [changeBookMark] = useChangeBookMarkMutation();
    const handleClickFavorite = () => {
        changeBookMark({ boothId: data.boothId }).unwrap()
            .then((result) => {
                if (result.isSuccess) {
                    dispatch(updateUser({ ...user, saveBoothIds: result.data }));
                }
            }).catch((error) => {
                console.log(error);
            });
    };

    const history = useHistory();
    function handleClickGoToGame(event: React.MouseEvent<HTMLElement>) {
        event.preventDefault();
        history.push(`./Game?shop=${data.boothNo.split("、")[0]}`);
    }

    let videoLink = data?.fieldList?.find(x => x.fieldId === 'video')?.value ?? "";
    const [openVideo, setOpenVideo] = React.useState<boolean>(false);
    const handleOpen = () => setOpenVideo(true), handleClose = () => setOpenVideo(false);

    return (
        <Box py={2}>
            <Container>
                <BoothCard
                    data={data}
                    onClickFollow={handleClickFavorite}
                />
                <Box display="inline-flex" gap={2} pt={2}>
                    <Button variant="outlined" href={`/Game?shop=${data.boothNo.split("、")[0]}`} onClick={handleClickGoToGame}>
                        <Typography>
                            {t("booth.button.game")}
                        </Typography>
                    </Button>
                    {videoLink != "" &&
                        <Button variant="outlined" onClick={handleOpen}>
                            <Typography>
                                {t("booth.button.video")}
                            </Typography>
                        </Button>
                    }
                </Box>
                <Dialog open={openVideo} onClose={handleClose} fullWidth>
                    <DialogTitle>
                        <Box display="flex" justifyContent="space-between">
                            <Typography variant="subtitle1" fontWeight="bold">
                                {t("booth.button.video")}
                            </Typography>
                            <IconButton onClick={handleClose} sx={{ mt: -1, mr: -1 }}>
                                <Close />
                            </IconButton>
                        </Box>
                    </DialogTitle>
                    <DialogContent>
                        <ReactPlayer
                            url={videoLink}
                            width="100%"
                            controls
                        />
                    </DialogContent>
                    <DialogActions />
                </Dialog>
            </Container>
        </Box>
    )
}
interface IntroSectionProps {
    data: Booth
}
function ContactSection(props: ContactSectionProps) {
    const { managers, actionBarProps } = props;
    const { t, i18n } = useTranslation();

    //顯示視窗
    const [currentData, setCurrentData] = React.useState<UserCardInfoModel>(null);
    const [open, setOpen] = React.useState(false);
    const handleOpen = (data: UserCardInfoModel) => {
        setCurrentData(data);
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    function handleDisplayChange(changeIndex: number) {
        let currentIndex = managers.findIndex(x => x.userId === currentData.userId);
        if (managers.length > 0) {
            if (currentIndex + changeIndex < 0) {
                setCurrentData(managers[managers.length - 1]);
            }
            else if (managers.length > currentIndex + changeIndex) {
                setCurrentData(managers[currentIndex + changeIndex]);
            }
            else {
                setCurrentData(managers[0]);
            }
        }
    }

    return (
        <Container>
            <Box display="grid" gridTemplateColumns={{ xs: "1fr", md: "1fr 270px" }} mt={3} minHeight="270px">
                <Box>
                    <Box>
                        <SubTitle icon={<People color='primary' fontSize='large' />}
                            text={t('booth.title.contact')}
                            subText={t('booth.subtitle.contact')}
                        />
                    </Box>
                    <Box px={2} pb={2} display="grid" gap={{ xs: 1, md: 2 }} gridTemplateColumns={{ xs: "repeat(2, 1fr)", sm: "repeat(3, 1fr)", lg: "repeat(4, 1fr)" }}>
                        {managers.map((user) => (
                            <Box key={user.userId}>
                                <BussinessCard
                                    data={user}
                                    isOnline={user.isOnline}
                                    simpleMode
                                    withBorder
                                    onClickShowMore={handleOpen}
                                />
                            </Box>
                        ))}
                    </Box>
                </Box>
                <BoothActionBar {...actionBarProps} />
            </Box>
            <UserCardDialog
                data={currentData}
                open={open}
                onClose={handleClose}
                handleChangePrev={() => handleDisplayChange(-1)}
                handleChangeNext={() => handleDisplayChange(1)}
            />
        </Container>
    )
}
interface ContactSectionProps {
    managers: UserCardInfoModel[];
    actionBarProps: BoothActionsProps;
}

function CustomContentSection(props: CustomContentSectionProps) {
    const { data } = props;
    const { t } = useTranslation();

    return (
        <Container>
            <SubTitle icon='square' text={t('booth.title.intro')} style={{ margin: '20px 0px' }} />
            {data.type == BoothType.一般攤位 ?
                <BoothIntro normalBoothData={data} /> :
                <div className="ck-content" dangerouslySetInnerHTML={{ __html: data.customContent }} />
            }
        </Container>
    )
}
interface CustomContentSectionProps {
    data: Booth
}

function RecruitSection(props: RecruitSectionProps) {
    const { jobs } = props;
    const { t } = useTranslation();
    return (
        <Container>
            <Box display="flex" mt={3}>
                <SubTitle icon='square' text={t('booth.title.jobs')} />
            </Box>
            <JobOpeningGridList
                jobOpenings={jobs}
            />
        </Container>
    )
}
interface RecruitSectionProps {
    jobs: JobOpening[];
}

function ArticleSection(props: ArticleSectionProps) {
    const { articles } = props;
    const { t, i18n } = useTranslation();

    const theme = useTheme();
    const tabletMatches = useMediaQuery(theme.breakpoints.up('sm'));

    //顯示視窗
    const [currentDisplayData, setCurrentDisplayData] = React.useState<BoothArticle>(null);
    const [openDisplay, setOpenDisplay] = React.useState(false);
    const handleOpenDisplay = (id?: string) => {
        if (id) {
            setCurrentDisplayData(articles.find(x => x.dataId === id));
        }
        else {
            setCurrentDisplayData(null);
        }
        setOpenDisplay(true);
    };
    const handleCloseDisplay = () => {
        setOpenDisplay(false);
    };

    return (
        <Container>
            <Box display="flex" mt={3}>
                <SubTitle icon='square' text={t('booth.title.article')} />
            </Box>
            <Paper sx={{ padding: { xs: "16px 10px", sm: "20px 40px" } }}>
                <BoothArticleTable
                    rows={articles?.map((item) => ({
                        id: item.dataId,
                        boothId: item.boothId,
                        boothChName: item.chName,
                        boothEnName: item.enName,
                        name: item.title,
                        time: new Date(item.createTime),
                        pin: item.isPin
                    } as BoothArticleTableData)) ?? []}
                    onOpen={handleOpenDisplay}
                    showPin={true}
                    paginationClassName="pagination"
                />
            </Paper>
            <BoothArticleDialog
                data={currentDisplayData}
                matches={tabletMatches}
                open={openDisplay}
                onClose={handleCloseDisplay}
            />
        </Container>
    )
}
interface ArticleSectionProps {
    articles: BoothArticle[];
}

function SeminarSection(props: SeminarsSectionProps) {
    const { seminars } = props;
    const { t } = useTranslation();
    return (
        <Container>
            <Box display="flex" mt={3}>
                <SubTitle icon='square' text={t('booth.title.agenda')} />
            </Box>
            <Stack padding={3} spacing={1}>
                {seminars.length > 0 && seminars.map((seminar) => (
                    seminar.agendas.map((agenda) => (
                        <AgendaCard
                            seminarId={seminar.seminarId}
                            seminarChName={seminar.chTitle}
                            seminarEnName={seminar.enTitle}
                            data={agenda}
                            key={agenda.agendaId}
                            showDate
                        />
                    ))))}
            </Stack>
        </Container>
    )
}
interface SeminarsSectionProps {
    seminars: Seminar[];
}