﻿import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { Seminar, Agenda, SeminarSignIn, CardInfo } from '../class';
import { ResultType } from './utils';

export interface SeminarModel {
    seminarId: string;
    chTitle: string;
    enTitle: string;
    chAbbrev: string;
    enAbbrev: string;
    agendas: AgendaModel[];
    youtubeLink: string;
    channelId: string;
}
export interface AgendaModel {
    seminarId: string;
    agendaId: string;
    title: string;
    boothId: string
    timeSlot: TimeSlot;
    eventContent: string;
}
export interface TimeSlot {
    startTime: string;
    endTime: string;
}
export interface SeminarSignInModel {
    dataId: string;
    seminarId: string;
    agendaId: string;
    timeSlot: TimeSlot;
}
export interface SeminarUserSignsData {
    id: string;
    seminarId: string;
    seminarChTitle: string;
    seminarEnTitle: string;
    agendaId: string;
    agendaTitle: string;
    boothId: string;
    boothChName: string;
    boothEnName: string;
    startTime: string;
    endTime: string;
    userSigns: CardInfo[];
}
export interface StageData {
    dataId: string;
    seminarId: string;
    agendaId: string;
    timeSlot: TimeSlot;
}

export const baseApi = createApi({
    reducerPath: 'seminarApi',
    baseQuery: fetchBaseQuery({ baseUrl: 'api/seminar' }),
    endpoints: (builder) => ({
        getAllSeminars: builder.query<ResultType<Seminar[]>, void>({
            query: () => ``,
        }),
        getSeminar: builder.query<ResultType<Seminar>, string>({
            query: (id) => `${id}`,
        }),
        getSeminarsByBooth: builder.query<ResultType<Seminar[]>, string>({
            query: (boothId) => `getByBooth/${boothId}`,
        }),
        getAgenda: builder.query<ResultType<Agenda>, string>({
            query: (id) => `${id}`,
        }),
        getNowAiring: builder.query<ResultType<Agenda>, string>({
            query: (seminarId) => `nowAiring/${seminarId}`,
        }),
        getSeminarSignInStatus: builder.query<ResultType<boolean>, string>({
            query: (seminarId) => `signIn/${seminarId}`,
        }),
        signInOnSeminar: builder.mutation<ResultType<Agenda>, string>({
            query: ((seminarId) => ({
                url: `signIn/${seminarId}`,
                method: 'POST',
            })),
        }),
    }),
})

//exhibitor或superuser或admin用api
export const seminarApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getStageList: builder.query<ResultType<StageData[]>, void>({
            query: () => `getList`,
        }),
        putSeminar: builder.mutation<ResultType<Seminar>, SeminarModel>({
            query: ({ ...patch }) => ({
                url: ``,
                method: 'PUT',
                body: patch
            }),
        }),
        deleteSeminar: builder.mutation<ResultType<null>, SeminarModel>({
            query: ({ ...patch }) => ({
                url: ``,
                method: 'DELETE',
                body: patch
            }),
        }),
        postAgenda: builder.mutation<ResultType<Agenda>, AgendaModel>({
            query: ({ ...patch }) => ({
                url: `agenda`,
                method: 'POST',
                body: patch
            }),
        }),
        putAgenda: builder.mutation<ResultType<Agenda>, AgendaModel>({
            query: ({ ...patch }) => ({
                url: `agenda`,
                method: 'PUT',
                body: patch
            }),
        }),
        deleteAgenda: builder.mutation<ResultType<null>, AgendaModel>({
            query: (patch) => ({
                url: `agenda`,
                method: 'DELETE',
                body: patch
            }),
        }),
        getAllSeminarSignIns: builder.query<ResultType<SeminarSignIn[]>, null>({
            query: () => `signInData`,
        }),
        insertSeminarSignIn: builder.mutation<ResultType<SeminarSignIn>, SeminarSignInModel>({
            query: ({ ...patch }) => ({
                url: `signInData`,
                method: 'POST',
                body: patch
            }),
        }),
        updateSeminarSignIn: builder.mutation<ResultType<SeminarSignIn>, SeminarSignInModel>({
            query: ({ ...patch }) => ({
                url: `signInData`,
                method: 'PUT',
                body: patch
            }),
        }),
        deleteSeminarSignIn: builder.mutation<ResultType<null>, SeminarSignInModel>({
            query: ({ ...patch }) => ({
                url: `signInData`,
                method: 'DELETE',
                body: patch
            }),
        }),
        getAllSeminarUserSigns: builder.query<ResultType<SeminarUserSignsData[]>, null>({
            query: () => `signIn/record`,
        }),
    }),
    overrideExisting: false
});

export const { useGetAllSeminarsQuery } = seminarApi;
export const { useLazyGetAllSeminarsQuery } = seminarApi;
export const { useGetSeminarQuery } = seminarApi;
export const { useLazyGetSeminarQuery } = seminarApi;
export const { useGetSeminarsByBoothQuery } = seminarApi;
export const { useLazyGetSeminarsByBoothQuery } = seminarApi;
export const { useGetAgendaQuery } = seminarApi;
export const { useGetNowAiringQuery } = seminarApi;
export const { useGetSeminarSignInStatusQuery } = seminarApi;
export const { useSignInOnSeminarMutation } = seminarApi;

//exhibitor或superuser或admin用api
export const { useGetStageListQuery } = seminarApi;
export const { usePutSeminarMutation } = seminarApi;
export const { useDeleteSeminarMutation } = seminarApi;
export const { usePostAgendaMutation } = seminarApi;
export const { usePutAgendaMutation } = seminarApi;
export const { useDeleteAgendaMutation } = seminarApi;
export const { useGetAllSeminarSignInsQuery } = seminarApi;
export const { useInsertSeminarSignInMutation } = seminarApi;
export const { useUpdateSeminarSignInMutation } = seminarApi;
export const { useDeleteSeminarSignInMutation } = seminarApi;
export const { useGetAllSeminarUserSignsQuery } = seminarApi;