﻿import * as React from 'react';
import { useBoothFieldNameList } from '../../store';
import { useLazyGetBoothQuery, usePutBoothMutation } from '../../components/services/booth';
import { useTranslation } from 'react-i18next';
import { Booth, BoothExtendField } from '../../components/class';
import EditIntroDialog from "../../Booth/BoothEdit";
import { SubTitle } from '../../components/responsive-components/Typography';
import { BoothCard } from '../../components/BoothCard/BoothCard';
import * as lodash from 'lodash';

export default function BoothPagingManage(props: BoothPagingManageProps) {
    const { boothId } = props;
    const { t } = useTranslation();

    const boothFieldNameList = useBoothFieldNameList();
    const [data, setData] = React.useState<Booth>(null);
    function handleDataChange(value: Booth) {
        let tempBooth = value;

        let tempList = [...tempBooth.fieldList];
        let newFields: BoothExtendField[] = [];

        boothFieldNameList.forEach((field) => {
            if (!tempList.find(x => x.fieldId === field.fieldId) &&
                field.boothTypeList.includes(value.type))
                newFields.push({ fieldId: field.fieldId, value: '' });
        });

        tempBooth = {
            ...tempBooth,
            fieldList: tempList.concat(newFields)
        };

        setData(tempBooth);
    }
    const [getBooth, { data: fetchData }] = useLazyGetBoothQuery();
    React.useEffect(() => {
        if (fetchData && fetchData.data) {
            handleDataChange(fetchData.data);
        }
    }, [fetchData])
    React.useEffect(() => {
        if (boothId && boothId != '') {
            getBooth({ boothId: boothId });
        }
    }, [boothId])

    const [putBooth, { isLoading: isPutLoading }] = usePutBoothMutation();
    const [openEditDialog, setOpenEditDialog] = React.useState(false);
    const handleClose = (value?: Booth) => {
        if (value) {
            let tempData = { boothId: data.boothId, ...value };
            putBooth(tempData).unwrap().then((result) => {
                if (result.isSuccess) {
                    handleDataChange(value);
                    setOpenEditDialog(false);
                }
            }).catch((error) => {
                console.log(error);
            });
        }
        else {
            setOpenEditDialog(false);
        }
    }

    return (<>
        <SubTitle
            icon='circle'
            text={t('manage.boothTool.editBooth')}
        />
        <BoothCard
            data={data}
            editable
            onClickEdit={() => { setOpenEditDialog(true) }}
        />
        {data &&
            <EditIntroDialog
                open={openEditDialog}
                data={data}
                onClose={handleClose}
                fullWidth={true}
                maxWidth="lg"
                isLoading={isPutLoading}
            />
        }
    </>)
};
interface BoothPagingManageProps {
    boothId: string;
}