﻿import * as React from 'react';
import { Backdrop, CircularProgress, Paper, Stack, TextField, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { GeneralTextField, PaperWrap, SubmitButton, TextButton } from './LoginWindow';
import ErrorPage from '../components/ErrorPage/404Page';
import { useDispatchWithType } from '../store';
import { useCheckUserMutation, useReverifyMutation, useVerifyMutation, useVerifyHashMutation } from '../components/services/login';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import './LoginStyleSheet.css';
import { loginUser } from '../store/rootReducer';

const HomeIcon = "images/logo.png"

type formData = {
    userId: string;
    code: string;
}
export default function VerifyWindow() {
    const history = useHistory();
    const query = new URLSearchParams(useLocation().search);

    const [checkUser] = useCheckUserMutation();
    const [reverify] = useReverifyMutation();
    const [verify, { isLoading }] = useVerifyMutation();
    const [verifyHash] = useVerifyHashMutation();
    const dispatch = useDispatchWithType();
    const { t, i18n } = useTranslation();

    const schema = yup.object().shape({
        code: yup.string().required(t('verify.code.error1')).min(6, t('verify.code.error2')),
    });
    const { register, handleSubmit, formState: { errors } } = useForm<formData>({
        mode: "onBlur", resolver: yupResolver(schema),
        defaultValues: { userId: query.get("u"), code: "" }
    });
    
    const [isCheckedUser, setIsCheckedUser] = React.useState(false); //是否檢查過 有沒有該筆使用者資料 (不是指有沒有通過)
    const [errorMessage, setErrorMessage] = React.useState<string>("");

    const [success, setSuccess] = React.useState(false);
    const [error, setError] = React.useState(false);

    React.useLayoutEffect(() => {
        if (query.get("u") && query.get("u") != "" && query.get("hash") && query.get("hash") != "") {
            verifyHash({ userId: query.get("u"), hash: query.get("hash") }).unwrap()
                .then(result => {
                    if (result.isSuccess) {
                        dispatch(loginUser(result.data));
                        setSuccess(true);
                    }
                });
        }
    }, []);

    React.useEffect(() => {
        if (!success) {
            checkUser(query.get("u")).unwrap()
                .then(data => {
                    if (data.isSuccess) {
                        setIsCheckedUser(true);
                    }
                    else {
                        setIsCheckedUser(true);
                        setError(true);
                    }
                });
        }
    }, [query.get("u")]);

    function handleResend() {
        reverify(query.get("u")).unwrap()
            .then(data => {
                if (data.isSuccess) {
                    history.push('/verify?resent=1&u=' + query.get("u"));
                }
            });
    }

    function onSubmit(data) {
        setErrorMessage("");
        verify(data).unwrap()
            .then(result => {
                if (result.isSuccess) {
                    dispatch(loginUser(result.data));
                    setSuccess(true);
                }
                else {
                    setErrorMessage(result.message);
                }
            }).catch((error) => {
                //後端報錯 ex:500、404
                setErrorMessage("Unconnect");
                console.error("發生錯誤", error)
            });
    }

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };

    if (error) {
        return (<ErrorPage />)
    }
    else if (success) {
        return (<>驗證成功，<a href="/">點我回首頁</a>。</>)
    }
    else if (isCheckedUser) {
        return (
            <div className="root bg-lavender">
                <PaperWrap className="py-5" elevation={3}>
                    <Stack width={280} justifyContent="stretch" alignItems="stretch">
                        <div className="d-flex align center w-100">
                            <img src={HomeIcon} style={{ height: '36px', maxWidth: '100%' }} />
                            <Typography variant='subtitle2' style={{ padding: 6, wordBreak: "keep-all" }}>{t('navbar.title')}</Typography>
                        </div>

                        <div style={{ margin: "24px 0px", color: '#cccccc' }}>
                            <Typography variant="h5" style={{ fontWeight: 'bold' }}>
                                {t('verify.subtitle1')}
                            </Typography>
                            <Typography variant="h5">
                                {t('verify.subtitle2')}
                            </Typography>
                            <Typography variant="h5">
                                {t('verify.subtitle3')}
                            </Typography>
                        </div>

                        <Stack component="form" onSubmit={handleSubmit(onSubmit)}>
                            <GeneralTextField
                                variant="outlined"
                                size='small'
                                placeholder={t('verify.code.placeholder')}
                                inputRef={register('code').ref}
                                error={!!errors.code}
                                helperText={errors.code ? errors.code.message : ' '}
                                {...register("code")}

                                FormHelperTextProps={{ style: { position: 'absolute', bottom: 0, right: 0 } }}
                                disabled={isLoading}
                            />
                            <div className="d-flex center" style={{ height: 30 }}>
                                <Typography variant='body1' sx={{ color: (theme) => theme.palette.error.main }}>{errorMessage}</Typography>
                            </div>
                            <SubmitButton type="submit" variant="contained" loading={isLoading}>
                                {t('verify.button')}
                            </SubmitButton>
                        </Stack>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Typography variant='body2'>{t('verify.question')}</Typography>
                            <TextButton onClick={handleResend}>{t('verify.resend')}</TextButton>
                        </div>

                        <div style={{ height: 12 }} />
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Typography variant='body2'>Click to</Typography>
                            {i18n.language == 'ch' ?
                                <TextButton onClick={() => changeLanguage('en')}>ENG</TextButton> :
                                <TextButton onClick={() => changeLanguage('ch')}>中文版</TextButton>
                            }
                        </div>
                    </Stack>
                </PaperWrap>
            </div >
        );
    }
    else {
        return (
            <Backdrop color="primary" open={true}>
                <CircularProgress />
            </Backdrop>
        )
    }
}