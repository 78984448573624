﻿import * as React from 'react';
import { Backdrop, Box, CircularProgress, Toolbar, Typography } from '@mui/material';
import { Col, Container, Row } from 'reactstrap';
import { Announce } from '../../../components/class';
import { useTranslation } from 'react-i18next';
import StartAnnounce from '../../../components/StartAnnounce/StartAnnounce';
import { BorderButton } from '../../../components/responsive-components';
import { Add } from '@mui/icons-material';
import { useDeleteAnnounceMutation, useGetAnnouncesQuery, usePutAnnounceMutation } from '../../../components/services/system';
import ErrorPage from '../../../components/ErrorPage/408Page';
import { InsertDialog } from '../../../components/StartAnnounce/StartAnnounceDialog';
import { SubTitle } from '../../../components/responsive-components/Typography';

export default function StartAnnouceEdit() {
    const { data: announceList, isLoading, isError, refetch } = useGetAnnouncesQuery(null, {
        refetchOnMountOrArgChange: true
    });
    const [putAnnounce] = usePutAnnounceMutation();
    const [deleteAnnounce] = useDeleteAnnounceMutation();
    const { t } = useTranslation();

    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    function handleSaveAnnouce(value: Announce) {
        return new Promise<void>((resolve, reject) => {
            putAnnounce(value).unwrap().then((result) => {
                if (result.isSuccess) {
                    resolve();
                    refetch();
                } else {
                    reject();
                }
            }).catch(() => {
                reject();
            });
        });
    };

    function handleDeleteAnnouce(value: Announce) {
        return new Promise<void>((resolve, reject) => {
            deleteAnnounce(value).unwrap().then((result) => {
                if (result.isSuccess) {
                    resolve();
                } else {
                    reject();
                }
                refetch();
            }).catch(() => {
                reject();
            });
        });
    }

    if (isError)
        return <ErrorPage />
    else {
        return (
            <div>
                <SubTitle
                    icon='circle'
                    text={t('manage.title.manage.newNotification')}
                    style={{ marginLeft: '36px' }}
                />

                <Box my={2.5}>
                    <Typography variant='subtitle1'>
                        {t('manage.announce.start_announce.description')}
                    </Typography>
                </Box>

                <Box my={2.5}>
                    <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                        <BorderButton onClick={() => handleClickOpen()}>
                            <Add />
                            <Typography variant='h6'>
                                {t('manage.announce.start_announce.new')}
                            </Typography>
                        </BorderButton>
                        
                        <InsertDialog
                            open={open}
                            handleSubmit={handleSaveAnnouce}
                            handleClose={handleClose}
                        />
                    </div>
                </Box>

                <Box my={2.5}>
                    {announceList && announceList.data.length > 0 ?
                            <Row>
                                {announceList.data.map((announce, index) => (
                                    <Col sm={6} lg={6} xs={12} className={'mt-3'} key={`announce: ${announce.dataId}`} style={{
                                        display: 'flex',
                                        justifyContent: 'center'
                                    }}>
                                        <StartAnnounce
                                            data={announce}
                                            isManage={true}
                                            saveAnnouce={handleSaveAnnouce}
                                            deleteAnnouce={handleDeleteAnnouce}
                                        />
                                    </Col>
                                ))}
                            </Row> :
                            <Typography variant='h6'>
                                {t('manage.announce.start_announce.none')}
                            </Typography>
                        }
                </Box>
                <Toolbar />
                <Backdrop color="primary" open={isLoading}>
                    <CircularProgress />
                </Backdrop>
            </div>
        )
    }
};