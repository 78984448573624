﻿import * as React from 'react';
import { Box, IconButton, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Footer } from './Footer';
import { NavBar } from './NavBar';
import { useTranslation } from 'react-i18next';
import { Facebook } from '@mui/icons-material';
const HomeIcon = "images/logo.png"

export type LayoutVarient = "login" | "noFooter";
export const Layout = (props: LayoutProps) => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));

    const [withNavBar, setWithNavBar] = React.useState(true);
    const [withContainer, setWithContainer] = React.useState(true);
    const [withFooter, setWithFooter] = React.useState(true);
    React.useEffect(() => {
        if (props.varient == "login") {
            setWithNavBar(false);
            setWithContainer(false);
            setWithFooter(false);
        }
        else if (props.varient == "noFooter") {
            setWithNavBar(true);
            setWithContainer(false);
            setWithFooter(false);
        }
        else {
            setWithNavBar(true);
            setWithContainer(true);
            setWithFooter(true);
        }
    }, [props.varient])

    const clearCssStyle: React.CSSProperties = {
        width: 'unset',
        height: 'unset',
        maxWidth: 'unset',
        padding: 'unset',
        margin: 'unset',
    }

    return (
        <>
            {withNavBar &&
                <NavBar logo={<Logo />} name={<LogoText />} />
            }

            <div style={withContainer ? { minHeight: window.innerHeight - 64 - 100 } : { ...clearCssStyle }}  >
                {props.children}
            </div>

            {withFooter &&
                <Footer position="relative" mt={{ xs: 2, sm: 4 }} py={{ xs: 1, sm: 2 }}>
                    <Box style={{ wordBreak: "keep-all" }}>
                        {matches &&
                            <IconButton color="inherit" sx={{ ml: -1 }} href="https://www.facebook.com/TempestDigitalTW/" target='_blank'><Facebook /></IconButton>
                        }
                        <span>copyright © <a style={{ color: "inherit" }} href="https://tempestdigi.com/" target='_blank'>2022 暴風數位股份有限公司</a> All right reserved.</span>
                        {!matches && <>
                            <br />
                            <IconButton color="inherit" sx={{ ml: -1 }} href="https://www.facebook.com/TempestDigitalTW/" target='_blank'><Facebook /></IconButton>
                        </>}
                    </Box>
                </Footer>
            }
        </>
    );
};
export interface LayoutProps {
    varient?: LayoutVarient;
    children: React.ReactElement
}

const Logo = () => {
    return (
        <img src={HomeIcon} style={{ height: '32px', maxWidth: '100%' }} />
    )
}
const LogoText = () => {
    const { t } = useTranslation();
    return (
        <Typography style={{ padding: 6 }}>{t('navbar.title')}</Typography>
    )
}