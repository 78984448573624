﻿import * as React from 'react';
import * as ReactDOMServer from 'react-dom/server';
import { Box, BoxProps, Button, Checkbox, CheckboxProps, FormControl, FormControlLabel, FormControlLabelProps, FormControlProps, FormGroup, FormGroupProps, FormHelperText } from '@mui/material';
import { MenuItem, styled, TextField, TextFieldProps, Tooltip, Typography, TypographyProps, useTheme } from '@mui/material';
import { important, TextField as NoLegendTextField } from './responsive-components';
import { RegisterOptions } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { fieldPrivacyList } from './class';
import { CheckCircle, Fax, Language, Person, Phone, Place, RemoveCircle } from '@mui/icons-material';
import { campusList, CheckItemType, academyList, gradeList, MenuItemType } from './selectOptions';
import Linkify from "react-linkify";

export const uniqueUserFieldList: string[] = [
	'targetList', 'studentId', 'school', 'campus', 'academy', 'grade'
]
export const uniqueBoothFieldList: string[] = [
	'edm', 'poster', 'establishment', 'job', 'unifiedBusinessNo', 'numOfEmployees', 'phone', 'address', 'website', 'fax'
];
export const targetList = [
	{ id: 'graduate', nameKey: 'user.editDialog.targetList.graduate' },
	{ id: 'employee', nameKey: 'user.editDialog.targetList.employee' },
	{ id: 'internship', nameKey: 'user.editDialog.targetList.internship' },
]
export const userCustomFieldList: CustomFieldElement[] = [
	//學號
	{
		id: 'studentId',
		displayComponent: ({ displayTitle, displayValue, children, ...other }) => (<>
			<Typography
				sx={{ fontSize: '14px', fontWeight: 'bold', color: (theme) => theme.palette.text.primary }}
				{...other}
			>
				{`${displayTitle}`}
			</Typography>
			<Typography
				sx={{ fontSize: '14px', color: (theme) => theme.palette.text.primary }}
			>
				{`${displayValue}`}
			</Typography>
			{children}
		</>),
		editComponent: (props) => (
			<TextField
				disabled
				hiddenLabel={!props.displayTitle}
				label={props.displayTitle}
				placeholder={ReactDOMServer.renderToString(<Trans i18nKey='selectOptions.studentId.placeholder'>placeholder</Trans>)}
				sx={{
					'& .MuiInputLabel-root': {
						'& .MuiInputLabel-asterisk': {
							color: 'red',
						}
					}
				}}
				error={!!props.errorMessage}
				helperText={props.errorMessage ?? '　'}
				{...props}
			/>
		),
		validation: {
			maxLength: {
				value: 20,
				message: ReactDOMServer.renderToString(<Trans i18nKey="user.editDialog.error_maxStringLengthWithNumber" count={20}>Max 20</Trans>)
			}
		}
	},
	//學校
	{
		id: 'school',
		displayComponent: ({ displayTitle, displayValue, children, ...other }) => (<>
			<Typography
				sx={{ fontSize: '14px', color: (theme) => theme.palette.text.primary }}
			>
				{`${displayValue}`}
			</Typography>
			{children}
		</>),
		editComponent: (props) => (
			<TextField
				disabled
				hiddenLabel={!props.displayTitle}
				label={props.displayTitle}
				placeholder={ReactDOMServer.renderToString(<Trans i18nKey='selectOptions.school.placeholder'>placeholder</Trans>)}
				sx={{
					'& .MuiInputLabel-root': {
						'& .MuiInputLabel-asterisk': {
							color: 'red',
						}
					}
				}}
				error={!!props.errorMessage}
				helperText={props.errorMessage ?? '　'}
				{...props}
			/>
		),
		validation: {
		}
	},
	//校區
	{
		id: 'campus',
		displayComponent: ({ displayTitle, displayValue, children, ...other }) => (<>
			<Typography
				sx={{ fontSize: '14px', color: (theme) => theme.palette.text.primary }}
			>
				{`${displayValue}`}
			</Typography>
			{children}
		</>),
		editComponent: (props) => (
			<TextField
				required
				hiddenLabel={!props.displayTitle}
				label={props.displayTitle}
				placeholder={ReactDOMServer.renderToString(<Trans i18nKey='selectOptions.campus.placeholder'>placeholder</Trans>)}
				sx={{
					'& .MuiInputLabel-root': {
						'& .MuiInputLabel-asterisk': {
							color: 'red',
						}
					}
				}}
				error={!!props.errorMessage}
				helperText={props.errorMessage ?? '　'}
				select
				{...props}
			>
				{campusList.map((option) => (
					<MenuItem key={option.value} value={option.value}>
						<Trans i18nKey={option.nameKey}>{option.value}</Trans>
					</MenuItem>
				))}
            </TextField >
		),
		validation: {
			required: ReactDOMServer.renderToString(<Trans i18nKey="user.editDialog.error_required">Required</Trans>)
		}
	},
	//學院
	{
		id: 'academy',
		displayComponent: ({ displayTitle, displayValue, children, ...other }) => (<>
			{`${displayValue}`}
			{children}
		</>),
		editComponent: (props) => (
			<TextField
				required
				hiddenLabel={!props.displayTitle}
				label={props.displayTitle}
				placeholder={ReactDOMServer.renderToString(<Trans i18nKey='selectOptions.academy.placeholder'>placeholder</Trans>)}
				sx={{
					'& .MuiInputLabel-root': {
						'& .MuiInputLabel-asterisk': {
							color: 'red',
						}
					}
				}}
				error={!!props.errorMessage}
				helperText={props.errorMessage ?? '　'}
				select
				{...props}
			>
				{academyList.map((option) => (
					<MenuItem key={option.value} value={option.value}>
						<Trans i18nKey={option.nameKey}>{option.value}</Trans>
					</MenuItem>
				))}
			</TextField >
		),
		validation: {
			required: ReactDOMServer.renderToString(<Trans i18nKey="user.editDialog.error_required">Required</Trans>)
		}
	},
	//年級
	{
		id: 'grade',
		displayComponent: ({ displayTitle, displayValue, children, ...other }) => (<>
			{`${displayValue}`}
			{children}
		</>),
		editComponent: (props) => (
			<TextField
				required
				hiddenLabel={!props.displayTitle}
				label={props.displayTitle}
				placeholder={ReactDOMServer.renderToString(<Trans i18nKey='selectOptions.grade.placeholder'>placeholder</Trans>)}
				sx={{
					'& .MuiInputLabel-root': {
						'& .MuiInputLabel-asterisk': {
							color: 'red',
						}
					}
				}}
				error={!!props.errorMessage}
				helperText={props.errorMessage ?? '　'}
				select
				{...props}
			>
				{gradeList.map((option) => (
					<MenuItem key={option.value} value={option.value}>
						<Trans i18nKey={option.nameKey}>{option.value}</Trans>
					</MenuItem>
				))}
			</TextField >
		),
		validation: {
			required: ReactDOMServer.renderToString(<Trans i18nKey="user.editDialog.error_required">Required</Trans>)
		}
	},
	//條件列表
	{
		id: 'targetList',
		displayComponent: ({ displayTitle, displayValue, children, ...other }) => (<>
			<CheckListDisplay
				checkList={targetList}
				displayTitle={displayTitle}
				displayValue={displayValue}
				{...other}
			/>
			{children}
		</>),
		editComponent: (props) => (
			<CheckListField
				checkList={targetList}
				{...props}
			/>
		),
		validation: {
		}
	},
	//技能
	{
		id: 'ability',
		displayComponent: ({ displayTitle, displayValue, children, ...other }) => (<>
			<Typography
				sx={{ fontSize: '14px', fontWeight: 'bold', color: (theme) => theme.palette.text.primary }}
				{...other}
			>
				{`${displayTitle}`}
			</Typography>
			<Typography
				sx={{ fontSize: '14px', color: (theme) => theme.palette.text.primary }}
			>
				{`${displayValue}`}
			</Typography>
			{children}
		</>),
		editComponent: (props) => (
			<TextField
				hiddenLabel={!props.displayTitle}
				label={props.displayTitle}
				multiline
				rows={4}
				placeholder={ReactDOMServer.renderToString(<Trans i18nKey='selectOptions.ability.placeholder'>placeholder</Trans>)}
				sx={{
					'& .MuiInputLabel-root': {
						'& .MuiInputLabel-asterisk': {
							color: 'red',
						}
					}
				}}
				error={!!props.errorMessage}
				helperText={props.errorMessage ?? '　'}
				{...props}
			/>
		),
		validation: {
			maxLength: {
				value: 2048,
				message: ReactDOMServer.renderToString(<Trans i18nKey="user.editDialog.error_maxStringLengthWithNumber" count={2048}>Max 2048</Trans>)
			}
		}
	},
	//證照 / 語言認證
	{
		id: 'certificate',
		displayComponent: ({ displayTitle, displayValue, children, ...other }) => (<>
			<Typography
				sx={{ fontSize: '14px', fontWeight: 'bold', color: (theme) => theme.palette.text.primary }}
				{...other}
			>
				{`${displayTitle}`}
			</Typography>
			<Typography
				sx={{ fontSize: '14px', color: (theme) => theme.palette.text.primary }}
			>
				{`${displayValue}`}
			</Typography>
			{children}
		</>),
		editComponent: (props) => (
			<TextField
				hiddenLabel={!props.displayTitle}
				label={props.displayTitle}
				multiline
				rows={4}
				placeholder={ReactDOMServer.renderToString(<Trans i18nKey='selectOptions.certificate.placeholder'>placeholder</Trans>)}
				sx={{
					'& .MuiInputLabel-root': {
						'& .MuiInputLabel-asterisk': {
							color: 'red',
						}
					}
				}}
				error={!!props.errorMessage}
				helperText={props.errorMessage ?? '　'}
				{...props}
			/>
		),
		validation: {
			maxLength: {
				value: 2048,
				message: ReactDOMServer.renderToString(<Trans i18nKey="user.editDialog.error_maxStringLengthWithNumber" count={2048}>Max 2048</Trans>)
			}
		}
	},
	//經歷
	{
		id: 'experience',
		displayComponent: ({ displayTitle, displayValue, children, ...other }) => (<>
			<Typography
				sx={{ fontSize: '14px', fontWeight: 'bold', color: (theme) => theme.palette.text.primary }}
				{...other}
			>
				{`${displayTitle}`}
			</Typography>
			<Typography
				sx={{ fontSize: '14px', color: (theme) => theme.palette.text.primary }}
			>
				{`${displayValue}`}
			</Typography>
			{children}
		</>),
		editComponent: (props) => (
			<TextField
				hiddenLabel={!props.displayTitle}
				label={props.displayTitle}
				multiline
				rows={4}
				placeholder={ReactDOMServer.renderToString(<Trans i18nKey='selectOptions.experience.placeholder'>placeholder</Trans>)}
				sx={{
					'& .MuiInputLabel-root': {
						'& .MuiInputLabel-asterisk': {
							color: 'red',
						}
					}
				}}
				error={!!props.errorMessage}
				helperText={props.errorMessage ?? '　'}
				{...props}
			/>
		),
		validation: {
			maxLength: {
				value: 2048,
				message: ReactDOMServer.renderToString(<Trans i18nKey="user.editDialog.error_maxStringLengthWithNumber" count={2048}>Max 2048</Trans>)
			}
		}
	},

	//公司 / 組織
	{
		id: 'organization',
		displayComponent: ({ displayTitle, displayValue, children, ...other }) => (<>
			<Typography
				sx={{ fontSize: '14px', fontWeight: 'bold', color: (theme) => theme.palette.text.primary }}
				{...other}
			>
				{`${displayTitle}`}
			</Typography>
			<Typography
				sx={{ fontSize: '14px', color: (theme) => theme.palette.text.primary }}
			>
				{`${displayValue}`}
			</Typography>
			{children}
		</>),
		editComponent: (props) => (
			<TextField
				required
				hiddenLabel={!props.displayTitle}
				label={props.displayTitle}
				placeholder={ReactDOMServer.renderToString(<Trans i18nKey='selectOptions.organization.placeholder'>placeholder</Trans>)}
				sx={{
					'& .MuiInputLabel-root': {
						'& .MuiInputLabel-asterisk': {
							color: 'red',
						}
					}
				}}
				error={!!props.errorMessage}
				helperText={props.errorMessage ?? '　'}
				{...props}
			/>
		),
		validation: {
			required: ReactDOMServer.renderToString(<Trans i18nKey="user.editDialog.error_required">Required</Trans>)
		}
	},
	//職稱
	{
		id: 'jobTitle',
		displayComponent: ({ displayTitle, displayValue, children, ...other }) => (<>
			<Typography
				sx={{ fontSize: '14px', fontWeight: 'bold', color: (theme) => theme.palette.text.primary }}
				{...other}
			>
				{`${displayTitle}`}
			</Typography>
			<Typography
				sx={{ fontSize: '14px', color: (theme) => theme.palette.text.primary }}
			>
				{`${displayValue}`}
			</Typography>
			{children}
		</>),
		editComponent: (props) => (
			<TextField
				required
				hiddenLabel={!props.displayTitle}
				label={props.displayTitle}
				placeholder={ReactDOMServer.renderToString(<Trans i18nKey='selectOptions.jobTitle.placeholder'>placeholder</Trans>)}
				sx={{
					'& .MuiInputLabel-root': {
						'& .MuiInputLabel-asterisk': {
							color: 'red',
						}
					}
				}}
				error={!!props.errorMessage}
				helperText={props.errorMessage ?? '　'}
				{...props}
			/>
		),
		validation: {
			required: ReactDOMServer.renderToString(<Trans i18nKey="user.editDialog.error_required">Required</Trans>)
		}
	},

	//想說的話
	{
		id: 'paragraph',
		displayComponent: ({ displayTitle, displayValue, children, ...other }) => (<>
			<Typography
				sx={{ fontSize: '14px', fontWeight: 'bold', color: (theme) => theme.palette.text.primary }}
				{...other}
			>
				{`${displayTitle}`}
			</Typography>
			<Typography
				sx={{ fontSize: '14px', color: (theme) => theme.palette.text.primary }}
			>
				{`${displayValue}`}
			</Typography>
			{children}
		</>),
		editComponent: (props) => (
			<TextField
				hiddenLabel={!props.displayTitle}
				label={props.displayTitle}
				multiline
				rows={4}
				placeholder={ReactDOMServer.renderToString(<Trans i18nKey='selectOptions.paragraph.placeholder'>placeholder</Trans>)}
				sx={{
					'& .MuiInputLabel-root': {
						'& .MuiInputLabel-asterisk': {
							color: 'red',
						}
					}
				}}
				error={!!props.errorMessage}
				helperText={props.errorMessage ?? '　'}
				{...props}
			/>
		),
		validation: {
			maxLength: {
				value: 2048,
				message: ReactDOMServer.renderToString(<Trans i18nKey="user.editDialog.error_maxStringLengthWithNumber" count={2048}>Max 2048</Trans>)
			}
		}
	},

	/*
	//語言技能
	{
		id: 'languageSkill',
		displayComponent: ({ displayTitle, displayValue, children, ...other }) => (<>
			{displayTitle &&
				<Typography
					sx={{ fontSize: '14px', fontWeight: 'bold', color: (theme) => theme.palette.text.primary }}
					{...other}
				>
					{`${displayTitle}`}
				</Typography>
			}
			<Typography
				sx={{ fontSize: '14px', color: (theme) => theme.palette.text.primary }}
			>
				{displayValue?.split(',').map((item, index, array) => (
					<span key={item}>
						<Trans i18nKey={languageSkillList.find(x => x.value === item)?.nameKey ?? item}>
							{item}
						</Trans>
						{index != (array.length - 1) && ', '}
					</span>
				))}
			</Typography>
			{children}
		</>),
		editComponent: (props) => (
			<MultipleSelectField
				required
				selectList={languageSkillList}
				error={!!props.errorMessage}
				helperText={props.errorMessage ?? '　'}
				sx={{
					'& .MuiInputLabel-root': {
						'& .MuiInputLabel-asterisk': {
							color: 'red',
						}
					}
				}}
				{...props}
			/>
		),
		validation: {
			required: ReactDOMServer.renderToString(<Trans i18nKey="user.editDialog.error_required">Required</Trans>),
		}
	}
	*/
];
export const boothCustomFieldList: CustomFieldElement[] = [
	//統一編號
	{
		id: 'unifiedBusinessNo',
		displayComponent: ({ displayTitle, displayValue, children, ...other }) => (<>
			<Typography
				sx={{ fontSize: '14px', color: (theme) => theme.palette.text.primary }}
			>
				{`${displayValue}`}
			</Typography>
			{children}
		</>),
		editComponent: (props) => (
			<NoLegendTextField
				disabled
				placeholder={ReactDOMServer.renderToString(<Trans i18nKey='selectOptions.unifiedBusinessNo.placeholder'>placeholder</Trans>)}
				sx={{
					'& .MuiInputLabel-root': {
						'& .MuiInputLabel-asterisk': {
							color: 'red',
						}
					}
				}}
				error={!!props.errorMessage}
				helperText={props.errorMessage ?? '　'}
				{...props}
			/>
		),
		validation: {
		}
	},
	//員工人數
	{
		id: 'numOfEmployees',
		displayComponent: ({ displayTitle, displayValue, children, ...other }) => (<>
			<div className="d-flex">
				<span className="d-flex align-items-center mr-2">
					<Person htmlColor="gray" />
				</span>
				{displayValue}
				<span className="d-flex align-items-end" style={{ fontSize: "0.875rem", marginLeft: "0.5rem", lineHeight: 1.8 }}>
					<Trans i18nKey="booth.content.employees">人</Trans>
				</span>
			</div>
			{children}
		</>),
		editComponent: (props) => (
			<NoLegendTextField
				placeholder={ReactDOMServer.renderToString(<Trans i18nKey='selectOptions.numOfEmployees.placeholder'>placeholder</Trans>)}
				sx={{
					'& .MuiInputLabel-root': {
						'& .MuiInputLabel-asterisk': {
							color: 'red',
						}
					}
				}}
				error={!!props.errorMessage}
				helperText={props.errorMessage ?? '　'}
				{...props}
			/>
		),
		validation: {
			maxLength: {
				value: 20,
				message: ReactDOMServer.renderToString(<Trans i18nKey="user.editDialog.error_maxStringLengthWithNumber" count={20}>Max 20</Trans>)
			}
		}
	},
	//成立時間
	{
		id: 'establishment',
		displayComponent: ({ displayTitle, displayValue, children, ...other }) => (<>
			<span style={{ fontSize: "1rem", color: "#909090" }}>
				<Trans i18nKey={'booth.content.establishment'} />
				<span style={{ fontSize: "1.25rem" }}>
					{displayValue}
				</span>
			</span>
			{children}
		</>),
		editComponent: (props) => (
			<NoLegendTextField
				placeholder={ReactDOMServer.renderToString(<Trans i18nKey='selectOptions.establishment.placeholder'>placeholder</Trans>)}
				sx={{
					'& .MuiInputLabel-root': {
						'& .MuiInputLabel-asterisk': {
							color: 'red',
						}
					}
				}}
				error={!!props.errorMessage}
				helperText={props.errorMessage ?? '　'}
				{...props}
			/>
		),
		validation: {
			maxLength: {
				value: 10,
				message: ReactDOMServer.renderToString(<Trans i18nKey="user.editDialog.error_maxStringLengthWithNumber" count={10}>Max 10</Trans>)
			}
		}
	},
	//電話
	{
		id: 'phone',
		displayComponent: ({ displayTitle, displayValue, children, ...other }) => (<>
			<div className="d-flex">
				<span className="d-flex align-items-center mr-2">
					<Phone htmlColor="gray" />
				</span>
				{displayValue}
			</div>
			{children}
		</>),
		editComponent: (props) => (
			<NoLegendTextField
				placeholder={ReactDOMServer.renderToString(<Trans i18nKey='selectOptions.phone.placeholder'>placeholder</Trans>)}
				sx={{
					'& .MuiInputLabel-root': {
						'& .MuiInputLabel-asterisk': {
							color: 'red',
						}
					}
				}}
				error={!!props.errorMessage}
				helperText={props.errorMessage ?? '　'}
				{...props}
			/>
		),
		validation: {
			maxLength: {
				value: 20,
				message: ReactDOMServer.renderToString(<Trans i18nKey="user.editDialog.error_maxStringLengthWithNumber" count={20}>Max 20</Trans>)
			}
		}
	},
	//地址
	{
		id: 'address',
		displayComponent: ({ displayTitle, displayValue, children, ...other }) => (<>
			<div className="d-flex">
				<span className="d-flex align-items-center mr-2">
					<Place htmlColor="gray" />
				</span>
				{displayValue}
			</div>
			{children}
		</>),
		editComponent: (props) => (
			<NoLegendTextField
				placeholder={ReactDOMServer.renderToString(<Trans i18nKey='selectOptions.address.placeholder'>placeholder</Trans>)}
				sx={{
					'& .MuiInputLabel-root': {
						'& .MuiInputLabel-asterisk': {
							color: 'red',
						}
					}
				}}
				error={!!props.errorMessage}
				helperText={props.errorMessage ?? '　'}
				{...props}
			/>
		),
		validation: {
			maxLength: {
				value: 200,
				message: ReactDOMServer.renderToString(<Trans i18nKey="user.editDialog.error_maxStringLengthWithNumber" count={200}>Max 200</Trans>)
			}
		}
	},
	//網址
	{
		id: 'website',
		displayComponent: ({ displayTitle, displayValue, children, ...other }) => (<>
			<div className="d-flex">
				<span className="d-flex align-items-center mr-2">
					<Language htmlColor="gray" />
				</span>
				<Linkify>
					{displayValue}
				</Linkify>
			</div>
			{children}
		</>),
		editComponent: (props) => (
			<NoLegendTextField
				placeholder={ReactDOMServer.renderToString(<Trans i18nKey='selectOptions.website.placeholder'>placeholder</Trans>)}
				sx={{
					'& .MuiInputLabel-root': {
						'& .MuiInputLabel-asterisk': {
							color: 'red',
						}
					}
				}}
				error={!!props.errorMessage}
				helperText={props.errorMessage ?? '　'}
				{...props}
			/>
		),
		validation: {
			maxLength: {
				value: 2048,
				message: ReactDOMServer.renderToString(<Trans i18nKey="user.editDialog.error_maxStringLengthWithNumber" count={2048}>Max 2048</Trans>)
			}
		}
	},
	//公司傳真
	{
		id: 'fax',
		displayComponent: ({ displayTitle, displayValue, children, ...other }) => (<>
			<div className="d-flex">
				<span className="d-flex align-items-center mr-2">
					<Fax htmlColor="gray" />
				</span>
				{displayValue}
			</div>
			{children}
		</>),
		editComponent: (props) => (
			<NoLegendTextField
				placeholder={ReactDOMServer.renderToString(<Trans i18nKey='selectOptions.fax.placeholder'>placeholder</Trans>)}
				sx={{
					'& .MuiInputLabel-root': {
						'& .MuiInputLabel-asterisk': {
							color: 'red',
						}
					}
				}}
				error={!!props.errorMessage}
				helperText={props.errorMessage ?? '　'}
				{...props}
			/>
		),
		validation: {
			maxLength: {
				value: 20,
				message: ReactDOMServer.renderToString(<Trans i18nKey="user.editDialog.error_maxStringLengthWithNumber" count={20}>Max 20</Trans>)
			}
		}
	},
	//公司簡介
	{
		id: 'intro',
		displayComponent: ({ displayTitle, displayValue, children, ...other }) => (<>
			<Typography variant='h6' fontWeight='bold' gutterBottom {...other}>
				{`${displayTitle} ｜ `}
			</Typography>
			<Typography variant='body2' whiteSpace='break-spaces' marginLeft={2}>
				{`${displayValue}`}
			</Typography>
			{children}
		</>),
		editComponent: (props) => (
			<NoLegendTextField
				multiline
				rows={4}
				//hiddenLabel={!props.displayTitle}
				//label={props.displayTitle}
				error={!!props.errorMessage}
				helperText={props.errorMessage ?? '　'}
				{...props}
			/>
		),
		validation: {
			maxLength: {
				value: 5000,
				message: ReactDOMServer.renderToString(<Trans i18nKey="user.editDialog.error_maxStringLengthWithNumber" count={5000}>Max 5000</Trans>)
			}
		}
	},
	//資本額
	{
		id: 'capital',
		displayComponent: ({ displayTitle, displayValue, children, ...other }) => (<>
			<Typography
				sx={{ fontSize: '14px', fontWeight: 'bold', color: (theme) => theme.palette.text.primary }}
				{...other}
			>
				{`${displayTitle}`}
			</Typography>
			<Typography
				sx={{ fontSize: '14px', color: (theme) => theme.palette.text.primary }}
			>
				{`${displayValue}`}
			</Typography>
			{children}
		</>),
		editComponent: (props) => (
			<NoLegendTextField
				placeholder={ReactDOMServer.renderToString(<Trans i18nKey='selectOptions.capital.placeholder'>placeholder</Trans>)}
				sx={{
					'& .MuiInputLabel-root': {
						'& .MuiInputLabel-asterisk': {
							color: 'red',
						}
					}
				}}
				error={!!props.errorMessage}
				helperText={props.errorMessage ?? '　'}
				{...props}
			/>
		),
		validation: {
			maxLength: {
				value: 20,
				message: ReactDOMServer.renderToString(<Trans i18nKey="user.editDialog.error_maxStringLengthWithNumber" count={20}>Max 20</Trans>)
			}
		}
	},
	//營業項目
	{
		id: 'services',
		displayComponent: ({ displayTitle, displayValue, children, ...other }) => (<>
			<Typography variant='h6' fontWeight='bold' gutterBottom {...other}>
				{`${displayTitle} ｜ `}
			</Typography>
			<Typography variant='body2' whiteSpace='break-spaces' marginLeft={2}>
				{`${displayValue}`}
			</Typography>
			{children}
		</>),
		editComponent: (props) => (
			<NoLegendTextField
				multiline
				rows={4}
				//hiddenLabel={!props.displayTitle}
				//label={props.displayTitle}
				error={!!props.errorMessage}
				helperText={props.errorMessage ?? '　'}
				{...props}
			/>
		),
		validation: {
			maxLength: {
				value: 2048,
				message: ReactDOMServer.renderToString(<Trans i18nKey="user.editDialog.error_maxStringLengthWithNumber" count={2048}>Max 2048</Trans>)
			}
		}
	},
	//薪資福利
	{
		id: 'salary',
		displayComponent: ({ displayTitle, displayValue, children, ...other }) => (<>
			<Typography variant='h6' fontWeight='bold' gutterBottom {...other}>
				{`${displayTitle} ｜ `}
			</Typography>
			<Typography variant='body2' whiteSpace='break-spaces' marginLeft={2}>
				{`${displayValue}`}
			</Typography>
			{children}
		</>),
		editComponent: (props) => (
			<NoLegendTextField
				multiline
				rows={4}
				//hiddenLabel={!props.displayTitle}
				//label={props.displayTitle}
				error={!!props.errorMessage}
				helperText={props.errorMessage ?? '　'}
				{...props}
			/>
		),
		validation: {
			maxLength: {
				value: 2048,
				message: ReactDOMServer.renderToString(<Trans i18nKey="user.editDialog.error_maxStringLengthWithNumber" count={2048}>Max 2048</Trans>)
			}
		}
	},
	//休假制度
	{
		id: 'absence',
		displayComponent: ({ displayTitle, displayValue, children, ...other }) => (<>
			<Typography variant='h6' fontWeight='bold' gutterBottom {...other}>
				{`${displayTitle} ｜ `}
			</Typography>
			<Typography variant='body2' whiteSpace='break-spaces' marginLeft={2}>
				{`${displayValue}`}
			</Typography>
			{children}
		</>),
		editComponent: (props) => (
			<NoLegendTextField
				multiline
				rows={4}
				//hiddenLabel={!props.displayTitle}
				//label={props.displayTitle}
				error={!!props.errorMessage}
				helperText={props.errorMessage ?? '　'}
				{...props}
			/>
		),
		validation: {
			maxLength: {
				value: 2048,
				message: ReactDOMServer.renderToString(<Trans i18nKey="user.editDialog.error_maxStringLengthWithNumber" count={2048}>Max 2048</Trans>)
			}
		}
	},
	/*//應徵方式
	{
		id: 'apply',
		displayComponent: ({ displayTitle, displayValue, children, ...other }) => (<>
			<Typography variant='h6' fontWeight='bold' gutterBottom {...other}>
				{`${displayTitle} ｜ `}
			</Typography>
			<Typography variant='body2' whiteSpace='break-spaces' marginLeft={2}>
				{`${displayValue}`}
			</Typography>
			{children}
		</>),
		editComponent: (props) => (
			<NoLegendTextField
				multiline
				rows={4}
				//hiddenLabel={!props.displayTitle}
				//label={props.displayTitle}
				error={!!props.errorMessage}
				helperText={props.errorMessage ?? '　'}
				{...props}
			/>
		),
		validation: {
			maxLength: {
				value: 2048,
				message: ReactDOMServer.renderToString(<Trans i18nKey="user.editDialog.error_maxStringLengthWithNumber" count={2048}>Max 2048</Trans>)
			}
		}
	},*/
	//職缺資訊
	{
		id: 'job',
		displayComponent: ({ displayTitle, displayValue, children, ...other }) => (<>
			<Typography variant='h6' fontWeight='bold' gutterBottom {...other}>
				{`${displayTitle} ｜ `}
			</Typography>
			<Typography variant='body2' whiteSpace='break-spaces' marginLeft={2}>
				{`${displayValue}`}
			</Typography>
			{children}
		</>),
		editComponent: (props) => (
			<NoLegendTextField
				multiline
				rows={4}
				//hiddenLabel={!props.displayTitle}
				//label={props.displayTitle}
				error={!!props.errorMessage}
				helperText={props.errorMessage ?? '　'}
				{...props}
			/>
		),
		validation: {
			maxLength: {
				value: 2048,
				message: ReactDOMServer.renderToString(<Trans i18nKey="user.editDialog.error_maxStringLengthWithNumber" count={2048}>Max 2048</Trans>)
			}
		}
	},
	//徵才互動獎品
	{
		id: 'prizes',
		displayComponent: ({ displayTitle, displayValue, children, ...other }) => (<>
			<Typography variant='h6' fontWeight='bold' gutterBottom {...other}>
				{`${displayTitle} ｜ `}
			</Typography>
			<Typography variant='body2' whiteSpace='break-spaces' marginLeft={2}>
				{`${displayValue}`}
			</Typography>
			{children}
		</>),
		editComponent: (props) => (
			<NoLegendTextField
				multiline
				rows={4}
				//hiddenLabel={!props.displayTitle}
				//label={props.displayTitle}
				error={!!props.errorMessage}
				helperText={props.errorMessage ?? '　'}
				{...props}
			/>
		),
		validation: {
			maxLength: {
				value: 2048,
				message: ReactDOMServer.renderToString(<Trans i18nKey="user.editDialog.error_maxStringLengthWithNumber" count={2048}>Max 2048</Trans>)
			}
		}
	},
];
interface CustomFieldElement {
	id: string;
	displayComponent: (props: TypographyProps & CustomProps) => JSX.Element;
	editComponent: (props: TextFieldProps & { displayTitle?: string, errorMessage?: string }) => JSX.Element;
	validation?: RegisterOptions;
}
interface CustomProps {
	displayTitle?: string,
	displayValue?: string
}

export function SelectPrivacyField(props: SelectPrivacyFieldProps) {
	const { ...other } = props;
	const { t, i18n } = useTranslation();
	return (
		<Tooltip title={t('user.editDialog.fieldPrivacy.tooltip')} placement='top' sx={{ ml: 'auto', mt: '0px', mb: '0px' }}>
			<NoLegendTextField
				variant="outlined"
				size="small"
				margin="dense"
				select
				SelectProps={{
					renderValue: (selected) => t(fieldPrivacyList.find(x => x.value === selected)?.nameKey),
					inputProps: { sx: { paddingTop: '4px', paddingBottom: '4px' } }
				}}
				InputProps={{ style: { color: '#757575', borderRadius: '0px' } }}
				style={{ backgroundColor: '#f3f3f3' }}
				{...other}
			>
				{fieldPrivacyList.map((option) => (
					<MenuItem key={option.value} value={option.value}
						style={{ backgroundColor: '#f3f3f3', color: '#757575' }}>
						{t(option.fullNameKey)}
					</MenuItem>
				))}
			</NoLegendTextField>
		</Tooltip>
	)
}
type SelectPrivacyFieldProps = CustomSelectPrivacyFieldProps & TextFieldProps;
interface CustomSelectPrivacyFieldProps {

}

export function CheckListDisplay(props: CheckListDisplayProps) {
	const { checkList, displayTitle, displayValue } = props;
	const { t, i18n } = useTranslation();

	const theme = useTheme();
	const checkedColor = props.checkedColor ?? theme.palette.success.light;
	const uncheckColor = props.uncheckColor ?? theme.palette.grey[400];

	const [checkListState, setCheckListState] = React.useState<CheckState[]>([]);
	function valueToState(value: string): CheckState[] {
		let newList: CheckState[] = [];
		for (let item of checkList) {
			newList.push({ id: item.id, nameKey: item.nameKey, value: false });
		}
		if (value && value != '') {
			let tempArray = value.split(',');
			for (let temp of tempArray) {
				let index = newList.findIndex(x => x.id === temp);
				if (index !== -1)
					newList[index].value = true;
			}
		}
		return newList;
	}

	React.useEffect(() => {
		if (checkList) {
			let newCheckList: CheckState[] = [];
			for (let check of checkList) {
				newCheckList.push({ id: check.id, nameKey: check.nameKey, value: false });
			}
			if (displayValue) {
				newCheckList = valueToState(displayValue);
			}
			setCheckListState(newCheckList);
		}
	}, [checkList, displayValue])

	const checkIcon = (isCheck: boolean): JSX.Element => {
		if (isCheck) {
			return (
				<CheckCircle sx={{ color: checkedColor }} />
			)
		}
		else {
			return (
				<RemoveCircle sx={{ color: uncheckColor }} />
			)
		}
	}

	const StyledBox: (props: BoxProps) => JSX.Element = styled(Box)(({ theme }) => ({
		display: 'flex',
		alignItems: 'center',
		marginRight: '12px',
		'&:last-child': {
			marginRight: '0px',
		},
	}));

	return (<>
		{checkListState.map((check) => (
			<StyledBox key={check.id}>
				{checkIcon(check.value)}
				<Typography sx={{ color: check.value ? 'inherit' : uncheckColor, ml: 0.5 }}>
					{t(check.nameKey)}
				</Typography>
			</StyledBox>
		))}
	</>)
}
type CheckListDisplayProps = CustomCheckListDisplayProps & CustomProps & TypographyProps;
interface CustomCheckListDisplayProps {
	checkList: CheckItemType[];
	checkedColor?: string;
	uncheckColor?: string;
}

export function CheckListField(props: CheckListFieldProps) {
	const { checkList, style, sx, value, onChange, inputProps,
		formControlProps, formGroupProps, formControlLabelProps, checkboxProps, required, helperText, error, ...other } = props;
	const { t, i18n } = useTranslation();

	const [checkListState, setCheckListState] = React.useState<CheckState[]>([]);
	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		let tempList = [...checkListState];
		let index = tempList.findIndex(x => x.id === event.target.name);
		if (index !== -1) {
			tempList[index].value = event.target.checked;
		}
		setCheckListState([...tempList]);
		onChange({ ...event, target: { ...event.target, value: stateToValue(tempList) } });
	};

	function valueToState(value: string): CheckState[] {
		let newList: CheckState[] = [];
		for (let item of checkList) {
			newList.push({ id: item.id, nameKey: item.nameKey, value: false });
		}
		if (value != '') {
			let tempArray = value.split(',');
			for (let temp of tempArray) {
				let index = newList.findIndex(x => x.id === temp);
				if (index !== -1)
					newList[index].value = true;
			}
		}
		return newList;
	}
	function stateToValue(list: CheckState[]): string {
		let newValue = '';
		for (let item of list) {
			if (item.value === true) {
				let startElement = newValue !== '' ? ',' : '';
				newValue = newValue.concat(startElement, item.id);
			}
		}
		return newValue;
	}

	React.useEffect(() => {
		if (checkList) {
			let newCheckList: CheckState[] = [];
			for (let check of checkList) {
				newCheckList.push({ id: check.id, nameKey: check.nameKey, value: false });
			}
			if (value) {
				newCheckList = valueToState(String(value));
			}
			setCheckListState(newCheckList);
		}
	}, [checkList, value])

	return (
		<FormControl
			required={required}
			error={error}
			//component="fieldset"
			variant="standard"
			style={{ ...style }}
			{...formControlProps}
		>
			<FormGroup
				sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}
				{...formGroupProps}
			>
				{checkListState.map((check) => (
					<FormControlLabel
						control={
							<Checkbox
								checked={check.value}
								onChange={handleChange}
								name={check.id}
								{...checkboxProps}
							/>
						}
						label={`${t(check.nameKey)}`}
						key={check.id}
						sx={{ mb: '0px' }}
						{...formControlLabelProps}
					/>
				))}
			</FormGroup>
			<FormHelperText>{helperText}</FormHelperText>
		</FormControl>
	)
}
type CheckListFieldProps = CustomCheckListFieldProps & TextFieldProps;
interface CustomCheckListFieldProps {
	checkList: CheckItemType[];
	formControlProps?: FormControlProps;
	formGroupProps?: FormGroupProps;
	formControlLabelProps?: FormControlLabelProps;
	checkboxProps?: CheckboxProps;
}
interface CheckState {
	id: string;
	nameKey: string;
	value: boolean;
}

export function MultipleSelectField(props: MultipleSelectFieldProps) {
	const { selectList, value, onChange, displayTitle, sx, style, SelectProps, inputProps, InputProps, error, helperText, FormHelperTextProps, ...other } = props;
	const { t, i18n } = useTranslation();

	const [selectItems, setSelectItems] = React.useState<string[]>([]);
	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSelectItems(typeof event.target.value === 'string' ? event.target.value.split(',').filter(x => x !== '') : event.target.value);
		onChange({
			...event, target: {
				...event.target,
				value: typeof event.target.value === 'object' ? (event.target.value as string[]).filter(x => x !== '').join(',') : event.target.value
			}
		});
	};

	React.useEffect(() => {
		if (selectList) {
			setSelectItems(String(value).split(',').filter(x => x !== ''));
		}
	}, [selectList])

	const noLegendSx = {
		'& .MuiOutlinedInput-root': {
			'& fieldset': {
				top: 0,
			},
			'& fieldset legend': {
				display: 'none',
			},
		}
	}

	return (
		<TextField
			hiddenLabel={!props.displayTitle}
			label={props.displayTitle}
			variant="outlined"
			size="small"
			margin="dense"
			select
			SelectProps={{
				multiple: true,
				renderValue: (selected) => {
					let tempList: string[] = [];
					for (let select of selected as string[]) {
						let temp = selectList.find(x => x.value == select);
						if (temp) tempList.push(t(temp.nameKey));
					}
					return tempList.join(', ');
				},
				MenuProps: { sx: { maxHeight: '300px', maxWidth: '100%' } }
				, ...SelectProps
			}}
			value={selectItems}
			onChange={handleChange}
			sx={!props.displayTitle ? { ...noLegendSx, ...sx } : { ...sx }}
			style={style}
			error={error}
			helperText={helperText}
			FormHelperTextProps={FormHelperTextProps}
			inputProps={{ ref: inputProps.ref }}
			InputProps={InputProps}
			{...other}
		>
			{selectList.map((item) => (
				<MenuItem key={item.value} value={item.value}>
					<Checkbox checked={selectItems.indexOf(item.value) > -1} />
					<Trans i18nKey={item.nameKey}>
						{item.value}
					</Trans>
				</MenuItem>
			))}
		</TextField>
	)
}
type MultipleSelectFieldProps = CustomMultipleSelectFieldProps & TextFieldProps;
interface CustomMultipleSelectFieldProps {
	selectList: MenuItemType[];
	displayTitle?: string;
}

/*
export function InternshipTimeTextField(props: TextFieldProps & { displayTitle?: string, errorMessage?: string }) {
	const { value, onChange, displayTitle, ...other } = props;

	const [inputText, setInputText] = React.useState('');

	return (<>
		<Typography sx={{
			fontSize: '16px',
			fontWeight: 'bold',
			color: theme => theme.palette.text.primary,
		}}>
			{props.displayTitle} {important}
		</Typography>
		<Box display='flex'>
			<NoLegendTextField
				variant="outlined"
				size="small"
				margin="dense"
				select
				error={!!props.errorMessage}
				helperText={props.errorMessage ?? '　'}
				SelectProps={{
					displayEmpty: true
				}}
				InputProps={{ style: { backgroundColor: '#ffffff' } }}
				sx={{ flex: 1 }}
				{...props}
			>
				<MenuItem disabled value={null}>
					<em><Trans i18nKey='customField.choose' /></em>
				</MenuItem>
				{internshipTimeList.map((item) => (
					<MenuItem key={item.value} value={item.value}>
						<Trans i18nKey={item.nameKey}>
							{item.value}
						</Trans>
					</MenuItem>
				))}
			</NoLegendTextField>
			{(value === "" || (!isNullOrUndefined(value) && internshipTimeList.findIndex(x => x.value == value) === -1)) &&
				<NoLegendTextField
					variant="outlined"
					size="small"
					margin="dense"
					value={inputText}
					onChange={onChange}
					InputProps={{ style: { backgroundColor: '#ffffff' } }}
					sx={{ marginLeft: '12px' }}
				/>
			}
		</Box>
	</>)
}
*/