﻿import * as React from 'react';
import { alpha, Box, BoxProps, Button, Paper, PaperProps, styled, Theme, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DataGrid, GridCellParams, GridColDef, GridRenderCellParams, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton, GridValueFormatterParams, GridValueGetterParams } from '@mui/x-data-grid';
import { zhTWGrid } from '../../data-grid-locale';
import { addDays, format, isPast } from 'date-fns';
import { renderCellExpand } from '../../../components/DataGrid/renderCellExpand';
import { useDispatchWithType, useUser } from '../../../store';
import { AppointmentData, useGetAppointmentDataQuery, UserCardInfoModel } from '../../../components/services/user';
import { AppointmentStatus, useChangeAppointmentStatusMutation } from '../../../components/services/chat';
import { setScrollTrigger, showModal } from '../../../store/rootReducer';
import { SubTitle } from '../../../components/responsive-components/Typography';
import { faCalendar, faClock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import enUS from 'date-fns/locale/en-US';
import zhTW from 'date-fns/locale/zh-TW';
import { SxProps } from '@mui/system';
import { UserCardDialog } from '../../../components/BussinessCard/UserCardDialog';
import { CardInfo } from '../../../components/class';
import { AppointmentDeclineModal } from '../../../components/Modal/AppointmentDeclineModal';
import { createTheme, ThemeProvider } from '@mui/material/styles';

export default function Appointment() {
    const theme = useTheme();
    const dispatch = useDispatchWithType();
    const { t } = useTranslation();

    const { data: appointmentData, isLoading, refetch } = useGetAppointmentDataQuery(null, { refetchOnMountOrArgChange: true });
    const [changeAppointment, { isLoading: isLoadingChangeAppointment }] = useChangeAppointmentStatusMutation();
    function handleAccept(data: AppointmentData) {
        changeAppointment({ actionId: data.actionId, chatId: data.chatId, isAccept: true }).unwrap().then((result) => {
            if (result.isSuccess) {
                refetch();
            }
        }).catch((error) => {
            console.log(error);
        });
    }

    //控制拒絕原因面板
    const [openReject, setOpenReject] = React.useState(false);
    const [currentRejectModalData, setCurrentRejectModalData] = React.useState<{ chatId: string, actionId: string }>(null);
    function handleReject(data: AppointmentData) {
        setCurrentRejectModalData({ chatId: data.chatId, actionId: data.actionId });
        setOpenReject(true);
        //dispatch(showModal({ modalType: "APPOINTMENT_DECLINE", modalProps: { chatId: data.chatId, actionId: data.actionId } }));
    }
    const handleCloseRejectModal = () => {
        setOpenReject(false);
    };
    const handleFinishRejectModal = () => {
        setOpenReject(false);
        refetch();
    };

    function handleOpenRoom(chatId: string) {
        if (chatId) {
            dispatch(showModal({
                modalType: "PRIVATE_VIDEO_CHAT",
                modalProps: { chatId: chatId }
            }));
        }
    }

    //開啟名片
    const [open, setOpen] = React.useState(false);
    const [currentCardData, setCurrentCardData] = React.useState<CardInfo>(null);
    function handleOpenCard(cardInfo: CardInfo) {
        setCurrentCardData(cardInfo);
        setOpen(true);
    }
    const handleClose = () => {
        setOpen(false);
    };

    return (<>
        <SubTitle
            icon={<FontAwesomeIcon icon={faClock} transform="shrink-8 down-3" mask={faCalendar} fixedWidth
                style={{ color: theme.palette.primary.main, height: '100%' }} />}
            text={t('manage.title.profile.appointment')}
        />
        <Box sx={{ "& .cell-blue": { color: "royalblue" }, "& .cell-green": { color: "green" }, "& .cell-red": { color: "red" } }}>
            <AppointmentList
                list={appointmentData?.data ? [...appointmentData.data].reverse() : []}
                loading={isLoading || isLoadingChangeAppointment || !appointmentData}
                handleAccept={handleAccept}
                handleReject={handleReject}
                handleOpenRoom={handleOpenRoom}
                handleOpenCard={handleOpenCard}
            />
        </Box>
        <UserCardDialog
            data={{ ...currentCardData } as UserCardInfoModel}
            open={open}
            onClose={handleClose}
        />
        <AppointmentDeclineModal
            {...currentRejectModalData}
            open={openReject}
            onClose={handleCloseRejectModal}
            onFinish={handleFinishRejectModal}
        />
    </>)
}

interface AppointmentListProps {
    list: AppointmentData[];
    handleAccept: (data: AppointmentData) => void;
    handleReject: (data: AppointmentData) => void;
    handleOpenRoom: (receiverId: string) => void;
    handleOpenCard: (cardInfo: CardInfo) => void;
    loading?: boolean;
}

const statusList = [
    { name: "manage.appointment.waited", type: 0, class: "cell-blue", haveBtn: true },
    { name: "manage.appointment.acccepted", type: 1, class: "cell-green", haveBtn: true },
    { name: "manage.appointment.rejected", type: 2, class: "cell-red", haveBtn: true },
    { name: "manage.appointment.expired", type: 3, class: "cell-red", haveBtn: false }
];
const ButtonBar: (props: BoxProps) => JSX.Element = styled(Box)(({ theme }) => ({
    position: "relative",
    marginBottom: theme.spacing(1),
    "& > .MuiButton-root": {
        color: theme.palette.text.primary,
        borderRadius: 0,
        "&.active": {
            color: theme.palette.primary.main,
            fontWeight: "bold",
            background: (theme) => alpha(theme.palette.primary.light, 1)
        }
    }
}));
const Indicator: (props: BoxProps) => JSX.Element = styled(Box)(({ theme }) => ({
    position: "absolute",
    height: "2px",
    bottom: 0,
    width: "100%",
    transition: theme.transitions.create(['all']),
    backgroundColor: theme.palette.primary.main
}));

export function AppointmentList(props: AppointmentListProps) {
    const { list, handleAccept, handleReject, handleOpenRoom, handleOpenCard, loading } = props;

    const user = useUser();
    const { t } = useTranslation();

    const [gridData, setGridData] = React.useState<GridData[]>([]);
    const [filterData, setFilterData] = React.useState<GridData[]>([]);
    const [filterType, setFilterType] = React.useState<number>(-1);

    React.useEffect(() => {
        if (user) {
            let datas: GridData[] = list.map((item) => {
                let isReceiver = (user.userId === item.receiver.userId) ? true : false;
                return ({
                    id: item.actionId,
                    isReceiver: isReceiver,
                    cardInfo: isReceiver ? item.sender : item.receiver,
                    status: item.status === AppointmentStatus.待回應 && isPast(addDays(new Date(item.appointedTime), 1)) ?
                        statusList[3].type : item.status,
                    dateTime: item.appointedTime,
                    message: item.message
                });
            });
            setGridData(datas);
            if (filterType === -1)
                setFilterData(datas);
            else
                setFilterData(datas.filter(row => row.status == filterType));
        }
        else {
            setGridData([]);
            setFilterData([]);
        }
    }, [list]);

    const containerRef = React.useRef<HTMLDivElement>();
    const [indicatorStyle, setIndicatorStyle] = React.useState<React.CSSProperties>({ left: 0, width: 0 });
    React.useEffect(() => {
        let rect = containerRef.current.firstElementChild.getBoundingClientRect();
        let containerRect = containerRef.current.getBoundingClientRect();
        setIndicatorStyle({ left: rect.left - containerRect.left, width: rect.width });
    }, [])
    function handleFilterButtonClick(event: React.MouseEvent<HTMLButtonElement>) {
        let element = (event.target as HTMLButtonElement);
        let rect = element.getBoundingClientRect();
        let containerRect = containerRef.current.getBoundingClientRect();
        setIndicatorStyle({ left: rect.left - containerRect.left, width: rect.width });

        let type: number = (element.value as any);
        setFilterType(type);
        if (type == -1)
            setFilterData(gridData);
        else
            setFilterData(gridData.filter(row => row.status == type));
    }

    const getClassName = (type: number) => filterType == type ? "active" : null;
    return (<>
        <ButtonBar ref={containerRef}>
            <Button value={-1} onClick={handleFilterButtonClick} className={getClassName(-1)}>{t('manage.appointment.all')}</Button>
            {statusList.filter(x => x.haveBtn).map((item) => (
                <Button key={item.type} value={item.type} onClick={handleFilterButtonClick} className={getClassName(item.type)}>
                    {t(item.name)}
                </Button>
            ))}
            <Indicator component="span" style={indicatorStyle} />
        </ButtonBar>
        <AppointmentDataGrid
            data={filterData}
            handleAccept={(id) => handleAccept(list.find(x => x.actionId === id))}
            handleReject={(id) => handleReject(list.find(x => x.actionId === id))}
            handleOpenRoom={(id) => handleOpenRoom(list.find(x => x.actionId === id)?.chatId)}
            handleOpenCard={(cardInfo) => handleOpenCard(cardInfo)}
            loading={loading || !user || !gridData}
        />
    </>)
}

interface GridData {
    id: string;
    isReceiver: boolean;
    cardInfo: CardInfo;
    status: number;
    dateTime: string;
    message: string;
}

interface AppointmentDataGridProps {
    data: GridData[];
    handleAccept: (id: string) => void;
    handleReject: (id: string) => void;
    handleOpenRoom: (id: string) => void;
    handleOpenCard: (cardInfo: CardInfo) => void;
    loading?: boolean;
}

function AppointmentDataGrid(props: AppointmentDataGridProps) {
    const { data, loading, handleAccept, handleReject, handleOpenRoom, handleOpenCard } = props;

    const { t, i18n } = useTranslation();

    function formati18n(date: Date, language) {
        let options = { locale: enUS };
        let dayOfWeek = "E"
        if (language == "ch") {
            options = { locale: zhTW };
            dayOfWeek = "EEEEE";
        }
        return format(date, `yyyy/MM/dd(${dayOfWeek}) HH:mm`, options);
    }

    const columns: GridColDef[] = [
        {
            field: 'name',
            headerName: '名字',//isReceiver ? t('manage.appointment.field.sender') : t('manage.appointment.field.receiver'),
            flex: 0.5,
            minWidth: 180,
            renderCell: ({ row: { cardInfo } }: GridRenderCellParams<string, GridData>) => (
                <a onClick={() => handleOpenCard(cardInfo)}
                    style={{ textDecoration: "underline", cursor: "pointer", lineHeight: "1.43" }}>
                    {cardInfo?.lastName} {cardInfo?.firstName}
                </a>
            )
        },
        {
            field: 'dateTime',
            headerName: t('manage.appointment.field.appointmentTime'),
            type: 'dateTime',
            width: 180,
            valueGetter: ({ value }: GridValueGetterParams<string>) => formati18n(new Date(value), i18n.language)
        },
        {
            field: 'status',
            headerName: t('manage.appointment.field.status'),
            width: 180,
            type: 'singleSelect',
            valueOptions: statusList.map(x => `${t(x.name)}`),
            valueFormatter: (params: GridValueFormatterParams) => `${t(statusList[Number(params.value)].name) || ''}`,
            renderCell: ({ id, row: { isReceiver }, value }: GridRenderCellParams<number, GridData>) => {
                if (isReceiver && value === statusList[0].type) {
                    return (<>
                        <Button
                            variant="contained"
                            color="secondary"
                            size="small"
                            onClick={() => handleReject(id.toString())}
                        >
                            {t('manage.appointment.button.reject')}
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            style={{ marginLeft: 16 }}
                            onClick={() => handleAccept(id.toString())}
                        >
                            {t('manage.appointment.button.acccept')}
                        </Button>
                    </>);
                }
                let className = statusList[value].class;
                switch (value) {
                    case statusList[0].type:
                        return <strong className={className}>{t('manage.appointment.state.waited')}</strong>;
                    case statusList[1].type:
                        return <strong className={className}>{isReceiver ? t('manage.appointment.state.hasAcccepted') : t('manage.appointment.state.beenAcccepted')}</strong>;
                    case statusList[2].type:
                        return <strong className={className}>{isReceiver ? t('manage.appointment.state.hasRejected') : t('manage.appointment.state.beenRejected')}</strong>;
                    case statusList[3].type:
                        return <strong className={className}>{t('manage.appointment.state.expired')}</strong>;
                    default:
                        return;
                }
            },
        },
        {
            field: 'message',
            headerName: "留言",//isReceiver ? t('manage.appointment.field.senderMessage') : t('manage.appointment.field.receiverMessage'),
            flex: 1,
            minWidth: 200,
            renderCell: renderCellExpand,
        },
        {
            field: 'action',
            headerName: '',
            width: 100,
            disableExport: true,
            renderCell: ({ id, row: { status } }: GridCellParams<any, GridData>) => {
                if (status === statusList[1].type) {
                    return (
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={() => handleOpenRoom(id.toString())}
                        >
                            {t('manage.appointment.button.enterRoom')}
                        </Button>
                    )
                }
            },
        }
    ];
    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                <GridToolbarExport csvOptions={{ utf8WithBom: true }} />
            </GridToolbarContainer>
        );
    }

    return (
        <ThemeProvider theme={(theme) => createTheme({ ...theme, palette: { ...theme.palette, background: { ...theme.palette.background, default: "#ffffff" } } })}>
            <DataGrid rows={data} columns={columns}
                pageSize={10}
                autoHeight
                getRowHeight={() => 'auto'}
                sx={{
                    '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: '8px' },
                    '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { py: '15px' },
                    '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': { py: '22px' },
                }}
                disableColumnSelector
                components={{
                    Toolbar: CustomToolbar
                }}
                localeText={i18n.language == "ch" ? zhTWGrid : null}
                loading={loading}
            />
        </ThemeProvider>
    )
}