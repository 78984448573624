﻿import { Avatar, AvatarGroup, Box, Button, Tooltip } from '@mui/material';
import * as React from 'react';
import { BoothDetailData, ManagerData, useGetBoothListQuery } from '../../../components/services/booth';
import { useTranslation } from 'react-i18next';
import { DataGrid, GridActionsCellItem, GridEnrichedColDef, GridRenderCellParams, GridRowParams, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton, GridValueFormatterParams, GridValueGetterParams } from '@mui/x-data-grid';
import { format } from 'date-fns';
import { zhTWGrid } from '../../data-grid-locale';
import { SubTitle } from '../../../components/responsive-components';
import { BoothInfoOverviewWithoutQuery } from '../../Booth/BoothInfoOverview';
import { Edit } from '@mui/icons-material';
import { Booth } from '../../../components/class';
import { useDispatchWithType } from '../../../store';
import { setScrollTrigger } from '../../../store/rootReducer';
import { useLazyGetPathQuery } from '../../../components/services/imageFile';
import { createTheme, ThemeProvider } from '@mui/material/styles';

export default function BoothList() {
    const { t } = useTranslation();

    //獲取攤位資料
    const { data: boothList, isLoading, refetch } = useGetBoothListQuery();

    //開啟攤位編輯頁
    const [open, setOpen] = React.useState(false);
    const [currentBooth, setCurrentBooth] = React.useState<Booth>();
    function handleOpenPage(boothId: string) {
        if (boothList?.data.length > 0) {
            setCurrentBooth(boothList.data.find(x => x.boothId === boothId));
            setOpen(true);
        }
    }
    const handleClose = () => {
        setOpen(false);
        refetch();
    };

    //載入完成滾動
    const dispatch = useDispatchWithType();
    React.useEffect(() => { dispatch(setScrollTrigger(true)) }, []);

    if (open) {
        return (<>
            <Button onClick={handleClose}>back</Button>
            <BoothInfoOverviewWithoutQuery booth={currentBooth} />
        </>)
    }
    else {
        return (<>
            <SubTitle
                icon='circle'
                text={t('manage.boothList.title')}
            />
            <BoothDataList
                dataList={boothList?.data.map(item => ({ ...item, id: item.boothId })) ?? []}
                loading={isLoading}
                handleOpenPage={handleOpenPage}
            />
        </>)
    }
}

interface GridDataRow extends BoothDetailData {
    id: string;
}
interface BoothDataListProps {
    dataList: GridDataRow[];
    handleOpenPage: (boothId: string) => void;
    loading?: boolean;
}

function BoothDataList(props: BoothDataListProps) {
    const { dataList, loading, handleOpenPage } = props;
    const { t, i18n } = useTranslation();

    const columns: GridEnrichedColDef<GridDataRow>[] = [
        {
            field: 'id',
            headerName: t('manage.boothList.options.id'),
            width: 150,
        },
        {
            field: 'name',
            headerName: t('manage.boothList.options.name'),
            flex: 0.8,
            minWidth: 150,
            valueGetter: ({ row: { chName } }: GridValueGetterParams<string, GridDataRow>) => chName,
        },
        {
            field: 'managers',
            headerName: t('manage.boothList.options.managers'),
            flex: 1,
            valueFormatter: ({ value: managers }: GridValueFormatterParams<ManagerData[]>) => managers.map(x => x.name).join(', '),
            renderCell: ({ value: managers }: GridRenderCellParams<ManagerData[]>) => (
                <AvatarGroup max={5}>
                    {managers.map((manager) => (
                        <Tooltip key={manager.userId} title={manager.name}>
                            <Avatar alt={manager.name} src={manager.logo} />
                        </Tooltip>
                    ))}
                </AvatarGroup>
            ),
        },
        {
            field: 'createTime',
            headerName: t('manage.boothList.options.createTime'),
            type: 'dateTime',
            width: 180,
            valueGetter: ({ value }: GridValueGetterParams<string>) => format(new Date(value), "yyyy/MM/dd HH:mm:ss"),
        },
        {
            field: 'actions',
            type: 'actions',
            width: 40,
            getActions: ({ row: { boothId } }: GridRowParams<GridDataRow>) => [
                <GridActionsCellItem icon={<Edit />} onClick={() => handleOpenPage(boothId)} label={t("manage.boothList.options.actions.edit")} showInMenu />,
            ]
        }
    ];

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                <GridToolbarExport csvOptions={{ utf8WithBom: true }} />
            </GridToolbarContainer>
        );
    }

    return (
        <ThemeProvider theme={(theme) => createTheme({ ...theme, palette: { ...theme.palette, background: { ...theme.palette.background, default: "#ffffff" } } })}>
            <DataGrid rows={dataList} columns={columns}
                pageSize={10}
                autoHeight
                disableColumnMenu
                disableColumnSelector
                components={{
                    Toolbar: CustomToolbar,
                }}
                localeText={i18n.language == "ch" ? zhTWGrid : null}
                loading={loading}
            />
        </ThemeProvider >
    )
}

function LazyAvatar(props: { userId?: string, boothId?: string }) {
    const [getPath, { avatarUrl }] = useLazyGetPathQuery({
        selectFromResult: ({ data }) => ({ avatarUrl: data?.data }),
    });
    React.useEffect(() => { getPath(props) }, [props]);
    return (
        <Avatar src={avatarUrl || ''} />
    );
}