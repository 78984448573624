﻿import { faBell, faCloud, faCommentDots } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Badge, Box, Fade, IconButton, List, ListItem, Paper, Popper, Typography, useMediaQuery, useTheme } from "@mui/material";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useSystemConnection } from "../../../store";
import { NoticeMessageContent } from "../../class";
import { SystemControlHub } from "../../react-signalr/systemControlHub";
import { useGetNotificationsQuery } from "../../services/chat";
import { ToastSystem } from "../Toast/ToastSystem";
import "./NotificationBell.css";
import { NotificationMessage } from "./NotificationMessage";

export function NotificationBell() {
    const { t, i18n } = useTranslation();

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('lg'));

    const [messages, setMessages] = React.useState<NoticeMessageContent[]>([]); //通知訊息
    const { data } = useGetNotificationsQuery();
    React.useLayoutEffect(() => { if (data) setMessages(data.data) }, [data]);

    const [read, setRead] = React.useState<boolean>(true);
    React.useLayoutEffect(() => {
        if (messages) {
            setRead(messages.every(x => x.hasBeenSeen));
        }
    }, [messages]);

    const systemConnection = useSystemConnection();
    const hubRef = React.useRef<SystemControlHub>();

    React.useEffect(() => {
        if (systemConnection != null) {
            hubRef.current = new SystemControlHub(systemConnection);

            //收到新通知訊息，更新資料
            hubRef.current.addHandler.ReceiveNoticeMessage((json) => {
                let message = JSON.parse(json) as NoticeMessageContent;
                setMessages((messages) => ([message, ...messages]));
            });
        }
        return (() => {
            if (hubRef.current) {
                hubRef.current.removeHandler.ReceiveNoticeMessage();
            }
        })
    }, [systemConnection]);

    const anchorRef = React.useRef<HTMLElement>();
    const [arrowRef, setArrowRef] = React.useState<HTMLElement | null>(null);
    const [open, setOpen] = React.useState<boolean>(false);

    const handlePopperClick = () => {
        if (open) {
            //更新前端
            setMessages((messages) => {
                let array = [...messages];
                return array.map(item => ({ ...item, hasBeenSeen: true }));
            });
            //更新後端
            if (hubRef.current) {
                hubRef.current.send.ReadNotice();
            }
        }
        setOpen((prev) => !prev);
    };

    return (
        <Box display="flex" width="46px" height="46px" justifyContent="center" alignItems="center" className="notification-bell">
            <Badge ref={anchorRef} variant="dot" invisible={read} sx={{
                "& > .MuiBadge-badge": {
                    inset: "14% 14% auto auto",
                    width: "9px", height: "9px",
                    borderRadius: "50%",
                    color: (theme) => theme.palette.background.paper,
                    background: (theme) => theme.palette.error.light
                }
            }}>
                <IconButton onClick={handlePopperClick} sx={{
                    p: 0,
                    fontSize: "16px",
                    backgroundColor: "rgba(0,0,0,0.1)", color: "primary.contrastText",
                    "&:hover": { backgroundColor: "rgba(0,0,0,0.2)", color: "rgba(255,255,255,0.75)" }
                }}>
                    <Box display="flex" width="30px" height="30px" justifyContent="center" alignItems="center" >
                        <FontAwesomeIcon icon={faBell} />
                    </Box>
                </IconButton>
            </Badge >
            <Popper open={open}
                anchorEl={anchorRef.current}
                disablePortal={true}
                placement='bottom'
                className="popper"
                transition
                modifiers={[
                    {
                        name: 'offset',
                        enabled: true,
                        options: {
                            offset: [0, 10]
                        },
                    },
                    {
                        name: 'preventOverflow',
                        enabled: true,
                        options: {
                            padding: 8,
                        },
                    },
                    {
                        name: 'arrow',
                        enabled: true,
                        options: {
                            element: arrowRef,
                        },
                    },
                ]}
            >
                {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={300}>
                        <div>
                            <span className="arrow" ref={setArrowRef} />
                            <Paper elevation={12} sx={{
                                width: "360px", maxWidth: matches ? "unset" : "calc(100vw - 16px)",
                                maxHeight: "calc(100vh - 100px)", overflowY: "auto"
                            }}>
                                <Box pt={1.5} px={2}>
                                    <Typography variant="h6" color="#3092FF" fontWeight="bold">{t('notification.bell.notification')}</Typography>
                                </Box>
                                {messages && messages.length > 0 ?
                                    <Box flex="1 1" sx={{ "& .MuiTypography-root": { wordBreak: "break-word", whiteSpace: "pre-wrap" } }}>
                                        <List>
                                            {messages.map((message, index) => (
                                                <NotificationMessage key={index.toString() + message.textCode} message={message} />
                                            ))}
                                        </List>
                                    </Box>
                                    :
                                    <Box display="flex" justifyContent="center" alignItems="center" color="primary.light" mb={3}>
                                        <Box pr={3} py={2}>
                                            <FontAwesomeIcon icon={faCommentDots} transform="shrink-7 down-2 right-2" mask={faCloud} color="inherit" size="3x" />
                                        </Box>
                                        <Typography component="span" color="text.secondary" fontWeight="bold">{t('notification.bell.empty')}</Typography>
                                    </Box>
                                }
                            </Paper>
                        </div>
                    </Fade>
                )}
            </Popper>
            <ToastSystem handleOpenNotice={() => setOpen(true)} disable={open} sx={{ zIndex: -1 }} />
        </Box >
    )
}