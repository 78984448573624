import { configureStore } from '@reduxjs/toolkit';
import { rootReducer } from "./rootReducer";
import { useDispatch, useSelector } from 'react-redux';
import { userApi } from '../components/services/user';
import { loginApi } from '../components/services/login';
import { inviteApi } from '../components/services/invite';
import { boothApi } from '../components/services/booth';
import { seminarApi } from '../components/services/seminar';
import { systemApi } from '../components/services/system';
import { chatApi } from '../components/services/chat';
import { articleApi } from '../components/services/article';
import { fileApi } from '../components/services/file';
import { liveStreamApi } from '../components/services/liveStream';
import { roomApi } from '../components/services/room';
import { imageFileApi } from '../components/services/imageFile';
import { questionApi } from '../components/services/question';
import { pointApi } from '../components/services/point';
import { dialogControlMiddleware } from './middleware/dialogControl';

export const store = configureStore({
    reducer: {
        rootReducer: rootReducer,
        [articleApi.reducerPath]: articleApi.reducer,
        [boothApi.reducerPath]: boothApi.reducer,
        [chatApi.reducerPath]: chatApi.reducer,
        [fileApi.reducerPath]: fileApi.reducer,
        [imageFileApi.reducerPath]: imageFileApi.reducer,
        [liveStreamApi.reducerPath]: liveStreamApi.reducer,
        [loginApi.reducerPath]: loginApi.reducer,
        [inviteApi.reducerPath]: inviteApi.reducer,
        [roomApi.reducerPath]: roomApi.reducer,
        [questionApi.reducerPath]: questionApi.reducer,
        [seminarApi.reducerPath]: seminarApi.reducer,
        [systemApi.reducerPath]: systemApi.reducer,
        [userApi.reducerPath]: userApi.reducer,
        [pointApi.reducerPath]: pointApi.reducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false,
    })/*.concat(logger)*/.concat(dialogControlMiddleware)//.concat(getCookiesMiddleware)
});

export type RootState = ReturnType<typeof store.getState>
export type DispatchType = typeof store.dispatch

//�`��hook
export const useUser = () => useSelector((state: RootState) => state.rootReducer.user);
export const useSystemConnection = () => useSelector((state: RootState) => state.rootReducer.systemConnection);
export const useDialogControl = () => useSelector((state: RootState) => state.rootReducer.dialogControl);
export const useBoothFieldNameList = () => useSelector((state: RootState) => state.rootReducer.boothFieldNameList);
export const useUserFieldNameList = () => useSelector((state: RootState) => state.rootReducer.userFieldNameList);
export const useModalControl = () => useSelector((state: RootState) => state.rootReducer.modalControl);
export const useScrollTrigger = () => useSelector((state: RootState) => state.rootReducer.scrollTrigger);
export const useWatchedStartAnounce = () => useSelector((state: RootState) => state.rootReducer.watchedStartAnounce);

export const useDispatchWithType = () => useDispatch<DispatchType>();