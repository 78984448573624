﻿import { Box, Collapse, Divider, IconButton, List, ListItem, Paper, Stack, Typography } from '@mui/material';
import { isSameDay } from 'date-fns';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { CSSTransition } from 'react-transition-group';
import { useSystemConnection, useUser } from '../../store';
import { ChatDialogInput, relativeDate, TimeData } from '../Chat/ChatDialog/ChatDialog';
import { ChannelMessageContent, PlainTextObject } from '../class';
import { ChannelMessage, SystemControlHub, TextCode } from '../react-signalr/systemControlHub';
import { useGetChannelMessagesQuery } from '../services/chat';
import { ChannelChatMessage, ChannelChatPinnedMessage } from './ChannelChatMessage';
import "./ChannelChatDialog.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbtack } from '@fortawesome/free-solid-svg-icons';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';

interface ChannelChatDialogProps {
    channelId: string;
    handleOpenCard: (userId: string) => void;
}
export default function ChannelChatDialog(props: ChannelChatDialogProps) {
    const { channelId, handleOpenCard } = props;
    const user = useUser();
    const { t, i18n } = useTranslation();

    const [currentChannelId, setCurrentChannel] = React.useState(channelId);
    const channelIdRef = React.useRef<string>(channelId);
    React.useEffect(() => { setCurrentChannel(channelId); channelIdRef.current = channelId }, [channelId]);
    const { data: result } = useGetChannelMessagesQuery(currentChannelId, { refetchOnMountOrArgChange: true });
    const [messages, setMessages] = React.useState<(ChannelMessageContent | TimeData)[]>([]);
    React.useEffect(() => {
        if (result && result.isSuccess) {
            setMessages(() => {
                let list: (ChannelMessageContent | TimeData)[] = [];
                result.data.forEach((item, index, array) => {
                    let isEarliestOnTheDay: boolean = false;
                    //比對前一個
                    if (index !== 0) {
                        if (!isSameDay(new Date(item.createTime), new Date(array[index - 1].createTime))) {
                            isEarliestOnTheDay = true;
                        }
                    } else { isEarliestOnTheDay = true; }
                    if (isEarliestOnTheDay) {
                        list.push({ timestamp: relativeDate(new Date(item.createTime), new Date(), i18n.language) });
                    }
                    list.push(item);
                });
                return list;
            });
        }
    }, [result]);

    const [pinnedMessages, setPinnedMessages] = React.useState<ChannelMessageContent[]>([]);
    React.useEffect(() => {
        let isMsg = (obj: ChannelMessageContent | TimeData): obj is ChannelMessageContent => {
            return "messageId" in obj;
        }
        setPinnedMessages(messages.filter(isMsg).filter(x => x.isPinned));
    }, [messages]);

    const systemConnection = useSystemConnection();
    const hubRef = React.useRef<SystemControlHub>();

    React.useEffect(() => {
        if (systemConnection != null) {
            hubRef.current = new SystemControlHub(systemConnection);
            hubRef.current.send.EnterChannel(currentChannelId);

            //收到新訊息，更新資料
            hubRef.current.addHandler.ReceiveChannelMessage((json) => {
                let message = JSON.parse(json) as ChannelMessageContent;
                setMessages((messages) => {
                    return [...messages, message];
                });
            });
            //收到訊息更新，更新資料
            hubRef.current.addHandler.UpdateChannelMessage((json) => {
                let message = JSON.parse(json) as ChannelMessageContent;
                setMessages((messages) => {
                    let array = [...messages];
                    let index = array.findIndex(x => (x as ChannelMessageContent).messageId === message.messageId);
                    if (index > -1)
                        array[index] = message;
                    return array;
                });
            });
            //收到換channel的指示，更新Channel
            hubRef.current.addHandler.OnChangeChannel((channelId) => {
                hubRef.current.send.LeaveChannel(channelIdRef.current);
                hubRef.current.send.EnterChannel(channelId);
                channelIdRef.current = channelId;
                setCurrentChannel(channelId);
            });
        }
        return (() => {
            if (hubRef.current) {
                hubRef.current.send.LeaveChannel(currentChannelId);
                hubRef.current.removeHandler.ReceiveChannelMessage();
            }
        })
    }, [systemConnection]);

    const [isOpenPinned, setIsOpenPinned] = React.useState(false);
    const openPinnedPanel = () => { setIsOpenPinned(true); }, closePinnedPanel = () => { setIsOpenPinned(false); };

    const msgContainerRef = React.useRef<HTMLDivElement>();
    const [first, setFirst] = React.useState<boolean>(true);
    React.useEffect(() => {
        if (messages.length > 0) {
            //第一次開啟
            if (first) {
                setFirst(false);
                scrollToBottom(msgContainerRef);
            }
            //收到自己傳送的新訊息，就滾到最下面
            else if ((messages[messages.length - 1] as ChannelMessageContent).senderId === user.userId) {
                scrollToBottom(msgContainerRef);
            }
            //收到其他人傳送的新訊息，提醒有新訊息(動態)
            else {
                //以後有時間再做
                scrollToBottom(msgContainerRef);
            }
        }
    }, [messages.length]);
    const scrollToBottom = (ref) => {
        //給些時間等messages更新
        setTimeout(() => {
            if (ref.current) {
                ref.current.scrollTop = ref.current.scrollHeight;
            }
        }, 200);
    };

    const [editValue, setEditValue] = React.useState<string>("");
    //文字訊息編輯
    const handleEditText = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEditValue(event.target.value);
    };

    //文字訊息送出
    const handleSubmit = () => {
        if (result.isSuccess) {
            let newMessage: ChannelMessage = {
                channelId: currentChannelId, textCode: TextCode.純文字及連結, textObject: { content: editValue }
            };
            if (hubRef.current)
                hubRef.current.send.SendChannelMessage(newMessage);
            else
                console.error("傳送失敗！請重新整理");
            setEditValue("");
        }
    };

    function handleOnPin(id: string) {
        if (hubRef.current)
            hubRef.current.send.PinChannelMessage(currentChannelId, id);
    }
    function handleOnUnpin(id: string) {
        if (hubRef.current)
            hubRef.current.send.UnpinChannelMessage(currentChannelId, id);
    }
    function handleOnDelete(id: string) {
        if (hubRef.current)
            hubRef.current.send.DeleteChannelMessage(currentChannelId, id);
    }

    function getLastMessage(lastMessage: ChannelMessageContent): string {
        if (lastMessage == null)
            return "";

        switch (lastMessage.textCode) {
            case TextCode.純文字及連結:
                return (lastMessage.textObject as PlainTextObject).content;
            case TextCode.貼圖:
                return "貼圖";
            default:
                return "檔案";
        }
    }

    if (!channelId)
        return null;
    return (
        <Paper variant="outlined" square sx={{ position: "relative", flex: "0 1 100%", height: "300px", overflowX: "hidden" }} className="channel-chat">
            <CSSTransition
                in={!isOpenPinned}
                timeout={350}
                classNames="dialog"
            >
                <Stack height="100%">
                    { /*置頂訊息顯示區 簡短版*/}
                    <Collapse in={pinnedMessages.length > 0}>
                        <Box display="inline-flex" sx={{ width: "100%", background: "#EDEDED", borderBottom: "1px solid #e3e3e3", pl: 2, cursor: "pointer" }} onClick={openPinnedPanel}>
                            <Typography noWrap sx={{ flex: "1 1 100%", overflow: "hidden", textOverflow: "ellipsis", mr: 1, transform: "translateY(2px)" }}>
                                <Box component="span" display="inline-block" mr={1} sx={{ transform: "rotateZ(45deg)", color: "error.light" }}>
                                    <FontAwesomeIcon icon={faThumbtack} />
                                </Box>
                                {getLastMessage(pinnedMessages[pinnedMessages.length - 1])}
                            </Typography>
                            <Typography component="span" color="text.secondary"><ChevronRight color="inherit" /></Typography>
                        </Box>
                    </Collapse>
                    { /*訊息顯示區*/}
                    <Box ref={msgContainerRef} flex="1 1" sx={{ "& .MuiTypography-root": { wordBreak: "break-word", whiteSpace: "pre-wrap" }, overflowY: "auto" }}>
                        <List>
                            {messages.map((message, index, array) => {
                                //時間標記
                                if ((message as TimeData).timestamp)
                                    return (<ListItem key={(message as TimeData).timestamp} disablePadding sx={{ justifyContent: "center" }}>
                                        <Typography variant="caption" color="rgba(148, 205, 245, 0.69)" >{(message as TimeData).timestamp}</Typography></ListItem>)
                                //一般訊息
                                else
                                    return (<ChannelChatMessage key={(message as ChannelMessageContent).messageId} message={(message as ChannelMessageContent)}
                                        onPin={handleOnPin} onDelete={handleOnDelete} onClickLogo={handleOpenCard} onClickName={handleOpenCard} />)
                            })}
                        </List>
                    </Box>
                    { /*輸入區*/}
                    <Box display="flex" px={1} py={1} sx={{ background: "#EFEFEF" }}>
                        <ChatDialogInput editable={result && result.isSuccess} editValue={editValue} onEditValueChange={handleEditText} onSend={handleSubmit}
                            sx={{ width: "100%", borderRadius: "20px", background: (theme) => theme.palette.background.paper, paddingLeft: 2 }}
                        />
                    </Box>
                </Stack>
            </CSSTransition>
            <CSSTransition
                in={isOpenPinned}
                timeout={350}
                classNames="pinned-panel"
                unmountOnExit
            >
                <Stack position="absolute" sx={{
                    top: 0,
                    right: "-100%",
                    bottom: 0,
                    left: "100%", background: "#EDEDED"
                }}>
                    <Box display="inline-block" color="text.secondary" sx={{ cursor: "pointer", background: "lightgrey", borderBottom: "1px solid #e3e3e3" }} onClick={closePinnedPanel}>
                        <ChevronLeft color="inherit" />
                        <Typography component="span" color="text.secondary" sx={{ transform: "translateY(2px)" }}>{t("onlineSeminar.return")}</Typography>
                    </Box>
                    <Box flex="1 1" sx={{ "& .MuiTypography-root": { wordBreak: "break-word", whiteSpace: "pre-wrap" }, overflowY: "auto" }}>
                        <List disablePadding>
                            {pinnedMessages.map((message) => (
                                <ChannelChatPinnedMessage key={message.messageId} message={message} onUnpin={handleOnUnpin} />
                            ))}
                        </List>
                    </Box>
                </Stack>
            </CSSTransition>
        </Paper>
    )
}