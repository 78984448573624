﻿import { DoubleArrow, Email, PersonAdd, PhoneEnabled, ReportGmailerrorred, Send } from "@mui/icons-material";
import { Avatar, Box, ButtonProps, Card, CardActions, CardContent, CardProps, Divider, IconButton, Skeleton, styled, SxProps, Typography, TypographyProps, useMediaQuery, useTheme } from "@mui/material";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { isNullOrUndefined } from "util";
import { CardInfo, CardType, ItemType } from "../class";
import { Button } from "../responsive-components";
import UserOnlineBadge, { UserOnlineBadgeProps } from "../responsive-components/UserOnlineBadge";
import { faPenNib } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatchWithType, useSystemConnection, useUser, useUserFieldNameList } from "../../store";
import { uniqueUserFieldList, userCustomFieldList } from "../CustomField";
import { showModal } from "../../store/rootReducer";
import { SystemControlHub } from "../react-signalr/systemControlHub";
import { logEvent } from "../Analytics";
import { academyDictionary, gradeDictionary } from "../selectOptions";
import { toRecord } from "../Admin/Utils";

type cardOption = { nameKey: string; styleKey: string; };
const cardStyleList: cardOption[] = [
	{ nameKey: "user.card.offCampus", styleKey: "unknown" },
	{ nameKey: "user.card.onCampus", styleKey: "student" },
	{ nameKey: "user.card.exhibitors", styleKey: "nonStudent" },
	{ nameKey: "user.card.organizer", styleKey: "admin" },
];
export interface BussinessCardProps<T extends CardInfo> {
	data: T;
	isOnline?: boolean;
	simpleMode?: boolean; //是否為小卡模式
	editable?: boolean;
	fixed?: boolean; //固定顯示方式，不做簡介rwd
	withBorder?: boolean; //是否有邊
	className?: string;

	onClickEdit?: (data: T) => void; //非小卡模式時，放入編輯按鈕並觸發此函式
	onClickShowMore?: (data: T) => void;
	onClickExchange?: (data: T) => void;
	onClickSendMessage?: (data: T) => void;
	onClickReport?: (data: T) => void;

	disableShowMore?: boolean; //小卡模式時，不顯示「更多」按鈕
	disableExchange?: boolean; //非小卡模式時，不顯示「交換名片」按鈕
	disableSendMessage?: boolean; //非小卡模式時，不顯示「發送訊息」按鈕
	disableReport?: boolean; //非小卡模式時，不顯示「檢舉」按鈕
}
const CardAvatar = styled(Avatar)(({ theme }) => ({
	width: "92px",
	height: "92px"
}));

export function BussinessCard<T extends CardInfo>(props: BussinessCardProps<T>) {
	const { data, isOnline, simpleMode, editable, fixed, withBorder, className, onClickEdit, onClickShowMore, onClickExchange, onClickSendMessage, onClickReport, disableShowMore, disableExchange, disableSendMessage, disableReport } = props;
	const { t, i18n } = useTranslation();

	const user = useUser();
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('md'));
	const dispatch = useDispatchWithType();
	const systemConnection = useSystemConnection();
	const hubRef = React.useRef<SystemControlHub>();
	React.useEffect(() => {
		if (systemConnection != null) {
			hubRef.current = new SystemControlHub(systemConnection);
		}
	}, [systemConnection]);

	//初始化
	const [cardData, setCardData] = React.useState<cardOption>();
	React.useEffect(() => {
		if (data)
			setCardData(cardStyleList[data.type]);
	}, [data]);


	//按鈕功能
	function handleClickEdit() {
		if (data?.userId) {
			if (onClickEdit) onClickEdit(data);
		}
		else console.log(`userId is null`);
	}
	function handleClickShowMore() {
		if (data?.userId) {
			if (onClickShowMore) onClickShowMore(data);
		}
		else console.log(`userId is null`);
	}
	function handleClickExchange() {
		if (data?.userId) {
			if (onClickExchange) onClickExchange(data);
			dispatch(showModal({ modalType: "EXCHANGE_CARD", modalProps: { receiverId: data.userId } }));
			logEvent('ExchangeCard', `BussinessCard`, `${data.userId}`);
		}
		else console.log(`userId is null`);
	}
	function handleClickSendMessage() {
		if (data?.userId) {
			if (onClickSendMessage) onClickSendMessage(data);
			if (hubRef.current)
				hubRef.current.invoke.OpenChatDialog(data.userId);
			logEvent('Chat', `BussinessCard`, `${data.userId}`);
		}
		else console.log(`userId is null`);
	}
	function handleClickReport() {
		if (data?.userId) {
			if (onClickReport) onClickReport(data);
			dispatch(showModal({
				modalType: "REPORT", modalProps: {
					jsonData: JSON.stringify(data)
				}
			}));
			logEvent('Report', `BussinessCard`, `${data.userId}`);
		}
		else console.log(`userId is null`);
	}

	//客製化欄位
	const userFieldNameList = useUserFieldNameList();
	let fieldList = userFieldNameList;
	if (data) fieldList = fieldList.filter(x => x.cardTypeList.includes(data.type));
	//fieldList = fieldList.filter(x => !config.editFieldExcluded.user.includes(x.fieldId)); //應該都會顯示吧? 如有屏蔽需求，可以去翻 Manage/CustomField

	let index = 0;
	if (i18n.language === 'en') index = 1;
	let studentId = fieldList?.find(x => x.fieldId === 'studentId')?.fieldName[index];
	let ability = fieldList?.find(x => x.fieldId === 'ability')?.fieldName[index];
	let certificate = fieldList?.find(x => x.fieldId === 'certificate')?.fieldName[index];
	let experience = fieldList?.find(x => x.fieldId === 'experience')?.fieldName[index];
	let paragraph = fieldList?.find(x => x.fieldId === 'paragraph')?.fieldName[index];

	function userFieldValueToType(fieldId: string, value: string) {
		let fieldValue = null;
		let userFieldName = userFieldNameList.find(x => x.fieldId === fieldId);
		if (userFieldName) {
			switch (userFieldName.fieldType) {
				case ItemType.String:
					fieldValue = String(value);
					break;
				case ItemType.Int:
					fieldValue = Number(value);
					break;
				case ItemType.Bool:
					fieldValue = Boolean(value);
					break;
				default:
					fieldValue = '';
			}
		}
		return fieldValue;
	}
	//targetList
	const targetListIndex = data.type === CardType.student ? data.infoFields.findIndex(x => x.fieldId === "targetList") : -1;
	const targetListElement = targetListIndex != -1 ? userCustomFieldList.find(x => x.id === "targetList") : null;

	//優化時間用
	const getFieldValue = (fieldId: string) => data.infoFields?.find(x => x.fieldId === fieldId)?.value;
	let academy = getFieldValue('academy');
	let grade = getFieldValue('grade');

	return (
		<Box className={className} sx={withBorder && {
			border: "2px solid black", borderRadius: "6px",
			boxShadow: "0px 3px 6px rgba(0, 0, 0, 16%)",
			borderColor: cardData && theme.palette.text.card[cardData.styleKey]
		}}>
			{/*身分*/}
			{cardData &&
				<Box
					display="flex"
					bgcolor={theme.palette.background.paper}
					color={theme.palette.text.card[cardData.styleKey]}
					px={2} py={1}
					sx={{ borderTopLeftRadius: "inherit", borderTopRightRadius: "inherit" }}
				>
					<Typography variant="subtitle1" fontWeight="bold">{t(cardData.nameKey)}</Typography>
					{/*編輯 or 檢舉*/}
					<Box display="flex" flex="1" justifyContent="flex-end" alignItems="flex-start" mr={-1}>
						{editable && data && user && (data.userId === user.userId) ?
							<Button variant="text" onClick={handleClickEdit}>{t('user.card.edit')}</Button>
							:
							!simpleMode && !disableReport && data && user && (data.userId !== user.userId) ?
								<IconButton onClick={handleClickReport}><ReportGmailerrorred /></IconButton>
								: null
						}
					</Box>
				</Box>
			}
			<Box bgcolor={theme.palette.background.paper} sx={{ borderBottomLeftRadius: "inherit", borderBottomRightRadius: "inherit" }}>

				<Box display="grid" gridTemplateColumns={(!fixed && (isMobile || simpleMode)) ? "1fr" : "100px 1fr"} gap={1} px={2}>
					{/*頭像*/}
					<Box margin={simpleMode && "auto"}>
						<UserOnlineBadge badgeWidth="18px" badgeHeight="18px" isOnline={isOnline} ripple>
							{data ?
								<CardAvatar src={data.logoUrl} /> :
								<Skeleton variant="circular">
									<CardAvatar />
								</Skeleton>
							}
						</UserOnlineBadge>
					</Box>
					{/*簡介*/}
					<Box>
						{data ? <>
							<Typography fontWeight="bold">{data.lastName} {data.firstName}</Typography>
							{data.type === CardType.student &&
								<Typography variant="body2" fontWeight="bold">
									{academy ? t(academyDictionary[academy].nameKey) : ""}
									{academy && grade && " / "}
									{grade ? t(gradeDictionary[grade].nameKey) : ""}
								</Typography>
							}
							{data.type !== CardType.student &&
								<Typography variant="body2" fontWeight="bold">
									{getFieldValue('organization')}
									{getFieldValue('organization') && getFieldValue('jobTitle') && " / "}
									{getFieldValue('jobTitle')}
								</Typography>
							}
							<br />
							<Box display="grid" gridTemplateColumns="20px 1fr" columnGap={1}>
								<Box component="span" color="primary.main">
									<PhoneEnabled fontSize='small' color="inherit" />
								</Box>
								<Typography variant="body2">{data.phone || t("未提供")}
								</Typography>

								<Box component="span" color="primary.main">
									<Email fontSize='small' color="inherit" />
								</Box>
								<Typography variant="body2">{data.email || t("未提供")}
								</Typography>
							</Box>

							{(simpleMode && !disableShowMore) && (targetListElement && data.infoFields[targetListIndex].value !== null) &&
								<Box pt={1.5}>
									{targetListElement.displayComponent({
										//displayValue: userData.infoFields[targetListIndex].value
										displayValue: userFieldValueToType(
											data.infoFields[targetListIndex].fieldId,
											data.infoFields[targetListIndex].value
										)
									})}
								</Box>
							}
						</> :
							<Skeleton variant="text" />
						}
					</Box>
				</Box>
				{/*更多按鈕*/}
				{simpleMode && !disableShowMore &&
					<Box px={2} display="flex" justifyContent="flex-end">
						<Button variant="text" onClick={handleClickShowMore}>
							{`${t('user.card.more')} >>`}
						</Button>
					</Box>
				}
				<Box height="20px" />
				{/*名片詳述*/}
				{!simpleMode && data &&
					<Box px={2}>
						<Divider style={{ marginInline: "-10px" }} />
						<Box height="16px" />

						{(targetListElement && data.infoFields[targetListIndex].value !== null) &&
							<Box display="flex" flexWrap="wrap" pb={2}>
								{targetListElement.displayComponent({
									//displayValue: userData.infoFields[targetListIndex].value
									displayValue: userFieldValueToType(
										data.infoFields[targetListIndex].fieldId,
										data.infoFields[targetListIndex].value
									)
								})}
							</Box>
						}

						<Box display="grid" gridTemplateColumns="80px 1fr" rowGap={1}>
							<Typography variant="body2" fontWeight="bold" gutterBottom>{ability}</Typography>
							<Typography variant="body2" gutterBottom>{getFieldValue('ability') ?? ""}</Typography>
							<Typography variant="body2" fontWeight="bold" gutterBottom>{certificate}</Typography>
							<Typography variant="body2" gutterBottom>{getFieldValue('certificate') ?? ""}</Typography>
							<Typography variant="body2" fontWeight="bold" gutterBottom>{experience}</Typography>
							<Typography variant="body2" gutterBottom>{getFieldValue('experience') ?? ""}</Typography>
							<Typography variant="body2" fontWeight="bold" >{paragraph}</Typography>
							<Typography variant="body2">{getFieldValue('paragraph') ?? ""}</Typography>
						</Box>
						<Box display="flex" justifyContent="flex-end">
							{!disableExchange && user && (data.userId !== user.userId) && !user.saveCardIds.includes(data?.userId) &&
								<Button
									variant="contained" startIcon={<PersonAdd />}
									onClick={handleClickExchange}
								>
									{t('user.card.exchange')}
								</Button>
							}
							{!disableSendMessage && user && (data.userId !== user.userId) &&
								<Button
									variant="contained" startIcon={<Send />} sx={{ ml: 1 }}
									onClick={handleClickSendMessage}
								>
									{t('user.card.message')}
								</Button>
							}
						</Box>
						<Box height="16px" />
					</Box>
				}
			</Box>
		</Box>
	);
}