﻿import * as React from 'react';
import { Agenda, Seminar } from '../../components/class';
import { Trans, useTranslation } from 'react-i18next';
import { Button } from '../../components/responsive-components';
import { Box, ButtonBase, ButtonProps, Card, CardContent, Divider, Fade, styled, Typography } from '@mui/material';
import { Article, CircleOutlined, Close, Redeem } from '@mui/icons-material';
import { CountDownTimer } from '../../components/Admin/Utils';
import Linkify from 'react-linkify';

const StyledButton: (props: ButtonProps) => JSX.Element = styled(Button)(({ theme }) => ({
    minWidth: '96px',
    color: '#FFFFFF',
    backgroundColor: '#12C735',
    '&:hover': {
        color: '#E6E6E6',
        backgroundColor: '#0EA02A',
    },
    '&:active': {
        backgroundColor: '#11DB38',
    },
}));
export default function AgendaCard(props: AgendaCardProps) {
    const { isManage, matches, seminarId, seminarChName, seminarEnName, data, showDate, onEdit, onDelete } = props;
    const { t, i18n } = useTranslation();

    return (
        <Card style={{ display: 'flex', flexDirection: 'row', position: 'relative' }}>
            <CardContent style={{ display: 'flex', flexDirection: 'column', minWidth: '80px', maxHeight: '200px' }}>
                <Typography fontWeight='bold' variant={matches ? 'subtitle2' : 'subtitle1'}>
                    {new Date(data.startTime).toLocaleString(
                        i18n.language == "ch" ? "zh-TW" : 'en-US',
                        { hour12: false, hour: '2-digit', minute: '2-digit' }
                    )}
                </Typography>
                <Divider flexItem orientation='vertical' variant='middle' style={{ flex: 1, margin: 'auto' }} />
                <Typography fontWeight='bold' variant={matches ? 'subtitle2' : 'subtitle1'}>
                    {new Date(data.endTime).toLocaleString(
                        i18n.language == "ch" ? "zh-TW" : 'en-US',
                        { hour12: false, hour: '2-digit', minute: '2-digit' }
                    )}
                </Typography>
            </CardContent>
            <Divider flexItem orientation='vertical' />
            <CardContent style={{ flex: 1, position: 'relative' }}>
                <Typography fontWeight='bold' style={{ fontSize: matches ? '12px' : '14px', display: 'flex', flexDirection: matches ? 'column' : 'row' }}>
                    {showDate &&
                        <span style={{ marginRight: '12px' }}>
                            {new Date(data.startTime).toLocaleString(
                                i18n.language == "ch" ? "zh-TW" : 'en-US',
                                { year: "numeric", month: '2-digit', day: '2-digit', weekday: 'narrow' }
                            )}
                        </span>
                    }
                    <span style={{ color: '#91CFFF' }}>
                        {i18n.language == "ch" ? seminarChName : seminarEnName}
                    </span>
                </Typography>
                <Typography fontWeight='bold' style={{ fontSize: matches ? '12px' : '16px' }}>
                    {i18n.language == "ch" ? data.boothChName : data.boothEnName}
                </Typography>
                <Typography fontWeight='bold' style={{ fontSize: matches ? '16px' : '22px' }}>
                    {data.title}
                </Typography>
                <Box style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end', marginTop: '12px', whiteSpace: "pre-wrap" }}>
                    {(data.eventContent && data.eventContent != '') &&
                        <Box sx={{ marginRight: "auto" }}>
                            {(data.eventContent && data.eventContent != '') &&
                                <Box id='eventContent' display="flex">
                                    <Box color="primary.main" mr={1}>
                                        <Redeem sx={{ color: '#FF51FF', marginRight: '6px' }} />
                                    </Box>
                                    <Box>
                                        <Typography fontWeight='bold'>
                                            {t('agenda.card.eventContent')}
                                        </Typography>
                                        <Linkify componentDecorator={(decoratedHref: string, decoratedText: string, key: number) => (
                                            <a target='_blank' style={{ color: '#007bff' }} href={decoratedHref} key={key}>
                                                {decoratedText}
                                            </a>
                                        )}>
                                            <Typography>
                                                {data.eventContent}
                                            </Typography>
                                        </Linkify>
                                    </Box>
                                </Box>
                            }
                        </Box>
                    }
                </Box>
                {isManage &&
                    <Box style={{
                        position: 'absolute', right: 0, top: 0, padding: '8px 16px',
                        display: 'flex', flexDirection: 'column'
                    }}>
                        {onEdit &&
                            <Button onClick={() => onEdit(data)}>
                                {t('agenda.card.edit')}
                            </Button>
                        }
                        {onDelete &&
                            <Button
                                onClick={() => onDelete(data, seminarId)}
                                sx={{
                                    color: 'gray',
                                    ':hover': {
                                        backgroundColor: '#70707010',
                                    }
                                }}
                            >
                                {t('agenda.card.delete')}
                            </Button>
                        }
                    </Box>
                }
            </CardContent>
        </Card>
    )
}
interface AgendaCardProps {
    isManage?: boolean;
    matches?: boolean;
    seminarId: string;
    seminarChName: string;
    seminarEnName: string;
    data: Agenda;
    showDate?: boolean;
    onEdit?: (value: Agenda) => void;
    onDelete?: (value: Agenda, seminarId: string) => void;
}