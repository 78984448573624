﻿import * as React from 'react';
import { SubTitle } from '../../components/responsive-components/Typography';
import { Trans, useTranslation } from 'react-i18next';
import { Dialog, DialogContent, styled, Typography, useTheme } from "@mui/material";
import { useProgressiveImage } from '../../Posters/LazyBackground';
import loading from '../../Posters/images/loading.jpg'
import { ConfirmButton, TextField } from '../../components/responsive-components';
import { RedeemSharp } from '@mui/icons-material';
import { useRedeemMutation } from '../../components/services/point';

const BinaryBackground = styled("div")(({ theme }) => ({
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "2rem 1rem",
    fontSize: "10rem",
    marginTop: "1rem",
    overflow: "hidden",
    textAlign: "center",
    "&:before": {
        content: "''",
        position: "absolute",
        inset: 0,
        backgroundColor: theme.palette.background.default,
        zIndex: -1,
    },
    "&:after": {
        content: `'11001110000001000100101100111110 00010010010010000010001100011100 01011011110111001010110110110101 11111010010110100110011000011101 00101110000111111010110111000111 11110001011101011111001010000101'`,
        position: "absolute",
        inset: 0,
        display: "flex",
        flexWrap: "wrap",
        wordBreak: "break-all",
        fontSize: "2.5rem",
        color: "#e9ecef",
        zIndex: -1,
    },
}));

export default function Redeem() {
    const { t } = useTranslation();

    const [redeem] = useRedeemMutation();
    const [text, setText] = React.useState("");
    const [response, setResponse] = React.useState<React.ReactNode>("");
    const [open, setOpen] = React.useState(false);
    function handleRedeem() {
        redeem(text).unwrap()
            .then(result => {
                if (result.isSuccess) {
                    setResponse(<><p><b>兌換成功✨</b></p><p>🎁你獲得了3顆金幣🎁</p></>);
                }
                else {
                    setResponse(<><p><b>兌換失敗</b></p><p>{result.message}</p></>);
                }
                setOpen(true);
            }).catch((error) => {
                //後端報錯 ex:500、404
                console.error("發生錯誤", error);
                setResponse("Unconnect");
                setOpen(true);
            });
    }

    return (
        <div>
            <BinaryBackground>
                <RedeemSharp style={{ color: "slategrey", fontSize: "inherit" }} />
                <div className="d-flex flex-column justify-content-end" style={{ height: 160 }}>
                    <h6>{t('manage.redeem.question')}</h6>
                    <TextField
                        style={{ width: 300, maxWidth: "calc(100vw - 240px)", margin: "0px 8px", justifyContent: "flex-end" }}
                        placeholder={t('manage.redeem.placeholder')} value={text} onChange={(e) => setText(e.target.value)}
                    >
                    </TextField>
                    <ConfirmButton style={{ width: 300, maxWidth: "calc(100vw - 240px)", margin: "16px 8px" }} onClick={handleRedeem}>
                        {t('manage.redeem.submit')}
                    </ConfirmButton>
                </div>
            </BinaryBackground>
            <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogContent>
                    <Typography variant="body1" align="center" paragraph>{response}</Typography>
                </DialogContent>
            </Dialog>
        </div>
    )
}