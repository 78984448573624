﻿import { Box, Container, Toolbar, useMediaQuery, useTheme } from '@mui/material';
import * as React from 'react';
import { PageTitleBarWithoutSticky } from '../../components/Layout/PageTitleBar';
import { useUser } from '../../store';
import SeminarPointsManage from './SeminarPointsManage';
import BoothPointsManage from './BoothPointsManage';
import GamePointsManage from './GamePointsManage';
import { LoyaltyCardData, useGetLoyaltyCardQuery } from '../../components/services/user';
import Redeem from './Redeem';
import { SubTitle } from '../../components/responsive-components';
import { useTranslation } from 'react-i18next';
import HeroRewardList from './HeroRewardList';

export default function PointList() {
    const { t } = useTranslation();

    const { data: card } = useGetLoyaltyCardQuery(null, {
        refetchOnMountOrArgChange: true
    });

    return (<>
        <Box className="manage.title.myPoints.seminarPointsManage" style={{ minHeight: 300 }}>
            <SubTitle
                icon='circle'
                text={t('manage.title.myPoints.seminarPointsManage')}
            />
            <SeminarPointsManage data={card?.data} />
            <Toolbar />
        </Box>      

        <Box className="manage.title.myPoints.boothPointsManage" style={{ minHeight: 300 }}>
            <SubTitle
                icon='circle'
                text={t('manage.title.myPoints.boothPointsManage')}
            />
            <BoothPointsManage data={card?.data} />
            <Toolbar />
        </Box>

        <Box className="manage.title.myPoints.gamePointsManage" style={{ minHeight: 300 }}>
            <SubTitle
                icon='circle'
                text={t('manage.title.myPoints.gamePointsManage')}
            />
            <GamePointsManage data={card?.data} />
            <Toolbar />
        </Box>

        <Box className="manage.title.myPoints.redeem" style={{ minHeight: 400 }}>
            <SubTitle
                icon='circle'
                text={t('manage.title.myPoints.redeem')}
            />
            <Redeem />
        </Box>

        <Box className="manage.title.myPoints.heroMedalHistory">
            <HeroRewardList />
        </Box>
    </>)
}