﻿import { KeyboardArrowUp } from "@mui/icons-material";
import { Fab, useMediaQuery, useTheme } from "@mui/material";
import * as React from "react";
import { useLocation } from "react-router-dom";
import { animateScroll, scroller } from 'react-scroll'
import scrollProps from '../ManageBoard/scrollProps';

export function ScrollToTop(props: { showOnMobile?: boolean }) {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

	/*const [scrollPosition, setScrollPosition] = React.useState(0);
	const handleScroll = () => {
		const position = window.pageYOffset;
		setScrollPosition(position);
	};
	React.useEffect(() => {
		window.addEventListener("scroll", handleScroll);

		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);*/

	const pathName = useLocation().pathname;
	function scrollToTop() {
		let matches = ['/manage'];
		let path = pathName.toLowerCase();
		if (matches.some(match => path.match(match))) {
			setTimeout(() => {
				scroller.scrollTo('backStagePanel', scrollProps);
			}, 100);
		}
		else {
			animateScroll.scrollToTop(scrollProps);
        }
	}

	if (props.showOnMobile || !isMobile) {
		return (
			<Fab onClick={() => scrollToTop()} sx={{ position: 'fixed', right: '1rem', bottom: '1rem', backgroundColor: 'white' }}>
				<KeyboardArrowUp sx={{ color: theme => theme.palette.grey[600], fontSize: '48px' }} />
			</Fab>
		)
	}
	else return null;
}