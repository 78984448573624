﻿import * as React from 'react';
import { Box, Button, IconButton, styled, Typography, useMediaQuery } from '@mui/material';
import { DeleteForever, Edit } from '@mui/icons-material';
import { Announce } from '../../components/class';
import { Trans, useTranslation } from 'react-i18next';
import { EditDialog } from './StartAnnounceDialog';
import { Card, CardActions, CardContent } from '../responsive-components';
import { InfoCardState } from '../responsive-components/Card';
import { logEvent } from '../Analytics';

const FloatButton = styled(IconButton)({
    margin: "8px 8px 0 0",
    '&:focus': {
        outline: 'none',
    }
});

export default function StartAnnounce(props: announceItemProps) {
    const { data, isManage, deleteAnnouce, saveAnnouce } = props;
    const matches = useMediaQuery('(min-width:768px)');
    const { t } = useTranslation();

    const state = data.isEnd ? InfoCardState.overdue
        : (data.link != '' ? InfoCardState.haveLink : InfoCardState.noLink);

    const linkText = [t('start_announce.card.link'), t('start_announce.card.nolink'), t('start_announce.card.over')]

    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const handleOpenLink = (event: React.SyntheticEvent, link: string) => {
        event.preventDefault();
        logEvent(`Home`, `HomeStepper`, `Link: ${link}`);
        window.open(link, '_blank');
    };

    return (
        <Card ismobile={(!matches) ? "true" : "false"} style={{ margin: 'auto' }}>
            <CardContent>
                <Typography
                    variant="subtitle2"
                    gutterBottom
                    sx={{ color: theme => theme.palette.text.secondary }}
                >
                    <Trans i18nKey={'start_announce.card.title'} />
                </Typography>

                <Typography variant="h6" fontWeight="bold" sx={{ wordBreak: "break-all", overflow: "auto" }}>
                    {data.content}
                </Typography>

                {(matches && isManage) &&
                    <Box display="flex" position="absolute" right="0" top="0">
                        <FloatButton onClick={handleClickOpen}>
                            <Edit />
                        </FloatButton>
                        <FloatButton onClick={() => deleteAnnouce(data)}>
                            <DeleteForever />
                        </FloatButton>
                    </Box>
                }
            </CardContent>
            <CardActions state={state}>
                <Button href={data.link} onClick={(e) => handleOpenLink(e, data.link)} disabled={data.isEnd || data.link == ''} sx={{ width: '100%', height: '100%', color: theme => theme.palette.text.primary }}>
                    <Typography variant="h6" fontWeight="bold">
                        {linkText[state]}
                    </Typography>
                </Button>
            </CardActions>
            <EditDialog
                data={data}
                open={open}
                handleSubmit={saveAnnouce}
                handleClose={handleClose}
            />
        </Card>
    )
};
interface announceItemProps {
    data: Announce;
    isManage?: boolean;
    saveAnnouce?: (value: Announce) => Promise<void>;
    deleteAnnouce?: (value: Announce) => Promise<void>;
}