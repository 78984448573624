﻿import * as React from 'react';
import { Backdrop, Box, Button, CircularProgress, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useCheckAccountQuery } from '../../components/services/user';
import { useEditPasswordMutation } from '../../components/services/user';
import { useSetPasswordMutation } from '../../components/services/user';
import { SubTitle, TextField } from '../../components/responsive-components';

interface changePasswordEditState {
    oldPassword: string, newPassword: string, confirmPassword: string
}
interface setPasswordEditState {
    newPassword: string, confirmPassword: string
}
export default function UserPassword() {
    const { t } = useTranslation();

    const { havePassword, account, isLoading, refetch } = useCheckAccountQuery(undefined, {
        selectFromResult: (result) => ({ havePassword: result.data?.data.password, ...result, account: result.data?.data.account })
    });

    const [changePWValues, setChangePWValues] = React.useState<changePasswordEditState>({
        oldPassword: "", newPassword: "", confirmPassword: ""
    });
    const [mountPWValues, setMountPWValues] = React.useState<setPasswordEditState>({
        newPassword: "", confirmPassword: ""
    });
    
    const editChangePWValues = (event) => {
        setChangePWValues({ ...changePWValues, [event.target.name]: event.target.value });
    };
    const editMountPWValues = (event) => {
        setMountPWValues({ ...mountPWValues, [event.target.name]: event.target.value });
    };

    const [errorText, setErrorText] = React.useState<string>();
    const [success, setSuccess] = React.useState<boolean>(false);
    const [editPassword, { isLoading: isPosting }] = useEditPasswordMutation();
    const [setPassword, { isLoading: isPosting2 }] = useSetPasswordMutation();
    async function handleChangePassword() {
        setSuccess(false);
        if (changePWValues.newPassword != changePWValues.confirmPassword) {
            setErrorText("manage.form.error.same");
            return;
        }
        if (Object.keys(changePWValues).some(x => changePWValues[x].trim() === "")) {
            setErrorText("manage.form.error.empty");
            return;
        }
        try {
            let result = await editPassword(changePWValues).unwrap();
            if (result.isSuccess) {
                setErrorText(null);
                setSuccess(true);
                setChangePWValues({
                    oldPassword: "", newPassword: "", confirmPassword: ""
                });
            }
            else setErrorText("manage.form.error.incorrect");
        }
        catch (error) {
            console.log(error);
            setErrorText("???");
        }
    }

    async function handleSetPassword() {
        setSuccess(false);
        if (mountPWValues.newPassword != mountPWValues.confirmPassword) {
            setErrorText("manage.form.error.same");
            return;
        }
        if (Object.keys(mountPWValues).some(x => mountPWValues[x].trim() === "")) {
            setErrorText("manage.form.error.empty");
            return;
        }
        try {
            let result = await setPassword(mountPWValues).unwrap();
            if (result.isSuccess) {
                setErrorText(null);
                setSuccess(true);
                setMountPWValues({
                    newPassword: "", confirmPassword: ""
                });
                refetch();
            }
            else setErrorText("???");
        }
        catch (error) {
            console.log(error);
            setErrorText("???");
        }
    }

    const ChangePassword = <Stack spacing={1}>
        {Object.keys(changePWValues).map((fieldName) => (
            <Box key={fieldName}>
                <Typography variant='subtitle1'>
                    {t('manage.form.' + fieldName)}
                </Typography>
                <TextField
                    variant='outlined'
                    type="password"
                    name={fieldName}
                    value={changePWValues[fieldName]}
                    onChange={editChangePWValues}
                />
            </Box>
        ))}
        <Typography color="error.main">
            {t(errorText || " ")}
        </Typography>
        <Button variant="contained" sx={{ alignSelf: "flex-start" }} onClick={handleChangePassword}>修改密碼</Button>
        <Typography>
            {success ? t('manage.form.success') : " "}
        </Typography>
    </Stack>;
    const SetPassword = <Stack spacing={1}>
        <Typography>你目前沒有設置密碼</Typography>
        {Object.keys(mountPWValues).map((fieldName) => (
            <Box key={fieldName}>
                <Typography variant='subtitle1'>
                    {t('manage.form.' + fieldName)}
                </Typography>
                <TextField
                    variant='outlined'
                    type="password"
                    name={fieldName}
                    value={mountPWValues[fieldName]}
                    onChange={editMountPWValues}
                />
            </Box>
        ))}
        <Typography color="error.main">
            {t(errorText || " ")}
        </Typography>
        <Button variant="contained" sx={{ alignSelf: "flex-start" }} onClick={handleSetPassword}>設定密碼</Button>
        <Typography>
            {success ? t('manage.form.success') : " "}
        </Typography>
    </Stack>;
    
    const ViewAccount = <>
        <Typography gutterBottom>{t('manage.form.account') + account}</Typography>
    </>;

    return (<>
        <SubTitle
            icon='circle'
            text={t('manage.title.account.password')}
        />
        {ViewAccount}
        {havePassword === true && ChangePassword}
        {havePassword === false && SetPassword}
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading || isPosting || isPosting2}>
            <CircularProgress />
        </Backdrop>
    </>)
};