﻿import { Button, Container, MenuItem, Stack, Toolbar, Typography, useMediaQuery, useTheme } from '@mui/material';
import * as React from 'react';
import { JobOpeningGridList } from '../Booth/JobOpening/JobOpeningTable';
import { Booth, JobOpening } from '../components/class';
import { PageTitleBar } from '../components/Layout/PageTitleBar';
import { useGetAllBoothNamesQuery, useGetAllJobOpeningQuery } from '../components/services/booth';
import { cloneDeep } from 'lodash';
import { TextField } from '../components/responsive-components';
import { useTranslation } from 'react-i18next';
import { boothTypeList } from '../components/boothType';
import { SubTitle } from '../components/responsive-components/Typography';
import { useSystemConnection } from '../store';
import { SystemControlHub } from '../components/react-signalr/systemControlHub';

export default function JobListPage() {
	const { jobs = [] } = useGetAllJobOpeningQuery(null, {
		selectFromResult: ({ data }) => ({ jobs: data?.data }),
		refetchOnMountOrArgChange: true
	});

	//換連線位置
	const systemConnection = useSystemConnection();
	const hubRef = React.useRef<SystemControlHub>();
	React.useEffect(() => {
		if (systemConnection != null) {
			hubRef.current = new SystemControlHub(systemConnection);
			hubRef.current.send.ChangeLocation({ location: 3, refId: "jobList" });
		}
	}, [systemConnection]);

	return (
		<div>
			<PageTitleBar titleTransKey={'title.jobList'}>
				<Container>
					<JobOpeningTableWithFilter jobOpenings={jobs} />
				</Container>
			</PageTitleBar>
		</div>
	)
}

function JobOpeningTableWithFilter(props: JobOpeningTableWithFilterProps) {
	const { jobOpenings } = props;

	const { t } = useTranslation();
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('lg'));

	const { booths = [] } = useGetAllBoothNamesQuery(null, {
		selectFromResult: ({ data }) => ({ booths: data?.data })
	});

	//類別搜尋
	function typeSearch(data: JobOpening, type: number): boolean {
		let isMatch = false;

		if (booths.find(x => x.boothId === data.boothId)?.category === type)
			isMatch = true;

		return isMatch;
	}
	const [searchType, setSearchType] = React.useState<number>(-1);
	const handleTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSearchType(Number(event.target.value));
	};

	//搜尋文字
	const [searchString, setSearchString] = React.useState<string>('');
	const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSearchString(event.target.value);
	};
	function textSearch(data: JobOpening): boolean {
		let isMatch = false;

		let textList = searchString.split(' ');
		isMatch = true;
		for (let text of textList) {
			let subMatch = false;
			if (data.title && data.title.includes(text)) subMatch = true;
			if (subMatch === false) {
				isMatch = false;
				break;
			}
		}

		return isMatch;
	}

	return (<>
		<Stack
			spacing={2}
			direction={matches ? 'column' : 'row'}
			//justifyContent="space-between"
			alignItems="center"
			style={{ padding: '16px 0px' }}
		>
			<div style={{
				display: 'flex', flexWrap: 'nowrap', alignItems: 'center',
				flex: 1, width: '80%', maxWidth: !matches && '437px', marginRight: !matches && '24px',
			}}>
				<Typography fontWeight='bold' noWrap style={{ paddingRight: '24px' }}>{t('jobList.type.title')}</Typography>
				<TextField
					select
					value={searchType}
					onChange={handleTypeChange}
					SelectProps={{
						displayEmpty: true
					}}
					style={{
						flex: 1
					}}
				>
					<MenuItem disabled value={-1}>
						{t('jobList.type.placeholder')}
					</MenuItem>
					<MenuItem value={100}>
						{t('booth_type.all')}
					</MenuItem>
					{boothTypeList.map((option) => (
						<MenuItem key={option.type} value={option.type}>
							{t(option.nameKey)}
						</MenuItem>
					))}
				</TextField>
			</div>

			<TextField
				variant='outlined'
				margin="dense"
				value={searchString}
				onChange={handleSearchChange}
				placeholder={t('jobList.search.placeholder')}
				style={{
					flex: 1, width: '80%', maxWidth: !matches && '420px',
					marginRight: !matches && '12px',
				}}
			/>
		</Stack>
		{boothTypeList.filter(x => (searchType === -1 || searchType === 100) || (x.type === searchType)).map((type) => (
			<div key={type.type}>
				<SubTitle icon='square' text={t(type.nameKey)} />
				<JobOpeningGridList jobOpenings={jobOpenings.filter(x => typeSearch(x, type.type)).filter(x => textSearch(x))} />
				<Toolbar variant='dense' />
			</div>
		))}
	</>)
}
interface JobOpeningTableWithFilterProps {
	jobOpenings: JobOpening[];
}