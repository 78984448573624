﻿import * as React from 'react';
import { Agenda, Seminar, SeminarSignIn } from '../../../components/class';
import { useTranslation } from 'react-i18next';
import { Box, Button, CardContent, Dialog, Divider, MenuItem, Stack, styled, TextFieldProps, Typography } from '@mui/material';
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { CountDownTimer } from '../../../components/Admin/Utils';
import { ConfirmButton, important, TextField } from '../../../components/responsive-components';
import { ErrorMessage } from '@hookform/error-message';
import { Controller, useForm } from 'react-hook-form';
import { DatePicker, LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { enUS, zhTW } from 'date-fns/locale';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { add, format, isToday } from 'date-fns';

export function SeminarSignInEditDialog(props: SeminarSignInEditDialogProps) {
    const { currentDate, open, defaultValue, seminars, currentSeminar, handleSubmit, handleClose, maxDate, minDate } = props;
    const { t, i18n } = useTranslation();

    return (<>
        <Dialog
            open={open}
            onClose={handleClose}
            scroll='paper'
            PaperProps={{
                style: {
                    overflow: 'initial',
                    padding: '20px'
                }
            }}
            maxWidth={"md"} fullWidth
        >
            <SeminarSignInEdit
                currentDate={currentDate}
                defaultValue={defaultValue}
                seminars={seminars}
                currentSeminar={currentSeminar}
                handleSubmit={handleSubmit}
                handleClose={handleClose}
                maxDate={maxDate}
                minDate={minDate}
            />
        </Dialog>
    </>)
}
interface SeminarSignInEditDialogProps {
    currentDate: Date;
    open: boolean;
    defaultValue: SeminarSignIn;
    seminars: Seminar[];
    currentSeminar: number;
    handleSubmit: (value: SeminarSignIn) => Promise<void>;
    handleClose: () => void;
    maxDate?: Date;
    minDate?: Date;
}

const DateTimeTextField: (props: TextFieldProps) => JSX.Element = styled(TextField)(({ theme }) => ({
    '& .MuiInputBase-input': {
        paddingTop: '12px',
    },
}));
export function SeminarSignInEdit(props: SeminarSignInEditProps) {
    const { currentDate, defaultValue, seminars, currentSeminar, handleSubmit, handleClose, maxDate, minDate } = props;
    const { t, i18n } = useTranslation();

    const [isLoading, setIsLoading] = React.useState(false);

    const schema = yup.object().shape({
        seminarId: yup.string().trim().required(t('agenda.form.error_required')),
        agendaId: yup.string().nullable().trim().required(t('agenda.form.error_required')),
        startTime: yup.date().nullable().required(t('agenda.form.error_required')).typeError(t('agenda.form.error_invalid'))
    });
    const { register, handleSubmit: handleOnSubmit, formState: { errors }, watch, setValue, reset, control } = useForm<SeminarSignIn>({
        mode: "onChange", resolver: yupResolver(schema), defaultValues: { ...defaultValue, seminarId: seminars[currentSeminar].seminarId }
    });

    const seminarIdValue = watch("seminarId");
    const handleSeminarIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue("seminarId", event.target.value)
    };

    //設定可選時間表
    const [dateList, setDateList] = React.useState<string[]>([]);
    React.useEffect(() => {
        if (seminarIdValue) {
            let seminar = seminars.find(x => x.seminarId === seminarIdValue);
            let tempList: string[] = [];
            for (let agenda of seminar.agendas) {
                if (!tempList.includes(new Date(agenda.startTime).toDateString()))
                    tempList.push(new Date(agenda.startTime).toDateString());
            }
            setDateList(tempList);
            setEventDateString('');
        }
    }, [seminarIdValue])

    const agendaIdValue = watch("agendaId");
    const handleAgendaIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue("agendaId", event.target.value)
        let agenda = seminars.find(x => x.seminarId === seminarIdValue)?.agendas.find(x => x.agendaId === event.target.value);
        if (agenda)
            setValue("startTime", agenda.startTime);
    };

    const [eventDateString, setEventDateString] = React.useState<string>('');
    const handleEventDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEventDateString(event.target.value);
    };

    const startTimeValue = watch("startTime");

    //簽到持續時間
    const [persistTime, setPersistTime] = React.useState<number>(10);
    const handlePersistTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPersistTime(Number(event.target.value));
    };

    const [success, setSuccess] = React.useState<boolean>(false);
    const [error, setError] = React.useState<boolean>(false);
    async function onSubmit(data: SeminarSignIn) {
        setError(false);
        setIsLoading(true);
        let startTime = new Date(data.startTime);
        let startDateTime = new Date(eventDateString + " " + startTime.toTimeString());
        let endDateTime = add(startDateTime, { minutes: persistTime });

        let temp: SeminarSignIn = {
            ...data,
            startTime: startDateTime.toISOString(),
            endTime: endDateTime.toISOString()
        }
        await handleSubmit(temp).then(() => {
            CountDownTimer(2, handleClose);
            setSuccess(true);
        }).catch((error) => {
            console.log(error);
            setError(true);
        });

        setIsLoading(false);
    }

    return (
        <Box component="form" onSubmit={handleOnSubmit(onSubmit)} style={{ overflowY: 'auto', display: 'flex', flexDirection: 'column' }}>
            <CardContent style={{ flex: 1, overflow: 'auto' }}>
                <Stack spacing={1}>
                    <Box id='seminarId-field'>
                        <div style={{ display: 'flex' }}>
                            <TextField
                                select
                                variant='standard'
                                placeholder={t('seminarSignIn.form.seminarId.placeholder')}
                                value={seminarIdValue}
                                onChange={handleSeminarIdChange}
                                disabled={isLoading || success}
                                style={{ width: '100%', paddingRight: '200px' }}
                                SelectProps={{
                                    MenuProps: {
                                        PaperProps: {
                                            style: { maxHeight: "400px" }
                                        }
                                    }
                                }}
                            >
                                <MenuItem disabled value={''}>
                                    {t('seminarSignIn.form.seminarId.placeholder')}
                                </MenuItem>
                                {seminars.map((option) => (
                                    <MenuItem key={option.seminarId} value={option.seminarId}>
                                        {i18n.language == "ch" ? option.chTitle : option.enTitle}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </div>
                        <div style={{ minHeight: '24px' }}>
                        </div>
                    </Box>

                    <Box display='flex' flexWrap='wrap' style={{ flex: 1 }}>
                        <Box id='eventDate-field' style={{ marginRight: '24px' }}>
                            <Box display='flex'>
                                <Typography sx={{ marginRight: '12px', whiteSpace: 'nowrap' }}>
                                    {important}
                                    {t('seminarSignIn.form.eventDate.title')}
                                </Typography>
                                <TextField
                                    select
                                    variant='standard'
                                    value={eventDateString}
                                    onChange={handleEventDateChange}
                                    disabled={isLoading || success || (seminarIdValue === null || seminarIdValue === '')}
                                    fullWidth
                                    error={!!errors.agendaId}
                                    SelectProps={{
                                        displayEmpty: true,
                                        MenuProps: {
                                            PaperProps: {
                                                style: { maxHeight: "400px" }
                                            }
                                        }
                                    }}
                                >
                                    <MenuItem disabled value={''}>
                                        {t('seminarSignIn.form.eventDate.placeholder')}
                                    </MenuItem>
                                    {dateList.map((option) => (
                                        <MenuItem key={option} value={option}>
                                            {new Date(option).toLocaleDateString()}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Box>
                            <div style={{ minHeight: '24px' }}>
                            </div>
                        </Box>

                        <Box id='agendaId-field' style={{ flex: 1 }}>
                            <Box display='flex'>
                                <Typography style={{ marginRight: '12px', whiteSpace: 'nowrap' }}>
                                    {important}
                                    {t('seminarSignIn.form.agendaTime.title')}
                                </Typography>
                                <TextField
                                    select
                                    variant='standard'
                                    value={agendaIdValue}
                                    onChange={handleAgendaIdChange}
                                    disabled={isLoading || success || (eventDateString === null || eventDateString === '')}
                                    fullWidth
                                    error={!!errors.agendaId}
                                    SelectProps={{
                                        displayEmpty: true,
                                        MenuProps: {
                                            PaperProps: {
                                                style: { maxHeight: "400px" }
                                            }
                                        }
                                    }}
                                >
                                    <MenuItem disabled value={''}>
                                        {t('seminarSignIn.form.agendaTime.placeholder')}
                                    </MenuItem>
                                    {seminars.find(x => x.seminarId == seminarIdValue)?.agendas
                                        .filter(x => new Date(x.startTime).toDateString() === eventDateString).map((option) => (
                                            <MenuItem key={option.agendaId} value={option.agendaId}>
                                                {`${new Date(option.startTime).toLocaleTimeString(i18n.language == "ch" ? "zh-TW" : 'en-US',
                                                    { hour12: false, hour: '2-digit', minute: '2-digit' })}-
                                                    ${new Date(option.endTime).toLocaleTimeString(i18n.language == "ch" ? "zh-TW" : 'en-US',
                                                        { hour12: false, hour: '2-digit', minute: '2-digit' })}`}
                                            </MenuItem>
                                        ))}
                                </TextField>
                            </Box>
                            <div style={{ minHeight: '24px' }}>
                            </div>
                        </Box>
                    </Box>

                    <Box display='flex'>
                        <Typography>
                            {t('seminarSignIn.form.companyName.title')}
                        </Typography>
                        <Typography fontWeight='bold'>
                            {i18n.language == "ch" ?
                                seminars.find(x => x.seminarId === seminarIdValue)?.agendas.find(x => x.agendaId === agendaIdValue)?.boothChName :
                                seminars.find(x => x.seminarId === seminarIdValue)?.agendas.find(x => x.agendaId === agendaIdValue)?.boothEnName}
                        </Typography>
                    </Box>

                    <Box display='flex' style={{ marginBottom: '24px' }}>
                        <Typography>
                            {t('seminarSignIn.form.seminarTitle.title')}
                        </Typography>
                        <Typography fontWeight='bold'>
                            {seminars.find(x => x.seminarId === seminarIdValue)?.agendas.find(x => x.agendaId === agendaIdValue)?.title}
                        </Typography>
                    </Box>

                    <Box display='flex' flexWrap='wrap'>
                        <Box display='flex'>
                            <Box id='startTime-field'>
                                <Typography>
                                    {important}
                                    {t('seminarSignIn.form.signInStartTime.title')}
                                </Typography>
                                <div style={{ display: 'flex' }}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={i18n.language == "ch" ? zhTW : enUS}>
                                        <Controller
                                            name="startTime"
                                            control={control}
                                            render={({ field }) => (
                                                <TimePicker
                                                    ampm={false}
                                                    minTime={isToday(new Date(eventDateString)) ? new Date() : null}
                                                    renderInput={(params) => <DateTimeTextField
                                                        {...params}
                                                        error={!!errors.startTime}
                                                        helperText={errors.startTime ? errors.startTime.message : ' '}
                                                    />}
                                                    value={field.value}
                                                    onChange={(time) => field.onChange(time)}
                                                    disabled={isLoading}
                                                    DialogProps={{
                                                        PaperProps: {
                                                            sx: { '& *:focus': { outline: 'none' } },
                                                        },
                                                    }}
                                                />
                                            )}
                                        />
                                    </LocalizationProvider >
                                </div>
                                <div style={{ minHeight: '24px' }}>
                                    <ErrorMessage
                                        errors={errors}
                                        name={'signInStartTime' as keyof SeminarSignIn}
                                        render={({ message }) =>
                                            <Typography sx={{ color: (theme) => theme.palette.error.main }}>
                                                {`${t(message)}`}
                                            </Typography>
                                        }
                                    />
                                </div>
                            </Box>
                            <span style={{ width: '36px' }} />
                            <Box id='endTime-field'>
                                <Typography>
                                    {important}
                                    {t('seminarSignIn.form.persistTime.title')}
                                </Typography>
                                <div style={{ display: 'flex' }}>
                                    <TextField
                                        type="number"
                                        variant='standard'
                                        value={persistTime}
                                        onChange={handlePersistTimeChange}
                                        disabled={isLoading || success}
                                        inputProps={{ style: { padding: '12px 0px 8px 12px' } }}
                                    />
                                </div>
                                <div style={{ minHeight: '24px' }}>
                                </div>
                            </Box>
                        </Box>
                    </Box>
                </Stack>
                <Box className="d-flex flex-row flex-wrap justify-content-end" style={{ position: 'absolute', top: '24px', right: '36px' }}>
                    <Button onClick={() => handleClose()}>{t('agenda.form.back')}</Button>
                </Box>
            </CardContent>
            <CardContent style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <ConfirmButton type='submit' loading={isLoading} disabled={success} >
                    {success ?
                        <Typography color='green'>
                            {t('seminarSignIn.form.success')}
                        </Typography> :
                        <Typography>
                            {t('seminarSignIn.form.submit')}
                        </Typography>
                    }
                </ConfirmButton>
                {error &&
                    <Typography color='error'>
                        {t('seminarSignIn.form.error')}
                    </Typography>
                }
            </CardContent>
        </Box>
    )
}

interface SeminarSignInEditProps {
    currentDate: Date;
    defaultValue: SeminarSignIn;
    seminars: Seminar[];
    currentSeminar: number;
    handleSubmit: (value: SeminarSignIn) => Promise<void>;
    handleClose: () => void;
    maxDate?: Date;
    minDate?: Date;
}