﻿import * as React from 'react';
import Game from './Game';
import { useUser } from '../store';
import { CheckIsMobile } from '../components/Admin/Utils';
const joystick = 'images/joystick.png';
const keyC = 'images/KeyBoardC.png';
const keyE = 'images/keyE.png';
const keyMove = 'images/keyMove.png';
const keyEnter = 'images/keyEnter.png';

export default function GameDisplay() {
    const user = useUser();

    const keyItems = [{
        src: CheckIsMobile() ? joystick : keyMove,
        text: "移動",
        imgWidth: CheckIsMobile() ? 100 : 140,
        textWidth: 4 * 16 * 1.5 //字數 * 單一em寬 * ?em
    }, {
        src: keyE,
        text: "互動",
        imgWidth: 60,
        textWidth: 2 * 16 * 1.5
    }, {
        src: keyC,
        text: "聊天室",
        imgWidth: 60,
        textWidth: 3 * 16 * 1.5
    }, {
        src: keyEnter,
        text: "聊天",
        imgWidth: 100,
        textWidth: 2 * 16 * 1.5
    }];
    const maxImgWidth = Math.max(...keyItems.map(i => i.imgWidth));
    const maxTextWidth = Math.max(...keyItems.map(i => i.textWidth));
    return (
        <>{user &&
            <Game />
        }</>
    )
}