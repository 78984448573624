﻿import * as React from 'react';
import { Box, Button, Dialog, DialogActions, Typography } from '@mui/material';
import { BoothQandA } from '../../components/class';
import { Trans, useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { ConfirmButton, important, TextField } from '../../components/responsive-components';

interface formData {
    answer: string
}
export default function BoardCardDialog(props: BoardCardDialogProps) {
    const { boardCardData, open, handleSubmit, handleClose } = props;
    const { t, i18n } = useTranslation();

    const schema = yup.object().shape({
        answer: yup.string().required("必填").max(50, "超過50字")
    });
    const { register, handleSubmit: handleOnSubmit, formState: { errors }, reset } = useForm<formData>({
        mode: "onBlur", resolver: yupResolver(schema),
        defaultValues: {
            answer: ''
        }
    });

    const [isLoading, setIsLoading] = React.useState(false);
    async function onSubmit(data: formData) {
        setIsLoading(true);
        const success = await handleSubmit({ ...boardCardData, ...data });
        if (success) {
            handleClose();
            reset();
        }
        setIsLoading(false);
    }

    return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm" sx={{ borderRadius: "10px" }}>
            <Box px={4.5} py={3}
                bgcolor={(theme) => theme.palette.grey[100]}
                sx={{ boxShadow: "0px 3px 6px rgba(0, 0, 0, 16%)", borderRadius: "10px 10px 0 0" }}
            >
                <Typography variant="subtitle1" color="text.secondary" paddingRight="40px">
                    {new Date(boardCardData.askTime).toLocaleString(
                        i18n.language == 'ch' ? 'zh-tw' : 'en-us', {
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                        weekday: 'long',
                        hour: 'numeric',
                        minute: 'numeric',
                        hour12: false
                    })}
                </Typography>
                <Typography variant="subtitle1" fontWeight="bold">
                    {`${t('boardCard.card.question')}：`}
                </Typography>
                <Box minHeight="100px" maxHeight="300px" overflow="auto">
                    <Typography variant="subtitle1" fontWeight="bold">
                        {boardCardData.question}
                    </Typography>
                </Box>
            </Box>
            <Box component="form" onSubmit={handleOnSubmit(onSubmit)}>
                <Box px={3} pt={2}
                    sx={{ borderRadius: "10px" }}
                >
                    <Typography variant="subtitle1" fontWeight="bold">
                        {important}{`${"撰寫回答"}：`}
                    </Typography>
                    <TextField
                        variant="outlined"
                        inputRef={register('answer').ref}
                        multiline
                        rows={4}
                        fullWidth
                        inputProps={{ maxLength: 50 }}
                        placeholder={"(上限50字)"}
                        error={!!errors.answer}
                        helperText={errors.answer ? errors.answer.message : ' '}
                        {...register("answer")}
                        disabled={isLoading}
                    />
                </Box>
                <DialogActions sx={{ px: 3, pb: 2 }}>
                    <ConfirmButton type="submit" loading={isLoading}>
                        <Trans i18nKey={'boardCard.replyDialog.submit'} />
                    </ConfirmButton>
                </DialogActions>
            </Box>
        </Dialog>
    );
}
interface BoardCardDialogProps {
    boardCardData: BoothQandA;
    open: boolean;
    handleSubmit: (value: Pick<BoothQandA, "dataId" | "answer">) => Promise<boolean>;
    handleClose: () => void;
}