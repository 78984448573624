﻿import * as React from 'react';
import BoothList from './BoothList';
import { Backdrop, CircularProgress, Container } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { PageTitleBar } from '../components/Layout/PageTitleBar';
import { useLazyGetAllBoothsQuery } from '../components/services/booth';
import { useSystemConnection } from '../store';
import { SystemControlHub } from '../components/react-signalr/systemControlHub';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}
export default function BoothListPage() {
    let query = useQuery();
    const location = useLocation()

    //換連線位置
    const systemConnection = useSystemConnection();
    const hubRef = React.useRef<SystemControlHub>();
    React.useEffect(() => {
        if (systemConnection != null) {
            hubRef.current = new SystemControlHub(systemConnection);
            hubRef.current.send.ChangeLocation({ location: 3, refId: "boothList" });
        }
    }, [systemConnection]);

    //取得攤位及在線資料
    const [getAllBooths, { boothList = [], isBoothListLoading }] = useLazyGetAllBoothsQuery({
        selectFromResult: ({ data, isLoading }) => ({ boothList: data?.data ?? [], isBoothListLoading: isLoading })
    });
    React.useEffect(() => { getAllBooths() }, []);

    const [filterNum, setFilterNum] = React.useState<number>(-1);
    React.useEffect(() => {
        if (query.get('type'))
            setFilterNum(Number(query.get('type')));
        else
            setFilterNum(-1);
    }, [location])

    return (<>
        <PageTitleBar titleTransKey={'title.booths'}>
            <Container>
                <BoothList
                    boothList={boothList}
                    category={filterNum}
                />
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={isBoothListLoading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Container>
        </PageTitleBar>
    </>)
}