﻿import * as React from 'react';
import { Box, Paper, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { SeminarSignIn, Agenda, Seminar } from '../../../components/class';
import { BorderButton, Button, SubTitle } from '../../../components/responsive-components';
import { useTranslation } from 'react-i18next';
import { Add } from '@mui/icons-material';
import { ScrollItemData, ScrollMenu, ScrollMenuRef } from '../../../Stage/Schedule/ScrollMenu';
import { SeminarSignInModel,  useDeleteSeminarSignInMutation, useGetAllSeminarSignInsQuery, useGetAllSeminarsQuery,useInsertSeminarSignInMutation, useUpdateSeminarSignInMutation } from '../../../components/services/seminar';
import SeminarSignInCard from './SeminarSignInCard';
import { SeminarSignInEditDialog } from './SeminarSignInEditDialog';

export default function SignInList() {
    const { t, i18n } = useTranslation();

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('lg'));
    const matchesPhone = useMediaQuery(theme.breakpoints.down('sm'));
    const scrollRef = React.useRef<ScrollMenuRef>();

    const { seminars } = useGetAllSeminarsQuery(null, {
        refetchOnMountOrArgChange: true,
        selectFromResult: (result) => ({ seminars: result?.data?.data })
    });

    const { signIns, refetch } = useGetAllSeminarSignInsQuery(null, {
        refetchOnMountOrArgChange: true,
        selectFromResult: (result) => ({ signIns: result?.data?.data })
    });

    const [currentIndex, setCurrentIndex] = React.useState<number>(0);
    function changeCurentSeminar(index: number) {
        setCurrentIndex(index);
    }

    //編輯視窗控制
    const [open, setOpen] = React.useState<boolean>(false);
    const [editSeminarSignIn, setEditSeminarSignIn] = React.useState<SeminarSignIn>({
        dataId: "", seminarId: "", agendaId: "", startTime: "", endTime: ""
    });
    function handleNewSeminarSignIn() {
        setEditSeminarSignIn({
            dataId: "", seminarId: "", agendaId: "", startTime: "", endTime: ""
        });
        setOpen(true);
    }
    function handleEditSeminarSignIn(seminarSignIn: SeminarSignIn) {
        setEditSeminarSignIn(seminarSignIn);
        setOpen(true);
    }
    function handleCloseDialog() {
        setOpen(false);
    }

    //新增編輯簽到時間
    const [insertSeminarSignIn] = useInsertSeminarSignInMutation();
    const [updateSeminarSignIn] = useUpdateSeminarSignInMutation();
    async function handleSubmitSeminarSignIn(value: SeminarSignIn) {
        try {
            let modelValue: SeminarSignInModel = {
                ...value,
                timeSlot: { startTime: value.startTime, endTime: value.endTime }
            };
            if (value.dataId === "") {
                let result = await insertSeminarSignIn(modelValue).unwrap();
                if (result.isSuccess) refetch();
                else {
                    console.error(result.message);
                    throw new Error("");
                }
            }
            else {
                let result = await updateSeminarSignIn(modelValue).unwrap();
                if (result.isSuccess) refetch();
                else {
                    console.error(result.message);
                    throw new Error("");
                }
            }
        }
        catch (error) {
            throw new Error("");
        }
    }

    //設定日期區段
    const [dateData, setDateData] = React.useState<ScrollItemData[]>([]);
    function handleChangeDate(index: number) {
        if (dateData[index]) {
            let tempArray = [...dateData];
            for (let temp of tempArray) {
                if (temp.isClicked) {
                    temp.isClicked = false;
                }
            }
            tempArray[index].isClicked = true;
            setDateData(tempArray);
        }
    }

    //初始化日期
    const [init, setInit] = React.useState<string[]>([]);
    React.useEffect(() => {
        let tempDateList: ScrollItemData[] = [];
        //假如有說明會資料
        if (seminars && seminars.length > 0) {
            for (let agenda of seminars[currentIndex].agendas) {
                let dateString = agenda.startTime;
                if (tempDateList.findIndex(x => x.date.toDateString() === new Date(dateString).toDateString()) === -1) tempDateList.push({ date: new Date(dateString), isClicked: false });
            }
            tempDateList.sort((a, b) => { if (a.date > b.date) return 1; else return -1; });

            let nowDate = new Date(new Date().toDateString());
            //先確認是否有說明會資料在裡面，有的話優先查詢最近有說明會資料的日期
            for (let index = tempDateList.length - 1; index >= 0; index--) {
                if (seminars[currentIndex].agendas.filter(x => new Date(x.startTime).toDateString() === tempDateList[index].date.toDateString()).length > 0 &&
                    tempDateList[index].date >= nowDate) {
                    for (let temp of tempDateList) {
                        temp.isClicked = false;
                    }
                    tempDateList[index].isClicked = true;
                }
            }
            //若無則找最近的日期或當日
            if (!tempDateList.find(x => x.isClicked === true))
                for (let index = 0; index < tempDateList.length; index++) {
                    if (tempDateList[index].date > nowDate) {
                        tempDateList[index > 0 ? index - 1 : 0].isClicked = true;
                        break;
                    }
                    //當日已超過所有活動時間
                    if (index === tempDateList.length - 1) {
                        tempDateList[index].isClicked = true;
                    }
                }
            setInit([]);
        }
        setDateData([...tempDateList]);
    }, [seminars, currentIndex])
    React.useEffect(() => { scrollRef.current.initial(); }, [init]);

    //刪除簽到時間
    const [deleteSeminarSignIn] = useDeleteSeminarSignInMutation();
    async function handleDeleteSeminarSignIn(value: SeminarSignIn) {
        try {
            let modelValue: SeminarSignInModel = {
                ...value,
                timeSlot: { startTime: value.startTime, endTime: value.endTime }
            };
            let result = await deleteSeminarSignIn(modelValue).unwrap();
            if (result.isSuccess) {
                refetch();
            }
            else {
                console.error(result.message);
                throw new Error("");
            }
        }
        catch (error) {
            throw new Error("");
        }
    }

    return (<>
        <SubTitle
            icon='circle'
            text={t('manage.title.manage.checkIn')}
        />
        <Box display="flex" justifyContent="flex-end">
            {seminars?.hasOwnProperty(currentIndex) &&
                <BorderButton onClick={handleNewSeminarSignIn}>
                    <Add fontSize={'small'} />
                    <Typography variant='subtitle1' fontWeight='bold' style={{ textTransform: 'initial' }}>
                        {t('seminar.agendaList.newagenda')}
                    </Typography>
                </BorderButton>
            }
        </Box>

        <Box paddingLeft={!matches && '140px'}>
            <ScrollMenu
                ref={scrollRef}
                hasMonth={true}
                data={dateData}
                handleDateClick={handleChangeDate}
            />
        </Box>
        <Box display="flex" flexDirection={!matches ? 'row' : 'column'}>
            <Stack spacing={2} padding={!matches ? 3 : 1} direction={!matches ? 'column' : "row"} style={{ overflow: 'auto' }}>
                {seminars?.map((seminar, index) => (
                    <Button
                        onClick={() => changeCurentSeminar(index)}
                        variant={index === currentIndex ? 'contained' : 'outlined'}
                        color={index === currentIndex ? 'primary' : 'inherit'}
                        key={seminar.seminarId}
                    >
                        {i18n.language == "ch" ? seminar.chTitle : seminar.enTitle}
                    </Button>
                ))}
            </Stack>
            <Paper variant='outlined' sx={{
                minWidth: '300px', minHeight: '941px', height: '941px',
                overflowY: 'auto', backgroundColor: '#f5f5f5',
                flex: 1
            }}>
                <Stack padding={!matchesPhone ? 3 : 1} spacing={1}>
                    {seminars?.length > 0 && signIns?.map((signIn) => {
                        let agenda = seminars[currentIndex].agendas.filter(x => new Date(x.startTime).toDateString() === dateData.find(x => x.isClicked === true)?.date.toDateString())
                            .find(y => y.agendaId === signIn.agendaId);
                        if (agenda)
                            return (
                                <SeminarSignInCard
                                    matches={matchesPhone}
                                    isManage={true}
                                    seminarName={i18n.language == "ch" ? seminars[currentIndex].chTitle : seminars[currentIndex].enTitle}
                                    data={signIn}
                                    agenda={agenda}
                                    key={signIn.dataId}
                                    onEdit={handleEditSeminarSignIn}
                                    onDelete={handleDeleteSeminarSignIn}
                                />
                            )
                    })}
                </Stack>
            </Paper>
        </Box>

        <SeminarSignInEditDialog
            currentDate={dateData.find(x => x.isClicked === true)?.date ?? new Date()}
            defaultValue={editSeminarSignIn}
            seminars={seminars}
            currentSeminar={currentIndex}
            open={open}
            handleSubmit={handleSubmitSeminarSignIn}
            handleClose={handleCloseDialog}
            minDate={dateData.length > 0 && dateData[0].date}
            maxDate={dateData.length > 0 && dateData[dateData.length - 1].date}
        />
    </>)
}