﻿import * as React from 'react';
import { Button, ConfirmButton, Dialog, DialogActions, DialogContent, DialogTitle, ErrorText, important, TextField } from '../responsive-components';
import { Backdrop, Box, Checkbox, CircularProgress, Dialog as HintDialog, DialogActions as HintDialogActions, FormControlLabel, FormHelperText, Grid, Stack, styled, TextField as OriginField, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { hideModal } from '../../store/rootReducer';
import { useDispatchWithType } from '../../store';
import { ReportGmailerrorredOutlined } from '@mui/icons-material';
import { usePostReportMutation } from '../services/system';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    '& .MuiToggleButtonGroup-grouped': {
        margin: theme.spacing(1),
        border: 0,
        background: "rgba(0, 0, 0, 0.04)",
        '&.Mui-disabled': {
            border: 0,
        },
        '&:not(:first-of-type)': {
            borderRadius: theme.shape.borderRadius,
        },
        '&:first-of-type': {
            borderRadius: theme.shape.borderRadius,
        },
    },
}));

type formData = {
    option: optionType;
    description: string;
}
enum optionType {
    不當行為,
    回報錯誤,
    其他
}
export interface ReportModalProps {
    jsonData: string;
}
export function ReportModal(props: ReportModalProps) {
    const { jsonData } = props;
    const { t } = useTranslation();
    const dispatch = useDispatchWithType();

    const [open, setOpen] = React.useState(true);

    const schema = yup.object().shape({
        message: yup.string().max(50, t("dialog.exchange.error_limit"))
    });
    const { register, handleSubmit: handleOnSubmit, formState: { errors }, control } = useForm<formData>({
        mode: "onBlur", resolver: yupResolver(schema),
        defaultValues: {
            option: optionType.不當行為,
            description: '',
        }
    });

    const [postReport, { isLoading }] = usePostReportMutation();
    function onSubmit(data: formData) {
        postReport({ reportType: data.option, description: data.description, jsonData: jsonData }).unwrap()
            .then((result) => {
                //200 OK
                if (result.isSuccess) {
                    setOpen(false);
                } else {

                }
            })
            .catch((error) => {
                //後端報錯 ex:500、404
                console.error("發生錯誤", error)
            });
    }
    //優雅的關閉
    function onClose() {
        setOpen(false);
    }
    React.useEffect(() => {
        if (!open)
            setTimeout(() => {
                dispatch(hideModal());
            }, 300);
    }, [open]);

    const [openAsk, setOpenAsk] = React.useState(false);
    const handleCancel = () => {
        setOpenAsk(true);
    };

    return (
        <>
            <Dialog open={open} onClose={handleCancel} maxWidth={"sm"} fullWidth sx={{ px: 2, py: 1 }}>
                <DialogTitle>
                    <Box display="flex" alignItems="center">
                        <ReportGmailerrorredOutlined sx={{ color: 'text.secondary', fontSize: "1.375rem", mr: 2 }} />
                        <Typography variant="h6" fontWeight="bold">{t("dialog.report.title")}</Typography>
                    </Box>
                </DialogTitle>
                <Box component="form" onSubmit={handleOnSubmit(onSubmit)}>
                    <DialogContent sx={{ py: 0 }}>
                        <Stack>
                            <Typography>{important}{t("dialog.report.question")}</Typography>
                            <Controller
                                name="option"
                                control={control}
                                render={({ field }) => (
                                    <StyledToggleButtonGroup
                                        color="primary"
                                        value={field.value}
                                        exclusive
                                        onChange={(event, value) => { if (value !== null) field.onChange(value) }}
                                    >
                                        <ToggleButton value={optionType.不當行為}>
                                            {t("dialog.report.ans_report")}
                                        </ToggleButton>
                                        <ToggleButton value={optionType.回報錯誤}>
                                            {t("dialog.report.ans_error")}
                                        </ToggleButton>
                                        <ToggleButton value={optionType.其他}>
                                            {t("dialog.report.ans_other")}
                                        </ToggleButton>
                                    </StyledToggleButtonGroup>
                                )}
                            />
                            <FormHelperText> </FormHelperText>
                            <Typography>{t("dialog.report.descript")}</Typography>
                            <TextField
                                variant="outlined"
                                inputRef={register('description').ref}
                                multiline
                                rows={4}
                                inputProps={{ maxLength: 50 }}
                                placeholder={t("dialog.report.limitNum")}
                                error={!!errors.description}
                                helperText={errors.description ? errors.description.message : ' '}
                                {...register("description")}
                            disabled={isLoading}
                            />
                        </Stack>
                    </DialogContent>
                    <DialogActions sx={{ justifyContent: 'flex-end' }}>
                        <ConfirmButton type="submit" loading={isLoading}>{t('dialog.report.submit')}</ConfirmButton>
                    </DialogActions>
                </Box>
                <HintDialog open={openAsk}>
                    <div style={{ padding: '2rem 2rem 1rem 2rem', textAlign: 'center', fontSize: '1rem' }} >
                        <span style={{ fontWeight: 'bold' }}>
                            {t('dialog.report.discardEdit')}
                        </span>
                    </div>
                    <HintDialogActions style={{ justifyContent: 'center' }}>
                        <Button autoFocus onClick={() => { setOpenAsk(false); onClose(); }}>{t('dialog.report.yes')}</Button>
                        <Button onClick={() => { setOpenAsk(false); }}>{t('dialog.report.no')}</Button>
                    </HintDialogActions>
                </HintDialog>
            </Dialog>
            {isLoading &&
                <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.modal + 1 }} open={isLoading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            }
        </>
    )
}