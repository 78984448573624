﻿import * as React from 'react';
import { Paper, Stack, TextField, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useHistory, useLocation } from "react-router-dom";
import { FilledButton, TextValidator } from '../components/responsive-components';
import { useTranslation } from 'react-i18next';
import { LowerTextField, PaperWrap, SubmitButton, TextButton, UpperTextField } from './LoginWindow';
import { useCheckResetPasswordMutation, useResetPasswordMutation } from '../components/services/login';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import './LoginStyleSheet.css';

const HomeIcon = "images/logo.png"

type formData = {
    userId: string;
    hash: string;
    newPassword: string;
    confirmPassword: string;
}
export default function ResetPasswordWindow() {
    const query = new URLSearchParams(useLocation().search);

    const [checkResetPassword] = useCheckResetPasswordMutation();
    const [resetPassword, { isLoading }] = useResetPasswordMutation();
    const { t, i18n } = useTranslation();

    const schema = yup.object().shape({
        newPassword: yup.string().required(t('resetPassword.newPassword.error1')).min(6, t('resetPassword.newPassword.error2')),
        confirmPassword: yup.string().required(t('resetPassword.confirmPassword.error1')).min(6, t('resetPassword.confirmPassword.error2')).oneOf([yup.ref('newPassword'), null], t('resetPassword.confirmPassword.error3'))
    });
    const { register, handleSubmit, formState: { errors } } = useForm<formData>({
        mode: "onBlur", resolver: yupResolver(schema), defaultValues: {
            userId: query.get("u"),
            hash: query.get("hash"),
            newPassword: '',
            confirmPassword: '',
        }
    });

    const [errorMessage, setErrorMessage] = React.useState<string>("");

    const [success, setSuccess] = React.useState(false);
    const [error, setError] = React.useState(false);

    React.useLayoutEffect(() => {
        if (!success) {
            checkResetPassword({ userId: query.get("u"), hash: query.get("hash") }).unwrap()
                .then(data => {
                    if (!data.isSuccess) {
                        setError(true);
                    }
                }).catch(() => setError(true));
        }
    }, [query.get("u")]);

    function onResetSubmit(data) {
        setErrorMessage("");
        resetPassword(data).unwrap()
            .then(result => {
                if (result.isSuccess) {
                    setSuccess(true);
                }
                else {
                    setErrorMessage(result.message);
                }
            }).catch((error) => {
                //後端報錯 ex:500、404
                setErrorMessage("Unconnect");
                console.error("發生錯誤", error)
            });
    }

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };

    if (success) {
        return (<>重設密碼成功！<a href="/">點我回首頁</a>。</>)
    }
    else {
        return (
            <div className="root bg-lavender">
                <PaperWrap className="py-5" elevation={3}>
                    <Stack width={280} justifyContent="stretch" alignItems="stretch">
                        <div className="d-flex align center w-100">
                            <img src={HomeIcon} style={{ height: '36px', maxWidth: '100%' }} />
                            <Typography variant='subtitle2' style={{ padding: 6, wordBreak: "keep-all" }}>{t('navbar.title')}</Typography>
                        </div>

                        <div style={{ margin: "24px 0px", color: '#cccccc' }}>
                            <Typography variant="h5">
                                {t('resetPassword.subtitle1')}
                            </Typography>
                            <Typography variant="h5">
                                {t('resetPassword.subtitle2')}
                            </Typography>
                            <Typography variant="h5">
                                {t('resetPassword.subtitle3')}
                            </Typography>
                        </div>

                        <Stack component="form" onSubmit={handleSubmit(onResetSubmit)}>
                            <UpperTextField
                                variant="outlined"
                                size='small'
                                type="password"
                                placeholder={t('resetPassword.newPassword.placeholder')}
                                inputRef={register('newPassword').ref}
                                error={!!errors.newPassword}
                                helperText={errors.newPassword ? errors.newPassword.message : ' '}
                                {...register("newPassword")}

                                FormHelperTextProps={{ style: { position: 'absolute', bottom: 0, right: 0 } }}
                                disabled={isLoading}
                            />
                            <LowerTextField
                                variant="outlined"
                                size='small'
                                type="password"
                                placeholder={t('resetPassword.confirmPassword.placeholder')}
                                inputRef={register('confirmPassword').ref}
                                error={!!errors.confirmPassword}
                                helperText={errors.confirmPassword ? errors.confirmPassword.message : ' '}
                                {...register("confirmPassword")}

                                FormHelperTextProps={{ style: { position: 'absolute', bottom: 0, right: 0 } }}
                                disabled={isLoading}
                            />
                            <div className="d-flex center" style={{ height: 30 }}>
                                <Typography variant='body1' sx={{ color: (theme) => theme.palette.error.main }}>{errorMessage}</Typography>
                            </div>
                            <SubmitButton type="submit" variant="contained" loading={isLoading}>
                                {t('resetPassword.button')}
                            </SubmitButton>
                        </Stack>

                        <div style={{ height: 12 }} />
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Typography variant='body2'>Click to</Typography>
                            {i18n.language == 'ch' ?
                                <TextButton onClick={() => changeLanguage('en')}>ENG</TextButton> :
                                <TextButton onClick={() => changeLanguage('ch')}>中文版</TextButton>
                            }
                        </div>
                    </Stack>
                </PaperWrap>
            </div >
        );
    }
}