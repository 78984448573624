﻿import * as React from 'react';
import { useUser } from '../../../store';
import { Booth, JobOpening } from '../../../components/class';
import { useTranslation } from 'react-i18next';
import BoothList from '../../../BoothList/BoothList';
import { useGetSaveBoothsMutation, useGetSaveRecruitsMutation } from '../../../components/services/user';
import { SubTitle } from '../../../components/responsive-components/Typography';
import { JobOpeningGridList } from '../../../Booth/JobOpening/JobOpeningTable';
import { Box, Divider, Toolbar, Typography } from '@mui/material';
import { BoothCardInfoModel } from '../../../components/services/booth';

export default function UserFavorite(props: { noPadding?: boolean }) {
    const user = useUser();
    const { t } = useTranslation();

    const [getSaveBooths, { isLoading: isGetSaveBoothsLoading }] = useGetSaveBoothsMutation();
    const [getSaveRecruits, { isLoading: isGetSaveRecruitsLoading }] = useGetSaveRecruitsMutation();
    const [saveBooths, setSaveBooths] = React.useState<Booth[]>([]);
    const [saveRecruits, setSaveRecruits] = React.useState<JobOpening[]>([]);
    React.useEffect(() => {
        if (user) {
            getSaveBooths().unwrap().then((result) => {
                if (result.isSuccess) {
                    setSaveBooths(result.data);
                }
            }).catch((error) => {
                console.log(error);
            });
            getSaveRecruits().unwrap().then((result) => {
                if (result.isSuccess) {
                    setSaveRecruits(result.data);
                }
            }).catch((error) => {
                console.log(error);
            });
        }
    }, [user]);

    return (<>
        <SubTitle
            icon='circle'
            text={t('manage.title.profile.favorite')}
        />
        {user && <>
            <Box display="flex" alignItems="center" mb={1}>
                <Typography fontWeight='bold' color="primary.main">
                    {t('manage.favorite.saveBooths')}
                </Typography>
                <Divider sx={{ flexGrow: 1, margin: '0px 0px 0px 20px', borderColor: theme => theme.palette.primary.main }} />
            </Box>
            <BoothList
                boothList={saveBooths.map(booth => ({ ...booth} as BoothCardInfoModel))}
                justDisplay
            />

            <Toolbar variant='dense' />

            <Box display="flex" alignItems="center" mb={1}>
                <Typography fontWeight='bold' color="primary.main">
                    {t('manage.favorite.saveRecruits')}
                </Typography>
                <Divider sx={{ flexGrow: 1, margin: '0px 0px 0px 20px', borderColor: theme => theme.palette.primary.main }} />
            </Box>
            <JobOpeningGridList
                jobOpenings={saveRecruits}
            />
        </>}
    </>)
}