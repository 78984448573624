﻿import * as React from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Checkbox, Divider, FormControlLabel, Stack, Typography, useMediaQuery } from '@mui/material';
import { Announce } from '../class';
import { Trans, useTranslation } from 'react-i18next';
import { Dialog, DialogActions, DialogContent, DialogTitle, ConfirmButton, TextField } from '../responsive-components';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

type formData = {
    dataId: string;
    content: string;
    link: string;
    isEnd: boolean;
}
export function InsertDialog(props: InsertDialogProps) {
    const { open, handleSubmit, handleClose } = props;

    const matches = useMediaQuery('(min-width:768px)');
    const { t } = useTranslation();

    const schema = yup.object().shape({
        content: yup.string().required(t('start_announce.dialog.error_required')).max(50, t('start_announce.dialog.error_maxStringLength')),
        link: yup.string().url(t('start_announce.dialog.error_url'))
    });
    const { register, handleSubmit: handleOnSubmit, formState: { errors }, reset } = useForm<formData>({
        mode: "onChange", resolver: yupResolver(schema),
        defaultValues: {
            dataId: 'Annouce-' + new Date().toISOString().substr(0, 19),
            content: '',
            link: '',
            isEnd: false
        }
    });

    function onSubmit(data) {
        setIsLoading(true);
        handleSubmit(data).then(() => {
            setIsLoading(false);
            handleClose();
            reset();
        }).catch(() => {
            setIsLoading(false);
        })
    }

    const [isLoading, setIsLoading] = React.useState(false);
    return (
        <Dialog open={open} onClose={handleClose} maxWidth={"md"} fullWidth>
            <Box component="form" onSubmit={handleOnSubmit(onSubmit)}>
                <DialogTitle className="m-0 mx-md-3 mt-2 mt-md-3">
                    <div className="d-flex justify-content-between align-items-end px-1">
                        <Typography
                            align='left'
                            variant={matches ? "h4" : "h5"}
                            sx={{ color: theme => theme.palette.text.secondary }}
                        >
                            <Trans i18nKey={'start_announce.dialog.title1'} />
                        </Typography>
                        {/*<Typography*/}
                        {/*    align='left'*/}
                        {/*    variant="body1"*/}
                        {/*    sx={{ color: theme => theme.palette.text.secondary }}*/}
                        {/*>*/}
                        {/*    <Trans i18nKey={'start_announce.dialog.textlimit'} />*/}
                        {/*</Typography>*/}
                    </div>
                    <Divider className="my-1" sx={{ backgroundColor: "grey"}} />
                </DialogTitle>
                <DialogContent className="m-0 mx-md-3 py-0 py-md-2">
                    <Stack sx={{ '& .MuiTypography-root': { minWidth: 200, width: '25%' } }}>
                        <Box display="flex" alignItems="center">
                            <TextField
                                variant='outlined'
                                multiline
                                fullWidth
                                rows={4}
                                placeholder={t('start_announce.dialog.content_placeholder')}
                                inputRef={register('content').ref}
                                error={!!errors.content}
                                helperText={errors.content ? errors.content.message : ' '}
                                {...register("content")}
                                disabled={isLoading}
                            />
                        </Box>
                        <Box display="flex" alignItems="center">
                            <Typography variant="h6" paragraph>
                                <span style={{ paddingRight: '0.8rem'}}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                        <path id="Icon_metro-link" data-name="Icon metro-link" d="M13.213,17.26a1.244,1.244,0,0,1-.883-.366,5.862,5.862,0,0,1,0-8.282L16.939,4a5.857,5.857,0,1,1,8.284,8.282l-2.107,2.106a1.248,1.248,0,1,1-1.765-1.765l2.107-2.106A3.36,3.36,0,0,0,18.7,5.77L14.1,10.378a3.363,3.363,0,0,0,0,4.752,1.248,1.248,0,0,1-.883,2.13ZM8.79,26.29a5.856,5.856,0,0,1-4.142-10l2.107-2.106a1.248,1.248,0,1,1,1.765,1.765L6.414,18.058a3.36,3.36,0,0,0,4.753,4.752L15.776,18.2a3.363,3.363,0,0,0,0-4.752,1.248,1.248,0,1,1,1.765-1.765,5.862,5.862,0,0,1,0,8.282l-4.609,4.608A5.82,5.82,0,0,1,8.79,26.29Z" transform="translate(-2.936 -2.29)" fill="#16b0d8" />
                                    </svg>
                                </span>
                                <Trans i18nKey={'start_announce.dialog.title_link'} />
                            </Typography>
                            <TextField
                                variant='outlined'
                                fullWidth
                                placeholder={'https://xxx.xxx.xxx'}
                                inputRef={register('link').ref}
                                error={!!errors.link}
                                helperText={errors.link ? errors.link.message : ' '}
                                {...register("link")}
                                disabled={isLoading}
                            />
                        </Box>
                        <Box display="flex" alignItems="center">
                            <Typography variant="h6">
                                <Trans i18nKey={'start_announce.dialog.title_over'} />
                            </Typography>
                            <FormControlLabel
                                control={
                                    <Checkbox color= "primary" />
                                }
                                label={
                                    <Typography variant="h6">
                                        <Trans i18nKey={'start_announce.dialog.over'} />
                                    </Typography>
                                }
                                inputRef={register('isEnd').ref}
                                {...register("isEnd")}
                            />
                        </Box>
                    </Stack>
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'center' }}>
                    <ConfirmButton type="submit" loading={isLoading}>
                        <Trans i18nKey={'start_announce.dialog.submit'} />
                    </ConfirmButton>
                </DialogActions>
            </Box>
        </Dialog>
    );
}
interface InsertDialogProps {
    open: boolean;
    handleSubmit: (value: Announce) => Promise<void>;
    handleClose: () => void;
}

export function EditDialog(props: EditDialogProps) {
    const { data, open, handleSubmit, handleClose } = props;
    const matches = useMediaQuery('(min-width:768px)');
    const { t } = useTranslation();

    const schema = yup.object().shape({
        content: yup.string().required(t('start_announce.dialog.error_required')).max(50, t('start_announce.dialog.error_maxStringLength')),
        link: yup.string().url(t('start_announce.dialog.error_url'))
    });
    const { register, handleSubmit: handleOnSubmit, formState: { errors } } = useForm<formData>({
        mode: "onChange", resolver: yupResolver(schema), defaultValues: data
    });

    function onSubmit(data) {
        setIsLoading(true);
        handleSubmit(data).then(() => {
            setIsLoading(false);
            handleClose();
        }).catch(() => {
            setIsLoading(false);
        })
    }

    const [isLoading, setIsLoading] = React.useState(false);
    return (
        <Dialog open={open} onClose={handleClose} maxWidth={"md"} fullWidth>
            <Box component="form" onSubmit={handleOnSubmit(onSubmit)}>
                <DialogTitle className="m-0 mx-md-3 mt-2 mt-md-3">
                    <div className="d-flex justify-content-between align-items-end px-1">
                        <Typography
                            align='left'
                            variant={matches ? "h4" : "h5"}
                            sx={{ color: theme => theme.palette.text.secondary }}
                        >
                            <Trans i18nKey={'start_announce.dialog.title2'} />
                        </Typography>
                        {/*<Typography*/}
                        {/*    align='left'*/}
                        {/*    variant="body1"*/}
                        {/*    sx={{ color: theme => theme.palette.text.secondary }}*/}
                        {/*>*/}
                        {/*    <Trans i18nKey={'start_announce.dialog.textlimit'} />*/}
                        {/*</Typography>*/}
                    </div>
                    <Divider className="my-1" sx={{ backgroundColor: "grey" }} />
                </DialogTitle>
                <DialogContent className="m-0 mx-md-3 py-0 py-md-2">
                    <Stack sx={{ '& .MuiTypography-root': { minWidth: 200, width: '25%' } }}>
                        <Box display="flex" alignItems="center">
                            <TextField
                                variant='outlined'
                                multiline
                                fullWidth
                                rows={4}
                                placeholder={t('start_announce.dialog.content_placeholder')}
                                inputRef={register('content').ref}
                                error={!!errors.content}
                                helperText={errors.content ? errors.content.message : ' '}
                                {...register("content")}
                                disabled={isLoading}
                            />
                        </Box>
                        <Box display="flex" alignItems="center">
                            <Typography variant="h6" sx={{ width: 200 }} paragraph>
                                <span style={{ paddingRight: "0.8rem" }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                        <path id="Icon_metro-link" data-name="Icon metro-link" d="M13.213,17.26a1.244,1.244,0,0,1-.883-.366,5.862,5.862,0,0,1,0-8.282L16.939,4a5.857,5.857,0,1,1,8.284,8.282l-2.107,2.106a1.248,1.248,0,1,1-1.765-1.765l2.107-2.106A3.36,3.36,0,0,0,18.7,5.77L14.1,10.378a3.363,3.363,0,0,0,0,4.752,1.248,1.248,0,0,1-.883,2.13ZM8.79,26.29a5.856,5.856,0,0,1-4.142-10l2.107-2.106a1.248,1.248,0,1,1,1.765,1.765L6.414,18.058a3.36,3.36,0,0,0,4.753,4.752L15.776,18.2a3.363,3.363,0,0,0,0-4.752,1.248,1.248,0,1,1,1.765-1.765,5.862,5.862,0,0,1,0,8.282l-4.609,4.608A5.82,5.82,0,0,1,8.79,26.29Z" transform="translate(-2.936 -2.29)" fill="#16b0d8" />
                                    </svg>
                                </span>
                                <Trans i18nKey={'start_announce.dialog.title_link'} />
                            </Typography>
                            <TextField
                                variant='outlined'
                                fullWidth
                                placeholder={'https://xxx.xxx.xxx'}
                                inputRef={register('link').ref}
                                error={!!errors.link}
                                helperText={errors.link ? errors.link.message : ' '}
                                {...register("link")}
                                disabled={isLoading}
                            />
                        </Box>
                        <Box display="flex" alignItems="center">
                            <Typography variant="h6" sx={{ width: 200 }}>
                                <Trans i18nKey={'start_announce.dialog.title_over'} />
                            </Typography>
                            <FormControlLabel
                                control={
                                    <Checkbox color="primary" defaultChecked={data.isEnd} />
                                }
                                label={
                                    <Typography variant="h6">
                                        <Trans i18nKey={'start_announce.dialog.over'} />
                                    </Typography>
                                }
                                inputRef={register('isEnd').ref}
                                {...register("isEnd")}
                            />
                        </Box>
                    </Stack>
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'center' }}>
                    <ConfirmButton type="submit" loading={isLoading}>
                        <Trans i18nKey={'start_announce.dialog.submit'} />
                    </ConfirmButton>
                </DialogActions>
            </Box>
        </Dialog>
    );
}
interface EditDialogProps {
    data: Announce;
    open: boolean;
    handleSubmit: (value: Announce) => Promise<void>;
    handleClose: () => void;
}