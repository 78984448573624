﻿import * as React from 'react';
import { Box, Dialog, dialogClasses, DialogContent, IconButton, useMediaQuery, useTheme } from "@mui/material";
import { CardInfo } from '../class';
import { Close, KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { BussinessCard } from './BussinessCardInfo';

interface OnlineCardInfo extends CardInfo { isOnline: boolean };
interface UserCardDialogProps {
	data: OnlineCardInfo;
	open: boolean;
	onClose: () => void;
	handleChangePrev?: () => void;
	handleChangeNext?: () => void;
	onClickExchange?: (data: OnlineCardInfo) => void;
	onClickSendMessage?: (data: OnlineCardInfo) => void;
	onClickReport?: (data: OnlineCardInfo) => void;
}
export function UserCardDialog(props: UserCardDialogProps) {
	const { data, open, onClose, handleChangePrev, handleChangeNext, onClickExchange, onClickSendMessage, onClickReport } = props;

	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

	function handleClose() {
		onClose();
	}
	function handleClickExchange(data: OnlineCardInfo) {
		if (onClickExchange) onClickExchange(data);
		onClose();
	}
	function handleClickSendMessage(data: OnlineCardInfo) {
		if (onClickSendMessage) onClickSendMessage(data);
		onClose();
	}
	function handleClickReport(data: OnlineCardInfo) {
		if (onClickReport) onClickReport(data);
		onClose();
	}

	return (
		<Dialog open={open} onClose={handleClose}
			fullWidth maxWidth="xs"
			PaperProps={{
				sx: {
					overflow: "initial",
					[`&.${dialogClasses.paper}`]: {
						background: "transparent"
					}
				}
			}}
			scroll="paper"
		>
			{handleChangePrev &&
				<Box position="absolute" top="50%" left={isMobile ? "0" : "-48px"} color="white" sx={{ transform: isMobile ? "translate(-100%, -50%)" : "translateY(-50%)" }}>
					<IconButton color="inherit" size={isMobile ? "small" : "medium"} onClick={handleChangePrev}>
						<KeyboardArrowLeft fontSize={isMobile ? "small" : "medium"} />
					</IconButton>
				</Box>
			}
			{handleChangeNext &&
				<Box position="absolute" top="50%" right={isMobile ? "0" : "-48px"} color="white" sx={{ transform: isMobile ? "translate(100%, -50%)" : "translateY(-50%)" }}>
					<IconButton color="inherit" size={isMobile ? "small" : "medium"} onClick={handleChangeNext} >
						<KeyboardArrowRight fontSize={isMobile ? "small" : "medium"} />
					</IconButton>
				</Box>
			}
			<Box position="absolute" top="0px" right={isMobile ? "0" : "-54px"} color="white" sx={{ transform: isMobile && "translate(90%, -60%)" }}>
				<IconButton color="inherit" size={isMobile ? "small" : "medium"} onClick={handleClose}>
					<Close fontSize={isMobile ? 'small' : 'large'} />
				</IconButton>
			</Box>

			<DialogContent style={{ padding: "0" }}>
				{data &&
					<BussinessCard
						data={data}
						isOnline={data?.isOnline}
						withBorder
						onClickExchange={handleClickExchange}
						onClickSendMessage={handleClickSendMessage}
						onClickReport={handleClickReport}
					/>
				}
			</DialogContent>
		</Dialog>
	)
}