﻿import { optionType } from "../../Modal/AppointmentDeclineModal";

//更多面板按鈕翻譯
export const t_delete = "chat.dialog.more.delete";
export const t_copy = "chat.dialog.more.copy";
export const t_copy_message = "chat.dialog.more.copy_message";

//預約面談拒絕
export const declineReasons = [
    { key: optionType[0], value: "chat.message.declineReason.0" },
    { key: optionType[1], value: "chat.message.declineReason.1" },
    { key: optionType[2], value: "chat.message.declineReason.2" },
    { key: optionType[3], value: "chat.message.declineReason.3" }
];