﻿import * as React from 'react';
import { SubTitle } from '../../components/responsive-components/Typography';
import { Trans, useTranslation } from 'react-i18next';
import { LoyaltyCardData, SilverCoinData, useGetSilverCoinRecordQuery } from '../../components/services/user';
import { Backdrop, Box, CircularProgress, Divider, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton, GridValueGetterParams } from '@mui/x-data-grid';
import { format } from 'date-fns';
import { zhTWGrid } from '../data-grid-locale';
import zhTW from 'date-fns/locale/zh-TW';
import { useProgressiveImage } from '../../Posters/LazyBackground';

const loading = 'images/loading.jpg'

interface BoothPointsManageProps {
    data: LoyaltyCardData;
}
export default function BoothPointsManage(props: BoothPointsManageProps) {
    const { data } = props;
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('lg'));

    const { data: coinRecordList, isLoading } = useGetSilverCoinRecordQuery(null, {
        refetchOnMountOrArgChange: true
    });
    const imageSrc = useProgressiveImage("images/企業銀幣.png");
    return (<>
        <Stack ml={{ xs: 0, sm: 3 }} mb={2}>
            <Typography variant='subtitle1'>
                <Trans i18nKey="manage.boothPointsManage.subTitle"
                    defaults="銀幣會由企業人資決定是否給予，於 <0>3/16 10:00-14:00 期間</0>同學可運用<0>文字聊天室</0>和參展企業進行職缺福利諮詢、預約與進行線上視訊面談等等，即有機會獲得一顆銀幣。<1/>累積 7 顆即可獲得一張<2>企業抽獎劵</2>（<2>銀幣抽獎券</2>）並同時參加抽獎，多項大獎等待幸運得你！<1/><3>※ 每人每家企業僅有一次可列入抽獎計算</3>"
                    components={[<span style={{ color: theme.palette.primary.main }}></span>, <br />, <strong></strong>, <span style={{ color: theme.palette.error.light }}></span>]}
                />
            </Typography>
        </Stack>
        <Box display="grid" gridTemplateColumns={{ xs: "1fr", sm: "74px 1fr" }} gap={1.5} alignItems="flex-end" justifyItems={{ xs: "center", sm: "flex-start" }} mb={1.5}>
            <img src={imageSrc || loading} width="74px" height="74px" />
            <Typography variant='h6' fontWeight="bold">
                <Trans i18nKey="manage.boothPointsManage.pointText"
                    defaults="已累積 <0>{{totalPoint}}</0> 顆銀幣<1/>共獲得 <0>{{totalTicket}}</0> 張企業抽獎券（銀幣抽獎券）"
                    values={{ totalPoint: data?.silverCoin, totalTicket: data?.silverTicket }}
                    components={[<span style={{ color: theme.palette.error.light }}></span>, <br />]}
                />
            </Typography>
        </Box>

        <Box display="flex" alignItems="center" mb={1}>
            <Typography fontWeight='bold' sx={{ color: theme => theme.palette.primary.main }}>
                {t('manage.boothPointsManage.tableTitle')}
            </Typography>
            <Divider sx={{ flexGrow: 1, margin: '0px 20px', borderColor: theme => theme.palette.primary.main }} />
        </Box>

        <CoinRecordListList dataList={coinRecordList?.data ?? []} loading={isLoading} />
    </>)
};

interface CoinRecordListProps {
    dataList: SilverCoinData[];
    loading: boolean;
}
function CoinRecordListList(props: CoinRecordListProps) {
    const { dataList, loading } = props;
    const { t, i18n } = useTranslation();

    const columns: GridColDef[] = [
        {
            field: 'date',
            headerName: t('manage.boothPointsManage.options.date'),
            //flex: 1,
            minWidth: 120,
            valueGetter: (params: GridValueGetterParams) => {
                if (i18n.language == "ch")
                    return `${format(new Date(params.row.createTime.toString()), "MM/dd(EEEEE)", { locale: zhTW })}`;
                else
                    return `${format(new Date(params.row.createTime.toString()), "MM/dd(E)")}`;
            },
        },
        {
            field: 'time',
            headerName: t('manage.boothPointsManage.options.time'),
            //flex: 1,
            minWidth: 120,
            valueGetter: (params: GridValueGetterParams) => format(Date.parse(params.row.createTime.toString()), "HH:mm"),
        },
        {
            field: 'senderName',
            headerName: t('manage.boothPointsManage.options.senderName'),
            flex: 0.5,
            minWidth: 150,
            valueGetter: (params: GridValueGetterParams) => {
                if (i18n.language == "ch")
                    return `${params.row.lastName || ''} ${params.row.firstName || ''}`;
                else
                    return `${params.row.firstName || ''} ${params.row.lastName || ''}`;
            },
        },
        {
            field: 'boothName',
            headerName: t('manage.boothPointsManage.options.boothName'),
            flex: 1,
            minWidth: 150,
            valueGetter: (params: GridValueGetterParams) => {
                if (i18n.language == "ch")
                    return `${params.row.boothChName || ''}`;
                else
                    return `${params.row.boothEnName || ''}`;
            },
        }
    ];

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                <GridToolbarExport csvOptions={{ utf8WithBom: true }} />
            </GridToolbarContainer>
        );
    }
    return (
        <DataGrid
            rows={dataList}
            columns={columns}
            autoHeight
            disableColumnMenu
            disableColumnSelector
            components={{
                Toolbar: CustomToolbar,
            }}
            localeText={i18n.language == "ch" ? zhTWGrid : null}
            loading={loading}
        />
    )
}