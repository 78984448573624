﻿import { Box, Theme } from "@mui/material";
import { ResponsiveStyleValue, SxProps } from "@mui/system";
import * as React from "react";
import * as CSS from 'csstype';

interface StepProgressBarProps {
    total: number;
    currentStep?: number;
    width?: number;
    lineWidth?: number;
    circleWidth?: number;
    //bar填充後顏色
    color?: CSS.Property.Color | ResponsiveStyleValue<CSS.Property.Color> | ((theme: Theme) => ResponsiveStyleValue<CSS.Property.Color>);
    //bar為空時的顏色
    emptyColor?: CSS.Property.Color | ResponsiveStyleValue<CSS.Property.Color> | ((theme: Theme) => ResponsiveStyleValue<CSS.Property.Color>);
}
export default function StepProgressBar(props: StepProgressBarProps) {
    const { total, color, emptyColor } = props;
    const currentStep = props.currentStep ?? 0, width = props.width ?? 175,
        lineWidth = props.lineWidth ?? 2,
        circleWidth = props.circleWidth ?? 15;

    const [steps,] = React.useState<boolean[]>([...Array(total)].map((x, i) => i <= currentStep));
    const insetY = (circleWidth - lineWidth) / 2;
    const stickHalfWidth = (width - (total * circleWidth)) / ((total - 1) * 2);
    const scale = (currentStep + 1) >= total ? 1 : currentStep < 0 ? 0 :
        (stickHalfWidth * (2 * currentStep + 1) + circleWidth * (currentStep + 1)) / width;
    const wrapperSx: SxProps<Theme> = {
        position: "relative",
        width: `${width}px`,
        height: `${circleWidth}px`,
        "&::before": {
            content: "' '",
            position: "absolute",
            inset: `${insetY}px 0`,
            background: emptyColor ?? ((theme) => theme.palette.grey[300]),
            zIndex: 0
        },
        "&::after": {
            content: "' '",
            position: "absolute",
            inset: `${insetY}px 0`,
            background: color ?? ((theme) => theme.palette.primary.main),
            transformOrigin: "left",
            transform: `scaleX(${scale})`,
            transition: (theme) => theme.transitions.create(['transform'])
        }
    };
    const itemSx: SxProps<Theme> = {
        borderRadius: "50%",
        width: circleWidth,
        height: circleWidth,
        transition: (theme) => theme.transitions.create(['background'])
    };
    return (
        <Box display="flex" justifyContent="space-between" sx={wrapperSx}>
            {steps.map((x, index) =>
                <Box key={`${index}`} sx={{ ...itemSx, background: index <= currentStep ? (color ?? ((theme) => theme.palette.primary.main)) : (emptyColor ?? ((theme) => theme.palette.grey[300])) }} />
            )}
        </Box>
    )
}