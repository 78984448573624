﻿import { Box, Typography } from '@mui/material';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Agenda, Seminar } from '../components/class';
import { SystemControlHub } from '../components/react-signalr/systemControlHub';
import { useGetNowAiringQuery } from '../components/services/seminar';
import { useSystemConnection } from '../store';

interface LiveInfoProps {
    seminarId: string;
}
export default function LiveInfo(props: LiveInfoProps) {
    const { seminarId } = props;
    const { t } = useTranslation();

    const { data: nowAiring, isLoading } = useGetNowAiringQuery(seminarId);
    const [info, setInfo] = React.useState<Agenda>();
    React.useEffect(() => { if (nowAiring) setInfo(nowAiring.data); }, [nowAiring])

    const systemConnection = useSystemConnection();
    const hubRef = React.useRef<SystemControlHub>();

    React.useEffect(() => {
        if (systemConnection != null) {
            hubRef.current = new SystemControlHub(systemConnection);

            //收到換場次通知，更新資料
            hubRef.current.addHandler.OnChangeAgenda((json) => {
                let agendaData = JSON.parse(json) as Agenda;
                setInfo(agendaData);
            });
        }
        return (() => {
            if (hubRef.current) {
                hubRef.current.removeHandler.OnChangeAgenda();
            }
        })
    }, [systemConnection]);

    if (!seminarId) {
        return null;
    }
    return (
        <Box flex="1 1" paddingBottom={0.5}>
            <Typography variant="caption">
                {t('onlineSeminar.progressing')}
            </Typography>
            {info ?
                <LiveInfoName
                    text={info?.title}
                    autoScroll
                    pauseOnOver
                    draggable
                /> :
                isLoading ? <Typography>
                    {t('onlineSeminar.loading')}
                </Typography> : <Typography>
                    {t('onlineSeminar.noAct')}
                </Typography>
            }
        </Box>
    )
}
interface LiveInfoNameProps {
    text: string;
    autoScroll?: boolean;
    pauseOnOver?: boolean;
    draggable?: boolean;
}
function LiveInfoName(props: LiveInfoNameProps) {
    const { text, autoScroll, pauseOnOver, draggable } = props;

    return (
        <Box>
            <Typography>{text}</Typography>
        </Box>
    )
}