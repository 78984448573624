﻿import * as React from "react";
import TextField, { BaseTextFieldProps } from '@mui/material/TextField';
import { parse, isValid } from 'date-fns';
import { zhTW } from 'date-fns/locale';
import { InputBaseProps } from "@mui/material";

export const useValidation = (validators: React.RefObject<ValidatorHandler>[]) => {
    const checkIsValid = () => {
        let isValid : boolean = true;
        validators.forEach(ref => {
            if (!ref.current.validate())
                isValid = false;
        })
        return isValid;
    };
    return { checkIsValid };
};

export type ValidatorHandler = {
    validate: () => boolean;
}

export interface ValidationRules {
    matchRegexp?: RegExp;
    isEmail?: boolean;
    isPhone?: boolean;
    isDate?: boolean;
    isEmpty?: boolean;
    required?: boolean;
    trim?: boolean;
    isNumber?: boolean;
    isFloat?: boolean;
    isPositive?: boolean;
    minNumber?: number;
    maxNumber?: number;
    minFloat?: number;
    maxFloat?: number;
    isString?: boolean;
    minStringLength?: number;
    maxStringLength?: number;
    maxFileSize?: number;
    allowedExtensions?: string
}

export interface TextValidatorProps extends BaseTextFieldProps {
    name?: string;
    varient?: 'standard' | 'filled' | 'outlined';
    size?: 'small' | 'medium';
    rules?: ValidationRules;
    errorMessages?: string[];
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onValidate?: (name: string, isValid: boolean) => void;
    InputProps?: Partial<InputBaseProps>;
}

export const TextValidator = React.forwardRef(
    function TextValidator(props: TextValidatorProps, ref) {
        const { name, varient, size, rules, errorMessages, onChange, onValidate, ...other } = props;
        const [invalidType, setInvalidType] = React.useState(null);
        const [isValid, setIsValid] = React.useState(true);
        const textRef = React.useRef<HTMLInputElement>(null);

        const handleChange = (e) => {
            onChange(e);
        };

        const handleBlur = (e) => {
            checkValidation(e.target.value);
        };

        React.useImperativeHandle(ref, () => ({
            validate() {
                return checkValidation(textRef.current.value);
            }
        }));

        function checkValidation(value) {
            let properties = Object.keys(rules);
            for (let i = 0; i < properties.length; i++) {
                let key = properties[i];
                let isValid = validations[key](value, rules[key]);
                //console.log(key);
                //console.log("isValid:" + isValid);
                if (!isValid) //校驗錯誤
                {
                    let index = i;
                    if (onValidate)
                        onValidate(name, false);
                    setIsValid(false);
                    setInvalidType(index);
                    return false;
                }
            }
            if (onValidate)
                onValidate(name, true);
            setIsValid(true);
            setInvalidType(null);
            return true;
        }

        function getErrorMessage() {
            if (invalidType != null) {
                return errorMessages[invalidType];
            }
            return "";
        }

        return (
            <TextField inputRef={textRef} name={name}
                variant={varient ?? "outlined"} size={size ?? "small"}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!isValid}
                helperText={(!isValid && getErrorMessage())}
                {...other} />
        )
    });

function isValidDate(d: any) {
    if (Object.prototype.toString.call(d) === "[object Date]") {
        // it is a date
        if (isNaN(d.getTime())) {  // d.valueOf() could also work
            // date is not valid
            return false;
        } else {
            // date is valid
            return true;
        }
    } else {
        // not a date
        return false;
    }
}

const isDate = (date) => {
    const parsedDate = parse(date, 'yyyy.M.d', new Date(), { locale: zhTW });
    return isValid(parsedDate);
}

// =================== Utils =====================

// from github "NewOldMax/react-form-validator-core" code

const isExisty = function (value) {
    return value !== null && value !== undefined;
};

const isEmpty = function (value) {
    if (value instanceof Array) {
        return value.length === 0;
    }
    return value === '' || !isExisty(value);
};

const isEmptyTrimed = function (value) {
    if (typeof value === 'string') {
        return value.trim() === '';
    }
    return true;
};

const validations = {
    matchRegexp: (value, regexp) => {
        const validationRegexp = (regexp instanceof RegExp ? regexp : (new RegExp(regexp)));
        return (isEmpty(value) || validationRegexp.test(value));
    },

    // eslint-disable-next-line
    isEmail: value => validations.matchRegexp(value, /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i),

    isPhone: value => validations.matchRegexp(value, /^(((\+\d{3})|(\(\+\d{3}\))) ?)?((\d{1,4})|(\(\d{2,4}\)))[- ]?\d{1,4}[- ]?\d{2,4}( ?#(\d+)){0,2}$/i),

    isDate: value => isDate(value),

    isEmpty: value => isEmpty(value),

    required: value => !isEmpty(value),

    trim: value => !isEmptyTrimed(value),

    isNumber: value => validations.matchRegexp(value, /^-?[0-9]\d*(\d+)?$/i),

    isFloat: value => validations.matchRegexp(value, /^(?:-?[1-9]\d*|-?0)?(?:\.\d+)?$/i),

    isPositive: (value) => {
        if (isExisty(value)) {
            return (validations.isNumber(value) || validations.isFloat(value)) && value >= 0;
        }
        return true;
    },

    maxNumber: (value, max) => isEmpty(value) || parseInt(value, 10) <= parseInt(max, 10),

    minNumber: (value, min) => isEmpty(value) || parseInt(value, 10) >= parseInt(min, 10),

    maxFloat: (value, max) => isEmpty(value) || parseFloat(value) <= parseFloat(max),

    minFloat: (value, min) => isEmpty(value) || parseFloat(value) >= parseFloat(min),

    isString: value => isEmpty(value) || typeof value === 'string' || value instanceof String,
    minStringLength: (value, length) => validations.isString(value) && value.length >= length,
    maxStringLength: (value, length) => validations.isString(value) && value.length <= length,

    // eslint-disable-next-line no-undef
    isFile: value => isEmpty(value) || value instanceof File,
    maxFileSize: (value, max) => isEmpty(value) || (validations.isFile(value) && value.size <= parseInt(max, 10)),
    allowedExtensions: (value, fileTypes) => isEmpty(value) || (validations.isFile(value) && fileTypes.split(',').indexOf(value.type) !== -1),
};