﻿import * as React from 'react';
import { SubTitle } from '../../../components/responsive-components/Typography';
import { useTranslation } from 'react-i18next';
import { Seminar } from '../../../components/class';
import { SeminarEditList } from '../../../Booth/Seminar/SeminarEditList';
import { cloneDeep } from 'lodash';
import { useGetAllSeminarsQuery } from '../../../components/services/seminar';
import { AgendaList } from '../../../Booth/Seminar/AgendaList';
import { Toolbar } from '@mui/material';
import { useDispatchWithType } from '../../../store';
import { setScrollTrigger } from '../../../store/rootReducer';

export default function SeminarManage() {
    const { t } = useTranslation();

    const { data: seminarsData, isSuccess, refetch } = useGetAllSeminarsQuery(null, { refetchOnMountOrArgChange: true });
    const [seminars, setSeminars] = React.useState<Seminar[]>([]);
    React.useEffect(() => {
        if (isSuccess) {
            setSeminars(cloneDeep(seminarsData.data))
        }
    }, [seminarsData])

    function handleChange(dataList: Seminar[]) {
        refetch();
    }

    //載入完成滾動
    const dispatch = useDispatchWithType();
    React.useEffect(() => { dispatch(setScrollTrigger(true)) }, []);

    return (<>
        <SubTitle
            icon='circle'
            text={t('manage.title.manage.stageList')}
        />
        <SeminarEditList
            seminars={seminars}
            onChange={handleChange}
        />
    </>)
};