﻿import { Backdrop, Box, CircularProgress, Container, Divider, IconButton, Paper, Typography } from '@mui/material';
import * as React from 'react';
import { PageTitleBar } from '../components/Layout/PageTitleBar';
import { CardType } from '../components/class';
import { useLazyGetAllUsersQuery, UserCardInfoModel } from '../components/services/user';
import { useSystemConnection, useUser } from '../store';
import { SystemControlHub } from '../components/react-signalr/systemControlHub';
import { Trans, useTranslation } from 'react-i18next';
import { SearchBar } from '../components/responsive-components/SearchBar';
import { BussinessCard } from '../components/BussinessCard/BussinessCardInfo';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { UserCardDialog } from '../components/BussinessCard/UserCardDialog';

export default function UserListPage() {
	const { t } = useTranslation();

	//換連線位置
	const systemConnection = useSystemConnection();
	const hubRef = React.useRef<SystemControlHub>();
	React.useEffect(() => {
		if (systemConnection != null) {
			hubRef.current = new SystemControlHub(systemConnection);
			hubRef.current.send.ChangeLocation({ location: 3, refId: "userList" });
		}
	}, [systemConnection]);

	//下載資料
	const [getAllUsers, { isLoading }] = useLazyGetAllUsersQuery();
	const [userList, setUserList] = React.useState<UserCardInfoModel[]>([]);
	React.useEffect(() => { init() }, []);
	async function init() {
		try {
			let result = await getAllUsers({ cardType: CardType.student }).unwrap();
			if (result.isSuccess)
				setUserList(result.data);
		} catch {
			console.error("發生問題");
		}
	}

	//篩選
	const [filterType, setFilterType] = React.useState<CardType>(null);
	const [filterText, setFilterText] = React.useState<string>("");
	const [filteredList, setFilteredList] = React.useState<UserCardInfoModel[]>([]);
	React.useEffect(() => {
		setFilteredList(userList.filter(isPass));
	}, [userList, filterType, filterText]);

	const handleChangeText = (value: string) => {
		if (filterText !== value) {
			setFilterText(value);
		}
	}

	function isPass(data: UserCardInfoModel) {
		//強制不顯示未完成填寫之名片
		if (data.lastName == null || data.firstName == null)
			return false;

		if (filterType != null) {
			if (data.type !== filterType) return false;
		}
		if (filterText && filterText != "" && !(`${data.lastName} ${data.firstName}`?.toLowerCase().includes(filterText.toLowerCase()) ||
			data.infoFields.some((field) => typeof field?.value === "string" && field.value.toLowerCase().includes(filterText.toLowerCase())) || data.email?.includes(filterText)))
			return false;

		return true;
	}

	const [page, setPage] = React.useState(1); //頁碼，起始值為1
	const [pageSize, setPageSize] = React.useState<number>(24); //一頁顯示之數量

	let showList = filteredList?.slice((page - 1) * pageSize, page * pageSize);
	let totalCount = filteredList?.length ?? 0;
	let currentPage = page ?? 1;
	let totalPages = Math.ceil(totalCount / pageSize) || 1;
	let hasNext = currentPage < totalPages;
	let hasPrevious = currentPage > 1;

	const handleNext = () => setPage(page => page + 1);
	const handlePrevious = () => setPage(page => page - 1);

	//顯示視窗
	const [currentData, setCurrentData] = React.useState<UserCardInfoModel>(null);
	const [open, setOpen] = React.useState(false);
	const handleOpen = (data: UserCardInfoModel) => {
		setCurrentData(data);
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
	};
	function handleDisplayChange(changeIndex: number) {
		let currentIndex = userList.findIndex(x => x.userId === currentData.userId);
		if (userList.length > 0) {
			if (currentIndex + changeIndex < 0) {
				setCurrentData(userList[userList.length - 1]);
			}
			else if (userList.length > currentIndex + changeIndex) {
				setCurrentData(userList[currentIndex + changeIndex]);
			}
			else {
				setCurrentData(userList[0]);
			}
		}
	}

	return (<>
		<PageTitleBar titleTransKey={'title.userList'}>
			<Container>
				<SearchBar
					placeholder={t("userList.search.placeholder")}
					//dataSource={["1", "2"]}
					onChange={(event) => handleChangeText(event.target.value)}
					onCancelSearch={() => handleChangeText("")}
					//onRequestSearch={handleChangeText}
					sx={{ xs: { width: "100%" }, md: { width: "400px" }, my: "auto" }}
				/>
			</Container>
			<Container>
				<Box display="flex" alignItems="center">
					<Typography>
						<Trans i18nKey="userList.totalNum" values={{ totalNum: totalCount }} />
					</Typography>

					<Paper style={{
						margin: '12px 2px 12px auto',
						width: 'fit-content',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'flex-end'
					}}>
						<IconButton onClick={handlePrevious} disabled={!hasPrevious}>
							<KeyboardArrowLeft />
						</IconButton>
						<Divider variant="middle" flexItem orientation="vertical" />
						<Box style={{ padding: '6px 14px' }}>
							<Typography variant="body2">
								<span>{currentPage}</span>
								{` / ${totalPages}`}
							</Typography>
						</Box>
						<Divider variant="middle" flexItem orientation="vertical" />
						<IconButton onClick={handleNext} disabled={!hasNext}>
							<KeyboardArrowRight />
						</IconButton>
					</Paper>
				</Box>
				<Box display="grid" gap={{ xs: 1, md: 2 }} gridTemplateColumns={{ xs: "repeat(2, 1fr)", sm: "repeat(4, 1fr)", md: "repeat(5, 1fr)", lg: "repeat(6, 1fr)", xl: "repeat(6, 1fr)" }}>
					{showList.map((user) => (
						<Box key={user.userId}>
							<BussinessCard
								data={user}
								isOnline={user.isOnline}
								simpleMode
								withBorder
								onClickShowMore={handleOpen}
							/>
						</Box>
					))}
				</Box>
				<UserCardDialog
					data={currentData}
					open={open}
					onClose={handleClose}
					handleChangePrev={() => handleDisplayChange(-1)}
					handleChangeNext={() => handleDisplayChange(1)}
				/>
			</Container>
			<Backdrop
				sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={isLoading}
			>
				<CircularProgress color="inherit" />
			</Backdrop>
		</PageTitleBar>
	</>)
}