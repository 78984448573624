﻿import { Email, Facebook, Favorite, FavoriteBorderRounded, Instagram, Language, Person, Phone, Place } from "@mui/icons-material";
import { Badge, BadgeProps, Box, Button, ButtonBase, IconButton, Link, Skeleton, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useProgressiveImage } from "../../Posters/LazyBackground";
import { useUser } from "../../store";
import { boothTypeList } from "../boothType";
import { Booth, BoothType } from "../class";
import { boothCustomFieldList } from "../CustomField";

type cardOption = { nameKey: string; styleKey: string; };
const cardStyleList: cardOption[] = [
];

export interface BoothCardProps<T extends Booth> {
	data: T;
	isOnline?: boolean;
	simpleMode?: boolean; //是否為小卡模式
	darkMode?: boolean;
	editable?: boolean;
	fixed?: boolean; //固定顯示方式，不做簡介rwd
	className?: string;

	onClickEdit?: (data: T) => void; //非小卡模式時，放入編輯按鈕並觸發此函式
	onClickFollow?: (data: T) => void;
	onClickShowMore?: (event: React.MouseEvent<HTMLElement>, data: T) => void;

	disableFollow?: boolean; //不顯示「❤️」按鈕
	disableShowMore?: boolean; //小卡模式時，不顯示「更多」按鈕
}
export function BoothCard<T extends Booth>(props: BoothCardProps<T>) {
	const { data, isOnline, simpleMode, darkMode, editable, fixed, className, onClickEdit, onClickFollow, onClickShowMore, disableFollow, disableShowMore } = props;
	const { t, i18n } = useTranslation();

	const user = useUser();
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('md'));

	//按鈕功能
	function handleClickEdit() {
		if (data?.boothId) {
			if (onClickEdit) onClickEdit(data);
		}
		else console.log(`boothId is null`);
	}
	function handleClickFollow() {
		if (data?.boothId) {
			if (onClickFollow) onClickFollow(data);
		}
		else console.log(`boothId is null`);
	}
	function handleClickShowMore(event: React.MouseEvent<HTMLElement>) {
		if (data?.boothId) {
			if (onClickShowMore) onClickShowMore(event, data);
		}
		else console.log(`boothId is null`);
	}

	const logo = useProgressiveImage(data?.logoUrl);
	const [logoSize, setLogoSize] = React.useState<{ width: number; height: number }>({ width: 450, height: 150 });
	const container = React.useRef<HTMLDivElement>(null);
	React.useEffect(() => {
		const resizeObserver = new ResizeObserver(entries => {
			let width = entries[0].target.clientWidth;
			if (width <= 900)
				setLogoSize({ width: 450, height: 150 });
			else
				setLogoSize({ width: 480, height: 160 });
		});
		resizeObserver.observe(container.current)
		return (() => {
			resizeObserver.disconnect();
		})
	}, []);

	//unifiedBusinessNo
	const unifiedBusinessNoIndex = data?.fieldList?.findIndex(x => x.fieldId === "unifiedBusinessNo") ?? -1;
	const unifiedBusinessNoElement = unifiedBusinessNoIndex != -1 ? boothCustomFieldList.find(x => x.id === "unifiedBusinessNo") : null;

	//numOfEmployees
	const numOfEmployeesIndex = data?.fieldList?.findIndex(x => x.fieldId === "numOfEmployees") ?? -1;
	const numOfEmployeesElement = numOfEmployeesIndex != -1 ? boothCustomFieldList.find(x => x.id === "numOfEmployees") : null;

	//phone
	const phoneIndex = data?.fieldList?.findIndex(x => x.fieldId === "phone") ?? -1;
	const phoneElement = phoneIndex != -1 ? boothCustomFieldList.find(x => x.id === "phone") : null;

	//address
	const addressIndex = data?.fieldList?.findIndex(x => x.fieldId === "address") ?? -1;
	const addressElement = addressIndex != -1 ? boothCustomFieldList.find(x => x.id === "address") : null;

	//website
	const websiteIndex = data?.fieldList?.findIndex(x => x.fieldId === "website") ?? -1;
	const websiteElement = websiteIndex != -1 ? boothCustomFieldList.find(x => x.id === "website") : null;

	//傳真
	const faxIndex = data?.fieldList?.findIndex(x => x.fieldId === "fax") ?? -1;
	const faxElement = faxIndex != -1 ? boothCustomFieldList.find(x => x.id === "fax") : null;

	return (
		<Box bgcolor={(!simpleMode && darkMode) ? theme.palette.background.black : theme.palette.background.paper}
			border={simpleMode && `1.5px solid ${theme.palette.grey[400]}`}
			//boxShadow={theme.shadows[3]}
			borderRadius={simpleMode && 3}
			color={!simpleMode && darkMode && theme.palette.text.white}
			className={className}
		>
			{/*編輯 or ❤️*/}
			<Box display="flex" justifyContent="flex-end" alignItems="flex-start" height={simpleMode ? "16px" : "30px"}
				pl={simpleMode && 2}
			>
				{editable && data && user ?
					<Button variant="text" onClick={handleClickEdit}>{t('booth.button.edit')}</Button>
					: !disableFollow && data && user ?
						<Box bgcolor={(!simpleMode && darkMode) ? theme.palette.background.black : theme.palette.background.paper} zIndex={1} borderRadius="50%">
							<IconButton onClick={handleClickFollow}>
								{user.saveBoothIds.includes(data.boothId) ?
									<Favorite style={{ color: '#fa6980' }} /> :
									<FavoriteBorderRounded style={{ color: '#545569' }}/>
								}
							</IconButton></Box>
						: null
				}
			</Box>
			<Box ref={container} display="grid" gridTemplateColumns={(!fixed && (isMobile || simpleMode)) ? "1fr" : `${logoSize.width}px 1fr`} gap={2} px={2}>
				{/*LOGO*/}
				<Box >
					<BoothCardBadge isOnline={isOnline} ripple>
						<ButtonBase sx={{ width: "100%", "&:hover": { color: theme.palette.text.secondary } }} {...(simpleMode ? { onClick: handleClickShowMore, href: `/Booth?id=${data?.boothId}` } : { disabled: true })}>
							<Box position="relative" width={"100%"/*isMobile ? "100%" : logoSize.width*/} maxWidth="100%" paddingTop={(logoSize.height / logoSize.width * 100) + "%"}>
								<Box position="absolute" top={0} right={0} bottom={0} left={0} borderRadius={3}>
									{data && data.logoUrl && data.logoUrl !== "" && logo ?
										<img src={logo} width="100%" height="100%" style={{ objectFit: "contain" }} />
										: data && (data.logoUrl === "" || data.logoUrl === null) ?
											<Box bgcolor={darkMode ? "background.black" : "grey.200"} width="100%" height="100%" display="flex" justifyContent="center" alignItems="center" border={darkMode && `2px solid ${theme.palette.primary.main}`}>
												<Typography color={darkMode && "white"}>NO LOGO</Typography>
											</Box>
											: <Skeleton variant="rectangular" width="100%" height="100%" />
									}
								</Box>
							</Box>
						</ButtonBase>
					</BoothCardBadge>
				</Box>
				{/*簡介*/}
				<Box>
					{/*名字 and 攤位位置*/}
					{simpleMode ? <>
						{data ? <>
							<Typography variant="h6" fontWeight="bold" noWrap>{data.chName || " "}</Typography>
							<Typography>{data.enName || ""}</Typography>
							<Box height="10px" />

							<Typography>
								<span style={{ color: boothTypeList[data.category]?.color }}>{t(boothTypeList[data.category]?.nameKey)}</span>
							</Typography>

							<Typography>
								<Box component="span" color="secondary.main" fontWeight="bold" mr={1}>
									攤位編號：
								</Box>
								{data.boothNo}
							</Typography>
							<Typography fontWeight="bold">
								<Box component="span" color="primary.main" mr={1}>
									<Place fontSize='small' color="inherit" />
								</Box>
								{data.boothLocation}
							</Typography>
						</> : <>
							<Typography variant="h6"><Skeleton variant="text" /></Typography>
							<Typography variant="subtitle1"><Skeleton variant="text" /></Typography>
						</>}</> : <>
						{data ? <>
							<Typography variant="subtitle1" mb={0.5}>
								<span style={{ color: boothTypeList[data.category]?.color }}>{t(boothTypeList[data.category]?.nameKey)}</span>
								<Box display={{ xs: "block", md: "none" }} width="100%" />

								<Box component="span" color="secondary.main" fontWeight="bold" ml={{ md: 3 }} mr={1}>
									攤位編號：
								</Box>
								{data.boothNo}

								<Box display={{ xs: "block", md: "none" }} width="100%" />

								<Box component="span" color="primary.main" fontWeight="bold" ml={{ md: 3 }} mr={0.5}>
									<Place fontSize='small' color="inherit" />
								</Box>
								{data.boothLocation}
							</Typography>
							<Typography variant="h5" fontWeight="bold">{data.chName || " "}</Typography>
							<Typography variant="h6">{data.enName || ""}</Typography>
						</> : <>
							<Typography variant="h5"><Skeleton variant="text" /></Typography>
							<Typography variant="h6"><Skeleton variant="text" /></Typography>
						</>}
					</>}

					<Box height="10px" />
					{/*其他攤位資料*/}
					{simpleMode ? <>
						{data ? <>
							{data.type === BoothType.一般攤位 ? <>
							</> : data.type === BoothType.廣告贊助 ? <>
							</> : data.type === BoothType.服務台 ? <>
								<Box display="flex" gap={1}>
									<Link
										href={data.fieldList?.find(x => x.fieldId === 'fb')?.value} target="_blank"
										sx={{ cursor: "pointer", "&:hover": { color: theme.palette.secondary.main } }}
									>
										<Facebook />
									</Link>
									<Link
										href={data.fieldList?.find(x => x.fieldId === 'ig')?.value} target="_blank"
										sx={{ cursor: "pointer", "&:hover": { color: theme.palette.secondary.main } }}
									>
										<Instagram />
									</Link>
								</Box>
							</> : null}
						</> : <>
							<Typography variant="subtitle1" gutterBottom><Skeleton variant="text" /></Typography>
							<Typography variant="subtitle1" gutterBottom><Skeleton variant="text" /></Typography>
						</>}
					</> : <>
						{data ? <>
								{data.type === BoothType.一般攤位 ? <>
									{data.fieldList[numOfEmployeesIndex].value != '' ? numOfEmployeesElement?.displayComponent({ displayValue: data.fieldList[numOfEmployeesIndex].value }) : <></>}
									{data.fieldList[phoneIndex].value != '' ? phoneElement?.displayComponent({ displayValue: data.fieldList[phoneIndex].value }) : <></>}
									{data.fieldList[addressIndex].value != '' ? addressElement?.displayComponent({ displayValue: data.fieldList[addressIndex].value }) : <></>}
									{data.fieldList[websiteIndex].value != '' ? websiteElement?.displayComponent({ displayValue: data.fieldList[websiteIndex].value }) : <></>}
									{data.fieldList[faxIndex].value != '' ? faxElement?.displayComponent({ displayValue: data.fieldList[faxIndex].value }) : <></>}
								</> : data.type === BoothType.廣告贊助 ? <>
									{data.fieldList[numOfEmployeesIndex].value != '' ? numOfEmployeesElement?.displayComponent({ displayValue: data.fieldList[numOfEmployeesIndex].value }) : <></>}
									{data.fieldList[phoneIndex].value != '' ? phoneElement?.displayComponent({ displayValue: data.fieldList[phoneIndex].value }) : <></>}
									{data.fieldList[addressIndex].value != '' ? addressElement?.displayComponent({ displayValue: data.fieldList[addressIndex].value }) : <></>}
									{data.fieldList[websiteIndex].value != '' ? websiteElement?.displayComponent({ displayValue: data.fieldList[websiteIndex].value }) : <></>}
									{data.fieldList[faxIndex].value != '' ? faxElement?.displayComponent({ displayValue: data.fieldList[faxIndex].value }) : <></>}
							</> : data.type === BoothType.服務台 ? <>
								<Box display="flex" gap={1}>
									<Link
										href={data.fieldList?.find(x => x.fieldId === 'fb')?.value} target="_blank"
										sx={{ cursor: "pointer", "&:hover": { color: theme.palette.secondary.main } }}
									>
										<Facebook />
									</Link>
									<Link
										href={data.fieldList?.find(x => x.fieldId === 'ig')?.value} target="_blank"
										sx={{ cursor: "pointer", "&:hover": { color: theme.palette.secondary.main } }}
									>
										<Instagram />
									</Link>
								</Box>
							</> : null}
						</> : <>
							<Typography variant="subtitle1"><Skeleton variant="text" /></Typography>
							<Typography variant="subtitle1"><Skeleton variant="text" /></Typography>
							<Typography variant="subtitle1"><Skeleton variant="text" /></Typography>
							<Typography variant="subtitle1"><Skeleton variant="text" /></Typography>
						</>}
					</>}
				</Box>
			</Box>
			{/*更多按鈕*/}
			{simpleMode && !disableShowMore &&
				<Box px={2} display="flex" justifyContent="flex-end" color="primary.main" height="16px">
					<Box alignSelf="flex-end" mb={1}>
					<Link
						href={`/Booth?id=${data?.boothId}`} underline="hover" onClick={handleClickShowMore}
						sx={{ cursor: "pointer", "&:hover": { color: theme.palette.secondary.main } }}
						>
							{`${t('user.card.more')} >>`}
						</Link>
					{ /*<Button variant="outlined" href={`/Booth?id=${data?.boothId}`} onClick={handleClickShowMore} sx={{borderRadius: "6px"}}>
						{`${t('user.card.more')} >>`}
					</Button>*/}
					</Box>
				</Box>
			}
		</Box>
	);
}

const BoothCardBadge = styled(({ isOnline, ripple, ...otherProps }: BoothCardBadgeProps) =>
	<Badge
		variant="dot"
		invisible={isOnline === null || isOnline === undefined}
		anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
		{...otherProps}
	/>
)(({ theme, isOnline, ripple }) => ({
	width: "100%",
	'& .MuiBadge-badge': {
		width: "20px",
		height: "20px",
		border: `3px solid ${theme.palette.background.paper}`,
		borderRadius: "50%",
		backgroundColor: isOnline ? theme.palette.success.light : theme.palette.grey[400],
		'&::after': {
			position: 'absolute',
			top: 0,
			left: 0,
			width: '100%',
			height: '100%',
			borderRadius: '50%',
			animation: (isOnline === true && ripple === true) && 'ripple 1.2s infinite ease-in-out',
			border: (isOnline === true && ripple === true) && `1px solid ${theme.palette.success.light}`,
			content: '""',
		},
	},
	'@keyframes ripple': {
		'0%': {
			transform: 'scale(.6)',
			opacity: 1,
		},
		'100%': {
			transform: 'scale(1.8)',
			opacity: 0,
		},
	}
}));
interface BoothCardBadgeProps extends BadgeProps {
	isOnline?: boolean;
	ripple?: boolean;
}