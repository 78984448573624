﻿import { alpha, Backdrop, Box, Button, Container, Divider, Grid, IconButton, Paper, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory, useLocation } from "react-router-dom";
import { AgendaList } from '../Booth/Seminar/AgendaList';
import { CardInfo, Seminar, UserPresence } from '../components/class';
import { useGetAllSeminarsQuery, useLazyGetAllSeminarsQuery } from '../components/services/seminar';
import VideoPlayer from '../components/VideoPlayer ';
import { PageLocation, useGetOnlineListMutation } from '../components/services/system';
import { useGetUserWithListMutation, useLazyGetUserQuery, UserCardInfoModel } from '../components/services/user';
import LiveInfo from './LiveInfo';
import SeminarSignIn from './SeminarSignIn';
import ChannelChatDialog from '../components/ChannelChat/ChannelChatDialog';
import { useSystemConnection } from '../store';
import { SystemControlHub } from '../components/react-signalr/systemControlHub';
import { PageTitleBar } from '../components/Layout/PageTitleBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { useGetTokenQuery } from '../components/services/room';
import { MediaSection } from '../components/MediaSection';
import { KeyboardArrowLeft, KeyboardArrowRight, People } from '@mui/icons-material';
import { BussinessCard } from '../components/BussinessCard/BussinessCardInfo';
import { UserCardDialog } from '../components/BussinessCard/UserCardDialog';
import { SubTitle } from '../components/responsive-components';

export default function Stage() {
    const { t, i18n } = useTranslation();

    let query = new URLSearchParams(useLocation().search);

    const theme = useTheme();

    const systemConnection = useSystemConnection();
    const hubRef = React.useRef<SystemControlHub>();
    React.useEffect(() => {
        if (systemConnection != null) {
            hubRef.current = new SystemControlHub(systemConnection);

            //換連線位置
            hubRef.current.send.ChangeLocation({ location: 2, refId: seminarId.current });

            //收到換直播連結通知，更新資料
            hubRef.current.addHandler.OnChangeLink((link) => {
                getAllSeminars();
            });

            setTimeout(() => { refreshOnlineList() }, 500);
        }
        return (() => {
            if (hubRef.current) {
                hubRef.current.removeHandler.OnChangeLink();
            }
        })
    }, [systemConnection]);

    const seminarId = React.useRef<string>();
    const [currentStage, setCurrentStage] = React.useState<Seminar>();
    React.useLayoutEffect(() => {
        seminarId.current = query.get('id');
        if (hubRef.current)
            hubRef.current.send.ChangeLocation({ location: 2, refId: seminarId.current });
    }, [query]);

    const [getAllSeminars, { seminars = [], isLoading }] = useLazyGetAllSeminarsQuery({
        selectFromResult: ({ data, isLoading }) => ({ seminars: data?.data, isLoading: isLoading })
    });
    React.useLayoutEffect(() => {
        getAllSeminars();
    }, [])
    React.useEffect(() => {
        if (seminarId.current && seminars?.length > 0)
            setCurrentStage({ ...seminars.find(x => x.seminarId == seminarId.current) });
    }, [seminarId, seminars]);


    //取得在線清單
    const [getOnlineList] = useGetOnlineListMutation();
    const [getUserList] = useGetUserWithListMutation();
    const [userList, setUserList] = React.useState([]);
    React.useEffect(() => {
        if (seminarId) {
            console.log("拉資料")
            refreshOnlineList();
        }
    }, [seminarId]);

    function onlyUnique(value, index, self) {
        return self.indexOf(value) === index;
    }
    async function refreshOnlineList() {
        setRefreshable(false);
        setLoading(true);
        try {
            let result = await getOnlineList({ location: PageLocation.活動議程, refId: seminarId.current }).unwrap();
            let onlineUserIds = result.data.filter(onlyUnique) ?? [];
            if (onlineUserIds.length > 0) {
                let userListResult = await getUserList(onlineUserIds).unwrap();
                if (userListResult.isSuccess) setUserList(userListResult.data);
            }
        }
        catch (error) {
            console.error(error);
        }
        finally {
            setLoading(false);
            if (isFirst) {
                setIsFirst(false);
                setRefreshable(true);
            }
            else {
                setTimeout(() => {
                    setRefreshable(true);
                }, 1000);
            }

        }
    }
    const [isFirst, setIsFirst] = React.useState<boolean>(true);
    const [loading, setLoading] = React.useState<boolean>(true);
    const [refreshable, setRefreshable] = React.useState<boolean>(false);

    return (<>
        <PageTitleBar titleTransKey={'title.agendaList'}>
            <Box py={{ xs: 4, sm: 3, md: 4 }} mb={2}>
                <Container>
                    <LiveShow seminar={currentStage} />
                </Container>
            </Box>
            {/*議程表*/}
            <Container sx={{ position: "relative", py: { xs: 2, sm: 2, md: 1.5 }, mb: 3 }}>
                <AgendaList seminars={seminars} />
            </Container>
            {/*在線人員*/}
            <Container sx={{ pt: 2 }}>
                <Box display="flex" flexWrap="wrap" alignItems="center" justifyContent="space-between">
                    <SubTitle icon={<People color='primary' fontSize='large' />}
                        text={t('onlineSeminar.onlineList')}
                    />
                    <IconButton color="primary" size="small" onClick={refreshOnlineList} disabled={!refreshable}
                        sx={{ "&.Mui-disabled": { color: alpha(theme.palette.grey[200], theme.palette.action.disabledOpacity) } }}>
                        {loading ?
                            <FontAwesomeIcon icon={faSpinner} pulse />
                            :
                            <FontAwesomeIcon icon={faSyncAlt} />
                        }
                    </IconButton>
                </Box>
                <OnlineList userList={userList} />
            </Container>
        </PageTitleBar>
        <Backdrop open={isLoading} sx={{ color: '#fff', zIndex: 10000, background: (theme) => theme.palette.background.default }}>
            <Typography>Loading...</Typography>
        </Backdrop>
    </>)
}

function LiveShow(props: { seminar: Seminar }) {
    const { seminar } = props;
    const { t, i18n } = useTranslation();

    const [height, setHeight] = React.useState(0)
    const streamContainer = React.useRef<HTMLDivElement>(null);
    React.useEffect(() => {
        const resizeObserver = new ResizeObserver(entries => {
            setHeight(entries[0].target.clientHeight);
        });
        resizeObserver.observe(streamContainer.current)
        return (() => {
            resizeObserver.disconnect();
        })
    }, []);

    //開啟名片
    const [open, setOpen] = React.useState(false);
    const [getUser, { data: cardData }] = useLazyGetUserQuery();
    function handleOpenCard(userId: string) {
        if (userId) {
            getUser({ userId }, true);
            setOpen(true);
        }
    }
    const handleClose = () => {
        setOpen(false);
    };

    return (<>
        <Typography variant="h5" fontWeight='bold'>{i18n.language == "ch" ? seminar?.chTitle : seminar?.enTitle}</Typography>
        <Typography variant="subtitle1">{t("onlineSeminar.notice_stage")}</Typography>
        <Grid container spacing={2}>
            <Grid item xs={12} lg={8}>
                <div ref={streamContainer} style={{ position: 'relative', overflow: 'hidden', width: '100%', paddingTop: '56.25%', marginBottom: "1rem" }}>
                    <VideoPlayer
                        enableControls
                        url={seminar?.youtubeLink}
                        width='100%'
                        height='100%'
                        style={{ position: 'absolute', inset: '0', width: '100%', height: '100%' }}
                    />
                </div>
            </Grid>
            <Grid item xs={12} lg={4}>
                <Stack sx={{ marginBottom: "1rem", height: height, minHeight: { xs: "484px", lg: "253px" } }}>
                    <Box display="inline-flex" marginBottom={4} position="relative">
                        <LiveInfo seminarId={seminar?.seminarId} />
                        <SeminarSignIn seminarId={seminar?.seminarId} />
                    </Box>
                    {seminar?.channelId &&
                        <ChannelChatDialog channelId={seminar?.channelId} handleOpenCard={handleOpenCard} />
                    }
                </Stack>
            </Grid>
        </Grid>
        <UserCardDialog
            data={{ ...cardData?.data } as UserCardInfoModel}
            open={open}
            onClose={handleClose}
        />
    </>)
}

function StageMedia(props: { seminar: Seminar }) {
    const { seminar } = props;
    const { token } = useGetTokenQuery(null, {
        selectFromResult: ({ data }) => ({ token: data?.data })
    });

    return (
        <div style={{ position: 'relative', overflow: 'hidden', width: '100%', paddingTop: '56.25%', marginBottom: "1rem" }}>
            <MediaSection roomId={seminar.seminarId} roomName={seminar.chTitle} token={token} varient="allowLiveStream" containerStyle={{ position: "absolute", inset: 0 }} />
        </div>
    )
}

/*function SpeakerList(props: { list: Speaker[] }) {
    const { list } = props;
    const { t } = useTranslation();
    const theme = useTheme();
    const isScreen = useMediaQuery(theme.breakpoints.up('md'));

    const [open, setOpen] = React.useState(false);
    const [selectedValue, setSelectedValue] = React.useState<Speaker>();
    function handleClick(value) {
        setOpen(true);
        setSelectedValue(value);
    }
    const handleClose = () => {
        setOpen(false);
    };
    function handleDisplayChange(direction: "left" | "right") {
        const increment = direction === "left" ? -1 : 1;
        let currentIndex = list.findIndex(x => x.title === selectedValue.title);
        if (list.length > 0) {
            if (currentIndex + increment < 0) {
                setSelectedValue(list[list.length - 1]);
            }
            else if (list.length > currentIndex + increment) {
                setSelectedValue(list[currentIndex + increment]);
            }
            else {
                setSelectedValue(list[0]);
            }
        }
    }

    //控制卷軸
    const container = React.useRef<HTMLDivElement>();
    const itemWidth = isScreen ? 128 : 128;
    React.useEffect(() => {
        if (container.current) {
            container.current.scrollTo(0, 0);
        }
    }, [container.current]);

    function onArrowClick(direction: "left" | "right") {
        const increment = direction === "left" ? -1 : 1;
        if (container.current) {
            container.current.scrollBy({
                top: 0,
                left: increment * itemWidth * (isScreen ? 4 : 2),
                behavior: 'smooth'
            });
        }
    };

    return (<>
        <Box display="flex">
            <IconButton onClick={() => onArrowClick("left")}>
                <ChevronLeft />
            </IconButton>
            <Box>
                <ScrollContainer innerRef={container} vertical={false}>
                    {list.map((item) => (
                        <ButtonBase onClick={() => handleClick(item)}>
                            <Skeleton>
                                <Avatar src={item.imageUrl} style={{ width: "128px", height: "128px" }} />
                            </Skeleton>
                            <Typography>{item.title}</Typography>
                            <Typography>{item.subtitle}</Typography>
                        </ButtonBase>
                    ))}
                </ScrollContainer>
            </Box>
            <IconButton onClick={() => onArrowClick("right")}>
                <ChevronRight />
            </IconButton>
        </Box>
        <Dialog onClose={handleClose} open={open} fullWidth={true} maxWidth={'sm'} scroll={'paper'}>
            <IconButton
                onClick={() => handleDisplayChange("left")}
                style={{
                    position: 'absolute', top: '50%', left: '-48px',
                    backgroundColor: 'white', color: 'gray'
                }}
            >
                <ArrowBackIosNew />
            </IconButton>
            <IconButton
                onClick={() => handleDisplayChange("right")}
                style={{
                    position: 'absolute', top: '50%', right: '-48px',
                    backgroundColor: 'white', color: 'gray'
                }}
            >
                <ArrowForwardIos />
            </IconButton>
            <IconButton
                onClick={handleClose}
                style={{
                    position: 'absolute', top: '-54px', right: '0px',
                    backgroundColor: 'transparent', color: 'white'
                }}
            >
                <Close fontSize='large' />
            </IconButton>

            <DialogContent>

            </DialogContent>
        </Dialog>
    </>)
}*/

interface OnlineListProps {
    userList: CardInfo[];
}
function OnlineList(props: OnlineListProps) {
    const { userList } = props;
    const theme = useTheme();

    const [page, setPage] = React.useState(1);
    const [pageSize, setPageSize] = React.useState<number>(24);

    let showList = userList?.slice((page - 1) * pageSize, page * pageSize);
    let totalCount = userList?.length ?? 0;
    let currentPage = page ?? 1;
    let totalPages = Math.ceil(totalCount / pageSize);
    let hasNext = currentPage < totalPages;
    let hasPrevious = currentPage > 1;

    const handleNext = () => setPage(page => page + 1);
    const handlePrevious = () => setPage(page => page - 1);

    //顯示視窗
    const [currentData, setCurrentData] = React.useState<UserCardInfoModel>(null);
    const [open, setOpen] = React.useState(false);
    const handleOpen = (data: CardInfo) => {
        setCurrentData({ ...data, isOnline: true } as UserCardInfoModel);
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    return (<>
        <Box display="flex" alignItems="flex-end" mb={1}>
            <Typography color="secondary">
                <Trans i18nKey="userList.totalNum" values={{ totalNum: totalCount }} />
            </Typography>

            <Paper sx={{
                marginInline: 'auto 2px',
                width: 'fit-content',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
            }}>
                <IconButton onClick={handlePrevious} disabled={!hasPrevious}>
                    <KeyboardArrowLeft />
                </IconButton>
                <Divider variant="middle" flexItem orientation="vertical" />
                <Box style={{ padding: '6px 14px' }}>
                    <Typography variant="body2">
                        <span>{currentPage}</span>
                        {` / ${totalPages}`}
                    </Typography>
                </Box>
                <Divider variant="middle" flexItem orientation="vertical" />
                <IconButton onClick={handleNext} disabled={!hasNext}>
                    <KeyboardArrowRight />
                </IconButton>
            </Paper>
        </Box>
        <Box display="grid" gap={{ xs: 1, md: 2 }} gridTemplateColumns={{ xs: "repeat(2, 1fr)", sm: "repeat(4, 1fr)", md: "repeat(5, 1fr)", lg: "repeat(6, 1fr)", xl: "repeat(6, 1fr)" }}>
            {showList.map((user) => (
                <Box key={user.userId}>
                    <BussinessCard
                        data={user}
                        isOnline
                        simpleMode
                        withBorder
                        onClickShowMore={handleOpen}
                    />
                </Box>
            ))}
        </Box>
        <UserCardDialog
            data={currentData}
            open={open}
            onClose={handleClose}
        />
    </>)
}