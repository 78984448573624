﻿import * as React from 'react';
import { Backdrop, CircularProgress, Typography } from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton, GridValueGetterParams } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { zhTWGrid } from '../../data-grid-locale';
import { ConfirmButton, Dialog } from '../../../components/responsive-components';
import { ReportData, useGetReportsQuery } from '../../../components/services/system';
import { SubTitle } from '../../../components/responsive-components/Typography';
import { BoothQandA, CardInfo } from '../../../components/class';
import BoardCard from '../../../Booth/BoardCard/BoardCard';
import { BussinessCard } from '../../../components/BussinessCard/BussinessCardInfo';
import { createTheme, ThemeProvider } from '@mui/material/styles';

export default function ReportList() {
    const { data: reportList, isLoading } = useGetReportsQuery();
    const { t } = useTranslation();

    const [open, setOpen] = React.useState(false);
    const [displayValue, setDisplayValue] = React.useState<string>('');
    function handleDetails(value: string) {
        setOpen(true);
        setDisplayValue(value)
    }
    const handleClose = () => {
        setOpen(false);
    };

    return (<>
        <SubTitle
            icon='circle'
            text={t('manage.reportList.title')}
            subText={t('manage.reportList.subTitle')}
        />
        <ReportDataList dataList={reportList?.data ?? []} handleDetails={handleDetails} loading={isLoading} />
        <DetailsWindow value={displayValue} open={open} onClose={handleClose} />
    </>)
};

function ReportDataList(props: ReportDataListProps) {
    const { dataList, loading, handleDetails } = props;
    const { t, i18n } = useTranslation();

    const columns: GridColDef[] = [
        {
            field: 'userId',
            headerName: t('manage.reportList.options.id'),
            //flex: 1,
            minWidth: 120,
        },
        {
            field: 'fullName',
            headerName: t('manage.reportList.options.name'),
            //flex: 0.8,
            minWidth: 150,
            renderCell: (params: GridRenderCellParams<string>) => (
                <>
                    {i18n.language == "ch" ?
                        `${params.getValue(params.id, 'lastName') || ''} ${params.getValue(params.id, 'firstName') || ''}` :
                        `${params.getValue(params.id, 'firstName') || ''} ${params.getValue(params.id, 'lastName') || ''}`
                    }
                </>
            ),
        },
        {
            field: 'description',
            headerName: t('manage.reportList.options.content'),
            flex: 1,
            minWidth: 280,
        },
        {
            field: 'jsonData',
            headerName: t('manage.reportList.options.details'),
            //flex: 1,
            minWidth: 140,
            renderCell: (params) => <ConfirmButton onClick={() => handleDetails(params.value.toString())}>{t('manage.reportList.details')}</ConfirmButton>
        },
        {
            field: 'createTime',
            headerName: t('manage.reportList.options.createTime'),
            type: 'dateTime',
            //flex: 1,
            minWidth: 160,
            renderCell: (params) => <>{format(new Date(params.value.toString()), "yyyy/MM/dd HH:mm")}</>
        }
    ];

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                <GridToolbarExport csvOptions={{ utf8WithBom: true }} />
            </GridToolbarContainer>
        );
    }

    return (
        <ThemeProvider theme={(theme) => createTheme({ ...theme, palette: { ...theme.palette, background: { ...theme.palette.background, default: "#ffffff" } } })}>
            <DataGrid
                rows={dataList}
                columns={columns}
                pageSize={10}
                autoHeight
                disableColumnMenu
                disableColumnSelector
                components={{
                    Toolbar: CustomToolbar,
                }}
                localeText={i18n.language == "ch" ? zhTWGrid : null}
                loading={loading}
            />
        </ThemeProvider >
    )
}
interface ReportDataListProps {
    dataList: ReportData[];
    handleDetails: (value: string) => void;
    loading?: boolean;
}

function DetailsWindow(props: DetailsWindowProps) {
    const { value, open, onClose } = props;

    React.useEffect(() => {
        if (value && value != '') {
            RenderContent(value);
		}
    }, [value])

    const [json, setJson] = React.useState<JsonType>(null);
    function RenderContent(value: string) {
        setJson(JSON.parse(value));
    }

    const handleClose = () => {
        onClose();
    };

    function emptyFunction() {

    }
    async function emptyAsyncFunction() {
        return true;
    }

    return (
        <Dialog onClose={handleClose} open={open} PaperProps={{ style: { background: 'none', boxShadow: 'none' } }}>
            {json &&
                <div style={{ minWidth: 600 }}>
                {("questioner" in json) ?
                    <BoardCard
                        data={json}
                        handleReply={emptyAsyncFunction}
                        handleLikeMarkClick={emptyAsyncFunction}
                        index={-1}
                        isManage={false}
                    /> :
                    ("userId" in json) ?
                        <BussinessCard
                            data={json}
                        />:
                    <></>
                }
                </div>
            }
        </Dialog>
    )
}
interface DetailsWindowProps {
    open: boolean;
    value: string;
    onClose: () => void;
}

type JsonType = BoothQandA | CardInfo | null;