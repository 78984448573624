﻿import * as React from "react";
import { Button, ConfirmButton, Dialog, DialogActions, DialogContent, DialogTitle, ErrorText, important, TextField } from '../components/responsive-components';
import { Backdrop, Box, CircularProgress, FormHelperText, styled, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { useDispatchWithType, useUser } from "../store";
import { showModal } from "../store/rootReducer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar, faClock } from '@fortawesome/free-solid-svg-icons';
import { CardInfo } from "../components/class";
import { BussinessCard } from "../components/BussinessCard/BussinessCardInfo";

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    '& .MuiToggleButtonGroup-grouped': {
        margin: theme.spacing(1),
        border: 0,
        '&.Mui-disabled': {
            border: 0,
        },
        '&:not(:first-of-type)': {
            borderRadius: theme.shape.borderRadius,
        },
        '&:first-of-type': {
            borderRadius: theme.shape.borderRadius,
        },
    },
}));

type formData = {
    receiverId: string;
}

interface BoothAppointmentProps {
    boothId: string;
    managers: CardInfo[];
    open: boolean;
    onClose: () => void;
}

export default function BoothAppointment(props: BoothAppointmentProps) {
    const { boothId, managers, open, onClose } = props;
    const { t } = useTranslation();
    const dispatch = useDispatchWithType();

    const schema = yup.object().shape({
        receiverId: yup.string().required("請選擇一位企業人員")
    });
    const { control, handleSubmit: handleOnSubmit } = useForm<formData>({
        mode: "onBlur", resolver: yupResolver(schema),
        defaultValues: {
            receiverId: '',
        }
    });

    function onNext(data: formData) {
        dispatch(showModal({ modalType: "APPOINTMENT", modalProps: { receiverId: data.receiverId } }));
        onClose();
    }

    return (
        <>
            <Dialog open={open} onClose={onClose} maxWidth={"sm"} fullWidth sx={{ px: 2, py: 1 }}>
                <DialogTitle>
                    <Box display="flex" alignItems="center">
                        <Box display="flex" width="22px" height="22px" justifyContent="center" alignItems="center" sx={{ color: 'primary.main', mr: 2 }}>
                            <FontAwesomeIcon icon={faClock} color="inherit" transform="shrink-8 down-3" mask={faCalendar} fixedWidth /></Box>
                        <Typography variant="h6" fontWeight="bold">{t("dialog.appointment.title")}</Typography>
                    </Box>
                </DialogTitle>
                <Box component="form" onSubmit={handleOnSubmit(onNext)}>
                    <DialogContent sx={{ py: 0 }}>
                        <Typography>{important}{"請選擇您想申請預約面談的企業人員："}</Typography>
                        <Controller
                            name="receiverId"
                            control={control}
                            render={({ field, fieldState }) => (<>
                                <StyledToggleButtonGroup
                                    color="primary"
                                    value={field.value}
                                    exclusive
                                    onChange={(event, value) => { if (value !== null) field.onChange(value) }}
                                >
                                    {managers.map((manager => (
                                        <ToggleButton key={manager.userId} value={manager.userId}>
                                            <BussinessCard
                                                data={manager}
                                                simpleMode
                                                withBorder
                                                disableShowMore
                                            />
                                        </ToggleButton>
                                    )))}
                                </StyledToggleButtonGroup>
                                <FormHelperText error={fieldState.invalid}>{fieldState.invalid ? fieldState.error.message : ' '}</FormHelperText>
                            </>)}
                        />
                    </DialogContent>
                    <DialogActions sx={{ justifyContent: 'flex-end' }}>
                        <ConfirmButton type="submit">{"下一步"}</ConfirmButton>
                    </DialogActions>
                </Box>
            </Dialog>
        </>
    )
}