﻿import * as React from 'react';
import { Backdrop, Button, CircularProgress, Dialog, DialogContent, DialogTitle, Divider, IconButton, Toolbar, Typography, useTheme } from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton, GridValueFormatterParams, GridValueGetterParams } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { zhTWGrid } from '../data-grid-locale';
import { SubTitle } from '../../components/responsive-components/Typography';
import { Close } from '@mui/icons-material';
import { SeminarUserSignsData, useGetAllSeminarUserSignsQuery } from '../../components/services/seminar';
import zhTW from 'date-fns/locale/zh-TW';
import { CardInfo, UserExtendField } from '../../components/class';
import { renderCellExpand } from '../../components/DataGrid/renderCellExpand';

export default function SeminarSignInRecordList() {
    const { t, i18n } = useTranslation();
    const { data: signInList, isLoading } = useGetAllSeminarUserSignsQuery(null, {
        refetchOnMountOrArgChange: true
    });

    const [displayValue, setDisplayValue] = React.useState<SeminarUserSignsData>(null);
    function handleDisplayUserSignList(id: string) {
        setDisplayValue(signInList.data.find(x => x.id === id));
        handleClickOpen();
    }

    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    return (
        <div>
            <SubTitle
                icon='circle'
                text={t('manage.title.analysis.seminarSignInRecord')}
                subText={t('manage.seminarSignInRecord.subTitle')}
            />
            <UserSignListWindow open={open} data={displayValue} onClose={handleClose}/>

            {signInList &&
                <div className="d-flex p-3">
                    <SeminarList dataList={signInList.data.map((item) => ({ ...item, number: item.userSigns.length }))} handleDisplay={handleDisplayUserSignList} />
                </div>
            }

            <Backdrop open={isLoading} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    )
}

interface SeminarListData extends SeminarUserSignsData {
    number: number;
}
interface SeminarListProps {
    dataList: SeminarListData[];
    handleDisplay: (id: string) => void;
}
function SeminarList(props: SeminarListProps) {
    const { dataList, handleDisplay } = props;
    const { t, i18n } = useTranslation();

    const columns: GridColDef[] = [
         {
            field: 'date',
            headerName: t('manage.seminarSignInRecord.options.date'),
            //flex: 1,
            minWidth: 120,
            valueGetter: (params: GridValueGetterParams) => {
                if (i18n.language == "ch")
                    return `${format(new Date(params.row.startTime.toString()), "MM/dd(EEEEE)", { locale: zhTW })}`;
                else
                    return `${format(new Date(params.row.startTime.toString()), "MM/dd(E)")}`;
            }
        },
        {
            field: 'time',
            headerName: t('manage.seminarSignInRecord.options.time'),
            //flex: 1,
            minWidth: 120,
            valueGetter: (params: GridValueGetterParams) =>
                `${format(Date.parse(params.row.startTime.toString()), "HH:mm")}-${format(Date.parse(params.row.endTime.toString()), "HH:mm")}`,
        },
        {
            field: 'agendaTitle',
            headerName: t('manage.seminarSignInRecord.options.agendaTitle'),
            flex: 0.5,
            minWidth: 150,
            valueGetter: (params: GridValueGetterParams) => {
                if (i18n.language == "ch")
                    return `${params.row.seminarChTitle || ''}`;
                else
                    return `${params.row.seminarEnTitle || ''}`;
            },
        },
        {
            field: 'boothName',
            headerName: t('manage.seminarSignInRecord.options.boothName'),
            flex: 1,
            minWidth: 150,
            valueGetter: (params: GridValueGetterParams) => {
                if (i18n.language == "ch")
                    return `${params.row.boothChName || ''}`;
                else
                    return `${params.row.boothEnName || ''}`;
            },
        },
        {
            field: 'number',
            headerName: t('manage.seminarSignInRecord.options.number'),
            flex: 0.5,
            minWidth: 100,
            type: 'number'
        },
        {
            field: 'id',
            headerName: t('manage.seminarSignInRecord.options.display'),
            minWidth: 100,
            renderCell: (params) =>
                <Button
                    onClick={() => handleDisplay(params.getValue(params.id, 'id').toString())}
                >
                    {`${t('manage.seminarSignInRecord.link')} >>`}
                </Button>
        }
    ];

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                <GridToolbarExport csvOptions={{ utf8WithBom: true }} />
            </GridToolbarContainer>
        );
    }
    const [page, setPage] = React.useState(10);

    return (
        <DataGrid
            rows={dataList}
            columns={columns}
            pageSize={10}
            autoHeight
            disableColumnMenu
            disableColumnSelector
            components={{
                Toolbar: CustomToolbar,
            }}
            localeText={i18n.language == "ch" ? zhTWGrid : null}
        />
    )
}

interface UserSignListWindowProps {
    open: boolean;
    onClose: () => void;
    data: SeminarUserSignsData;
}
function UserSignListWindow(props: UserSignListWindowProps) {
    const { open, onClose, data } = props;
    const { t, i18n } = useTranslation();

    const handleClose = () => {
        onClose();
    };

    if (data == null)
        return null;

    return (
        <Dialog onClose={handleClose} open={open} fullWidth={true} maxWidth={'xl'}
            PaperProps={{ sx: { width: 'calc(100% - 120px)', overflow: 'initial', height: '100%' } }}>
            <DialogTitle>
                <Typography component="span" variant='h6' fontWeight='bold' color="primary" sx={{marginRight: '24px'}}>
                    {t("manage.seminarSignInRecord.listTitle")}
                </Typography>
                <Typography component="span" variant='h6' fontWeight='bold' sx={{marginRight: '24px'}}>
                    {i18n.language == "ch" ?
                        format(new Date(data.startTime), "MM/dd(EEEEE)", { locale: zhTW }) :
                        format(new Date(data.startTime), "MM/dd(E)")
                    }
                </Typography>
                <Typography component="span" variant='h6' fontWeight='bold' sx={{marginRight: '24px'}}>
                    {`${format(new Date(data.startTime), "HH:mm")}-${format(new Date(data.endTime), "HH:mm")}`}
                </Typography>
                <Typography component="span" variant='h6' fontWeight='bold' sx={{marginRight: '24px'}}>
                    {i18n.language == "ch" ? `${data.seminarChTitle}` : `${data.seminarEnTitle}`}
                </Typography>
                <Typography component="span" variant='h6' fontWeight='bold' sx={{marginRight: '24px'}}>
                    {`${(i18n.language == "ch" ? data.boothChName : data.boothEnName) || ''}`}
                </Typography>   
            </DialogTitle>
            <Divider />
            <DialogContent>
                <Typography variant='h6' fontWeight='bold' sx={{ color: theme => theme.palette.primary.main }}>
                    {t("manage.seminarSignInRecord.listSubtitle")}
                </Typography>
                <UserSignList dataList={data.userSigns.map((item) => ({ ...item, id: item.userId }))} />
            </DialogContent>
            <IconButton
                onClick={handleClose}
                style={{
                    position: 'absolute', top: '0px', right: '-54px',
                    backgroundColor: 'transparent', color: 'white'
                }}
            >
                <Close fontSize='large' />
            </IconButton>
        </Dialog>
    )
}

interface UserSignListData extends CardInfo {
    id: string;
}
interface UserSignListProps {
    dataList: UserSignListData[];
}
function UserSignList(props: UserSignListProps) {
    const { dataList } = props;
    const { t, i18n } = useTranslation();

    const columns: GridColDef[] = [
        {
            field: 'fullName',
            headerName: t('manage.seminarSignInRecord.options.fullName'),
            flex: 0.8,
            minWidth: 150,
            valueGetter: (params: GridValueGetterParams) => {
                if (i18n.language == "ch")
                    return `${params.row.lastName || ''} ${params.row.firstName || ''}`;
                else
                    return `${params.row.firstName || ''} ${params.row.lastName || ''}`;
            },
        },
        {
            field: 'studentId',
            headerName: t('manage.seminarSignInRecord.options.studentId'),
            flex: 0.8,
            minWidth: 150,
            valueGetter: (params: GridValueGetterParams) =>
                `${(params.row.infoFields as UserExtendField[])?.filter((item) => item.fieldId === "studentId").pop()?.value || ''}`,
            valueFormatter: (params) => params.value ? `'${params.value}` : "",
        },
        {
            field: 'academy',
            headerName: t('manage.seminarSignInRecord.options.department'),
            flex: 1,
            minWidth: 120,
            valueGetter: (params: GridValueGetterParams) =>
                `${(params.row.infoFields as UserExtendField[])?.filter((item) => item.fieldId === "academy").pop()?.value || ''}`,
            renderCell: renderCellExpand,
        },
        {
            field: 'grade',
            headerName: t('manage.seminarSignInRecord.options.grade'),
            flex: 1,
            minWidth: 140,
            valueGetter: (params: GridValueGetterParams) =>
                `${(params.row.infoFields as UserExtendField[])?.filter((item) => item.fieldId === "grade").pop()?.value || ''}`,
            renderCell: renderCellExpand,
        },
        {
            field: 'phone',
            headerName: t('manage.seminarSignInRecord.options.phone'),
            flex: 1,
            minWidth: 140,
            valueGetter: (params: GridValueGetterParams) =>
                `${params.row.phone || ''}`,
            valueFormatter: (params) => params.value ? `'${params.value}` : "",
            renderCell: renderCellExpand,
        },
        {
            field: 'email',
            headerName: t('manage.seminarSignInRecord.options.email'),
            flex: 1,
            minWidth: 140,
            valueGetter: (params: GridValueGetterParams) =>
                `${params.row.email || ''}`,
            renderCell: renderCellExpand,
        },
    ];

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                <GridToolbarExport csvOptions={{ utf8WithBom: true }} />
            </GridToolbarContainer>
        );
    }

    return (
        <DataGrid rows={dataList} columns={columns}
            pageSize={10}
            autoHeight
            disableColumnMenu
            disableColumnSelector
            components={{
                Toolbar: CustomToolbar,
            }}
            localeText={i18n.language == "ch" ? zhTWGrid : null}
        />
    )
}