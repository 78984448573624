﻿import * as React from 'react';
import { makeStyles } from "@mui/styles";
import { Backdrop, Box, CircularProgress, Stack, Toolbar, Typography } from '@mui/material';
import { Col, Container, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { ConfirmButton, TextField } from '../../../components/responsive-components';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { useSystemConnection } from '../../../store';
import { SystemControlHub } from '../../../components/react-signalr/systemControlHub';
import { SubTitle } from '../../../components/responsive-components/Typography';

const useStyles = makeStyles((theme) => ({
    root: {

    },
    title: {
        padding: 10,
        borderRadius: "6px 6px 0px 0px",
        fontWeight: 'bold',
        color: 'white',
        background: 'linear-gradient(to right, #368AFC, #7DDCFF)',
    },
    row: {
        display: 'flex',
        justifyContent: 'center',
    },
    successText: {
        color: '#4de80d',
        fontWeight: 'bold',
    },
}));

export default function PublishNotify() {
    const classes = useStyles();
    const { t } = useTranslation();

    const schema = yup.object().shape({
        messageContent: yup.string(),
        link: yup.string(),
    });
    const { register, handleSubmit, formState: { errors }, reset } = useForm({
        mode: "onBlur", resolver: yupResolver(schema),
        defaultValues: { message: '', link: '' }
    });

    const systemConnection = useSystemConnection();
    const hubRef = React.useRef<SystemControlHub>();
    React.useEffect(() => {
        if (systemConnection != null)
            hubRef.current = new SystemControlHub(systemConnection);
    }, []);
    const [isLoading, setIsLoading] = React.useState(false);

    function onSubmit(data) {
        setIsLoading(true);
        //廣播通知的參數格式已經固定好了
        //message: 文字訊息
        //link: 連結
        if (hubRef.current) {
            hubRef.current.invoke.SendNoticeMessage(data.message, data.link).then(() => {
                reset();
                setIsLoading(false);
            });
        }
    }

    return (
        <div>
            <SubTitle
                icon='circle'
                text={t('manage.title.manage.annoucement')}
                style={{ marginLeft: '36px' }}
            />

                <Box my={2.5}>
                    <Typography variant='subtitle1'>
                        {t('manage.announce.immediate_announcement.description')}
                    </Typography>
                </Box>

                <Stack component="form" onSubmit={handleSubmit(onSubmit)}>
                    <Typography variant='h6'>
                        {t('manage.announce.immediate_announcement.message_field')}
                    </Typography>
                    <TextField
                        variant="outlined"
                        inputRef={register('message').ref}
                        error={!!errors.message}
                        helperText={errors.message ? errors.message.message : ' '}
                        {...register("message")}

                        inputProps={{ maxLength: 50 }}
                    />

                    <Typography variant='h6'>
                        {t('manage.announce.immediate_announcement.link_field')}
                    </Typography>
                    <TextField
                        variant="outlined"
                        inputRef={register('link').ref}
                        error={!!errors.link}
                        helperText={errors.link ? errors.link.message : ' '}
                        {...register("link")}
                    />
                    <Box display="flex" justifyContent="flex-end">
                        <ConfirmButton type="submit" loading={isLoading}>
                            {t('manage.announce.immediate_announcement.submit')}
                        </ConfirmButton>
                    </Box>
                </Stack>
        </div>
    )
};