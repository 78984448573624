﻿export const boothTypeList: BoothType[] = [
	{ nameKey: 'booth_type.complex', type: 0, color: 'deepskyblue' },
	{ nameKey: 'booth_type.information', type: 1, color: 'blueviolet' },
	{ nameKey: 'booth_type.semiconductor', type: 2, color: 'darkgoldenrod' },
	{ nameKey: 'booth_type.optoelectronics', type: 3, color: 'gray' },
	{ nameKey: 'booth_type.electronics', type: 4, color: 'darkcyan' },
	{ nameKey: 'booth_type.network', type: 5, color: 'tomato' },
	{ nameKey: 'booth_type.job', type: 6, color: 'cornflowerblue' },
	{ nameKey: 'booth_type.biomedical', type: 7, color: 'chocolate' },
	{ nameKey: 'booth_type.consortium', type: 8, color: 'yellowgreen' },
	{ nameKey: 'booth_type.organization', type: 9, color: 'crimson' },
	{ nameKey: 'booth_type.public', type: 10, color: 'plum' },
	{ nameKey: 'booth_type.startup', type: 11, color: 'darkblue' },
	{ nameKey: 'booth_type.GLORIA', type: 12, color: 'darkorange' },
];

export interface BoothType {
	nameKey: string,
	type: number,
	color: string,
}