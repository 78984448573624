﻿import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { ResultType } from './utils';

export interface PointData {
    id: string;
    points: number;
    firstName: string;
    lastName: string;
    studentId: string;
    grade: string;
    department: string;
    email: string;
}

export interface TicketData {
    id: string;
    firstName: string;
    lastName: string;
    studentId: string;
    grade: string;
    department: string;
    email: string;
    createTime: string;
}
export enum LoyaltyCardPointType {
    說明會點數,
    銀幣,
    金幣,
    英雄勳章
}
export enum LotteryTicketType {
    說明會,
    銀幣,
    金幣
}

export interface HeroMedalHistoryModel {
    id: string;
    senderBoothChName: string;
    senderBoothEnName: string;
    senderName: string;
    reward: string;
    createTime: string;
}
export interface HeroMedalHistoryData {
    id: string;
    studentId: string;
    studentName: string;
    studentAcademy: string;
    studentGrade: string;
    studentEmail: string;
    senderBoothChName: string;
    senderBoothEnName: string;
    senderName: string;
    reward: string;
    isExchanged: boolean;
    createTime: string;
}

//通用api
const baseApi = createApi({
    reducerPath: 'pointApi',
    baseQuery: fetchBaseQuery({ baseUrl: '/api/point' }),
    endpoints: (builder) => ({
        redeem: builder.mutation<ResultType<null>, string>({
            query: (code) => ({
                url: `redeem`,
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: '"' + code + '"'
            }),
        }),
        getMyHeroRewardHistory: builder.query<ResultType<HeroMedalHistoryModel[]>, void>({
            query: () => `heroReward`,
        }),
    })
});

//superuser或admin用api
export const pointApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getRedeemCodes: builder.query<ResultType<any>, void>({
            query: () => `redeemCode`,
        }),
        getNewRedeemCode: builder.mutation<ResultType<string>, void>({
            query: () => ({
                url: `redeemCode`,
                method: 'POST',
            }),
        }),
        deleteRedeemCode: builder.mutation<ResultType<null>, string>({
            query: (code) => ({
                url: `redeemCode/${code}`,
                method: 'DELETE',
            }),
        }),
        redeemReward: builder.mutation<ResultType<null>, { userId: string, count: number }>({
            query: ({ ...patch }) => ({
                url: `redeemReward`,
                method: 'POST',
                body: patch,
            }),
        }),
        getPointList: builder.query<ResultType<PointData[]>, LoyaltyCardPointType>({
            query: (type) => `points/${type}`,
        }),
        getTicketList: builder.query<ResultType<TicketData[]>, LotteryTicketType>({
            query: (type) => `tickets/${type}`,
        }),
        getUserHeroRewardHistory: builder.query<ResultType<HeroMedalHistoryModel[]>, string>({
            query: (studentId) => `heroReward/${studentId}`,
        }),
        getHeroRewardHistoryData: builder.query<ResultType<HeroMedalHistoryData[]>, string>({
            query: (boothId) => boothId === null ? `heroReward/all` : `heroReward/list/${boothId}`,
        }),
        redeemHeroReward: builder.mutation<ResultType<null>, { studentId: string, id: string }>({
            query: ({ studentId, id }) => ({
                url: `heroReward/redeem/${studentId}/${id}`,
                method: 'POST',
                body: "",
            }),
        }),
    }),
    overrideExisting: false
});

export const { useRedeemMutation } = pointApi;
export const { useGetMyHeroRewardHistoryQuery } = pointApi;
export const { useLazyGetMyHeroRewardHistoryQuery } = pointApi;

//superuser或admin用api
export const { useGetRedeemCodesQuery } = pointApi;
export const { useGetNewRedeemCodeMutation } = pointApi;
export const { useDeleteRedeemCodeMutation } = pointApi;
export const { useRedeemRewardMutation } = pointApi;
export const { useGetPointListQuery } = pointApi;
export const { useLazyGetPointListQuery } = pointApi;
export const { useGetTicketListQuery } = pointApi;
export const { useLazyGetTicketListQuery } = pointApi;
export const { useGetUserHeroRewardHistoryQuery } = pointApi;
export const { useLazyGetUserHeroRewardHistoryQuery } = pointApi;
export const { useGetHeroRewardHistoryDataQuery } = pointApi;
export const { useLazyGetHeroRewardHistoryDataQuery } = pointApi;
export const { useRedeemHeroRewardMutation } = pointApi;