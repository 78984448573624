﻿import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { Question } from '../class';
import { ResultType } from './utils';

export const baseApi = createApi({
    reducerPath: 'questionApi',
    baseQuery: fetchBaseQuery({ baseUrl: 'api/question' }),
    endpoints: (builder) => ({
        getAllQuestions: builder.query<ResultType<Question[]>, void>({
            query: () => ``,
        }),
        getQuestion: builder.query<ResultType<Question>, string>({
            query: (id) => `${id}`,
        }),
    }),
})

//exhibitor或superuser或admin用api
export const questionApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        postQuestion: builder.mutation<ResultType<null>, Question>({
            query: (patch) => ({
                url: ``,
                method: 'POST',
                body: patch
            }),
        }),
        putQuestion: builder.mutation<ResultType<null>, Question>({
            query: (patch) => ({
                url: ``,
                method: 'PUT',
                body: patch
            }),
        }),
        deleteQuestion: builder.mutation<ResultType<null>, Question>({
            query: (patch) => ({
                url: ``,
                method: 'DELETE',
                body: patch
            }),
        })
    }),
    overrideExisting: false
});

export const { useGetQuestionQuery } = baseApi;
export const { useLazyGetQuestionQuery } = baseApi;
export const { useGetAllQuestionsQuery } = baseApi;
export const { useLazyGetAllQuestionsQuery } = baseApi;
export const { usePostQuestionMutation } = questionApi;
export const { usePutQuestionMutation } = questionApi;
export const { useDeleteQuestionMutation } = questionApi;