﻿import * as React from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import { Booth, BoothArticle, UserRole } from '../class'
import { useForm } from 'react-hook-form';
import { useUser } from '../../store';
import { ErrorMessage } from '@hookform/error-message';
import { ConfirmButton, important, TextField } from '../responsive-components';
import { useTranslation } from 'react-i18next';
import TextEditor from '../Editor/CKEditor';

export default function BoothArticleEditDialog(props: BoothArticleEditDialogProps) {
    const { boothId, data, open, onClose, isLoading } = props;
    const { t, i18n } = useTranslation();

    const user = useUser();
    const { register, watch, formState: { errors }, handleSubmit, setValue, reset } = useForm<BoothArticle>({
        defaultValues: data ??
        {
            dataId: '', boothId: boothId, posterId: user.userId, editorId: [],
            title: '', content: '', createTime: new Date().toISOString()
        },
        mode: 'onChange'
    });

    const watchContentField = watch("content");
    const handleContentChange = (content: string) => setValue("content", content);

    const pinValue = watch("isPin");
    const handlePinChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue("isPin", (event.target.value === 'true'))
    };

    React.useEffect(() => {
        register("content", {
            required: `${t('boothArticle.editDialog.error_required')}`
        });
    }, [])

    React.useEffect(() => {
        reset(data ?? {
            dataId: '',
            boothId: boothId,
            posterId: user.userId,
            editorId: [],
            title: '',
            content: '',
            isPin: false,
            createTime: new Date().toISOString()
        });
    }, [data, open])

    const handleClose = (result?: BoothArticle) => {
        onClose(result);
    };

    return (
        <Dialog
            onClose={handleClose}
            fullWidth
            maxWidth='lg'
            open={open}
            PaperProps={{ sx: { overflow: 'inherit' } }}
            disableEnforceFocus
        >
            <Box component="form" onSubmit={handleSubmit(handleClose)}>
                <DialogTitle>
                    <Box className="d-flex flex-row flex-wrap justify-content-center">
                        <Typography variant='h6'>
                            {data ? `${t('boothArticle.editDialog.editTitle')}` : `${t('boothArticle.editDialog.newTitle')}`}
                        </Typography>
                        <Button onClick={() => handleClose()} style={{ position: 'absolute', top: '12px', right: '12px' }}>
                            {t('boothArticle.editDialog.discard')}
                        </Button>
                    </Box>
                </DialogTitle>
                <DialogContent style={{ maxHeight: 'calc(100vh - 200px)' }}>
                    {(user && user.role >= UserRole.administrator) &&
                        <Box flex='1 0' className="d-flex  mt-2" style={{ alignItems: 'center' }}>
                            <Typography variant='h6' fontWeight='bold' style={{ marginLeft: '7px' }}>
                                {`${t('boothArticle.editDialog.isPin')}`}
                            </Typography>
                            <RadioGroup
                                row
                                value={pinValue}
                                onChange={handlePinChange}
                                style={{ marginLeft: '24px' }}
                            >
                                <FormControlLabel value={false} control={<Radio />} label={`${t('boothArticle.editDialog.no')}`} style={{ marginBottom: '0px' }} />
                                <FormControlLabel value={true} control={<Radio />} label={`${t('boothArticle.editDialog.yes')}`} style={{ marginBottom: '0px' }} />
                            </RadioGroup>
                        </Box>
                    }
                    <Box flex='1 0 100%' className="d-flex flex-column mt-2">
                        <Typography variant='h6' fontWeight='bold'>
                            {important}{`${t('boothArticle.editDialog.title')}`}
                        </Typography>
                        <TextField
                            variant='outlined'
                            size="small"
                            margin="dense"
                            inputProps={{ maxLength: 3001 }}
                            error={!!errors.title}
                            {...register("title", {
                                required: `${t('boothArticle.editDialog.error_required')}`
                            })}
                        />
                        <div style={{ minHeight: '24px' }}>
                            <ErrorMessage
                                errors={errors}
                                name={'title'}
                                render={({ message }) =>
                                    <Typography sx={{ color: (theme) => theme.palette.error.main }}>
                                        {`${t(message)}`}
                                    </Typography>
                                }
                            />
                        </div>
                    </Box>
                    <Box flex='1 0 100%' className="d-flex flex-column mt-2">
                        <Typography variant='h6' fontWeight='bold'>
                            {important}{`${t('boothArticle.editDialog.content')}`}
                        </Typography>
                        <TextEditor
                            data={watchContentField}
                            onChange={handleContentChange}
                        />
                        <div style={{ minHeight: '24px' }}>
                            <ErrorMessage
                                errors={errors}
                                name={'content'}
                                render={({ message }) =>
                                    <Typography sx={{ color: (theme) => theme.palette.error.main }}>
                                        {`${t(message)}`}
                                    </Typography>
                                }
                            />
                        </div>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <ConfirmButton type="submit" disabled={isLoading}>
                        {t('boothArticle.editDialog.saveButton')}
                    </ConfirmButton>
                </DialogActions>
            </Box>
        </Dialog>
    )
}
interface BoothArticleEditDialogProps {
    boothId: string;
    data: BoothArticle;
    open: boolean;
    onClose: (result?: BoothArticle) => void;
    isLoading?: boolean;
}