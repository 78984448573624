﻿import * as React from 'react';
import { SubTitle } from '../../components/responsive-components/Typography';
import { Trans, useTranslation } from 'react-i18next';
import { LoyaltyCardData } from '../../components/services/user';
import { Box, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useProgressiveImage } from '../../Posters/LazyBackground';

const loading = 'images/loading.jpg'

interface GamePointsManageProps {
    data: LoyaltyCardData;
}
export default function GamePointsManage(props: GamePointsManageProps) {
    const { data } = props;
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('lg'));

    const imageSrc = useProgressiveImage("images/遊戲金幣.png");
    return (<>
        <Stack ml={{ xs: 0, sm: 3 }} mb={2}>
            <Typography variant='subtitle1'>
                <Trans i18nKey="manage.gamePointsManage.subTitle"
                    defaults="本集點於 <0>10/5 10:00-14:00 期間</0>，於<0>遊戲會場</0>內完成任務即可獲得金幣！<1/>累積 10 顆即可獲得一張<2>加碼遊戲抽獎券</2>（<2>金幣抽獎券</2>）並同時參加抽獎。<1/><3>※ 每人由「職涯戰爭大撒幣」活動獲得金幣至多16顆</3><1/><3>※ 共有8組問卷，可收集24顆金幣</3>"
                    components={[<span style={{ color: theme.palette.primary.main }}></span>, <br />, <strong></strong>, <span style={{ color: theme.palette.error.light }}></span>]}
                />
            </Typography>
        </Stack>
        <Box display="grid" gridTemplateColumns={{ xs: "1fr", sm: "74px 1fr" }} gap={1.5} alignItems="flex-end" justifyItems={{ xs: "center", sm: "flex-start" }} mb={1.5}>
            <img src={imageSrc || loading} width="74px" height="74px" />
            <Typography variant='h6' fontWeight="bold">
                <Trans i18nKey="manage.gamePointsManage.pointText"
                    defaults="已累積 <0>{{totalPoint}}</0> 顆金幣<1/>共獲得 <0>{{totalTicket}}</0> 張加碼遊戲抽獎券（金幣抽獎券）"
                    values={{ totalPoint: data?.goldenCoin, totalTicket: data?.goldenTicket }}
                    components={[<span style={{ color: theme.palette.error.light }}></span>, <br />]}
                />
            </Typography>
        </Box>
    </>)
};