﻿import * as React from 'react';
import { Box, Divider, Link, Paper, styled, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Table, TableBody, TableCell, tableCellClasses, TableCellProps, TableContainer, TableHead, TableRow, TableRowProps } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Button } from '../responsive-components';
import { PaginationActionButton } from '../responsive-components/Button';
import { PushPin } from '@mui/icons-material';

export interface BoothArticleTableData {
    id: string;
    boothId: string;
    boothChName: string;
    boothEnName: string;
    name: string;
    time: Date;
    pin: boolean;
}

const ArticleTableCell: (props: TableCellProps) => JSX.Element = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        fontSize: '0.875rem',
        [theme.breakpoints.up('sm')]: {
            fontSize: '1rem'
        },
        color: 'grey',
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: '0.75rem',
        [theme.breakpoints.up('sm')]: {
            fontSize: '1rem'
        },
    },
}));

//表格
export default function BoothArticleTable(props: BoothArticleTableProps) {
    const { isManage, rows, onOpen, onEdit, onDelete, showPin, className, paginationClassName } = props;
    const { t } = useTranslation();

    const [page, setPage] = React.useState(0);
    const rowsPerPage = 10;

    const [emptyRows, setEmptyRows] = React.useState([]);
    React.useEffect(() => {
        if (page > 0) {
            let tempRows = [];
            for (let count = 0; count < (Math.max(0, (1 + page) * rowsPerPage - rows.length)); count++) {
                tempRows.push(count);
            }
            setEmptyRows(tempRows);
        }
        else {
            setEmptyRows([]);
        }
    }, [page])

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, newPage: number) => {
        setPage(newPage);
    };
    const TableCell = ArticleTableCell;
    return (<>
        <TableContainer className={className}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            {t('boothArticle.table.news')}
                        </TableCell>
                        <TableCell align="left">
                            <Box display='flex'>
                                <Divider flexItem orientation='vertical' style={{ marginRight: '10px' }} />
                                {t('boothArticle.table.time')}
                            </Box>
                        </TableCell>
                        {isManage &&
                            <TableCell />
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    {(rowsPerPage > 0
                        ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        : rows
                    ).map((row) => (
                        <BoothArticleTableRow
                            isManage={isManage}
                            row={row}
                            onOpen={onOpen}
                            onEdit={onEdit}
                            onDelete={onDelete}
                            key={row.id}
                            showPin={showPin}
                        />
                    ))}
                    {emptyRows.map((empty, index) => (
                        <TableRow key={index}>
                            <TableCell style={{ border: 0 }}>{'　'}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
        <Paper className={paginationClassName} sx={{
            margin: '12px 2px 12px auto',
            width: 'fit-content',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end'
        }}>
            <PaginationActionButton
                direction={'left'}
                count={rows.length}
                page={page}
                rowsPerPage={rowsPerPage}
                onPageChange={handleChangePage}
            />
            <Divider variant="middle" flexItem orientation='vertical' />
            <Box style={{ padding: '6px 14px' }}>
                <Typography variant={'body2'}>
                    <span>{page + 1}</span>
                    {` / ${Math.max(1, Math.ceil(rows.length / rowsPerPage))}`}
                </Typography>
            </Box>
            <Divider variant="middle" flexItem orientation='vertical' />
            <PaginationActionButton
                direction={'right'}
                count={rows.length}
                page={page}
                rowsPerPage={rowsPerPage}
                onPageChange={handleChangePage}
            />
        </Paper>
    </>)
}
interface BoothArticleTableProps {
    isManage?: boolean;
    rows: BoothArticleTableData[];
    onOpen?: (id: string) => void;
    onEdit?: (id: string) => void;
    onDelete?: (id: string) => void;
    showPin?: boolean;
    className?: string;
    paginationClassName?: string;
}

//列
function BoothArticleTableRow(props: BoothArticleTableRowProps) {
    const { isManage, row, TableRowProps, onOpen, onEdit, onDelete, showPin } = props;
    const { t, i18n } = useTranslation();

    const TableCell = ArticleTableCell;
    return (
        <TableRow
            {...TableRowProps}
        >
            <TableCell scope="row" width='100%' align="left" style={{ maxWidth: '400px', whiteSpace: 'nowrap' }}>
                {(showPin && row.pin) &&
                    <PushPin style={{ color: 'red' }} />
                }
                {onOpen ?
                    <Link
                        component="button"
                        onClick={() => onOpen(row.id)}
                        underline="hover"
                        style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', maxWidth: '100%' }}
                    >
                        {(row.boothId != null && row.boothId != '') &&
                            `【${i18n.language == 'ch' ? row.boothChName : row.boothEnName}】`
                        }
                        {row.name}
                    </Link> :
                    <>
                        {(row.boothId != null && row.boothId != '') &&
                            `【${i18n.language == 'ch' ? row.boothChName : row.boothEnName}】`
                        }
                        {row.name}
                    </>
                }
            </TableCell>
            <TableCell align="left" style={{ paddingLeft: '24px' }}>
                {new Date(row.time).toLocaleString(
                    i18n.language == 'ch' ? 'zh-tw' : 'en-us', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                })}
            </TableCell>
            {isManage &&
                <TableCell style={{ padding: '4px 8px' }}>
                    {onEdit &&
                        <Button onClick={() => onEdit(row.id)}>
                            {t('boothArticle.table.edit')}
                        </Button>
                    }
                    {onDelete &&
                        <Button
                            onClick={() => onDelete(row.id)}
                            sx={{
                                color: 'gray',
                                ':hover': {
                                    backgroundColor: '#70707010',
                                }
                            }}
                        >
                            {t('boothArticle.table.delete')}
                        </Button>
                    }
                </TableCell>
            }
        </TableRow>
    )
}
interface BoothArticleTableRowProps {
    row: BoothArticleTableData;
    TableRowProps?: TableRowProps;
    isManage?: boolean;
    onOpen?: (id: string) => void;
    onEdit?: (id: string) => void;
    onDelete?: (id: string) => void;
    showPin?: boolean;
}