﻿import * as React from 'react';
import { useUser } from '../../../store';
import { CardInfo } from '../../../components/class';
import { Trans, useTranslation } from 'react-i18next';
import { useGetSaveCardsMutation, UserCardInfoModel } from '../../../components/services/user';
import { SubTitle } from '../../../components/responsive-components/Typography';
import { Box, Divider, IconButton, Paper, Typography, useTheme } from '@mui/material';
import { useGetPresenceInfosMutation, UserPresence } from '../../../components/services/system';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { UserCardDialog } from '../../../components/BussinessCard/UserCardDialog';
import { BussinessCard } from '../../../components/BussinessCard/BussinessCardInfo';

export default function UserSaveCards() {
    const { t } = useTranslation();
    const user = useUser();

    const [getSaveCards, { saveCards = [] }] = useGetSaveCardsMutation({
        selectFromResult: ({ data }) => ({ saveCards: data?.data ?? [] })
    });
    const [getPresenceInfos, { presenceList = [] }] = useGetPresenceInfosMutation({
        selectFromResult: ({ data }) => ({ presenceList: data?.data ?? [] })
    });
    React.useEffect(() => {
        if (user) {
            getSaveCards();
            getPresenceInfos({ userIds: user.saveCardIds });
        }
    }, [user])

    return (<>
        <SubTitle
            icon='circle'
            text={t('manage.title.profile.saveCards')}
        />
        <SaveCardList userList={saveCards} presenceList={presenceList} />
    </>)
};

interface SaveCardListProps {
    userList: CardInfo[];
    presenceList: UserPresence[];
}
function SaveCardList(props: SaveCardListProps) {
    const { userList, presenceList } = props;
    const theme = useTheme();

    const [page, setPage] = React.useState(1);
    const [pageSize, setPageSize] = React.useState<number>(4);

    //responsive pageSize
    React.useLayoutEffect(() => {
        const setResponsiveness = () => {
            return window.innerWidth < theme.breakpoints.values.sm
                ? setPageSize(4)
                : window.innerWidth < theme.breakpoints.values.md
                    ? setPageSize(8)
                    : window.innerWidth < theme.breakpoints.values.xl
                        ? setPageSize(10) :
                        setPageSize(12);
        };

        setResponsiveness();
        window.addEventListener("resize", () => setResponsiveness());

        return () => {
            window.removeEventListener("resize", () => setResponsiveness());
        }
    }, []);

    let totalCount = userList?.length ?? 0;
    let currentPage = page ?? 1;
    let totalPages = Math.ceil(totalCount / pageSize);
    let hasNext = currentPage < totalPages;
    let hasPrevious = currentPage > 1;

    const handleNext = () => setPage(page => page + 1);
    const handlePrevious = () => setPage(page => page - 1);

    //顯示視窗
    const [currentData, setCurrentData] = React.useState<UserCardInfoModel>(null);
    const [open, setOpen] = React.useState(false);
    const handleOpen = (data: CardInfo) => {
        setCurrentData({
            ...data,
            isOnline: presenceList?.find(x => x.userId === data.userId).isOnline
        } as UserCardInfoModel);
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    return (<>
        <Box display="flex" alignItems="center" mb={1}>
            <Typography color="text.secondary">
                <Trans i18nKey="userList.totalNum" values={{ totalNum: totalCount }} />
            </Typography>

            <Paper sx={{
                marginInline: 'auto 2px',
                width: 'fit-content',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
            }}>
                <IconButton onClick={handlePrevious} disabled={!hasPrevious}>
                    <KeyboardArrowLeft />
                </IconButton>
                <Divider variant="middle" flexItem orientation="vertical" />
                <Box style={{ padding: '6px 14px' }}>
                    <Typography variant="body2">
                        <span>{currentPage}</span>
                        {` / ${totalPages}`}
                    </Typography>
                </Box>
                <Divider variant="middle" flexItem orientation="vertical" />
                <IconButton onClick={handleNext} disabled={!hasNext}>
                    <KeyboardArrowRight />
                </IconButton>
            </Paper>
        </Box>
        <Box display="grid" gap={{ xs: 1, md: 2 }} gridTemplateColumns={{ xs: "repeat(2, 1fr)", sm: "repeat(4, 1fr)", md: "repeat(5, 1fr)", lg: "repeat(5, 1fr)", xl: "repeat(6, 1fr)" }}>
            {userList.map((user) => (
                <Box key={user.userId}>
                    <BussinessCard
                        data={user}
                        isOnline={presenceList?.find(x => x.userId === user.userId)?.isOnline}
                        simpleMode
                        withBorder
                        onClickShowMore={handleOpen}
                    />
                </Box>
            ))}
        </Box>
        <UserCardDialog
            data={currentData}
            open={open}
            onClose={handleClose}
        />
    </>)
}