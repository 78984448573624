﻿import { Close, Event, Help, List, RoomService, Videocam } from '@mui/icons-material';
import { Backdrop, Box, ButtonProps, Container, IconButton, Stack, styled, Typography, useMediaQuery, useTheme } from '@mui/material';
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from '@mui/material';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { CheckIsMobile } from '../components/Admin/Utils';
import { logEvent } from '../components/Analytics';
import { Button, Dialog, DialogContent } from '../components/responsive-components';
import BoardCardTable from './BoardCard/BoardCardTable';
import BoothAppointment from './BoothAppointment';
import BoothOnlineUserList from './BoothOnlineUserList';

interface BoothAction {
    name: string;
    icon: React.ReactNode;
    function: Function;
    buttonProps?: ButtonProps;
}

export default function BoothActions(props: BoothActionsProps) {
    const { boothId, handleOpenMedia, handleOpenAppointment, handleRingBell, showOnCall } = props;
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const { t } = useTranslation();

    //彈出視窗
    const [display, setDisplay] = React.useState<React.ReactNode>(null);
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = (node?: React.ReactNode) => {
        setDisplay(node);
        setOpen(true);
    };
    const handleClose = () => {
        setDisplay(null);
        setOpen(false);
    };

    //功能列表
    const actions: BoothAction[] = [
        {
            name: t('booth.title.video'),
            icon: <Videocam sx={{ color: (theme) => theme.palette.primary.main }} style={{ fontSize: isMobile ? '22px' : '30px' }} />,
            function: () => {
                handleOpenMedia();
                logEvent('Booth', `${boothId}: SidebarButton`, `MediaRoom`);
            },
            buttonProps: {
                color: "inherit",
                variant: "outlined",
                size: "large"
            }
        },
        {
            name: t('booth.title.appointment'),
            icon: <Event sx={{ color: (theme) => theme.palette.primary.main }} style={{ fontSize: isMobile ? '22px' : '30px' }} />,
            function: handleOpenAppointment,
            buttonProps: {
                color: "inherit",
                variant: "outlined",
                size: "large"
            }
        },
        {
            name: t('booth.title.online'),
            icon: <List sx={{ color: (theme) => theme.palette.primary.main }} style={{ fontSize: isMobile ? '22px' : '30px' }} />,
            function: () => {
                handleClickOpen(<BoothOnlineUserList boothId={boothId} onClose={handleClose} />);
                logEvent('Booth', `${boothId}: SidebarButton`, `OnlineUser`);
            },
            buttonProps: {
                color: "inherit",
                variant: "outlined",
                size: "large"
            }
        },
        {
            name: t('booth.title.board'),
            icon: <Help sx={{ color: (theme) => theme.palette.primary.main }} style={{ fontSize: isMobile ? '22px' : '30px' }} />,
            function: () => {
                handleClickOpen(<BoardCardTable boothId={boothId} />);
                logEvent('Booth', `${boothId}: SidebarButton`, `BoardCardTable`);
            },
            buttonProps: {
                color: "inherit",
                variant: "outlined",
                size: "large"
            }
        },
        {
            name: t('booth.title.bell'),
            icon: <RoomService sx={{ color: '#FBA017' }} style={{ fontSize: isMobile ? '22px' : '30px' }} />,
            function: () => {
                handleRingBell();
                logEvent('ServiceBell', `Booth`, `${boothId}`);
                logEvent('Booth', `${boothId}: SidebarButton`, `ServiceBell`);
            },
            buttonProps: {
                color: "inherit",
                variant: "outlined",
                size: "large"
            }
        },
    ];

    return (<>
        {isMobile ?
            <BoothActionSpeedDial actions={actions} /> :
            <BoothActionBar actions={actions} showOnCall={showOnCall} />
        }
        <BoothActionDialog
            open={open}
            onClose={handleClose}
        >
            {display}
        </BoothActionDialog>
    </>)
};
export interface BoothActionsProps {
    boothId: string;
    handleOpenMedia: Function;
    handleOpenAppointment: Function;
    handleRingBell: Function;
    showOnCall?: boolean;
}

const ActionButton = styled(Button)(({ theme }) => ({
    borderRadius: theme.spacing(1),
    boxShadow: theme.shadows[2],
    borderColor: "white"
    //border: `2px solid ${theme.palette.grey[500]}`,
}));
function BoothActionBar(props: BoothActionBarProps) {
    const { actions, showOnCall } = props;
    const { t } = useTranslation();

    return (
        <Box position="relative">
            <Stack
                direction="column"
                alignItems="flex-end"
                spacing={2}
                //style={{ position: 'absolute', zIndex: 5, top: '500px', right: 100 }}
            >
                {actions.map((action) => (
                    <Box key={action.name}>
                        <ActionButton startIcon={action.icon} onClick={() => { action.function(); }} {...action.buttonProps}>
                            <Typography variant="h5" component="p" fontWeight="bold">
                                {action.name}
                            </Typography>
                        </ActionButton>
                    </Box>
                ))}
            </Stack>
            {showOnCall &&
                <Box position="absolute" right={0} top="-16px" bgcolor="red" color="white" borderRadius={1.5} px={1} py={0.25}>
                    <Typography variant='body2'>{t("booth.title.onCall")}</Typography>
                </Box>
            }
        </Box>
    )
};
interface BoothActionBarProps {
    actions: BoothAction[];
    showOnCall?: boolean;
}

function BoothActionSpeedDial(props: BoothActionSpeedDialProps) {
    const { actions } = props;

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (<>
        <Backdrop open={open} style={{ zIndex: 500 }} />
        <SpeedDial
            ariaLabel="SpeedDial"
            sx={{ position: 'fixed', bottom: 16, right: 16, "& .MuiSpeedDial-actions": { flexDirection: "column" } }}
            icon={<SpeedDialIcon />}
            onClose={handleClose}
            onOpen={handleOpen}
            open={open}
        >
            {actions.map((action) => (
                <SpeedDialAction
                    key={action.name}
                    icon={action.icon}
                    tooltipTitle={
                        <Typography variant='body1' fontWeight='bold' style={{ wordBreak: 'initial', whiteSpace: 'nowrap' }}>
                            {action.name}
                        </Typography>
                    }
                    tooltipOpen
                    onClick={() => { action.function(); handleClose(); }}
                />
            ))}
        </SpeedDial>
    </>)
};
interface BoothActionSpeedDialProps {
    actions: BoothAction[];
}

function BoothActionDialog(props: BoothActionDialogProps) {
    const { onClose, open, children } = props;
    const handleClose = () => {
        onClose();
    };

    return (
        <Dialog
            //fullScreen={!matches}
            onClose={handleClose}
            fullWidth
            maxWidth='lg'
            open={open}
            PaperProps={{ sx: { overflow: 'inherit', height: '100%' } }}
        >
            <Button
                variant='contained'
                onClick={handleClose}
                sx={{
                    position: 'absolute', right: -28, top: -28, padding: '2px 2px', minWidth: 'unset',
                    color: 'inherit',
                    backgroundColor: '#ffffff',
                    '&:hover': {
                        backgroundColor: '#f1f1f1',
                    },
                }}
            >
                <Close />
            </Button>
            {children}
        </Dialog>
    );
}
interface BoothActionDialogProps {
    open: boolean;
    onClose: () => void;
    children?: React.ReactNode;
}