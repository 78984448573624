﻿import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Circle, Clear, Send } from "@mui/icons-material";
import { Avatar, Badge, Box, Button, Collapse, IconButton, List, ListItem, ListItemAvatar, ListItemButton, ListItemIcon, ListItemText, ListSubheader, Paper, Stack, Tooltip, Typography } from "@mui/material";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { TransitionGroup } from "react-transition-group";
import { useSystemConnection, useUser } from "../../../store";
import { CardType, InteractionTextObject, MessageContent, PlainTextObject, StampTextObject, TextCode, UserPresence } from "../../class";
import { SystemControlHub } from "../../react-signalr/systemControlHub";
import UserOnlineBadge from "../../responsive-components/UserOnlineBadge";
import { Contact, ContactInfo } from "../../services/chat";

interface ContactDialogProps {
    contactList: Contact[];
    onClose: () => void;
    onSelectUser: (userId:string) => void;
    onSelectGroup: (chatId: string) => void;
    mobile?: boolean;
    draggableRef?: React.Ref<HTMLDivElement>;
}
export function ContactDialog(props: ContactDialogProps) {
    const { contactList, onClose, onSelectUser, onSelectGroup, mobile, draggableRef } = props;
    const user = useUser();
    const { t, i18n } = useTranslation();
    const [contactInfos, setContactInfos] = React.useState<Contact[]>([]);
    const [groupInfos, setGroupInfos] = React.useState<Contact[]>([]);
    React.useLayoutEffect(() => {
        if (contactList) {
            setContactInfos(contactList.filter(x => !x.isGroup));
            setGroupInfos(contactList.filter(x => x.isGroup));
        }
    }, [contactList]);

    const containerRef = React.useRef<HTMLDivElement>();
    const [overflow, setOverflow] = React.useState<boolean>(false); //用來鎖定手機滾動
    React.useEffect(() => {
        //手機版
        //檢查是否有overflow
        if (containerRef.current)
            setOverflow(containerRef.current.offsetHeight < containerRef.current.scrollHeight);
    }, [contactInfos]);

    const handleClose = () => {
        onClose();
    };
    const handleSelectUser = (userId: string) => {
        onSelectUser(userId);
    };
    const handleSelectGroup = (chatId: string) => {
        onSelectGroup(chatId);
    };

    function getLastMessage(lastMessage: MessageContent): string {
        if (lastMessage == null)
            return "";
        let fromMe = lastMessage.senderId === user.userId;
        if (lastMessage.isDeleted) {
            return fromMe ? t("chat.contact.you_delete") : t("chat.contact.other_delete");
        }
        switch (lastMessage.textCode) {
            case TextCode.系統通知:
                return t("chat.contact.system");
            case TextCode.純文字及連結:
                return (lastMessage.textObject as PlainTextObject).content;
            case TextCode.互動訊息:
                let interactionType = (lastMessage.textObject as InteractionTextObject).interactionType;
                let isAccept = !(interactionType & 1);
                let type = interactionType >> 1;
                let verbText = isAccept ? t("chat.contact.accepted") : t("chat.contact.decline");
                let frontWords, backWords;
                switch (type) {
                    case 0:
                        frontWords = fromMe ? t("chat.contact.you_accepted_front") : t("chat.contact.other_accepted_front");
                        backWords = fromMe ? t("chat.contact.you_accepted_exchange") : t("chat.contact.other_accepted_exchange");
                        break;
                    case 1:
                        frontWords = fromMe ? t("chat.contact.you_accepted_front") : t("chat.contact.other_accepted_front");
                        backWords = fromMe ? t("chat.contact.you_accepted_appointment") : t("chat.contact.other_accepted_appointment");
                        break;
                }
                return frontWords + verbText + backWords;
            case TextCode.貼圖:
                return fromMe ? t("chat.contact.you_sticker") : t("chat.contact.other_sticker");
            case TextCode.交換名片:
                return fromMe ? t("chat.contact.you_exchange") : t("chat.contact.other_exchange");
            case TextCode.預約面談:
                return fromMe ? t("chat.contact.you_appointment") : t("chat.contact.other_appointment");
            case TextCode.視訊邀請:
                return fromMe ? t("chat.contact.you_video") : t("chat.contact.other_video");
            case TextCode.蓋章:
                let stampType = (lastMessage.textObject as StampTextObject).stampType;
                const stampList = [t('dialog.stamp.silverCoin'), t('dialog.stamp.heroMedal')];
                return (fromMe ? t('chat.message.send_stamp') : t('chat.message.get_stamp')) + stampList[stampType];
            default:
                return fromMe ? t("chat.contact.you_file") : t("chat.contact.other_file");
        }
    }

    return (
        <Box position="relative" width={mobile ? "100%" : 350} height={mobile ? "100%" : 455} ml={mobile ? 0 : 2} sx={{ "& *": { touchAction: "none" } }}>
            <Paper elevation={12} sx={{ width: "100%", height: "100%", borderRadius: "10px 10px 0 0" }}>
                <Stack height="100%">
                    { /*聯絡人header*/}
                    <Box ref={draggableRef} display="flex" alignItems="center" p={1} sx={{
                        boxShadow: "rgb(0 0 0 / 16%) 0px 2px 9px", borderRadius: "10px 10px 0 0",
                        position: "relative", height: "56px",
                        zIndex: (theme) => theme.zIndex.drawer + 1
                    }}>
                        <Box display="flex" flex="0 0" ml={1.5} mr={1.5}>
                            <Send color="primary" fontSize="medium" sx={{ transform: "rotateZ(321deg) translateX(3px)" }} />
                        </Box>
                        <Box display="flex" flex="1 1" sx={{ overflow: "hidden" }}>
                            <Typography noWrap fontWeight="bold" sx={{ overflow: "hidden", textOverflow: "ellipsis", pt: 0.25 }}>
                                {t("chat.contact.title")}
                            </Typography>
                        </Box>
                        <Box display="flex" flex="0 0">
                            <Tooltip title={t("chat.contact.closeRoom")}>
                                <IconButton onClick={handleClose}>
                                    <Box display="flex" width="20px" height="20px" justifyContent="center" alignItems="center" sx={{ color: 'primary.main' }}>
                                        <FontAwesomeIcon icon={faTimes} transform="shrink-3" /></Box></IconButton>
                            </Tooltip>
                        </Box>
                        {mobile &&
                            <Box position="absolute" width="100%" top="6px" left={0} display="flex" justifyContent="center">
                                <Box width="32px" height="4px" bgcolor="grey.400" border="0px solid" borderRadius="10px" />
                            </Box>
                        }
                    </Box>
                    { /*聯絡人顯示區*/}
                    <Box ref={containerRef} flex="1 1" sx={{ "& .MuiTypography-root": { wordBreak: "break-word" }, overflowY: "auto", "&, & *": { touchAction: overflow ? "pan-y" : "none" } }}>
                        <List>
                            <TransitionGroup>
                                {contactInfos.map((contact, index) => (
                                    <Collapse key={contact.contactInfo.userId} timeout={mobile ? 0 : 300}>
                                        <ListItem disablePadding secondaryAction={
                                            contact.contactInfo.hasBusinessCard ?
                                                <Box sx={{ background: "#1ED6AB", color: "white", borderRadius: "8px", padding: "5px 15px" }}>
                                                    <Typography variant="caption" fontWeight="bold">
                                                        <FontAwesomeIcon icon={faCheck} className="mr-1" />
                                                        {t("chat.contact.exchanged")}
                                                    </Typography>
                                                </Box>
                                                : null
                                        }>
                                            <ListItemButton style={contact.contactInfo.hasBusinessCard ? { paddingRight: "100px", overflow: "hidden" } : { overflow: "hidden" }}
                                                onClick={() => { handleSelectUser(contact.contactInfo.userId); }}>
                                                { /*頭像*/}
                                                <ListItemAvatar>
                                                    <UserOnlineBadge isOnline={contact.contactInfo.isOnline}>
                                                        <Avatar src={contact.contactInfo.logoUrl} />
                                                    </UserOnlineBadge>
                                                </ListItemAvatar>
                                                <ListItemText primary={
                                                    <>
                                                        <Typography
                                                            sx={{
                                                                overflow: "hidden", textOverflow: "ellipsis",
                                                                display: 'inline', color: contact.lastMessage && !contact.lastMessage.hasBeenSeen ? "primary.main" : "text.primary",
                                                                fontWeight: contact.lastMessage && !contact.lastMessage.hasBeenSeen ? "bold" : "normal"
                                                            }}
                                                        >
                                                            {contact.contactInfo.lastName} {contact.contactInfo.firstName}
                                                        </Typography>
                                                        <Typography
                                                            sx={{ display: 'inline', ml: 1 }}
                                                            component="span"
                                                            variant="caption"
                                                            color="text.secondary"
                                                            noWrap
                                                        >
                                                            {contact.contactInfo.type === CardType.student && <>
                                                                {contact.contactInfo.infoFields.find(x => x.fieldId === 'school')?.value}
                                                                {contact.contactInfo.infoFields.find(x => x.fieldId === 'department')?.value}
                                                            </>}
                                                            {contact.contactInfo.type === CardType.nonStudent && <>
                                                                {contact.contactInfo.infoFields.find(x => x.fieldId === 'companyName')?.value}
                                                                {contact.contactInfo.infoFields.find(x => x.fieldId === 'jobTitle')?.value}
                                                            </>}
                                                        </Typography>
                                                    </>
                                                } secondary={getLastMessage(contact.lastMessage)}
                                                    primaryTypographyProps={{
                                                        noWrap: true, overflow: "hidden", textOverflow: "ellipsis",
                                                    }}
                                                    secondaryTypographyProps={{
                                                        noWrap: true, variant: "caption",
                                                        color: contact.lastMessage && !contact.lastMessage.hasBeenSeen ? "primary.main" : "text.primary",
                                                        fontWeight: contact.lastMessage && !contact.lastMessage.hasBeenSeen ? "bold" : "normal"
                                                    }} />
                                                { /*未讀標記*/}
                                                {contact.lastMessage && !contact.lastMessage.hasBeenSeen &&
                                                    <ListItemIcon sx={{ minWidth: "22px", color: "error.light", fontSize: "12px", mb: "auto", justifyContent: "flex-end" }}>
                                                        <Circle color="inherit" fontSize="inherit" />
                                                    </ListItemIcon>
                                                }
                                            </ListItemButton>
                                        </ListItem >
                                    </Collapse>
                                ))}
                            </TransitionGroup>
                        </List>
                        {groupInfos.length > 0 &&
                            <List subheader={
                                <ListSubheader component="div">
                                    {t("chat.contact.groupChat")}
                                </ListSubheader>
                            }>
                                <TransitionGroup>
                                    {groupInfos.map((contact, index) => (
                                        <Collapse key={index}>
                                            <ListItem disablePadding>
                                                <ListItemButton style={{ overflow: "hidden" }} onClick={() => { handleSelectGroup(""); }}>
                                                    <ListItemAvatar>
                                                        <UserOnlineBadge isOnline={true}>
                                                            <Avatar />
                                                        </UserOnlineBadge>
                                                    </ListItemAvatar>
                                                    <ListItemText primary="title" secondary="latest message" secondaryTypographyProps={{ noWrap: true, variant: "caption" }} />
                                                </ListItemButton>
                                            </ListItem >
                                        </Collapse>
                                    ))}
                                </TransitionGroup>
                            </List>
                        }
                    </Box>
                </Stack>
            </Paper>
        </Box>
    )
}