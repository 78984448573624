﻿import { HelpOutline, Padding } from "@mui/icons-material";
import { Backdrop, Box, Button, CircularProgress, Container, DialogContent, Divider, List, ListItem, MenuItem, Select, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import * as React from "react";
import { CardType } from "../components/class";
import { EditUserDataRef, UserEditType } from "../components/Manage/UserDataEdit";
import { useUser } from "../store";
import { PageStepLoadingButton, Test as TestFunc } from "./QuickStart";
import UserEditStepper from "./UserEditStepper";
import { Trans, useTranslation } from "react-i18next";

const icons = "images/nonStudent/Quick_Start_01.png";
const bell_icon = "images/nonStudent/Quick_Start_02.png";
const nonStudent_1 = "images/nonStudent/Quick_Start_08.png";
const nonStudent_2 = "images/nonStudent/Quick_Start_15.png";
const student_1 = "images/student/Quick_Start_03.png";
const student_2 = "images/student/Quick_Start_05.png";
const student_3 = "images/student/Quick_Start_12.png";
const student_4 = "images/student/Quick_Start_13.png";
const student_5 = "images/student/Quick_Start_14.png";

const m_icons = "images/nonStudent/m_Quick_Start_01.png";
const m_bell_icon = "images/nonStudent/m_Quick_Start_02.png";
const m_nonStudent_1 = "images/nonStudent/m_Quick_Start_08.png";
const m_nonStudent_2 = "images/nonStudent/m_Quick_Start_15.png";
const m_student_1 = "images/student/m_Quick_Start_03.png";
const m_student_2 = "images/student/m_Quick_Start_05.png";
const m_student_3 = "images/student/m_Quick_Start_12.png";
const m_student_4 = "images/student/m_Quick_Start_13.png";
const m_student_5 = "images/student/m_Quick_Start_14.png";

export interface IQuickStartContentProps {
    onPrevious: () => void;
    onNext: () => void;
    userType: CardType;
}

export const useQuickStartContents = (props: IQuickStartContentProps) => {
    const { onPrevious, onNext, userType } = props;
    const contents_1: React.ReactElement[] = [
        <BasicSetting onPrevious={onPrevious} onNext={onNext} userType={userType} />,
        <AdvanceSetting onPrevious={onPrevious} onNext={onNext} userType={userType} />,
        //<GuideView view="basicFeature" onPrevious={onPrevious} onNext={onNext} userType={userType} />,
        //<GuideView view="coreFeature" onPrevious={onPrevious} onNext={onNext} userType={userType} />,
        //<GuideView view="howToGetPoint" onPrevious={onPrevious} onNext={onNext} userType={userType} />,

    ];
    const contents_2: React.ReactElement[] = [
        <BasicSetting onPrevious={onPrevious} onNext={onNext} userType={userType} />,
        <AdvanceSetting onPrevious={onPrevious} onNext={onNext} userType={userType} />,
        //<GuideView view="basicFeature" onPrevious={onPrevious} onNext={onNext} userType={userType} />,
        //<GuideView view="coreFeature" onPrevious={onPrevious} onNext={onNext} userType={userType} />,
    ];
    switch (userType) {
        case CardType.student:
            return (
                contents_1.map((content) => (
                    <>{content}</>
                ))
            )
        default:
            return (
                contents_2.map((content) => (
                    <>{content}</>
                ))
            )
    }
}

export const BasicSetting = (props: IQuickStartContentProps) => {
    const { onPrevious, onNext, userType } = props;

    const editor = React.useRef<EditUserDataRef>();

    const handleOnNext = async () => {
        setLoading(true);

        let result = false;
        if (editor.current) {
            await editor.current.handleSubmit().then((success) => {
                result = success;
            }).catch((error) => {
                result = error;
            });
        }

        setLoading(false);
        if (result)
            onNext();
    }

    const [loading, setLoading] = React.useState(false);
    const LoadingButton = PageStepLoadingButton;

    return (
        <>
            {/*<Divider sx={{ borderBottomWidth: "3px", borderColor: (theme) => theme.palette.background.black }} />*/}
            <DialogContent sx={{ overflowY: "initial" }}>
                <Typography variant="h4" fontWeight="bold" sx={{ color: "primary.main", fontStyle: "italic", paddingTop: 1 }}>
                    <Trans i18nKey={"quickStart.basicSetting.title1"} />
                </Typography>
                <Typography variant="h4" fontWeight="bold" sx={{ color: "primary.main", paddingBottom: 1 }}>
                    <Trans i18nKey={"quickStart.basicSetting.title2"} components={[<span style={{ color: "inherit", fontStyle: "italic" }}></span>]} />
                </Typography>
                <Typography variant="h6" fontWeight="bold">
                    <Trans i18nKey={"quickStart.basicSetting.title3"} />
                </Typography>
            </DialogContent>
            <Divider sx={{ borderColor: (theme) => theme.palette.text.secondary }} />
            <DialogContent>
                <UserEditStepper editType={UserEditType.primary} ref={editor} />
            </DialogContent>
            {/*<Divider sx={{ borderBottomWidth: "3px", borderColor: (theme) => theme.palette.background.black }} />*/}
            <Box display="flex" justifyContent="flex-end" padding="8px 24px" sx={{ background: (theme) => theme.palette.primary.main, color: (theme) => theme.palette.background.paper }}>
                <LoadingButton loading={loading} color="inherit" onClick={handleOnNext}>
                    <Trans i18nKey={"quickStart.button.next"} />
                </LoadingButton>
            </Box>
        </>
    )
}

export const AdvanceSetting = (props: IQuickStartContentProps) => {
    const { onPrevious, onNext, userType } = props;

    const editor = React.useRef<EditUserDataRef>();

    const handleOnNext = async () => {
        setLoading(true);

        let result = false;
        if (editor.current) {
            await editor.current.handleSubmit().then((success) => {
                result = success;
            }).catch((error) => {
                result = error;
            });
        }

        setLoading(false);
        console.log(`result: ${result}`);
        if (result)
            onNext();
    }
    const [loading, setLoading] = React.useState(false);
    const LoadingButton = PageStepLoadingButton;

    switch (userType) {
        case CardType.unknown:
        case CardType.student:
            return (<>
                {/*<Divider sx={{ borderBottomWidth: "3px", borderColor: (theme) => theme.palette.background.black }} />*/}
                <DialogContent sx={{ overflowY: "initial" }}>
                    <Typography variant="h4" fontWeight="bold" sx={{ color: "primary.main", fontStyle: "italic", paddingTop: 1 }}>
                        <Trans i18nKey={"quickStart.advanceSetting.title1"} />
                    </Typography>
                    <Typography variant="h4" fontWeight="bold" sx={{ color: "primary.main", paddingBottom: 1 }}>
                        <Trans i18nKey={"quickStart.advanceSetting.title2"} components={[<span style={{ color: "inherit", fontStyle: "italic" }}></span>]} />
                    </Typography>
                    <Typography variant="h6" fontWeight="bold">
                        <Trans i18nKey={"quickStart.advanceSetting.title3.student"} />
                    </Typography>
                </DialogContent>
                <Divider sx={{ borderColor: (theme) => theme.palette.text.secondary }} />
                <DialogContent>
                    <UserEditStepper editType={UserEditType.secondary} ref={editor} />
                </DialogContent>
                {/*<Divider sx={{ borderBottomWidth: "3px", borderColor: (theme) => theme.palette.background.black }} />*/}
                <Box display="flex" justifyContent="space-between" padding="8px 24px" sx={{ background: (theme) => theme.palette.primary.main, color: (theme) => theme.palette.background.paper }}>
                    <LoadingButton color="inherit" onClick={onPrevious}>
                        <Trans i18nKey={"quickStart.button.previous"} />
                    </LoadingButton>
                    <LoadingButton loading={loading} color="inherit" onClick={handleOnNext}>
                        <Trans i18nKey={"quickStart.button.next"} />
                    </LoadingButton>
                </Box>
            </>)
        case CardType.nonStudent:
        case CardType.admin:
            return (<>
                {/*<Divider sx={{ borderBottomWidth: "3px", borderColor: (theme) => theme.palette.background.black }} />*/}
                <DialogContent sx={{ overflowY: "initial" }}>
                    <Typography variant="h4" fontWeight="bold" sx={{ color: "primary.main", fontStyle: "italic", paddingTop: 1  }}>
                        <Trans i18nKey={"quickStart.advanceSetting.title1"} />
                    </Typography>
                    <Typography variant="h4" fontWeight="bold" sx={{ color: "primary.main", paddingBottom: 1 }}>
                        <Trans i18nKey={"quickStart.advanceSetting.title2"} components={[<span style={{ color: "inherit", fontStyle: "italic" }}></span>]} />
                    </Typography>
                    <Typography variant="h6" fontWeight="bold">
                        <Trans i18nKey={"quickStart.advanceSetting.title3.nonStudent"} />
                    </Typography>
                </DialogContent>
                <Divider sx={{ borderColor: (theme) => theme.palette.text.secondary }} />
                <DialogContent>
                    <UserEditStepper editType={UserEditType.secondary} ref={editor} />
                </DialogContent>
                {/*<Divider sx={{ borderBottomWidth: "3px", borderColor: (theme) => theme.palette.background.black }} />*/}
                <Box display="flex" justifyContent="space-between" padding="8px 24px" sx={{ background: (theme) => theme.palette.primary.main, color: (theme) => theme.palette.background.paper }}>
                    <LoadingButton color="inherit" onClick={onPrevious}>
                        <Trans i18nKey={"quickStart.button.previous"} />
                    </LoadingButton>
                    <LoadingButton loading={loading} color="inherit" onClick={handleOnNext}>
                        <Trans i18nKey={"quickStart.button.next"} />
                    </LoadingButton>
                </Box>
            </>)
        default:
            return (
                <Box display="flex" justifyContent="center" alignItems="center" width="100%" height="100%">
                    <CircularProgress />
                </Box>
            )
    }
}

type viewType = "basicFeature" | "coreFeature" | "howToGetPoint";
export const GuideView = (props: { view: viewType } & IQuickStartContentProps) => {
    const { view, onPrevious, onNext, userType } = props;
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));

    if (view == "basicFeature") {
        switch (userType) {
            case CardType.unknown:
            case CardType.student:
                return (
                    <Box>
                        <Typography variant="h4" fontWeight="bold" sx={{ color: "primary.main" }}>{"開始你的求職旅程，"}</Typography>
                        <Typography variant="h5" sx={{ color: "primary.main", pt: 2 }}>{"1. 如何使用此網站逛展？"}</Typography>
                        <Typography variant="subtitle1">{"此次線上博覽會分為網頁逛展與遊戲逛展，兩種逛展方式彼此資訊互通，皆可與企業主管互動、查看企業資訊等等。"}<br />
                            {"1. 網頁逛展利於搜尋企業攤位、職缺項目。"}<br />
                            {"2. 遊戲逛展著重在遊玩展會地圖、與NPC互動並且與其他玩家(包括企業主管)線上互動。"}</Typography>

                        <Typography variant="h5" sx={{ color: "primary.main", pt: 2 }}>{"2. 如何跟參展企業互動？"}</Typography>
                        <Typography variant="subtitle1">{"此次博覽會有多項功能可讓同學們與企業主管進行媒合，包括："}<br />
                            {"1. 一對一系列的文字聊天室、預約視訊系統、交換名片功能"}<br />
                            {"2. 多對多功能的企業問答板、企業視訊聊天室"}</Typography>
                        <Box>
                            <img width="100%" src={mobile ? m_icons : icons} />
                        </Box>
                        <Typography variant="h5" sx={{ color: "primary.main", pt: 2 }}>{"3. 參展企業離線時，我該怎麼辦？"}</Typography>
                        <Typography variant="subtitle1">{"離峰時段主管們不一定能即時收到同學們的訊息，建議同學可適時點選網頁中的服務鈴按鈕，系統將發送信箱通知你在找他。"}</Typography>
                        <Box>
                            <img width="100%" src={mobile ? m_bell_icon : bell_icon} />
                        </Box>

                        <Box display="flex" justifyContent="space-between">
                            <Button color="primary" onClick={onPrevious}>{"上一步"}</Button>
                            <Button color="primary" onClick={onNext}>{"下一步"}</Button>
                        </Box>
                    </Box>
                )
            case CardType.nonStudent:
            case CardType.admin:
                return (
                    <Box>
                        <Typography variant="h4" fontWeight="bold" sx={{ color: "primary.main" }}>{"開始您的徵才活動，"}</Typography>
                        <Typography variant="h5" sx={{ color: "primary.main", pt: 2 }}>{"1. 如何使用此網站進行徵才？"}</Typography>
                        <Typography variant="subtitle1">{"此次線上博覽會分為網頁逛展與遊戲逛展，兩種逛展方式彼此資訊互通，皆可與同學們互動、顯示企業資訊等等。"}<br />
                            {"1. 網頁逛展利於查找在線學生名片、顯示企業資訊以及職缺項目。"}<br />
                            {"2. 遊戲逛展著重在趣味逛展、與同學互動、使用聊天氣泡框發送即時訊息。"}</Typography>

                        <Typography variant="h5" sx={{ color: "primary.main", pt: 2 }}>{"2. 如何布置您的企業攤位？"}</Typography>
                        <Typography variant="subtitle1">{"此次博覽會有多項功能可讓企業主管們進行攤位布置，包括："}<br />
                            {"1. 基本徵才工具：新增徵才小卡、上傳活動海報、編輯公司資訊"}<br />
                            {"2. 互動類型工具：發布最新消息、經營企業問答板、企業視訊聊天室"}</Typography>
                        <Box>
                            <img width="100%" src={mobile ? m_nonStudent_2 : nonStudent_2} />
                        </Box>

                        <Typography variant="h5" sx={{ color: "primary.main", pt: 2 }}>{"3. 如何進行線上說明會？"}</Typography>
                        <Typography variant="subtitle1">{"進入線上說明會需由學校承辦人提供連結進入，可至大會服務台頁面內進行聯繫。"}</Typography>
                        <Typography variant="subtitle1" color="primary" fontWeight="bold">{"聯繫路徑：列表總覽 > 攤位列表 > 大會服務台 > 聯絡人員"}</Typography>
                        <Box>
                        </Box>

                        <Typography variant="h5" sx={{ color: "primary.main", pt: 2 }}>{"4. 如何在離線時收到學生訊息通知？"}</Typography>
                        <Typography variant="subtitle1">{"如主管們無法隨時在線，站內設有服務鈴提供學生按鈴，系統將發送信箱通知告知有學生找您。"}</Typography>
                        <Box>
                            <img width="100%" src={mobile ? m_bell_icon : bell_icon} />
                        </Box>

                        <Box display="flex" justifyContent="space-between">
                            <Button color="primary" onClick={onPrevious}>{"上一步"}</Button>
                            <Button color="primary" onClick={onNext}>{"下一步"}</Button>
                        </Box>
                    </Box>
                )
            default:
                return (
                    <Box display={userType !== null ? "none" : "flex"} justifyContent="center" alignItems="center" width="100%" height="100%">
                        <CircularProgress />
                    </Box>
                )
        }
    } else if (view == "coreFeature") {
        switch (userType) {
            case CardType.unknown:
            case CardType.student:
                return (
                    <Box>
                        <Typography variant="h4" fontWeight="bold" sx={{ color: "primary.main" }}>{"尋找想要的企業，"}</Typography>
                        <Typography variant="h5" sx={{ color: "primary.main", pt: 2 }}>{"1. 我想尋找想要的企業與職缺"}</Typography>
                        <Typography variant="subtitle1">{"點選網頁上方菜單中的列表總覽，選擇攤位列表可查找所有企業，選擇職缺列表可查找所有已新增的職缺。"}</Typography>
                        <Box>
                            <img width="100%" src={mobile ? m_student_1 : student_1} />
                        </Box>

                        <Typography variant="h5" sx={{ color: "primary.main", pt: 2 }}>{"2. 我想更了解企業求職相關制度"}</Typography>
                        <Typography variant="subtitle1">{"點選網頁上方菜單中的活動議程，可觀看本次博覽會中所有的企業說明會、公司參訪，同學們可踴躍報名參與。"}</Typography>
                        <Box>
                            <img width="100%" src={mobile ? m_student_2 : student_2} />
                        </Box>

                        <Typography variant="h5" sx={{ color: "primary.main", pt: 2 }}>{"3. 我想查看更多企業發布的最新消息"}</Typography>
                        <Typography variant="subtitle1">{"點選網頁上方菜單中的最新消息，可看到更多企業人員發布的最新訊息。"}</Typography>

                        <Box display="flex" justifyContent="space-between">
                            <Button color="primary" onClick={onPrevious}>{"上一步"}</Button>
                            <Button color="primary" onClick={onNext}>{"下一步"}</Button>
                        </Box>
                    </Box>
                )
            case CardType.nonStudent:
            case CardType.admin:
                return (
                    <Box>
                        <Typography variant="h4" fontWeight="bold" sx={{ color: "primary.main" }}>{"尋找想要的學生，"}</Typography>
                        <Typography variant="h5" sx={{ color: "primary.main", pt: 2 }}>{"1. 可以在哪裡可以找到學生？"}</Typography>
                        <Typography variant="subtitle1">{"1. 搜尋學生列表：列表總覽 > 學生名片列表，搜尋關鍵字或條件篩選即可。"}<br />
                            {"2. 在線人員列表：於企業攤位頁面中，可查看當前位於攤位內的逛展學生。"}<br />
                            {"3. 前往遊戲逛展：進入遊戲與學生進行虛擬逛展互動，並使用聊天氣泡框發送即時訊息等等。"}</Typography>
                        <Box>
                            <img width="100%" src={mobile ? m_nonStudent_1 : nonStudent_1} />
                        </Box>

                        <Typography variant="h5" sx={{ color: "primary.main", pt: 2 }}>{"2. 如何跟同學線上互動"}</Typography>
                        <Typography variant="subtitle1">{"此次博覽會有多項功能可讓與會人員進行媒合，包括："}<br />
                            {"1. 一對一系列的文字聊天室、預約視訊系統、交換名片功能"}<br />
                            {"2. 多對多功能的企業問答板、企業視訊聊天室"}</Typography>
                        <Box>
                            <img width="100%" src={mobile ? m_icons : icons} />
                        </Box>

                        <Box display="flex" justifyContent="space-between">
                            <Button color="primary" onClick={onPrevious}>{"上一步"}</Button>
                            <Button color="primary" onClick={onNext}>{"下一步"}</Button>
                        </Box>
                    </Box>
                )
            default:
                return (
                    <Box display="flex" justifyContent="center" alignItems="center" width="100%" height="100%">
                        <CircularProgress />
                    </Box>
                )
        }
    } else {
        switch (userType) {
            case CardType.unknown:
            case CardType.student:
                return (
                    <Box>
                        <Typography variant="h4" fontWeight="bold" sx={{ color: "primary.main" }}>{"除此之外，還有集點抽獎活動，"}</Typography>
                        <Typography variant="h5" sx={{ color: "primary.main", pt: 2 }}>{"1. 線上活動集點（蒐集粉梅花，轉扭蛋）"}</Typography>
                        <Typography variant="subtitle1">{"集點時間：3/19 10:00 - 16:00"}<br />
                            {"本集點於網站內蒐集，累積至對應之朵數的粉梅花，即可於遊戲逛展中獲得扭蛋機會。（扭蛋獎品數量有限，兌完為止）"}</Typography>
                        <Box>
                            <img width="100%" src={mobile ? m_student_3 : student_3} />
                        </Box>

                        <Typography variant="h5" sx={{ color: "primary.main", pt: 2 }}>{"2. 實體說明會集點+摸彩（蒐集紫梅花）"}</Typography>
                        <Typography variant="subtitle1">{"集點時間：3/7 - 3/11、3/14 - 3/18"}<br />
                            {"參與實體企業說明會並以手機掃描QR簽到，即可獲得1朵紫梅花"}<br />
                            {"累積至對應數量之點數，即可兌換對應獎品和摸彩券。"}</Typography>
                        <Box>
                            <img width="100%" src={mobile ? m_student_4 : student_4} />
                        </Box>

                        <Typography variant="h5" sx={{ color: "primary.main", pt: 2 }}>{"3. 實體博覽會集點+摸彩（蒐集白梅花）"}</Typography>
                        <Typography variant="subtitle1">{"集點時間：3/19 9:00 - 15:00"}<br />
                            {"參與實體博覽會並以手機掃描企業攤位提供的QR，即可獲得1朵白梅花"}<br />
                            {"累積 25 點即可兌換一張白梅花摸彩券，以此類推，兌換6張為上限。"}</Typography>
                        <Box>
                            <img width="100%" src={mobile ? m_student_5 : student_5} />
                        </Box>

                        <Box display="flex" justifyContent="space-between">
                            <Button color="primary" onClick={onPrevious}>{"上一步"}</Button>
                            <Button color="primary" onClick={onNext}>{"下一步"}</Button>
                        </Box>
                    </Box>
                )
            case CardType.nonStudent:
            case CardType.admin:
                return (
                    <Box display="flex" justifyContent="center" alignItems="center" width="100%" height="100%">
                        <CircularProgress />
                    </Box>
                )
            default:
                return (
                    <Box display="flex" justifyContent="center" alignItems="center" width="100%" height="100%">
                        <CircularProgress />
                    </Box>
                )
        }
    }
}