﻿//Google 登入
export const googleClientId = "";

//jitsi server domain
export const mediaDomain = 'expomedia.act.nycu.edu.tw';

//jitsi live streaming server domain
export const liveDomain = "nycu01.japaneast.cloudapp.azure.com";

//Google 分析
export const gaTrackingId = "G-FYW0RMCDP3";