﻿import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { BussinessCard } from '../../../components/BussinessCard/BussinessCardInfo';
import EditUserDataDialog from '../../../components/Manage/UserDataEdit';
import { SubTitle } from '../../../components/responsive-components/Typography';
import { useDispatchWithType, useUser, useUserFieldNameList } from '../../../store';
import { CardInfo, UserExtendField } from '../../../components/class';
import { useEditUserDataMutation } from '../../../components/services/user';
import { Box, Slide, useMediaQuery, useTheme } from '@mui/material';
import { updateUser } from '../../../store/rootReducer';

function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
};
export default function UserProfile() {
    const { t } = useTranslation();

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const user = useUser();
    const userFieldNameList = useUserFieldNameList();
    const [userData, setUserData] = React.useState<CardInfo>(null);

    //初始化
    const [first, setFirst] = React.useState<boolean>(true);
    React.useEffect(() => {
        if (user && first) {
            setFirst(false);
            setUserData(() => {
                let fieldList = [...user.infoFields];
                let newFields: UserExtendField[] = [];
                userFieldNameList.forEach((field) => {
                    if (!fieldList.find(x => x.fieldId === field.fieldId) &&
                        field.cardTypeList.includes(user.type))
                        newFields.push({ fieldId: field.fieldId, value: '', privacy: field.privacy });
                });
                return { ...user, infoFields: fieldList.concat(newFields)}
            });
        }
    }, [user])

    const dispatch = useDispatchWithType();
    const [editUser, { isLoading: isEditLoading }] = useEditUserDataMutation();
    const [openEditDialog, setOpenEditDialog] = React.useState(false);
    async function handleClose(value?: CardInfo) {
        if (value && user) {
            try {
                let result = await editUser({ ...value, userId: user.userId, boothIds: user.boothIds }).unwrap();
                if (!result.isSuccess) return false;
                setUserData(value);
                let payload = { ...user, ...value, infoFields: [...value.infoFields.map((obj) => ({ ...obj }))] };
                dispatch(updateUser(payload));
                setOpenEditDialog(false);
                return true;
            } catch (error) {
                console.log(error);
                return false;
            }
        }
        else {
            setOpenEditDialog(false);
            return true;
        }
    }

    return (<>
        <SubTitle
            icon='circle'
            text={t('manage.title.profile.myCard')}
        />
        {user && <>
            <Box maxWidth="460px">
                <BussinessCard
                    data={user}
                    editable
                    fixed
                    withBorder
                    disableExchange
                    disableSendMessage
                    disableReport
                    onClickEdit={() => { setOpenEditDialog(true) }}
                />
            </Box>
            <EditUserDataDialog
                open={openEditDialog}
                userId={user.userId}
                data={userData}
                onClose={handleClose}
                fullWidth={true}
                maxWidth="sm"
                isLoading={isEditLoading}
                scroll='paper'
                fullScreen={fullScreen}
                TransitionComponent={fullScreen ? React.forwardRef(Transition) : undefined}
            />
        </>}
    </>);
}