﻿import * as React from "react";
import { Box, List, ListItem, SxProps, Theme, useMediaQuery, useTheme } from "@mui/material";
import { useSystemConnection } from "../../../store";
import { NoticeMessageContent } from "../../class";
import { SystemControlHub } from "../../react-signalr/systemControlHub";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { NotificationToast } from "./NotificationToast";
import { CheckIsMobile } from "../../Admin/Utils";

interface ToastSystemProps {
    handleOpenNotice?: () => void;
    disable?: boolean;
    sx?: SxProps<Theme>;
}
export interface NoticeToastContent extends NoticeMessageContent {
    id: number
};

export function ToastSystem(props: ToastSystemProps) {
    const { handleOpenNotice, disable, sx } = props;
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('lg'));

    const [toastContents, setToastContents] = React.useState<NoticeToastContent[]>([]);
    const [count, setCount] = React.useState(0); //用作id使用，auto increase
    const getId = () => { let n = count; setCount(n + 1); return n; }

    const hideRef = React.useRef(disable);//為了讓signalR handler能探聽到state所做的ref
    React.useEffect(() => { hideRef.current = disable;  }, [disable]);

    const systemConnection = useSystemConnection();
    const hubRef = React.useRef<SystemControlHub>();

    React.useEffect(() => {
        if (systemConnection != null) {
            hubRef.current = new SystemControlHub(systemConnection);

            //收到新通知訊息，更新資料
            hubRef.current.addHandler.ReceiveNoticeMessage((json) => {
                if (!hideRef.current) {
                    let message = JSON.parse(json) as NoticeMessageContent;
                    setToastContents((toastContents) => ([...toastContents, { ...message, id: getId() }]));
                }
            });
        }
        return (() => {
            if (hubRef.current) {
                hubRef.current.removeHandler.ReceiveNoticeMessage();
            }
        })
    }, [systemConnection]);

    //模擬收訊息
    /*React.useEffect(() => {
        let interval = setInterval(() => {
            console.log(hideRef.current)
            if (!hideRef.current) {
                let message = { textCode: 0, textObject: { senderId: "U-0QmVo8uZs2P", content: "try it try it try it 文字文字文字文字 try it try it try it 文字文字文字文字", link: "google.com" }, hasBeenSeen: true, createTime: "" };
                setToastContents((toastContents) => ([...toastContents, { ...message, id: toastContents.length }]));
            }
        }, 6000);
        return (() => {
            clearInterval(interval);
        })
    }, []);*/

    function handleClose(id: number) {
        setToastContents(toastContents =>
            toastContents.filter(item => item.id !== id)
        )
    };
    function handleOnClick(id: number) {
        handleOpenNotice();
    };
    return (
        <Box position="fixed" top={matches ? "54px" : "42px"} right={matches ? "1.25rem" : "0.5rem"} bottom="auto" left="auto" className="notification-toast"
            sx={sx ?? { zIndex: (theme) => theme.zIndex.appBar - 1 }}
        >
            <List>
                <TransitionGroup component={null}>
                    {toastContents.map((content) => (
                        <CSSTransition
                            key={content.id}
                            timeout={350}
                            classNames="notification-toast-item"
                        >
                            <ListItem sx={{ px: 0 }}>
                                <NotificationToast
                                    message={content}
                                    closeToast={handleClose}
                                    autoClose
                                    pauseOnHover
                                    onClick={handleOnClick}
                                    closeOnClick
                                    delay={5000}
                                />
                            </ListItem>
                        </CSSTransition>
                    ))}
                </TransitionGroup>
            </List>
        </Box>
    )
}