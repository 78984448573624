﻿import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { BoothArticle } from '../class';
import { ResultType } from './utils';

export interface BoothArticleModel {
    dataId: string;
    boothId: string;
    posterId: string;
    editorId: string[];
    title: string;
    content: string;
    createTime: string;
}

export interface BoothArticleWithPoster {
    dataId: string;
    boothId: string;
    chName: string;
    enName: string;
    posterId: string;
    firstName: string;
    lastName: string;
    jobTitle: string;
    editorId: string[];
    title: string;
    content: string;
    isPin: boolean;
    createTime: string;
}

export const baseApi = createApi({
    reducerPath: 'articleApi',
    baseQuery: fetchBaseQuery({ baseUrl: 'api/article' }),
    endpoints: (builder) => ({
        getAllArticles: builder.query<ResultType<BoothArticle[]>, void>({
            query: () => ``,
        }),
        getArticle: builder.query<ResultType<BoothArticle>, string>({
            query: (id) => `${id}`,
        }),
        getArticlesByBooth: builder.query<ResultType<BoothArticle[]>, string>({
            query: (boothId) => `getByBooth/${boothId}`,
        }),
        getAllArticlesWithUser: builder.query<ResultType<BoothArticleWithPoster[]>, void>({
            query: () => `withposter`,
        })
    }),
})

//exhibitor或superuser或admin用api
export const articleApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        postArticle: builder.mutation<ResultType<null>, BoothArticleModel>({
            query: (patch) => ({
                url: ``,
                method: 'POST',
                body: patch
            }),
        }),
        putArticle: builder.mutation<ResultType<null>, BoothArticleModel>({
            query: (patch) => ({
                url: ``,
                method: 'PUT',
                body: patch
            }),
        }),
        deleteArticle: builder.mutation<ResultType<null>, BoothArticleModel>({
            query: (patch) => ({
                url: ``,
                method: 'DELETE',
                body: patch
            }),
        })
    }),
    overrideExisting: false
});

export const { useGetAllArticlesQuery } = articleApi;
export const { useLazyGetAllArticlesQuery } = articleApi;
export const { useGetArticleQuery } = articleApi;
export const { useGetArticlesByBoothQuery } = articleApi;
export const { useLazyGetArticlesByBoothQuery } = articleApi;
export const { useGetAllArticlesWithUserQuery } = articleApi;
export const { useLazyGetAllArticlesWithUserQuery } = articleApi;
export const { usePostArticleMutation } = articleApi;
export const { usePutArticleMutation } = articleApi;
export const { useDeleteArticleMutation } = articleApi;