﻿import * as React from 'react';
import { Box, Dialog, IconButton, Paper, styled, Typography, TypographyProps, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { BoothArticle } from '../class';
import { DialogContent } from '../responsive-components';
import { Close } from '@mui/icons-material';

export default function BoothArticlePage(props: BoothArticlePageProps) {
    const { data, matches } = props;

    return (
        <Paper style={{ padding: matches ? '20px 40px' : '16px 10px' }}>
            {data &&
                <BoothArticleContent
                data={data}
                matches={matches}
                />
            }
        </Paper>
    )
}
interface BoothArticlePageProps {
    data: BoothArticle;
    matches: boolean;
}

export function BoothArticleDialog(props: BoothArticleDialogProps) {
    const { data, matches, open, onClose } = props;

    function handleClose() {
        onClose();
    }

    return (
        <Dialog
            onClose={handleClose}
            fullWidth
            maxWidth='lg'
            open={open}
            PaperProps={{ sx: { overflow: 'initial' } }}
            scroll={'body'}
        >
            <IconButton
                onClick={handleClose}
                style={{
                    position: 'absolute', top: '0px', right: '-42px',
                    backgroundColor: 'transparent', color: 'white'
                }}
            >
                <Close fontSize='large' />
            </IconButton>
            <DialogContent style={{ minHeight: '400px' }}>
                {data &&
                    <BoothArticleContent
                        data={data}
                        matches={matches}
                    />
                }
            </DialogContent>
        </Dialog>
    )
}
interface BoothArticleDialogProps {
    data: BoothArticle;
    matches: boolean;
    open: boolean;
    onClose: () => void;
}

export function BoothArticleContent(props: BoothArticlePageProps) {
    const { data, matches } = props;

    const StyledTimeTypography: (props: TypographyProps) => JSX.Element = styled(Typography)(({ theme }) => ({
        fontSize: matches ? '16px' : '11px',
        color: 'gray',
    }));
    const StyledTitleTypography: (props: TypographyProps) => JSX.Element = styled(Typography)(({ theme }) => ({
        fontSize: matches ? '30px' : '20px',
        fontWeight: 'bold',
        color: theme.palette.primary.main,
    }));

    const { t, i18n } = useTranslation();

    return (<>
        <StyledTimeTypography>
            {`${t('boothArticle.table.time')} ${new Date(data.createTime).toLocaleString(
                i18n.language == 'ch' ? 'zh-tw' : 'en-us', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
            })}`}
        </StyledTimeTypography>

        {(data.boothId != null && data.boothId != '') &&
            <StyledTitleTypography>
                {`【${i18n.language == 'ch' ? data.chName : data.enName}】`}
            </StyledTitleTypography>
        }
        <StyledTitleTypography>
            {data.title}
        </StyledTitleTypography>

        <div className="ck-content"
            dangerouslySetInnerHTML={{ __html: data.content }}
        />
    </>)
}
interface BoothArticlePageProps {
    data: BoothArticle;
    matches: boolean;
}