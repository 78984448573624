﻿import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent } from '@mui/material';
import StartAnnounce from '../components/StartAnnounce/StartAnnounce';
import { useGetAnnouncesQuery } from '../components/services/system';
import { logEvent } from '../components/Analytics';

export interface HomeStepperProps {
    open: boolean;
    onClose?: () => void;
}

export interface HomeStepperContent {
    message: string;
    link: string;
}

export default function HomeStepper(props: HomeStepperProps) {
    const { open, onClose } = props;
    const { t } = useTranslation();

    const { contents = [], isLoading } = useGetAnnouncesQuery(undefined, {
        selectFromResult: ({ data, isLoading }) => ({ contents: data?.data, isLoading }),
        refetchOnMountOrArgChange: true
    });

    const [page, setPage] = React.useState<number>(0);
    const Content = () => {
        if (contents.length != 0) {
            return (
                <StartAnnounce
                    data={contents[page]}
                />
            )
        }
        else {
            return (
                <div>Empty</div>
            )
        }
    };

    const ChangePage = (index: number) => {
        logEvent(`Home`, `HomeStepper`, `ChangePage`);
        setPage(index);
    }

    return (
        <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth="sm" style={{ background: 'none', boxShadow: 'none' }}>
            <DialogContent>
                {!isLoading ?
                    <Content /> :
                    <CircularProgress />
                }
            </DialogContent>
            <DialogActions>
                {page > 0 &&
                    <Button variant="contained" onClick={() => ChangePage(page - 1)}>{t('home.stepper.last')}</Button>
                }
                {page < contents.length - 1 &&
                    <Button variant="contained" onClick={() => ChangePage(page + 1)}>{t('home.stepper.next')}</Button>
                }
                <Button variant="contained" onClick={onClose}>{t('home.stepper.skip')}</Button>
            </DialogActions>
        </Dialog>
    )
}