﻿import { Avatar, Backdrop, Box, Button, CircularProgress, Dialog, Divider, IconButton, Menu, MenuItem, Paper, Select, Tooltip } from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton, GridValueFormatterParams } from '@mui/x-data-grid';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Booth, CardInfo, UserRole, userRoleList } from '../../../components/class';
import { useEditBoothManagementMutation, useEditUserLevelMutation, useGetUsersWithLevelQuery, UserCardInfoModel, UserManageModel } from '../../../components/services/user';
import { useGetAllBoothsQuery } from '../../../components/services/booth';
import { useUser } from '../../../store';
import { zhTWGrid } from '../../data-grid-locale';
import { AddCircleOutline, Cancel } from '@mui/icons-material';
import { cloneDeep } from 'lodash';
import { SubTitle } from '../../../components/responsive-components/Typography';
import { TextField } from '../../../components/responsive-components';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { UserCardDialog } from '../../../components/BussinessCard/UserCardDialog';

export default function UsersLevelManage(props) {
    const { t, i18n } = useTranslation();

    //獲取所有使用者資料
    const { data: userListData, isLoading: isUserListLoading, isSuccess: isUserListSuccess } = useGetUsersWithLevelQuery(null, {
        refetchOnMountOrArgChange: true
    });
    React.useEffect(() => {
        if (userListData?.isSuccess && userListData?.data) {
            setUserManageModelList([...userListData.data]);
        }
    }, [userListData])
    const [userManageModelList, setUserManageModelList] = React.useState<UserManageModel[]>([]);

    //獲取所有攤位資料
    const { data: boothListData, isLoading: isBoothListLoading, isSuccess: isBoothListSuccess } = useGetAllBoothsQuery(null, {
        refetchOnMountOrArgChange: true
    });
    React.useEffect(() => {
        if (boothListData?.isSuccess && boothListData?.data) {
            setBoothList([...boothListData.data]);
        }
    }, [boothListData])
    const [boothList, setBoothList] = React.useState<Booth[]>([]);

    //名片顯示視窗
    const [cardOpen, setCardOpen] = React.useState(false);
    const [currentCardData, setCurrentCardData] = React.useState<CardInfo>(null);
    function handleOpenCard(userId: string) {
        let data = userManageModelList.find(x => x.userId === userId);
        if (data) {
            setCurrentCardData(data);
            setCardOpen(true);
        }
    }
    const handleCloseCard = () => {
        setCardOpen(false);
    };

    //編輯使用者權限
    const [editUserLevel, { isLoading: isEditLevelLoading }] = useEditUserLevelMutation();
    function handleEditUserLevel(userId: string, role: UserRole) {
        editUserLevel({
            userId: userId,
            role: role
        }).unwrap().then(
            (result) => {
                if (!result.isSuccess) {
                    setDialogMessage(result.message);
                    setOpenDialog(true);
                    return;
                }

                let tempList = cloneDeep(userManageModelList);
                let index = tempList.findIndex(x => x.userId === userId);
                if (index === -1) {
                    setDialogMessage(t('manage.usersLevelManage.message.error'));
                    setOpenDialog(true);
                    return;
                }

                tempList[index].role = role;
                setUserManageModelList([...tempList]);
                setDialogMessage(t('manage.usersLevelManage.message.edit'));
                setOpenDialog(true);
            },
            (error) => {
                setDialogMessage(t('manage.usersLevelManage.message.error'));
                setOpenDialog(true);
                console.log(error);
            }
        )
    }

    //攤位選擇菜單
    const [currentUserId, setCurrentUserId] = React.useState<string>(null);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const boothMenuOpen = Boolean(anchorEl);
    function handleOpenBoothMenu(event: React.MouseEvent<HTMLButtonElement>, userId: string) {
        if (userId) {
            setCurrentUserId(userId);
            setAnchorEl(event.currentTarget);
        }
    }
    const handleCloseBoothMenu = (boothId?: string) => {
        if (boothId && currentUserId) {
            handleAddBooth(currentUserId, boothId);
        }
        setCurrentUserId(null);
        setAnchorEl(null);
    };

    //編輯攤位權限
    const [editBoothManagement, { isLoading: isEditBoothLoading }] = useEditBoothManagementMutation();
    function handleAddBooth(userId: string, boothId: string) {
        editBoothManagement({
            userId: userId,
            boothId: boothId,
            state: true
        }).unwrap().then(
            (result) => {
                if (!result.isSuccess) {
                    setDialogMessage(result.message);
                    setOpenDialog(true);
                    return;
                }

                let tempList = cloneDeep(userManageModelList);
                let index = tempList.findIndex(x => x.userId === userId);
                if (index === -1) {
                    setDialogMessage(t('manage.usersLevelManage.message.error'));
                    setOpenDialog(true);
                    return;
                }

                if (tempList[index].boothIds.includes(boothId)) {
                    setDialogMessage(t('manage.usersLevelManage.message.duplicate'));
                    setOpenDialog(true);
                    return;
                }

                tempList[index].boothIds.push(boothId);
                setUserManageModelList([...tempList]);
                setDialogMessage(t('manage.usersLevelManage.message.new'));
                setOpenDialog(true);
            },
            (error) => {
                setDialogMessage(t('manage.usersLevelManage.message.error'));
                setOpenDialog(true);
                console.log(error);
            }
        )
    }
    function handleDeleteBooth(userId: string, boothId: string) {
        editBoothManagement({
            userId: userId,
            boothId: boothId,
            state: false
        }).unwrap().then(
            (result) => {
                if (!result.isSuccess) {
                    setDialogMessage(result.message);
                    setOpenDialog(true);
                    return;
                }

                let tempList = cloneDeep(userManageModelList);
                let index = tempList.findIndex(x => x.userId === userId);
                if (index === -1) {
                    setDialogMessage(t('manage.usersLevelManage.message.error'));
                    setOpenDialog(true);
                    return;
                }

                tempList[index].boothIds = tempList[index].boothIds.filter(x => x !== boothId);
                setUserManageModelList([...tempList]);
                setDialogMessage(t('manage.usersLevelManage.message.delete'));
                setOpenDialog(true);
            },
            (error) => {
                setDialogMessage(t('manage.usersLevelManage.message.error'));
                setOpenDialog(true);
                console.log(error);
            }
        )
    }

    //彈出結果視窗
    const [openDialog, setOpenDialog] = React.useState(false);
    const [dialogMessage, setDialogMessage] = React.useState("");
    const dialogContents = (
        <div style={{ padding: '1rem 2rem', textAlign: 'center', fontSize: '1rem' }} >
            <p style={{ marginBottom: 0 }}>
                {dialogMessage}
            </p>
        </div>
    );

    return (
        <div>
            <SubTitle
                icon='circle'
                text={t('manage.usersLevelManage.title')}
            />
            <UserManageTable
                dataList={userManageModelList}
                boothList={boothList}
                handleOpenCard={handleOpenCard}
                handleEditUserLevel={handleEditUserLevel}
                handleOpenBoothMenu={handleOpenBoothMenu}
                handleAddBooth={handleAddBooth}
                handleDeleteBooth={handleDeleteBooth}
            />
            <UserCardDialog
                data={{ ...currentCardData } as UserCardInfoModel}
                open={cardOpen}
                onClose={handleCloseCard}
            />
            <BoothSelectWindow
                anchorEl={anchorEl}
                open={boothMenuOpen}
                boothList={boothList}
                handleClose={handleCloseBoothMenu}
            />
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isUserListLoading || isBoothListLoading || isEditLevelLoading || isEditBoothLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>{dialogContents}</Dialog>
        </div>
    )
};

interface UserManageTableProps {
    dataList: UserManageModel[];
    boothList: Booth[];
    handleOpenCard: (userId: string) => void;
    handleEditUserLevel: (userId: string, role: UserRole) => void;
    handleOpenBoothMenu: (event: React.MouseEvent<HTMLButtonElement>, userId: string) => void;
    handleAddBooth: (userId: string, boothId: string) => void;
    handleDeleteBooth: (userId: string, boothId: string) => void;
}
function UserManageTable(props: UserManageTableProps) {
    const { dataList, boothList, handleOpenCard, handleEditUserLevel, handleOpenBoothMenu, handleAddBooth, handleDeleteBooth } = props;
    const { t, i18n } = useTranslation();
    const user = useUser();

    const columns: GridColDef[] = [
        {
            field: 'userId',
            headerName: t('manage.usersLevelManage.options.user'),
            flex: 0.5,
            minWidth: 280,
            renderCell: ({ row: { firstName, lastName }, value: userId }: GridRenderCellParams<string, UserManageModel>) => (
                <Button sx={{ textTransform: 'unset' }} onClick={() => handleOpenCard(userId)}>{`${userId} - ${firstName} ${lastName}`}</Button>
            ),
        },
        {
            field: 'role',
            headerName: t('manage.usersLevelManage.options.role'),
            width: 220,
            valueFormatter: (params: GridValueFormatterParams) => params.value,
            renderCell: (params: GridRenderCellParams<string>) => (
                <Select
                    variant="standard"
                    disabled={String(params.id) === user?.userId || user?.role < (params.getValue(params.id, 'role') as UserRole)}
                    value={params.getValue(params.id, 'role')}
                    onChange={(event) => handleEditUserLevel(String(params.id), event.target.value as UserRole)}
                    sx={{ width: '100%', maxWidth: "200px" }}
                >
                    {userRoleList.filter(x => x.value != UserRole.anonymous).filter(x => x.value <= user?.role).map((userRole) => (
                        <MenuItem value={userRole.value}>{t(userRole.nameKey)}</MenuItem>
                    ))}
                </Select>
            ),
        },
        {
            field: 'boothIds',
            headerName: t('manage.usersLevelManage.options.boothIds'),
            flex: 1,
            minWidth: 200,
            valueFormatter: (params: GridValueFormatterParams) => {
                let array: string[] = params.value as string[];
                return array.join(', ');
            },
            renderCell: (params: GridRenderCellParams<string>) => (
                <Box display='flex' flexWrap='wrap'>
                    {(params.value as any).map((item: string) => (
                        <BoothButton
                            userId={String(params.id)}
                            booth={boothList.find(x => x.boothId === item)}
                            handleDeleteBooth={handleDeleteBooth}
                        />
                    ))}
                    <IconButton
                        onClick={(event) => handleOpenBoothMenu(event as React.MouseEvent<HTMLButtonElement, MouseEvent>, String(params.id))}
                        color="primary" component="span">
                        <AddCircleOutline />
                    </IconButton>
                </Box>
            ),
        }
    ];

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                <GridToolbarExport csvOptions={{ utf8WithBom: true }} />
            </GridToolbarContainer>
        );
    }

    return (
        <ThemeProvider theme={(theme) => createTheme({ ...theme, palette: { ...theme.palette, background: { ...theme.palette.background, default: "#ffffff" } } })}>
            <DataGrid
                rows={dataList}
                columns={columns}
                getRowId={(row) => row.userId}
                pageSize={10}
                autoHeight
                disableColumnMenu
                disableColumnSelector
                components={{
                    Toolbar: CustomToolbar,
                }}
                localeText={i18n.language == "ch" ? zhTWGrid : null}
            />
        </ThemeProvider>
    )
}

interface BoothButtonProps {
    userId: string;
    booth: Booth;
    handleDeleteBooth: (userId: string, boothId: string) => void;
}
function BoothButton(props: BoothButtonProps) {
    const { userId, booth, handleDeleteBooth } = props;
    const { t, i18n } = useTranslation();

    const [isHover, setIsHover] = React.useState(false);
    const handleMouseOver = () => { setIsHover(true); }
    const handleMouseLeave = () => { setIsHover(false); }

    return (
        <Tooltip title={i18n.language === 'ch' ? booth?.chName : booth?.enName ?? 'Not Found'}>
            <Box sx={{ position: 'relative', mr: '6px' }} onMouseOver={handleMouseOver} onMouseLeave={handleMouseLeave}>
                <Avatar src={booth?.logoUrl ?? ''} />
                {isHover &&
                    <Button
                        onClick={() => handleDeleteBooth(userId, booth?.boothId)}
                        color="error" disableRipple sx={{ p: 0, minWidth: "unset", position: 'absolute', top: '-4px', right: '-4px' }}>
                        <Cancel color='inherit' fontSize='small' />
                    </Button>
                }
            </Box>
        </Tooltip>
    )
}

const ITEM_HEIGHT = 48;
interface BoothSelectWindowProps {
    boothList: Booth[];
    open: boolean;
    anchorEl: Element | ((element: Element) => Element);
    handleClose: (boothId?: string) => void;
}
function BoothSelectWindow(props: BoothSelectWindowProps) {
    const { boothList, open, anchorEl, handleClose } = props;
    const { t, i18n } = useTranslation();

    //搜尋文字
    const [searchString, setSearchString] = React.useState<string>('');
    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchString(event.target.value);
    };
    function textSearch(data: Booth): boolean {
        let isMatch = false;

        if (data.chName && data.chName.includes(searchString)) isMatch = true;
        if (data.enName && data.enName.toLowerCase().includes(searchString.toLowerCase())) isMatch = true;
        if (data.abbrev && data.abbrev.includes(searchString)) isMatch = true;

        return isMatch;
    }

    return (
        <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={() => handleClose()}
        >
            <TextField
                variant='outlined'
                margin="dense"
                value={searchString}
                onChange={handleSearchChange}
                placeholder={t('manage.usersLevelManage.placeholder.booth')}
                style={{
                    padding: 12,
                    width: '100%',
                    minWidth: 200,
                    maxWidth: 320,
                    margin: 0,
                }}
            />
            <Divider />
            <Paper elevation={0} style={{ maxHeight: ITEM_HEIGHT * 4.5, maxWidth: 320, overflowY: 'auto', overflowX: 'hidden' }}>
                {boothList.filter(x => textSearch(x)).map((booth) => (
                    <MenuItem key={booth.boothId} onClick={() => handleClose(booth.boothId)}>
                        {i18n.language === 'ch' ? booth.chName : booth.enName}
                    </MenuItem>
                ))}
            </Paper>
        </Menu>
    )
}