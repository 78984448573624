﻿import { Button, Stack, Typography } from '@mui/material';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { BoothManageInvitation } from '../components/class';

export default function RetypePage(props: RetypePageProps) {
    const { code, data, handleRecheckInvite } = props;
    const history = useHistory();
    const { t } = useTranslation();
    return (
        <div>
            <Stack width={280} spacing={3} justifyContent="stretch" alignItems="stretch">
                <div>
                    <Typography align='center'>
                        {t("invite.wrongInput")}
                    </Typography>
                </div>
                <Button variant='contained' color='primary' onClick={() => handleRecheckInvite()}>{t("invite.button.retype")}</Button>
                <Button variant='contained' color='error' onClick={() => history.push("/")}>{t("invite.button.giveUp")}</Button>
            </Stack>
        </div >
    );
}
interface RetypePageProps {
    code: string;
    data: BoothManageInvitation;
    handleRecheckInvite: () => void;
}