﻿import * as React from 'react';
import { Stack, Typography } from '@mui/material';
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { GeneralTextField, PaperWrap, SubmitButton, TextButton } from './LoginWindow';
import { useForgotPasswordMutation } from '../components/services/login';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import './LoginStyleSheet.css';

const HomeIcon = "images/logo.png"

type formData = {
    email: string;
}
export default function ForgotPasswordWindow() {
    const history = useHistory();

    const [forgotPassword, { isLoading }] = useForgotPasswordMutation();
    const { t, i18n } = useTranslation();

    const schema = yup.object().shape({
        email: yup.string().required(t('forgotPassword.email.error1')).email(t('forgotPassword.email.error2')),
    });
    const { register, handleSubmit, formState: { errors } } = useForm<formData>({
        mode: "onBlur", resolver: yupResolver(schema),
        defaultValues: { email: "" }
    });

    const [errorMessage, setErrorMessage] = React.useState<string>("");

    const [success, setSuccess] = React.useState(false);

    function handleRegister() {
        history.push("/Register");
    }

    function onForgotSubmit(data) {
        setErrorMessage("");
        forgotPassword(data)
            .unwrap()
            .then(result => {
                if (result.isSuccess) {
                    setSuccess(true);
                }
                else {
                    setErrorMessage(result.message);
                }
            }).catch((error) => {
                //後端報錯 ex:500、404
                setErrorMessage("Unconnect");
                console.error("發生錯誤", error)
            });
    }

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };

    if (success) {
        return (<>重設密碼的連結已成功寄送至您的信箱，<a href="/">點我回首頁</a>。</>)
    }
    else {
        return (
            <div className="root bg-lavender">
                <PaperWrap className="py-5" elevation={3}>
                    <Stack width={280} justifyContent="stretch" alignItems="stretch">
                        <div className="d-flex align center w-100">
                            <img src={HomeIcon} style={{ height: '36px', maxWidth: '100%' }} />
                            <Typography variant='subtitle2' style={{ padding: 6, wordBreak: "keep-all" }}>{t('navbar.title')}</Typography>
                        </div>

                        <div style={{ margin: "24px 0px", color: '#cccccc' }}>
                            <Typography variant="h5" style={{ fontWeight: 'bold' }}>
                                {t('forgotPassword.subtitle1')}
                            </Typography>
                            <Typography variant="h5">
                                {t('forgotPassword.subtitle2')}
                            </Typography>
                            <Typography variant="h5">
                                {t('forgotPassword.subtitle3')}
                            </Typography>
                        </div>

                        <Stack component="form" sx={{ minWidth: 200 }} onSubmit={handleSubmit(onForgotSubmit)}>
                            <GeneralTextField
                                variant="outlined"
                                size='small'
                                placeholder={t('forgotPassword.email.placeholder')}

                                inputRef={register('email').ref}
                                error={!!errors.email}
                                helperText={errors.email ? errors.email.message : ' '}
                                {...register("email")}

                                FormHelperTextProps={{ style: { position: 'absolute', bottom: 0, right: 0 } }}
                                disabled={isLoading}
                            />
                            <div className="d-flex center" style={{ height: 30 }}>
                                <Typography variant='body1' sx={{ color: (theme) => theme.palette.error.main }}>{errorMessage}</Typography>
                            </div>
                            <SubmitButton type="submit" variant="contained" loading={isLoading}>
                                {t('forgotPassword.button')}
                            </SubmitButton>
                        </Stack>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Typography variant='body2'>{t('login.question')}</Typography>
                            <TextButton onClick={handleRegister}>{t('login.signup')}</TextButton>
                        </div>

                        <div style={{ height: 12 }} />
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Typography variant='body2'>Click to</Typography>
                            {i18n.language == 'ch' ?
                                <TextButton onClick={() => changeLanguage('en')}>ENG</TextButton> :
                                <TextButton onClick={() => changeLanguage('ch')}>中文版</TextButton>
                            }
                        </div>
                    </Stack>
                </PaperWrap>
            </div >
        );
    }
}