﻿import * as React from 'react';
import { SubTitle } from '../../components/responsive-components/Typography';
import { Trans, useTranslation } from 'react-i18next';
import { LoyaltyCardData, useGetUserSeminarSignInDatasQuery, UserSeminarSignInData } from '../../components/services/user';
import { Backdrop, Box, Button, CircularProgress, Divider, Fade, LinearProgress, Popper, Stack, styled, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton, GridValueGetterParams } from '@mui/x-data-grid';
import { format } from 'date-fns';
import { zhTWGrid } from '../data-grid-locale';
import zhTW from 'date-fns/locale/zh-TW';
import { HelpOutline } from '@mui/icons-material';
import { PopperPaper } from '../../Login/LoginWindow';

const Square = styled("div")(({ theme }) => ({
    position: "relative",
    margin: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
        margin: theme.spacing(0.5),
    },
    border: "1px solid",
    borderColor: theme.palette.grey[600],
    [theme.breakpoints.down('md')]: {
        width: "92px",
        height: "92px",
    },
    [theme.breakpoints.up('md')]: {
        width: "94px",
        height: "94px",
    },
    [theme.breakpoints.up('lg')]: {
        width: "96px",
        height: "96px",
    },
}));

interface SeminarPointsManageProps {
    data: LoyaltyCardData;
}
export default function SeminarPointsManage(props: SeminarPointsManageProps) {
    const { data } = props;
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const { data: signInDataList, isLoading } = useGetUserSeminarSignInDatasQuery(null, {
        refetchOnMountOrArgChange: true
    });

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const openPopper = Boolean(anchorEl);
    const handleClickShowHelp = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    //顯示數量
    const [displayCount, setDisplayCount] = React.useState<number>(6);
    function handleShowMore() {
        setDisplayCount(22);
    }
    function handleHideMore() {
        setDisplayCount(6);
    }
    return (<>
        <Stack ml={{ xs: 0, sm: 3 }} mb={2}>
            <Typography variant='subtitle1'>
                參加Open House線上說明會可參加對應場次之抽獎活動，如遲到、早退或線上未配合隨機簽到，將不計入參加該場次之抽獎。
            </Typography>
            <Typography variant='subtitle1' color="error.light">
                ※ 並非每場說明會皆有抽獎活動與獎品，詳細請參照每場說明會之「說明會獎品」資訊。
            </Typography>
            <Box sx={{ cursor: "pointer" }} onClick={handleClickShowHelp}>
                <Typography variant='subtitle1' color={theme.palette.grey[400]} sx={{ display: "flex", alignItems: "center" }}>
                    <HelpOutline sx={{ marginRight: "0.5rem" }} fontSize="small" />
                    <Trans i18nKey="manage.seminarPointsManage.help" />
                </Typography>
            </Box>
            <Popper open={openPopper} anchorEl={anchorEl} placement="bottom" transition modifiers={[
                {
                    name: 'offset',
                    enabled: true,
                    options: {
                        offset: [100, 0]
                    },
                },
                {
                    name: 'preventOverflow',
                    enabled: true,
                    options: {
                        padding: 8,
                    }
                }
            ]}>
                {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>
                        <div>
                            <PopperPaper elevation={3} sx={{ width: "360px", maxWidth: { xs: "calc(100vw - 16px)", lg: "unset" } }}>
                                <Typography>獎品登記兌換自3月14日至3月17日15:00止，兌換人請務必攜帶學生證與集點信件，至職發組（交大校區資訊技術中心3樓）進行登記作業；</Typography>
                                <Typography>獎品發放時程將另行個別通知，且須為本人，不接受代領。</Typography>
                            </PopperPaper>
                        </div>
                    </Fade>
                )}
            </Popper>
        </Stack>
        {isLoading &&
            <LinearProgress />
        }
        {new Date() > new Date("2022-03-10 20:00:00 +08:00") &&
            <Stack paddingBottom='12px'>
                <Typography variant='h6' fontWeight="bold">
                    <Trans i18nKey="manage.seminarPointsManage.pointText"
                        defaults="已累積 <0>{{totalPoint}}</0> 點，共獲得 <0>{{totalTicket}}</0> 張說明會抽獎券"
                        values={{ totalPoint: data?.seminarPoint, totalTicket: data?.seminarTicket }}
                        components={[<span style={{ color: theme.palette.error.light }}></span>]}
                    />
                </Typography>
                <Box display="flex" flexWrap="wrap">
                    {[...Array(22)].map((x, index) => {
                        if (isMobile && index >= displayCount) return null;
                        else return (
                            <Square key={index.toString()}>
                                {data?.seminarPointData[index] ? <>
                                    <Box display="flex" justifyContent="center" alignItems="center" width="100%" height="100%">
                                        <Box p={0.5} sx={{ border: "4px solid", borderColor: theme.palette.success.light }}>
                                            <Typography color={theme.palette.success.light} fontWeight="bold" variant="body2"
                                                sx={{ border: "1px solid", borderColor: theme.palette.success.light, px: 0.8 }}>已簽到</Typography>
                                        </Box>
                                    </Box>
                                    {data?.seminarPointData[index].isExchanged ?
                                        <Typography color="primary" variant="body2" align="center" sx={{ position: "absolute", bottom: "2px", left: 0, right: 0 }}>此章已兌換</Typography>
                                        :
                                        <Typography color={theme.palette.grey[400]} variant="body2" align="center" sx={{ position: "absolute", bottom: "2px", left: 0, right: 0 }}>此章未兌換</Typography>
                                    }
                                </> :
                                    <Box display="flex" justifyContent="center" alignItems="center" width="100%" height="100%">
                                        <Typography color={theme.palette.grey[400]} fontWeight="bold" variant="body2">說明會</Typography>
                                    </Box>
                                }
                            </Square>
                        )
                    })}
                </Box>
                {isMobile && displayCount < 22 &&
                    <Stack py={2.5}>
                        <Button
                            variant="outlined"
                            onClick={() => handleShowMore()}
                            sx={{
                                textTransform: 'none',
                                borderRadius: "50px"
                            }}
                        >
                            <Typography variant='h6'>
                                {t('顯示全部')}
                            </Typography>
                        </Button>
                    </Stack>
                }
                {isMobile && displayCount === 22 &&
                    <Stack py={2.5}>
                        <Button
                            variant="outlined"
                            onClick={() => handleHideMore()}
                            sx={{
                                textTransform: 'none',
                                borderRadius: "50px"
                            }}
                        >
                            <Typography variant='h6'>
                                {t('向上收合')}
                            </Typography>
                        </Button>
                    </Stack>
                }
            </Stack>
        }

        <Box display="flex" alignItems="center" mb={1}>
            <Typography fontWeight='bold' sx={{ color: theme => theme.palette.primary.main }}>
                {t('manage.seminarPointsManage.tableTitle')}
            </Typography>
            <Divider sx={{ flexGrow: 1, margin: '0px 20px', borderColor: theme => theme.palette.primary.main }} />
        </Box>

        <SignInDataList dataList={signInDataList?.data ?? []} loading={isLoading} />
    </>)
};

interface SignInDataListProps {
    dataList: UserSeminarSignInData[];
    loading: boolean;
}
function SignInDataList(props: SignInDataListProps) {
    const { dataList, loading } = props;
    const { t, i18n } = useTranslation();

    const columns: GridColDef[] = [
        {
            field: 'date',
            headerName: t('manage.seminarPointsManage.options.date'),
            //flex: 1,
            minWidth: 120,
            valueGetter: (params: GridValueGetterParams) => {
                if (i18n.language == "ch")
                    return `${format(new Date(params.row?.startTime.toString()), "MM/dd(EEEEE)", { locale: zhTW })}`;
                else
                    return `${format(new Date(params.row?.startTime.toString()), "MM/dd(E)")}`;
            },
        },
        {
            field: 'time',
            headerName: t('manage.seminarPointsManage.options.time'),
            //flex: 1,
            minWidth: 120,
            valueGetter: (params: GridValueGetterParams) =>
                `${format(Date.parse(params.row?.startTime.toString()), "HH:mm")}-${format(Date.parse(params.row?.endTime.toString()), "HH:mm")}`,
        },
        {
            field: 'seminarTitle',
            headerName: t('manage.seminarPointsManage.options.seminarTitle'),
            //flex: 0.5,
            minWidth: 150,
            valueGetter: (params: GridValueGetterParams) => {
                if (i18n.language == "ch")
                    return `${params.row?.seminarChTitle || ''}`;
                else
                    return `${params.row?.seminarEnTitle || ''}`;
            },
        },
        {
            field: 'agendaTitle',
            headerName: t('manage.seminarPointsManage.options.agendaTitle'),
            flex: 0.5,
            minWidth: 150,
        },
        {
            field: 'boothName',
            headerName: t('manage.seminarPointsManage.options.boothName'),
            flex: 1,
            minWidth: 150,
            valueGetter: (params: GridValueGetterParams) => {
                if (i18n.language == "ch")
                    return `${params.row?.boothChName || ''}`;
                else
                    return `${params.row?.boothEnName || ''}`;
            },
        }
    ];

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                <GridToolbarExport csvOptions={{ utf8WithBom: true }} />
            </GridToolbarContainer>
        );
    }
    return (
        <DataGrid
            rows={dataList}
            columns={columns}
            autoHeight
            disableColumnMenu
            disableColumnSelector
            components={{
                Toolbar: CustomToolbar,
            }}
            localeText={i18n.language == "ch" ? zhTWGrid : null}
            loading={loading}
        />
    )
}