﻿import * as React from 'react';
import { Agenda, SeminarSignIn } from '../../../components/class';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../components/responsive-components';
import { Box, Card, CardContent, Divider, Typography } from '@mui/material';

export default function SeminarSignInCard(props: SeminarSignInProps) {
    const { isManage, matches, seminarName, data, agenda, showDate, onEdit, onDelete } = props;
    const { t, i18n } = useTranslation();

    return (
        <Card style={{ display: 'flex', flexDirection: 'row' }}>
            <CardContent style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography fontWeight='bold' variant={matches ? 'subtitle2' : 'subtitle1'}>
                    {new Date(agenda.startTime).toLocaleString(
                        i18n.language == "ch" ? "zh-TW" : 'en-US',
                        { hour12: false, hour: '2-digit', minute: '2-digit' }
                    )}
                </Typography>
                <Divider flexItem orientation='vertical' variant='middle' style={{ flex: 1, margin: 'auto' }} />
                <Typography fontWeight='bold' variant={matches ? 'subtitle2' : 'subtitle1'}>
                    {new Date(agenda.endTime).toLocaleString(
                        i18n.language == "ch" ? "zh-TW" : 'en-US',
                        { hour12: false, hour: '2-digit', minute: '2-digit' }
                    )}
                </Typography>
            </CardContent>
            <Divider flexItem orientation='vertical' />
            <CardContent style={{ flex: 1, position: 'relative' }}>
                <Typography fontWeight='bold' style={{ fontSize: matches ? '12px' : '14px' }}>
                    {showDate &&
                        <span style={{ marginRight: '12px' }}>
                            {new Date(data.startTime).toLocaleString(
                                i18n.language == "ch" ? "zh-TW" : 'en-US',
                                { year: "numeric", month: '2-digit', day: '2-digit', weekday: 'narrow' }
                            )}
                        </span>
                    }
                    <span style={{ color: '#91CFFF' }}>
                        {seminarName}
                    </span>
                </Typography>
                <Typography fontWeight='bold' style={{ fontSize: matches ? '12px' : '16px' }}>
                    {i18n.language == "ch" ? agenda.boothChName : agenda.boothEnName}
                </Typography>
                <Typography fontWeight='bold' style={{ fontSize: matches ? '16px' : '22px' }}>
                    {agenda.title}
                </Typography>
                <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                    <Typography fontWeight='bold' variant={matches ? 'subtitle2' : 'subtitle1'} style={{ marginRight: '24px' }}>
                        {t('seminarSignIn.card.signInStartTime')}
                        <span style={{ color: 'red' }}>
                        {new Date(data.startTime).toLocaleString(
                            i18n.language == "ch" ? "zh-TW" : 'en-US',
                            { hour12: false, hour: '2-digit', minute: '2-digit' }
                            )}
                        </span>
                    </Typography>
                    <Typography fontWeight='bold' variant={matches ? 'subtitle2' : 'subtitle1'}>
                        {t('seminarSignIn.card.persistTime')}
                        <span style={{ color: 'red' }}>
                            {(new Date(data.endTime).getTime() - new Date(data.startTime).getTime()) / 1000 / 60}
                        </span>
                        {t('seminarSignIn.card.minutes')}
                    </Typography>
                </Box>
                {isManage &&
                    <Box style={{
                        position: 'absolute', right: 0, top: 0, padding: '8px 16px',
                        display: 'flex', flexDirection: 'column'
                    }}>
                        {onEdit &&
                            <Button onClick={() => onEdit(data)}>
                                {t('agenda.card.edit')}
                            </Button>
                        }
                        {onDelete &&
                            <Button
                                onClick={() => onDelete(data)}
                                sx={{
                                    color: 'gray',
                                    ':hover': {
                                        backgroundColor: '#70707010',
                                    }
                                }}
                            >
                                {t('agenda.card.delete')}
                            </Button>
                        }
                    </Box>
                }
            </CardContent>
        </Card>
    )
}
interface SeminarSignInProps {
    isManage?: boolean;
    matches?: boolean;
    seminarName: string;
    data: SeminarSignIn;
    agenda: Agenda;
    showDate?: boolean;
    onEdit?: (value: SeminarSignIn) => void;
    onDelete?: (value: SeminarSignIn) => void;
}