﻿import * as React from 'react';
import { Box, Button, Container, MenuItem, Stack, Toolbar, Typography, useMediaQuery, useTheme } from "@mui/material";
import { boothTypeList } from '../components/boothType';
import { useTranslation } from 'react-i18next';
import { TextField } from '../components/responsive-components';
import { BoothCardInfoModel } from '../components/services/booth';
import { useDispatchWithType, useUser } from '../store';
import { BoothCard } from '../components/BoothCard/BoothCard';
import { SearchBar } from '../components/responsive-components';
import { useChangeBookMarkMutation } from '../components/services/user';
import { updateUser } from '../store/rootReducer';
import { useHistory } from 'react-router-dom';

export default function BoothList(props: BoothListProps) {
    const { boothList, category, justDisplay, withContainer } = props;
    const history = useHistory();

    //篩選
    const [filteredBoothList, setFilteredBoothList] = React.useState<BoothCardInfoModel[]>([]);

    const [filterCategory, setFilterCategory] = React.useState<number>(category ?? -1);
    const [filterText, setFilterText] = React.useState<string>("");
    React.useEffect(() => {
        setFilteredBoothList(boothList?.filter(isPass) ?? []);
    }, [boothList, filterCategory, filterText]);

    const handleSelectChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        handleChangeCategory(Number(event.target.value));
    };
    const handleChangeCategory = (type: number) => {
        if (filterCategory !== type) {
            setFilterCategory(type);
        }
    };
    const handleChangeText = (value: string) => {
        if (filterText !== value) {
            setFilterText(value);
        }
    };

    function isPass(data: BoothCardInfoModel) {
        if (filterCategory >= 0) {
            if (data.category !== filterCategory) return false;
        }
        if (filterText && filterText != "" && !(data.chName?.toLowerCase().includes(filterText.toLowerCase()) ||
            data.enName?.toLowerCase().includes(filterText.toLowerCase()) || data.boothNo?.includes(filterText)))
            return false;

        return true;
    }

    //顯示數量
    const [displayMore, setDisplayMore] = React.useState<number>(3);
    function handleMoreCard() {
        setDisplayMore(displayMore + 6);
    }

    //❤️按鈕
    const user = useUser();
    const dispatch = useDispatchWithType();
    const [changeBookMark] = useChangeBookMarkMutation();
    function handleClickFavorite(data: BoothCardInfoModel) {
        changeBookMark({ boothId: data.boothId }).unwrap()
            .then((result) => {
                if (result.isSuccess) {
                    dispatch(updateUser({ ...user, saveBoothIds: result.data }));
                }
            }).catch((error) => {
                console.log(error);
            });
    };

    //更多按鈕
    function handleClickShowMore(event: React.MouseEvent<HTMLElement>, data: BoothCardInfoModel) {
        event.preventDefault();
        history.push(`/Booth?id=${data.boothId}`);
    }

    if (withContainer) {
        return (<>
            {!justDisplay &&
                <Container sx={{ py: 1, mb: 2 }}>
                    <FilterAndSearch
                        filterCategory={filterCategory}
                        handleSelectChange={handleSelectChange}
                        handleChangeText={handleChangeText}
                    />
                </Container>
            }

            <Container>
                <HiddenableBoothList
                    booths={filteredBoothList}
                    displayMore={displayMore}
                    handleMoreCard={handleMoreCard}
                    handleClickFavorite={handleClickFavorite}
                    handleClickShowMore={handleClickShowMore}
                />
            </Container>
        </>)
    }
    else return (<>
        {!justDisplay &&
            <Box py={1} mb={2}>
                <FilterAndSearch
                    filterCategory={filterCategory}
                    handleSelectChange={handleSelectChange}
                    handleChangeText={handleChangeText}
                />
            </Box>
        }
        <HiddenableBoothList
            booths={filteredBoothList}
            displayMore={displayMore}
            handleMoreCard={handleMoreCard}
            handleClickFavorite={handleClickFavorite}
            handleClickShowMore={handleClickShowMore}
        />
    </>)
}

interface BoothListProps {
    boothList: BoothCardInfoModel[];
    category?: number;
    justDisplay?: boolean;
    withContainer?: boolean;
}

interface FilterAndSearchProps {
    filterCategory: number;
    handleSelectChange: (event: React.ChangeEvent<HTMLInputElement>)=>void;
    handleChangeText: (value: string)=>void;
}
function FilterAndSearch(props: FilterAndSearchProps) {
    const { filterCategory, handleSelectChange, handleChangeText } = props;
    const { t } = useTranslation();

    return (
        <Box display="grid" gridTemplateColumns={{ xs: "1fr", md: "1fr 400px" }} gap={1} py={1} mb={2}>
            <TextField
                select
                value={filterCategory}
                onChange={handleSelectChange}
                fullWidth
                sx={{}}
            >
                <MenuItem value={-1}>
                    {t('booth_type.all')}
                </MenuItem>
                {boothTypeList?.map((option) => (
                    <MenuItem key={option.type} value={option.type}>
                        {t(option.nameKey)}
                    </MenuItem>
                ))}
            </TextField>

            <SearchBar
                placeholder={t('boothList.search.placeholder')}
                onChange={(event) => handleChangeText(event.target.value)}
                onCancelSearch={() => handleChangeText("")}
                sx={{ xs: { width: "100%" }, md: { width: "400px" }, my: "auto", boxShadow: "none", border: "1px solid", borderColor: "rgba(0, 0, 0, 0.23)", p: "3px", "&:hover": { borderColor: "inherit" } }}
            />
        </Box>
    )
}

interface HiddenableBoothListProps {
    booths: BoothCardInfoModel[];
    displayMore: number;
    handleMoreCard: () => void;
    handleClickFavorite: (data: BoothCardInfoModel) => void;
    handleClickShowMore: (event: React.MouseEvent<HTMLElement>, data: BoothCardInfoModel) => void;
}
function HiddenableBoothList(props: HiddenableBoothListProps) {
    const { booths, displayMore, handleMoreCard, handleClickFavorite, handleClickShowMore } = props;
    const { t } = useTranslation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (<>
        <Box display="grid" gap={{ xs: 1, md: 2 }} gridTemplateColumns={{ xs: "1fr", md: "repeat(2, 1fr)" }}>
            {booths.map((booth, index) => {
                if (isMobile && index > displayMore) return null;
                else return (
                    <Box key={booth.boothId}>
                        <BoothCard
                            data={booth}
                            isOnline={booth.isOnline}
                            simpleMode
                            onClickFollow={handleClickFavorite}
                            onClickShowMore={handleClickShowMore}
                        />
                    </Box>
                )
            })}
        </Box>
        {isMobile && booths.length > displayMore &&
            <Stack py={2.5}>
                <Button variant="contained" color="secondary"
                    onClick={() => handleMoreCard()}
                    sx={{
                        textTransform: 'none',
                    }}
                >
                    <Typography variant='h6'>
                        {t('boothList.more')}
                    </Typography>
                </Button>
            </Stack>
        }
    </>)
}