﻿import * as React from 'react';
import { Button, ConfirmButton, Dialog, DialogActions, DialogContent, DialogTitle, ErrorText, important, TextField } from '../responsive-components';
import { Backdrop, Box, Checkbox, CircularProgress, Dialog as HintDialog, DialogActions as HintDialogActions, FormControlLabel, Grid, Stack, TextField as OriginField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { hideModal } from '../../store/rootReducer';
import { useDispatchWithType, useSystemConnection } from '../../store';
import { useApplyAppointmentMutation } from '../services/chat';
import { SystemControlHub } from '../react-signalr/systemControlHub';
import { DatePicker, LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import zhTW from "date-fns/locale/zh-TW";
import enUS from 'date-fns/locale/en-US';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faClock } from '@fortawesome/free-solid-svg-icons';
import { format, isToday } from 'date-fns';

type formData = {
    date: Date;
    time: Date;
    message: string;
}
export interface AppointmentModalProps {
    receiverId: string;
}
export function AppointmentModal(props: AppointmentModalProps) {
    const { receiverId } = props;
    const { t, i18n } = useTranslation();
    const dispatch = useDispatchWithType();

    const systemConnection = useSystemConnection();
    const hubRef = React.useRef<SystemControlHub>();

    const [open, setOpen] = React.useState(true);
    const [chatId, setChatId] = React.useState(null);
    React.useEffect(() => {
        if (systemConnection != null) {
            hubRef.current = new SystemControlHub(systemConnection);
            hubRef.current.invoke.GetChatId(receiverId).then((result) => {
                if (result.isSuccess) {
                    setChatId(result.data);
                }
            });
        }
    }, [systemConnection]);

    const schema = yup.object().shape({
        date: yup.date().nullable().typeError(t('dialog.appointment.error_invalid')).required(t('dialog.appointment.error_required'))
            .min(new Date(format(new Date(), "yyyy/MM/dd")), t('dialog.appointment.error_out_of_range')),
        time: yup.date().nullable().typeError(t('dialog.appointment.error_invalid')).required(t('dialog.appointment.error_required')).when(
            "date",
            (date: Date, schema) => (date && isToday(date)) ? schema.min(new Date(), t('dialog.appointment.error_out_of_range')) : schema),
        message: yup.string().max(50, t('dialog.appointment.error_limit'))
    });
    const { register, handleSubmit: handleOnSubmit, formState: { errors }, watch, control } = useForm<formData>({
        mode: "onBlur", resolver: yupResolver(schema),
        defaultValues: {
            date: new Date(),
            time: new Date(),
            message: '',
        }
    });
    const dateValue = watch("date");

    const [appointment, { isLoading }] = useApplyAppointmentMutation();
    function onSubmit(data: formData) {
        let date = format(data.date, "yyyy/MM/dd"), time = format(data.time, "kk:mm");
        let appointedTime = new Date(date + " " + time);
        appointment({ chatId: chatId, appointedTime: appointedTime, message: data.message }).unwrap()
            .then((result) => {
                //200 OK
                if (result.isSuccess) {
                    setOpen(false);
                } else {

                }
            })
            .catch((error) => {
                //後端報錯 ex:500、404
                console.error("發生錯誤", error)
            });
    }
    //優雅的關閉
    function onClose() {
        setOpen(false);
    }
    React.useEffect(() => {
        if (!open)
            setTimeout(() => {
                dispatch(hideModal());
            }, 300);
    }, [open]);

    const [openAsk, setOpenAsk] = React.useState(false);
    const handleCancel = () => {
        setOpenAsk(true);
    };

    return (
        <>
            <Dialog open={open} onClose={handleCancel} maxWidth={"sm"} fullWidth sx={{ px: 2, py: 1, /*"& .MuiDialog-paper": { borderRadius: "15px" }*/ }}>
                <DialogTitle>
                    <Box display="flex" alignItems="center">
                        <Box display="flex" width="22px" height="22px" justifyContent="center" alignItems="center" sx={{ color: 'primary.main', mr: 2 }}>
                            <FontAwesomeIcon icon={faClock} color="inherit" transform="shrink-8 down-3" mask={faCalendar} fixedWidth /></Box>
                        <Typography variant="h6" fontWeight="bold">{t('dialog.appointment.title')}</Typography>
                    </Box>
                </DialogTitle>
                <Box component="form" onSubmit={handleOnSubmit(onSubmit)}>
                    <DialogContent sx={{ py: 0 }}>
                        <Stack>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <Typography>{important}{t('dialog.appointment.date')}</Typography>
                                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={i18n.language == "ch" ? zhTW : enUS}>
                                        <Controller
                                            name="date"
                                            control={control}
                                            render={({ field }) => (
                                                <DatePicker
                                                    inputFormat="yyyy/MM/dd"
                                                    mask="____/__/__"
                                                    minDate={new Date(format(new Date(), "yyyy/MM/dd"))}
                                                    renderInput={(params) => <TextField
                                                        {...params}
                                                        error={!!errors.date}
                                                        helperText={errors.date ? errors.date.message : ' '}
                                                    />}
                                                    value={field.value}
                                                    onChange={(date) => field.onChange(date)}
                                                    disabled={isLoading}
                                                />
                                            )}
                                        />
                                    </LocalizationProvider >
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography>{important}{t('dialog.appointment.time')}</Typography>
                                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={i18n.language == "ch" ? zhTW : enUS}>
                                        <Controller
                                            name="time"
                                            control={control}
                                            render={({ field }) => (
                                                <TimePicker
                                                    ampm={false}
                                                    minTime={isToday(dateValue) ? new Date() : null}
                                                    renderInput={(params) => <TextField
                                                        {...params}
                                                        error={!!errors.time}
                                                        helperText={errors.time ? errors.time.message : ' '}
                                                    />}
                                                    value={field.value}
                                                    onChange={(time) => field.onChange(time)}
                                                    disabled={isLoading}
                                                    DialogProps={{
                                                        PaperProps: {
                                                            sx: { '& *:focus': { outline: 'none' } },
                                                        },
                                                    }}
                                                />
                                            )}
                                        />
                                    </LocalizationProvider >
                                </Grid>
                            </Grid>
                            <Typography>{t('dialog.appointment.message')}</Typography>
                            <TextField
                                variant="outlined"
                                inputRef={register('message').ref}
                                multiline
                                rows={4}
                                inputProps={{ maxLength: 50 }}
                                placeholder={t('dialog.appointment.limitNum')}
                                error={!!errors.message}
                                helperText={errors.message ? errors.message.message : ' '}
                                {...register("message")}
                                disabled={isLoading}
                            />

                            <FormControlLabel
                                control={
                                    <Checkbox defaultChecked
                                        required
                                    />
                                }
                                label={<>{important}{t('dialog.appointment.agreeInfo')}</>}
                            />
                            <Typography variant="caption" color="error.light">{t('dialog.appointment.notice_1')}</Typography>
                            <Typography variant="caption" color="error.light">{t('dialog.appointment.notice_2')}</Typography>
                        </Stack>
                    </DialogContent>
                    <DialogActions sx={{ justifyContent: 'flex-end' }}>
                        <ConfirmButton type="submit" loading={isLoading}>{t('dialog.appointment.submit')}</ConfirmButton>
                    </DialogActions>
                </Box>
                <HintDialog open={openAsk}>
                    <div style={{ padding: '2rem 2rem 1rem 2rem', textAlign: 'center', fontSize: '1rem' }} >
                        <span style={{ fontWeight: 'bold' }}>
                            {t('dialog.appointment.discardEdit')}
                        </span>
                    </div>
                    <HintDialogActions style={{ justifyContent: 'center' }}>
                        <Button autoFocus onClick={() => { setOpenAsk(false); onClose(); }}>{t('dialog.appointment.yes')}</Button>
                        <Button onClick={() => { setOpenAsk(false); }}>{t('dialog.appointment.no')}</Button>
                    </HintDialogActions>
                </HintDialog>
            </Dialog>
            {
                isLoading &&
                <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.modal + 1 }} open={isLoading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            }
        </>
    )
}